import styled from "styled-components";
import colors, { ConstantVariables } from "../../reusable/colorConstants";

export const DEFAULT_BACKGROUND_COLOR_HEADER = colors.WHITE;
export const DEFAULT_BACKGROUND_COLOR_BODY = colors.GREY_1;

export const DEFAULT_FONT_HEADER = "para-main semi-bold";
export const DEFAULT_FONT_BODY = "para-main semi-bold";
export const DEFAULT_FONT_HEADER_COLOR = colors.GREY_2;

export const DEFAULT_HEADER_HEIGHT = "3.5rem";
export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: red;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.DefaultHeaderBackgroundColor || DEFAULT_BACKGROUND_COLOR_HEADER};
  height: ${DEFAULT_HEADER_HEIGHT};
`;

export const HeaderTitle = styled.div`
  width: calc(100% / ${(props) => props.numCols});
  background-color: ${(props) =>
    props.style ? props.style.backgroundColor : "transparent"};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) =>
    props.sortActive &&
    `  svg{
      transform: rotate(180deg);
    }`}
`;
export const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.DefaultBodyBackgroundColor || DEFAULT_BACKGROUND_COLOR_BODY};
`;

export const RowItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ViewItem = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.width !== null ? props.width : `calc(100% / ${props.numCols})`};
`;
export const Detail = styled.div`
  width: 100%;
  display: none;
  ${(props) => props.visitable && "display: flex;flex-direction: column;"}
`;
