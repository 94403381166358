export default {
  api: {
    url: "https://dev-victory-api.bagang.ai/",
    dev: "https://dev-victory-api.bagang.ai/",
    TFA: 'https://2fa-dev.ecogiong.com/api/',
  },
  loopApi: {
    login: {
      url: 'https://apid.loop.vn/api/authentication/login',
    },
    loginToManagement:
      'https://apid.loop.vn/api/authentication/login-to-management',
    productSold: 'https://apid.loop.vn/api/statistic/products-sold',
  },
  warehouseLoopShopMap: [
    {
      id: 1, //kho tong
      shopId: '',
    },
    {
      id: 2, //HCM
      shopId: '9bfa4928-25d1-47d9-8809-998a6268b0d5',
    },
    {
      id: 3, //BD
      shopId: '0a6a31c0-3e37-42c3-a85c-7788d07a702e',
    },
  ],
}

export const NPP_KHANG_PHUC_CODE = 'NPPBD553';
export const NPP_KHANG_PHUC_DISCOUNT_PERCENT = 6.75;

export const NUOC_DAM_SEN_CODE = 'ATP568';
export const NUOC_DAM_SEN_DISCOUNT_PERCENT = 15;

export const firebaseConfigBD = {
  apiKey: 'AIzaSyBb1WeqLnVcVvPGAqNObH3SN8ZV6JOQWYY',
  authDomain: 'eco-giong.firebaseapp.com',
  databaseURL: 'https://eco-giong.firebaseio.com',
  projectId: 'eco-giong',
  storageBucket: 'eco-giong.appspot.com',
  messagingSenderId: '352848277484',
  appId: '1:352848277484:web:a663999123b9cf90291ac9',
}

export const firebaseConfigHCM = {
  apiKey: 'AIzaSyCYxL2QG7GQ-JLqMIed8SMI1YRUgENF5eQ',
  authDomain: 'eco-giong-prod.firebaseapp.com',
  databaseURL: 'https://eco-giong-prod.firebaseio.com',
  projectId: 'eco-giong-prod',
  storageBucket: 'eco-giong-prod.appspot.com',
  messagingSenderId: '111139335031',
  appId: '1:111139335031:web:a69ba30a3baca8299d0f72',
}

export const authoModuleTypes = {
  INVENTORY: 1,
  QUAN_LY_TON_KHO: 3,
  KIEM_KE: 4,
  HAO_HUT: 5,
  SAN_XUAT: 6,
  DE_NGHI_DAT_HANG: 7,
  DAT_HANG: 8,
  NHAP_HANG: 9,
  CHUYEN_HANG: 10,
  HUY_HANG: 11,
  TON_KHO: 13,
  DU_LIEU_GOC: 14,
  CATEGORY: 15,
  DON_VI_DO_LUONG: 16,
  HANG_HOA: 17,
  NHAN_HIEU: 18,
  HANG_HOA_VOI_NHAN_HIEU: 19,
  QUY_DOI: 20,
  CONG_THUC: 21,
  KHU_VUC: 22,
  KHO: 23,
  NHA_CUNG_CAP: 24,
  NHA_SAN_XUAT: 25,
  POS: 26,
  QUAN_LY_BAN_HANG: 27,
  QUAN_LY_DOANH_THU: 28,
  CAU_HINH_POS: 29,
  BILL_KHACH_HANG: 30,
  BAN_HANG: 32,
  'MON_AN/DANH_MUC': 33,
  COMBO: 34,
  DOANH_THU_NGAY: 35,
  DOANH_THU_GIO: 36,
  GIAO_HANG: 37,
  CA: 38,
  GIAM_GIA: 39,
  GIAO_DICH: 40,
  THANH_VIEN: 41,
  THONG_KE: 42,
  CHI_TIEU: 43,
  NAP_TIEN: 44,
  VOUCHER: 45,
  QUA_TANG: 46,
  BAGANG: 47,
  QUAN_LY_FEEDBACK: 48,
  HRMS: 49,
  UPLOAD_DUTY_ROSTER: 50,
  REPORT_CHAM_CONG: 51,
  PICKUP: 52,
  BAO_CAO: 53,
  BINH_DUONG: 54,
  HO_CHI_MINH: 55,
}

export const voucherOrderStatus = {
  PENDING: 1,
  IN_APPROVAL_PROCESS: 2,
  APPROVED: 3,
  USED: 6,
  ON_HOLD: 7,
  REJECTED: 8,
  CANCELLED: 9,
  DOING: 10,
  WAIT_FOR_DELIVERY: 11,
  DELIVERED: 12,

  PUSHED_TO_SHIPPER_APP: 13,
}

export const APPS = {
  CO_UT: 2,
}
