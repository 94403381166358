import { call, put, takeEvery, all } from 'redux-saga/effects'
import { fetchMaterial, fetchActiveMaterial, createNewMaterial, updateMaterial, fetchProduct, hideProductApi, fetchGetMaterialDataDetailApi, updateProductUnitPriceApi, getAllActiveMaterialWithWarehouse, getAllActiveMaterialTPWithWarehouse, fetchMaterialPagination, fetchAllAppStoreMaterials, fetchActiveMaterialsTP, createMaterialAppStores, fetchMaterialStoreAppDetail, updateMaterialStoreApps, deleteMaterialAppStores } from './api/materialApi'
import { fetchSite, fetchSitePagination, updateSiteApi } from './api/siteApi'
import { fetchMaterialBrand, createNewMaterialBrand, fetchMaterialBrandPagination, updateMaterialBrands } from './api/materialBrandApi'
import { fetchConversion, createNewConversion, updateConversion, fetchConversionPagination } from './api/conversionApi'
import { fetchFormula, createNewFormula, fetchFormulaDetailApi, callDeleteFormulaApi, fetchStoreFormulaApi, getUomOfMaterialApi, fetchFormulaPagination, updateFormulaApi } from './api/formulaApi'
import { createWarehouseApi, fetchAllWarehouse, fetchWarehouse, fetchWarehousePagination, updateWarehouse } from './api/warehouseApi'
import { fetchSupplier, createNewSupplier } from './api/supplierApi'
import { fetchManufacturer, createNewManufacturer } from './api/manufacturerApi'

import { createGoodsReceivedApi, fetchGoodsReceivedsApi, fetchGoodsReceivedDetailApi, fetchGoodsReceivedsV2, updateGoodsReceivedApi, deleteGoodsReceived } from './api/goodsReceivedApi'
import { createStockTransferApi, fetchStockTransfersApi, fetchStockTransferDetailApi, fetchStockTransferByFilter } from './api/stockTransferApi'
import { createStockRequestApi, fetchStockRequestsApi, fetchStockRequestDetailApi, fetchStockRequestsByWarehouseApi, updateStockRequestApi, updateStockRequestApiV3, fetchListStatusStockRequest, fetchListMateritalFilter, fetchStockRequestByFilter, updateStockRequestV2, deleteStockRequest, updateStatusStockRequest, fetchInventoryManageByFilter, fetchInventoryCheckByFilter, createNewInventoryCheck,fetchStockCheckDetailApi, updateCheckRequest, fetchInventoryLostByFilter, fetchListMateritalFilterByCategory } from './api/stockRequestApi'

import { fetchBrandByMaterialId, fetchAllBrands, createNewBrand, fetchAllBrandsPagination, updateNewBrandApi } from './api/brandApi'
import { fetchUomByMaterialAndBrandId, fetchAllUoms, createNewUom, updateUom, fetchAllUomsPagination, fetchUomByMaterial } from './api/uomApi'
import { fetchInventoryCheckStockApi, fetchInventoryLostStockApi, fetchWarehouseStockApi } from './api/stockApi'
import { fetchMaterialInstancesByWarehouseApi, createStockDestructionApi, fetchStockDestructionsApi, fetchStockDestructionDetailApi, updateStockDestructionApi, updateStatusStockDestruction } from './api/stockDestructionApi'
import { fetchCategory, updateCategory, createNewCategory, fetchParentCategory, updateCategoryOrderByApi, fetchCategoryPagination } from './api/categoryApi'
import { fetchStockTakeApi, fetchStockTakeDetailApi, createStockTakeApi, approveStockTakeApi, approveRequestSend } from './api/stockTakeApi'
import { fetchVoucherOrders, fetchPOSVoucher, fetchAppVoucher, fetchCategoriesFilter, fetchMaterialsFilter, fetchMaterialRedeemVoucher, cancelVoucherOrders, approveVoucherOrders, fetchPOSVoucherDetail, fetchAppVoucherDetail, createPOSVoucher, updatePOSVoucher, createAppVoucher, updateAppVoucher,  updateStatusPOSVoucher, fetchListUserVoucher, updateStatusAppVoucher, createRedeemVoucher, updateRedeemVoucher, updateStatusRedeemVoucher, approveVoucherOrdersV2, getDiscountItemsApi, createNewVoucherOrderApi, updateVoucherOrderApi } from './api/voucherApi';
import { fetchShipperListApi, setStatusShipper } from './api/shipperApi';

import {
  fetchLoopDataApi, fetchLoopTokenApi, fetchLoopManagementTokenApi,
  fetchLoopTransactionsApi, createNewLoopGetDataApi, fetchGetLoopDataDetailApi,
  fetchStockReconcilesApi, createStockReconcileApi, fetchStockReconcileDetailApi
} from './api/stockReconcileApi'

import { createStockBalanceApi, fetchStockBalancesApi, fetchStockBalanceDetailApi } from './api/stockBalanceApi'

import { fetchSalesReportApi, fetchOrdersApi, approveBillApi, cancelBillApi, getStorePOSInfoApi, updateStoreApi, fetchSalesFilterReportApi, fetchGetSaleDataDetailApi, setOrderStatusApi, fetchOrderDetailApi } from './api/salesApi'
import { fetchFeedbackDataApi, fetchGetFeedbackDataDetailApi, createFeedbackApi } from './api/feedbackApi'
import { fetchStore, fetchStoreUId, fetchStoreUIdV2 } from './api/storeApi'
import { createPayslipApi, createScheduleApi, fetchCashInReportApi, fetchPayslipDetailsApi, fetchPayslipMasterApi, fetchScheduleDetail, fetchScheduleReportApi, fetchUploadMastersApi, sendEmailPayslipApi, fetchAllStaffMasterApi, updateCashInReportApi, uploadPaySlipVictory, fetchMonthPaySlip, fetchPaySlipDetail } from './api/shiftApi'
import { fetchStockRequestPendingByIdApi, createPurchaseOrderApi, deletePurchaseOrderApi, submitPurchaseOrderApi, fetchPurchaseOrderApi, fetchPurchaseOrderDetailApi, fetchStockRequestPendingApi, fetchPurchaseOrderByFilter, fetchListStatusPurchaseOrder, fetchPurchaseOrderById } from './api/purchaseOrderApi'
import { approveRequestApi, approveTransactionApi, sendEmailPayslipVictory } from './api/workFlowApi'
import { createGoodsProduceApi, fetchGoodsProducesApi, fetchGoodsProducesApiV2,  getAllStatusGoodsProduce, fetchGoodsProduceDetailApi, fetchGoodsProduceReportApi } from './api/goodsProduceApi'
import { workFlowTypes } from './reusable/workFlowTypes'
import { changeNameFolderApi, changeNameFileApi, createFolderApi, deleteFileApi, deleteFolderApi, fetchFoldersApi, uploadFileApi, uploadFolderApi, pinNotifyApi, fecthGeneralInfoHRM } from './api/handbookApi'

import { fetchMaterialSalesReportApi } from "./api/reportApi";
import { createNewSRProduce, fetchSRProduceApi, getSRProduceById, updateStatusSRProduce, updateSRProduce } from './api/SRProduceApi';
import { fetchSRDeliverApi, newSRDeliverApi, fetchSRDeliverById, updateSEDeliver, fetchListDriverWithPlateApi, fetchDriversApi, createNewDriversApi, fetchDriversByIdApi, updateDriversApi } from './api/SRDeliverApi';
import { fetchSRReceivedApi, createNewSRReceived, fetchSRReceivedById } from './api/SRReceivedApi';
import { fetchCommodityDeliveryApi, createNewCmdDelivery, fetchCmdDeliveryById } from './api/commodityDeliveryApi';
import { fetchCommodityCancelationApi, createNewCmdCancelation, fetchCmdCancelationById } from './api/commodityCancelApi';
import { fetchGoodsReturningApi, createNewGoodsReturn, fetchGoodsReturnById } from './api/goodsReturnApi';
import { 
  fetchVoucherSaleOrdersApi, fetchSaleOrderApi, 
  fetchSaleOrderById, fetchVoucherSaleOrderToDeliver,
  fetchListProductionDiaryApi, createNewProductionDiaryApi,
  fetchProductionDiaryByIdApi, updateProductionDiaryApi
 } from './api/saleOrderApi';
/* REVENUE */
import { fetchRevenueMonth, fetchRevenueWeek, fetchRevenueDate, fetchRevenueQuarter, fetchRevenueYear, fetchRevenueGeneral, fecthRevenueHour, fecthRevenueCategory, fecthRevenueCombo, fecthGetTotalFoodSold, fecthRevenueShift, fecthRevenueShiftDetail, fecthRevenueServiceMode, fecthRevenuePayments } from './api/revenueApi'
/* EMPLOYEE */
import {
  fetchEmployee,
  fetchEmployeeDetail,
  createNewEmployeeApi,
  updateEmployeeApi,
  updateAvatarEmployeeApi,
  updateUserStatusEmployeeApi,
} from './api/employeeApi'
import { fetchTitleApi } from './api/titleApi'
import { fetchBranchApi } from './api/branchApi'
import { fetchDepartmentApi } from './api/departmentApi'
import { fetchTypeContractApi } from './api/typeContractApi'
import { fetchLevelAbilityApi } from './api/levelAbilityApi'
import { fetchReasonRecruitmentApi } from './api/reasonRecruitmentApi'
import { fetchWorkHourApi } from './api/workHourApi'
import { fetchReasonEndApi } from './api/reasonEndApi'
import { fetchTypeEndApi } from './api/typeEndApi'

/* WEBGROUP */
import {
  fetchWebGroup,
  fetchMenuByWebGroup,
  fetchStaffByWebGroup,
  createUserGroupPermissionsByWebGroupIdApi,
  fetchDataAccessStoreByWebGroupId,
  fetchDataAccessWarehouseByWebGroupId,
  createUserGroupDataAccessApi,
  fetchAppByWebGroup,
  updateUserGroupAppApi,
  createWebGroupApi,
  updateWebGroupApi,
} from './api/webGroupApi';

import {fecthPOSFileSetting, fecthAllPOSFile, fetchApp, getProductsWithCustomerApi } from './api/appApi'

import {
  fetchOrgChart,
  createOrgChartApi,
  fetchListEmployee,
} from "./api/orgApi";
import { fetchAllPaymentType, fetchAllServiceMode, fetchAllStatusPayment, fetchAllStatusTransaction, getAllTransactionAsync, getAllTransactionV2Async, getInfiniteTransactionV2Async, getTransactionV2ById} from './api/portalRevenueApi'

import orderSaga from 'src/sagas/order/orderSaga'
import goodsTransferSaga from 'src/sagas/goodsTransfer/goodsTransferSaga'
import goodsDestructionSaga from 'src/sagas/goodsDestruction/goodsDestructionSaga'
import inventoryStockSaga from 'src/sagas/inventoryStock/inventoryStock'


import { fetchPosContentSettingApi, fetchPosContentTypeApi, fetchPosFileSettingApi, setPosContentTypeApi } from './api/configPosDataApi'
import { fetchAllCustomer, fecthAllCustomerGroup, createNewCustomer, fecthDetailsCustomer, updateCustomer, createNewGroupCustomerApi, fecthDetailsGroupCustomerApi, updateGroupCustomerApi } from './api/customerApi';
import { fetchGoodTransferDetailApi, fetchGoodTransferListApi } from './api/GoodTransferApi'

function* loadCategory(action) {
  try {
    yield put({ type: "SHOW_CATEGORY_LOADING" });
    let res = yield call(fetchCategory)
    yield put({ type: "FETCH_CATEGORY_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_CATEGORY_FAILED", payload: e.message });
  }
}
function* loadCategoryPagination(action) {
  try {
    yield put({ type: "SHOW_CATEGORY_LOADING" });
    let res = yield call(fetchCategoryPagination,action.payload)
    yield put({ type: "FETCH_CATEGORY_PAGINATION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_CATEGORY_PAGINATION_FAILED", payload: e.message });
  }
}
function* loadParentCategories(action) {
  try {
    yield put({ type: "SHOW_CATEGORY_LOADING" })
    let res = yield call(fetchParentCategory, action.payload)
    yield put({ type: "FETCH_PARENT_CATEGORY_SUCCEEDED", payload: res.data })
  } catch (e) {
    yield put({ type: "FETCH_PARENT_CATEGORY_FAILED", payload: e.message })
  }
}

function* loadMaterial(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(fetchMaterial)
    yield put({ type: "FETCH_MATERIAL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_FAILED", payload: e.message });
  }
}
function* loadMaterialPagination(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(fetchMaterialPagination, action.payload)
    yield put({ type: "FETCH_MATERIAL_PAGINATION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_PAGINATION_FAILED", payload: e.message });
  }
}
function* loadAllActiveMaterialWarehouse(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(getAllActiveMaterialWithWarehouse, action.payload);
    yield put({ type: "FETCH_MATERIAL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_FAILED", payload: e.message });
  }
}

function* loadAllActiveMaterialTPWarehouse(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(getAllActiveMaterialTPWithWarehouse, action.payload);
    yield put({ type: "FETCH_MATERIAL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_FAILED", payload: e.message });
  }
}

function* loadActiveMaterial(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    yield put({ type: "SHOW_ALL_ACTIVE_MATERIAL_LOADING" });
    let res = yield call(fetchActiveMaterial)
    yield put({ type: "FETCH_ALL_ACTIVE_MATERIAL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_FAILED", payload: e.message });
  }
}

function* loadActiveMaterialTP() {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(fetchActiveMaterialsTP)
    yield put({ type: "FETCH_APP_STORE_MATERIAL_TP_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_APP_STORE_MATERIAL_TP_FAILED", payload: e.message });
  }
}

function* loadGetMaterialDataDetail(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(fetchGetMaterialDataDetailApi, action.payload)
    yield put({ type: "FETCH_MATERIAL_DETAIL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_DETAIL_FAILED", payload: e.message });
  }
}
function* loadProduct(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(fetchProduct)
    yield put({ type: "FETCH_PRODUCT_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_PRODUCT_FAILED", payload: e.message });
  }
}

function* loadFormula(action) {
  try {
    yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(fetchFormula, action.payload);
    yield put({ type: "FETCH_FORMULA_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_FORMULA_FAILED", payload: e.message });
  }
}
function* loadFormulaPagination(action) {
  try {
    yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(fetchFormulaPagination, action.payload);
    yield put({ type: "FETCH_FORMULA_PAGINATION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_FORMULA_PAGINATION_FAILED", payload: e.message });
  }
}
function* loadStoreFormula() {
  try {
    yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(fetchStoreFormulaApi)
    yield put({ type: "LOAD_STORE_FORMULA_SUCCEEDED", payload: res.data })
  } catch (e) {
    yield put({ type: "LOAD_STORE_FORMULA_FAILED", payload: e.message })
  }
}

function* loadMaterialBrand(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_BRAND_LOADING" });
    let res = yield call(fetchMaterialBrand)
    yield put({ type: "FETCH_MATERIAL_BRAND_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_BRAND_FAILED", payload: e.message });
  }
}
function* loadMaterialBrandPagination(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_BRAND_LOADING" });
    let res = yield call(fetchMaterialBrandPagination, action.payload)
    yield put({ type: "FETCH_MATERIAL_BRAND_PAGINATION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_BRAND_PAGINATION_FAILED", payload: e.message });
  }
}
function* loadConversion(action) {
  try {
    yield put({ type: "SHOW_CONVERSION_LOADING" });
    let res = yield call(fetchConversion)
    yield put({ type: "FETCH_CONVERSION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_CONVERSION_FAILED", payload: e.message });
  }
}
function* loadConversionPagination(action) {
  try {
    yield put({ type: "SHOW_CONVERSION_LOADING" });
    let res = yield call(fetchConversionPagination, action.payload)
    yield put({ type: "FETCH_CONVERSION_PAGINATION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_CONVERSION_PAGINATION_FAILED", payload: e.message });
  }
}
function* loadWarehouse(action) {
  try {
    yield put({ type: "SHOW_WAREHOUSE_LOADING" });
    let res = yield call(fetchWarehouse, action.payload)
    yield put({ type: "FETCH_WAREHOUSE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_WAREHOUSE_FAILED", payload: e.message });
  }
}
function* loadWarehousePagination(action) {
  try {
    yield put({ type: "SHOW_WAREHOUSE_LOADING" });
    let res = yield call(fetchWarehousePagination, action.payload)
    yield put({ type: "FETCH_WAREHOUSE_PAGINATION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_WAREHOUSE_PAGINATION_FAILED", payload: e.message });
  }
}
function* loadAllWarehouse(action) {
  try {
    yield put({ type: "SHOW_STOCK_LOADING" });
    let res = yield call(fetchAllWarehouse, action.payload);
    yield put({ type: "FETCH_ALL_WAREHOUSE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_WAREHOUSE_FAILED", payload: e.message });
  }
}

// function* updateWarehouse(action) {
//   try {
//     console.log("HERE",action.payload)
//     yield put({ type: "FETCH_WAREHOUSE_DETAIL_SUCCEEDED", payload: action.payload });
//   } catch (e) {
//     yield put({ type: "FETCH_WAREHOUSE_DETAIL_FAILED", payload: e.message });
//   }
// }
function* loadStore(action) {
  try {
    yield put({ type: "SHOW_STORE_LOADING" });
    let res = yield call(fetchStore)
    yield put({ type: "FETCH_STORE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_STORE_FAILED", payload: e.message });
  }
}

function* loadStoreUId(action) {
  try {
    yield put({ type: "SHOW_STORE_UID_LOADING" });
    let res = yield call(fetchStoreUId, action.payload)
    yield put({ type: "FETCH_STORE_UID_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_STORE_UID_FAILED", payload: e.message });
  }
}

function* loadStoreUIdV2(action) {
  try {
    yield put({ type: "SHOW_STORE_UID_LOADING" });
    let res = yield call(fetchStoreUIdV2, action.payload)
    yield put({ type: "FETCH_STORE_UID_V2_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_STORE_UID_V2_FAILED", payload: e.message });
  }
}


function* loadSupplier(action) {
  try {
    yield put({ type: "SHOW_SUPPLIER_LOADING" });
    let res = yield call(fetchSupplier)
    yield put({ type: "FETCH_SUPPLIER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SUPPLIER_FAILED", payload: e.message });
  }
}

function* loadManufacturer(action) {
  try {
    yield put({ type: "SHOW_MANUFACTURER_LOADING" });
    let res = yield call(fetchManufacturer)
    yield put({ type: "FETCH_MANUFACTURER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_MANUFACTURER_FAILED", payload: e.message });
  }
}

function* loadAllUoms(action) {
  try {
    yield put({ type: "SHOW_UOM_LOADING" });
    let res = yield call(fetchAllUoms)
    yield put({ type: "FETCH_ALL_UOMS_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_UOMS_FAILED", payload: e.message });
  }
}
function* loadAllUomsPagination(action) {
  try {
    yield put({ type: "SHOW_UOM_LOADING" });
    let res = yield call(fetchAllUomsPagination, action.payload)
    yield put({ type: "FETCH_ALL_UOMS_SUCCEEDED_PAGINATION", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_UOMS_FAILED_PAGINATION", payload: e.message });
  }
}
function* submitNewUom(action) {
  try {
    yield put({ type: "SHOW_UOM_LOADING" });
    let res = yield call(createNewUom, action.payload)
    yield put({ type: "SAVE_NEW_UOM_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_UOM_FAILED", payload: e.message });
  }
}

function* callUpdateUom(action) {
  try {
    yield put({ type: "SHOW_UOM_LOADING" });
    let res = yield call(updateUom, action.payload)
    yield put({ type: "UPDATE_UOM_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_UOM_FAILED", payload: e.message });
  }
}
function* callUpdateWarehouse(action) {
  try {
    yield put({ type: "SHOW_WAREHOUSE_LOADING" });
    let res = yield call(updateWarehouse, action.payload)
    yield put({ type: "UPDATE_WAREHOUSE_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_WAREHOUSE_FAILED", payload: e.message });
  }
}

function* submitNewMaterial(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(createNewMaterial, action.payload)
    yield put({ type: "SAVE_NEW_MATERIAL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_MATERIAL_FAILED", payload: e.message });
  }
}

function* createdNewMaterialAppStores(action) {
  try {
    let res = yield call(createMaterialAppStores, action.payload)
    yield put({ type: "SUMIT_CREATE_MATERIAL_APP_STORE_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUMIT_CREATE_MATERIAL_APP_STORE_FAILED", payload: e.message });
  }
}

function* callUpdateMaterial(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(updateMaterial, action.payload)
    yield put({ type: "UPDATE_MATERIAL_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_MATERIAL_FAILED", payload: e.message });
  }
}

function* submitNewConversion(action) {
  try {
    yield put({ type: "SHOW_CONVERSION_LOADING" });
    let res = yield call(createNewConversion, action.payload)
    yield put({ type: "SAVE_NEW_CONVERSION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_CONVERSION_FAILED", payload: e.message });
  }
}

function* submitNewFormula(action) {
  try {
    yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(createNewFormula, action.payload)
    yield put({ type: "SAVE_NEW_FORMULA_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_FORMULA_FAILED", payload: e.message });
  }
}
function* updateFormula(action) {
  try {
    yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(updateFormulaApi, action.payload)
    yield put({ type: "UPDATE_FORMULA_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_FORMULA_FAILED", payload: e.message });
  }
}
function* callUpdateCategory(action) {
  try {
    yield put({ type: "SHOW_CATEGORY_LOADING" });
    let res = yield call(updateCategory, action.payload)
    yield put({ type: "UPDATE_CATEGORY_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_CATEGORY_FAILED", payload: e.message });
  }
}

function* callCreateWarehouse(action) {
  try {
    yield put({ type: "SHOW_WAREHOUSE_LOADING" });
    let res = yield call(createWarehouseApi, action.payload);
    yield put({ type: "CREATE_WAREHOUSE_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "CREATE_WAREHOUSE_FAILED", payload: e.message });
  }
}

function* submitNewMaterialBrand(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_BRAND_LOADING" });
    let res = yield call(createNewMaterialBrand, action.payload)
    yield put({ type: "SAVE_NEW_MATERIAL_BRAND_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_MATERIAL_BRAND_FAILED", payload: e.message });
  }
}
function* updateMaterialBrand(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_BRAND_LOADING" });
    let res = yield call(updateMaterialBrands, action.payload)
    yield put({ type: "UPDATE_MATERIAL_BRAND_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_MATERIAL_BRAND_FAILED", payload: e.message });
  }
}
function* submitNewCategory(action) {
  try {
    yield put({ type: "SHOW_CATEGORY_LOADING" });
    let res = yield call(createNewCategory, action.payload)
    yield put({ type: "SAVE_NEW_CATEGORY_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_CATEGORY_FAILED", payload: e.message });
  }
}

function* loadAllBrands(action) {
  try {
    yield put({ type: "SHOW_BRAND_LOADING" });
    let res = yield call(fetchAllBrands)
    yield put({ type: "FETCH_ALL_BRANDS_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_BRANDS_FAILED", payload: e.message });
  }
}
function* loadAllBrandsPagination(action) {
  try {
    yield put({ type: "SHOW_BRAND_LOADING" });
    let res = yield call(fetchAllBrandsPagination, action.payload)
    yield put({ type: "FETCH_ALL_BRANDS_PAGINATION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_BRANDS_PAGINATION_FAILED", payload: e.message });
  }
}

function* submitNewBrand(action) {
  try {
    yield put({ type: "SHOW_BRAND_LOADING" });
    let res = yield call(createNewBrand, action.payload)
    yield put({ type: "SAVE_NEW_BRAND_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_BRAND_FAILED", payload: e.message });
  }
}
function* updateNewBrand(action) {
  try {
    yield put({ type: "SHOW_BRAND_LOADING" });
    let res = yield call(updateNewBrandApi, action.payload)
    yield put({ type: "UPDATE_NEW_BRAND_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_NEW_BRAND_FAILED", payload: e.message });
  }
}
function* submitNewSupplier(action) {
  try {
    yield put({ type: "SHOW_SUPPLIER_LOADING" });
    let res = yield call(createNewSupplier, action.payload)
    yield put({ type: "SAVE_NEW_SUPPLIER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_SUPPLIER_FAILED", payload: e.message });
  }
}

function* submitNewManufacturer(action) {
  try {
    yield put({ type: "SHOW_MANUFACTURER_LOADING" });
    let res = yield call(createNewManufacturer, action.payload)
    yield put({ type: "SAVE_NEW_MANUFACTURER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SAVE_NEW_MANUFACTURER_FAILED", payload: e.message });
  }
}

function* loadBrandByMaterialId(action) {
  try {
    let res = yield call(fetchBrandByMaterialId, action.payload)
    yield put({ type: "FETCH_BRAND_BY_MATERIAL_SUCCEEDED", payload: res.data });
    yield put({ type: "CLEAR_OUMS" })
  } catch (e) {
    yield put({ type: "FETCH_BRAND_BY_MATERIAL_FAILED", payload: e.message });
  }
}

function* submitNewGoodsReceived(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(createGoodsReceivedApi, action.payload)
    if (res) {
      let workFlowPayload = {
        WorkflowTypeId: workFlowTypes.GOODS_RECEIVED,
        TransactionId: res.data.data.id,
        CreatedBy: action.payload.CreatedBy
      }
      let workFlowRes = yield call(approveRequestApi, workFlowPayload);
    }
    yield put({ type: "SUBMIT_NEW_GOODS_RECEIVE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_GOODS_RECEIVE_FAILED", payload: e.message });
  }
}

function* approveGoodsReceived(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(approveRequestApi, action.payload);
    yield put({ type: "SUBMIT_GOODS_RECEIVED_WORK_FLOW_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_GOODS_RECEIVED_WORK_FLOW_FAILED", payload: e.message });
  }
}

function* submitNewGoodsReceivedV2(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(createGoodsReceivedApi, action.payload);
    yield put({ type: "SUBMIT_NEW_GOODS_RECEIVE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_GOODS_RECEIVE_FAILED", payload: e.message });
  }
}

function* updateGoodsReceived(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(updateGoodsReceivedApi, action.payload);
    yield put({ type: "UPDATE_GOODS_RECEIVE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_GOODS_RECEIVE_FAILED", payload: e.message });
  }
}



function* submitNewGoodsProduce(action) {
  try {
    yield put({ type: "SHOW_GOODS_PRODUCE_LOADING" });
    let res = yield call(createGoodsProduceApi, action.payload);
    if (res) {
      let workFlowPayload = {
        WorkflowTypeId: workFlowTypes.GOODS_PRODUCE,
        TransactionId: res.data.data.id,
        CreatedBy: action.payload.CreatedBy
      }
      let workFlowRes = yield call(approveRequestApi, workFlowPayload);
    }
    yield put({
      type: "SUBMIT_NEW_GOODS_PRODUCE_SUCCEEDED",
      payload: res.data,
    });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_GOODS_PRODUCE_FAILED", payload: e.message });
  }
}

function* loadGoodsProduces(action) {
  try {
    yield put({ type: "SHOW_GOODS_PRODUCE_LOADING" });
    let res = yield call(fetchGoodsProducesApi, action.payload);
    yield put({
      type: "FETCH_GOODS_PRODUCES_SUCCEEDED",
      payload: res.data.data,
    });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_PRODUCES_FAILED", payload: e.message });
  }
}

function* loadGoodsProducesV2(action) {
  try {
    yield put({ type: "SHOW_GOODS_PRODUCE_LOADING" });
    let res = yield call(fetchGoodsProducesApiV2, action.payload);
    yield put({
      type: "FETCH_GOODS_PRODUCES_SUCCEEDED",
      payload: res.data.data,
    });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_PRODUCES_FAILED", payload: e.message });
  }
}



function* loadAllStatusGoodsProduce() {
  try {
    let res = yield call(getAllStatusGoodsProduce);
    yield put({
      type: "SET_STATUS_LIST",
      payload: res.data.data,
    });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_PRODUCES_FAILED", payload: e.message });
  }
}



function* loadGoodsProduceDetail(action) {
  try {
    yield put({ type: "SHOW_GOODS_PRODUCE_LOADING" });
    let res = yield call(fetchGoodsProduceDetailApi, action.payload);
    yield put({
      type: "FETCH_GOODS_PRODUCE_DETAIL_SUCCEEDED",
      payload: res.data.data,
    });
  } catch (e) {
    yield put({
      type: "FETCH_GOODS_PRODUCE_DETAIL_FAILED",
      payload: e.message,
    });
  }
}

function* submitNewStockRequest(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(createStockRequestApi, action.payload);
    if (res) {
      let workFlowPayload = {
        WorkflowTypeId: workFlowTypes.STOCK_REQUEST,
        TransactionId: res.data.data.id,
        CreatedBy: action.payload.UserId
      }
      let workFlowRes = yield call(approveRequestApi, workFlowPayload);
    }

    yield put({ type: "SUBMIT_NEW_STOCK_REQUEST_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* newStockRequest(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(createStockRequestApi, action.payload);
    yield put({ type: "SUBMIT_NEW_STOCK_REQUEST_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* approveRequest(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let workFlowPayload = {
      WorkflowTypeId: workFlowTypes.STOCK_REQUEST,
      TransactionId: action.payload.transactionId,
      CreatedBy: action.payload.userId,
    }
    yield call(approveRequestApi, workFlowPayload);
    yield put({ type: "SET_OFF_LOADING"});
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* updateStockRequest(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(updateStockRequestApi, action.payload);

    yield put({ type: "UPDATE_STOCK_REQUEST_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* updateStockRequestApiV2(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(updateStockRequestV2, action.payload);

    yield put({ type: "UPDATE_STOCK_REQUEST_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* updateStockRequestApi_V3(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(updateStockRequestApiV3, action.payload);

    yield put({ type: "UPDATE_STOCK_REQUEST_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* deleteStockRequestApi(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    yield call(deleteStockRequest, action.payload);
    yield put({ type: "SET_DELETED_SUCCESS_STOCK_REQUEST", payload: null });
  } catch (e) {
    yield put({ type: "UPDATE_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* updateStockRequestStatus(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    yield call(updateStatusStockRequest, action.payload);
    yield put({ type: "UPDATE_STOCK_REQUEST_SUCCEEDED", payload: null });
  } catch (e) {
    yield put({ type: "UPDATE_STOCK_REQUEST_FAILED", payload: e.message });
  }
}



function* submitNewPurchaseOrder(action) {
  try {
    yield put({ type: "SHOW_PURCHASE_ORDER_LOADING" });
    let res = yield call(createPurchaseOrderApi, action.payload)
    if (res) {
      let workFlowPayload = {
        WorkflowTypeId: workFlowTypes.PURCHASE_ORDER,
        TransactionId: res.data.data.id,
        CreatedBy: action.payload.CreatedBy
      };
      let workFlowRes = yield call(approveRequestApi, workFlowPayload);
    }
    yield put({ type: "SUBMIT_NEW_PURCHASE_ORDER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_PURCHASE_ORDER_FAILED", payload: e.message });
  }
}

function* submitNewStockTransfer(action) {
  try {
    yield put({ type: "SHOW_STOCK_TRANSFER_LOADING" });
    let res = yield call(createStockTransferApi, action.payload)
    if (res) {
      let workFlowPayload = {
        WorkflowTypeId: workFlowTypes.STOCK_TRANSFER,
        TransactionId: res.data.data.id,
        CreatedBy: action.payload.CreatedBy
      };
      let workFlowRes = yield call(approveRequestApi, workFlowPayload);
    }
    yield put({ type: "SUBMIT_NEW_STOCK_TRANSFER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_TRANSFER_FAILED", payload: e.message });
  }
}

function* loadUomByMaterialAndBrandId(action) {
  try {
    let res = yield call(fetchUomByMaterialAndBrandId, action.payload)
    yield put({ type: "FETCH_UOM_BY_MATERIAL_BRAND_SUCCEEDED", payload: res.data });
    yield put({ type: "UPDATE_MODAL_BRAND", payload: { id: action.payload.brandId, name: action.payload.brandName } });
  } catch (e) {
    yield put({ type: "FETCH_UOM_BY_MATERIAL_BRAND_FAILED", payload: e.message });
  }
}
function* loadUomByMaterial(action) {
  try {
    let res = yield call(fetchUomByMaterial, action.payload)
    yield put({ type: "FETCH_UOM_BY_MATERIAL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_UOM_BY_MATERIAL_FAILED", payload: e.message });
  }
}
function* loadGoodsReceiveds(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(fetchGoodsReceivedsApi, action.payload)
    yield put({ type: "FETCH_GOODS_RECEIVEDS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_RECEIVEDS_FAILED", payload: e.message });
  }
}

function* loadGoodsReceivedsV2(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(fetchGoodsReceivedsV2, action.payload);
    yield put({ type: "FETCH_GOODS_RECEIVEDS_SUCCEEDED_V2", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_RECEIVEDS_FAILED_V2", payload: e.message });
  }
}


function* deleteGoodsReceivedV2(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(deleteGoodsReceived, action.payload);
    yield put({ type: "DELETE_GOODS_RECEIVE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "DELETE_GOODS_RECEIVE_FAILED", payload: e.message });
  }
}


function* loadGoodsReceivedDetail(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(fetchGoodsReceivedDetailApi, action.payload)
    yield put({ type: "FETCH_GOODS_RECEIVED_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_RECEIVED_DETAIL_FAILED", payload: e.message });
  }
}

function* loadStockTakeDetail(action) {
  try {
    yield put({ type: "SHOW_STOCK_TAKE_LOADING" });
    let res = yield call(fetchStockTakeDetailApi, action.payload)
    yield put({ type: "FETCH_STOCK_TAKE_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_TAKE_DETAIL_FAILED", payload: e.message });
  }
}

function* loadGetLoopDataDetail(action) {
  try {
    yield put({ type: "SHOW_STOCK_LOADING" });
    let res = yield call(fetchGetLoopDataDetailApi, action.payload)
    yield put({ type: "FETCH_LOOP_DATA_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_LOOP_DATA_DETAIL_FAILED", payload: e.message });
  }
}

function* loadFormulaDetail(action) {
  try {
    yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(fetchFormulaDetailApi, action.payload)
    yield put({ type: "FETCH_FORMULA_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_FORMULA_DETAIL_FAILED", payload: e.message });
  }
}
function* getUomOfMaterial(action) {
  try {
    // yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(getUomOfMaterialApi, action.payload)
    yield put({ type: "GET_UOM_MATERIAL_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "GET_UOM_MATERIAL_FAILED", payload: e.message });
  }
}
function* loadWarehouseStock(action) {
  try {
    yield put({ type: "SHOW_STOCK_LOADING" });
    let res = yield call(fetchWarehouseStockApi, action.payload)
    yield put({ type: "FETCH_WAREHOUSE_STOCK_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_WAREHOUSE_STOCK_FAILED", payload: e.message });
  }
}
function* loadLostStock(action) {
  try {
    yield put({ type: "SHOW_STOCK_LOADING" });
    let res = yield call(fetchInventoryLostStockApi, action.payload)
    yield put({ type: "FETCH_LOST_INVENTORY_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_LOST_INVENTORY_FAILED", payload: e.message });
  }
}
function* loadCheckStock(action) {
  try {
    yield put({ type: "SHOW_STOCK_LOADING" });
    let res = yield call(fetchInventoryCheckStockApi, action.payload)
    yield put({ type: "FETCH_CHECK_INVENTORY_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_CHECK_INVENTORY_FAILED", payload: e.message });
  }
}



function* submitUpdateConversion(action) {
  try {
    yield put({ type: "SHOW_CONVERSION_LOADING" });
    let res = yield call(updateConversion, action.payload)
    yield put({ type: "UPDATE_CONVERSION_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "UPDATE_CONVERSION_FAILED", payload: e.message });
  }
}

function* submitNewLoopGetData(action) {
  try {
    yield put({ type: "SHOW_STOCK_RECONCILE_LOADING" });
    let res = yield call(createNewLoopGetDataApi, action.payload)
    yield put({ type: "SUBMIT_NEW_LOOP_GET_DATA_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_LOOP_GET_DATA_FAILED", payload: e.message });
  }
}

function* loadStockRequest(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchStockRequestsApi, action.payload)
    yield put({ type: "FETCH_STOCK_REQUEST_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* loadStockRequestInStockTransfer(action) {
  try {
    yield put({ type: "SHOW_STOCK_TRANSFER_LOADING" });
    let res = yield call(fetchStockRequestsApi, action.payload)
    yield put({ type: "FETCH_STOCK_REQUEST_STOCK_TRANSFER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_REQUEST_STOCK_TRANSFER_FAILED", payload: e.message });
  }
}

function* loadStockRequestByWarehouse(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchStockRequestsByWarehouseApi, action.payload)
    yield put({ type: "FETCH_STOCK_REQUEST_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* loadAllStockRequest(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchListStatusStockRequest, action.payload)
    yield put({ type: "FETCH_ALL_STOCK_REQUEST_SUCCEEDED_V2", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_STOCK_REQUEST_FAILED", payload: e.message });
  }
}

function* loadMaterialFilter(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchListMateritalFilter, action.payload)
    yield put({ type: "FETCH_MATERIAL_FILTER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_FILTER_SUCCEEDED", payload: e.message });
  }
}

function* loadMaterialFilterByCategory(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchListMateritalFilterByCategory, action.payload)
    yield put({ type: "FETCH_MATERIAL_FILTER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_FILTER_SUCCEEDED", payload: e.message });
  }
}

function* loadStockRequestByFilter(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchStockRequestByFilter, action.payload)
    yield put({ type: "FETCH_STOCK_REQUEST_BY_FILTER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_REQUEST_BY_FILTER_SUCCEEDED", payload: e.message });
  }
}
function* loadInventoryManageByFilter(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchInventoryManageByFilter, action.payload)
    yield put({ type: "FETCH_INVENTORY_MANAGE_BY_FILTER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_INVENTORY_MANAGE_BY_FILTER_FAILED", payload: e.message });
  }
}
function* loadInventoryCheckByFilter(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchInventoryCheckByFilter, action.payload)
    yield put({ type: "FETCH_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_INVENTORY_CHECK_BY_FILTER_FAILED", payload: e.message });
  }
}
function* loadInventoryLostByFilter(action) {
  try {
    console.log("FILTER",action.payload)
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchInventoryLostByFilter, action.payload)
    yield put({ type: "FETCH_INVENTORY_LOST_BY_FILTER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_INVENTORY_LOST_BY_FILTER_FAILED", payload: e.message });
  }
}

function* loadStockRequestDetail(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchStockRequestDetailApi, action.payload)
    yield put({ type: "FETCH_STOCK_REQUEST_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_STOCK_REQUEST_DETAILT_FAILED", payload: e.message });
  }
}
function* loadCheckRequestDetail(action) {
  console.log("go go go",action.payload);
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(fetchStockCheckDetailApi, action.payload)
    yield put({ type: "FETCH_CHECK_REQUEST_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_CHECK_REQUEST_DETAILT_FAILED", payload: e.message });
  }
}

function* loadStockRequestDetailInStockTransfer(action) {
  try {
    yield put({ type: "SHOW_STOCK_TRANSFER_LOADING" });
    let res = yield call(fetchStockRequestDetailApi, action.payload)
    yield put({ type: "FETCH_STOCK_TRANSFER_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_TRANSFER_DETAIL_FAILED", payload: e.message });
  }
}

function* loadingStockRequestPending(action) {
  try {
    yield put({ type: "SHOW_PURCHASE_ORDER_LOADING" });
    yield put({ type: "SHOW_STOCK_PENDING_REQUEST_LOADING" });
    let res = yield call(fetchStockRequestPendingApi, action.payload)
    yield put({ type: "FETCH_STOCK_PENDING_REQUEST_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_PENDING_REQUEST_FAILED", payload: e.message });
  }
}

function* loadPurchaseOrder(action) {
  try {
    yield put({ type: "SHOW_PURCHASE_ORDER_LOADING" });
    let res = yield call(fetchPurchaseOrderApi, action.payload)
    yield put({ type: "FETCH_PURCHASE_ORDER_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_PURCHASE_ORDER_FAILED", payload: e.message });
  }
}

function* loadPurchaseOrderDetail(action) {
  try {
    yield put({ type: "SHOW_PURCHASE_ORDER_LOADING" });
    let res = yield call(fetchPurchaseOrderDetailApi, action.payload)
    yield put({ type: "FETCH_PURCHASE_ORDER_DETAIL_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_PURCHASE_ORDER_DETAIL_FAILED", payload: e.message });
  }
}


//=============================
function* loadStockTransfers(action) {
  try {
    yield put({ type: "SHOW_STOCK_TRANSFER_LOADING" });
    let res = yield call(fetchStockTransfersApi, action.payload)
    yield put({ type: "FETCH_STOCK_TRANSFERS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_TRANSFERS_FAILDED", payload: e.message });
  }
}

function* loadStockTransferDetail(action) {
  try {
    yield put({ type: "SHOW_STOCK_TRANSFER_LOADING" });
    let res = yield call(fetchStockTransferDetailApi, action.payload)
    yield put({ type: "FETCH_STOCK_TRANSFER_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_TRANSFER_DETAILT_FAILED", payload: e.message });
  }
}

function* loadMaterialInstancesByWarehouse(action) {
  console.log("loadMaterialInstancesByWarehouse", action.payload);
  try {
    let res = yield call(fetchMaterialInstancesByWarehouseApi, action.payload)
    yield put({ type: "FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_FAILED", payload: e.message });
  }
}

function* submitNewStockDestruction(action) {
  try {
    yield put({ type: "SHOW_STOCK_DESTRUCTION_LOADING" });
    let res = yield call(createStockDestructionApi, action.payload)
    if (res) {
      let workFlowPayload = {
        WorkflowTypeId: workFlowTypes.STOCK_DESTRUCTION,
        TransactionId: res.data.data.id,
        CreatedBy: action.payload.CreatedBy
      };
      let workFlowRes = yield call(approveRequestApi, workFlowPayload);
    }
    yield put({ type: "SUBMIT_NEW_STOCK_DESTRUCTION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_DESTRUCTION_FAILED", payload: e.response.data.errors[0].message });
  }
}

function* submitNewStockDestructionV2(action) {
  try {
    yield put({ type: "SHOW_STOCK_DESTRUCTION_LOADING" });
    let res = yield call(createStockDestructionApi, action.payload);
    yield put({ type: "SUBMIT_NEW_STOCK_DESTRUCTION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_DESTRUCTION_FAILED", payload: e.response.data.errors[0].message });
  }
}

function* updateNewStockDestructionV2(action) {
  try {
    yield put({ type: "SHOW_STOCK_DESTRUCTION_LOADING" });
    let res = yield call(updateStockDestructionApi, action.payload);
    yield put({ type: "SUBMIT_UPDATE_STOCK_DESTRUCTION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_DESTRUCTION_FAILED", payload: e.response.data.errors[0].message });
  }
}

function* submitNewSchedule(action) {
  try {
    yield put({ type: "SHOW_SCHEDULE_LOADING" });
    let res = yield call(createScheduleApi, action.payload)
    yield put({ type: "SUBMIT_NEW_SCHEDULE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_SCHEDULE_FAILED", payload: e.message });
  }
}

function* submitNewPayslip(action) {
  try {
    yield put({ type: "SHOW_PAYSLIP_LOADING" });
    let res = yield call(createPayslipApi, action.payload)
    yield put({ type: "SHOW_PAYSLIP_LOADING_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SHOW_PAYSLIP_LOADING_FAILED", payload: e.message });
  }
}

function* loadPayslip() {
  try {
    yield put({ type: "SHOW_PAYSLIP_LOADING" });
    let res = yield call(fetchPayslipMasterApi)
    yield put({ type: "LOAD_PAYSLIP_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "LOAD_PAYSLIP_FAILED", payload: e.message });
  }
}

function* loadPayslipDetail(action) {
  try {
    yield put({ type: "SHOW_PAYSLIP_LOADING" });
    let res = yield call(fetchPayslipDetailsApi, action.payload)
    yield put({ type: "LOAD_PAYSLIP_DETAILS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "LOAD_PAYSLIP_DETAILS_FAILED", payload: e.message });
  }
}

function* sendEmialPayslip(action) {
  try {
    yield put({ type: "SHOW_PAYSLIP_LOADING" });
    let res = yield call(sendEmailPayslipApi, action.payload)
    yield put({ type: "SEND_EMAIL_PAYSLIP_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SEND_EMAIL_PAYSLIP_FAILED", payload: e.message });
  }
}

function* submitNewStockReconciles(action) {
  try {
    yield put({ type: "SHOW_STOCK_RECONCILE_LOADING" });
    console.log("submitNewStockReconciles", action.payload);
    let res = yield call(createStockReconcileApi, action.payload)
    console.log("submitNewStockReconciles", res);
    yield put({ type: "SUBMIT_NEW_STOCK_RECONCILE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_RECONCILE_FAILED", payload: e.message });
  }
}

function* submitNewStockTake(action) {
  try {
    yield put({ type: "SHOW_STOCK_TAKE_LOADING" });
    let res = yield call(createStockTakeApi, action.payload)
    if (res) {
      let workFlowPayload = {
        WorkflowTypeId: workFlowTypes.STOCK_TAKE,
        TransactionId: res.data.data.id,
        CreatedBy: action.payload.CreatedBy
      };
      let workFlowRes = yield call(approveRequestApi, workFlowPayload);
    }
    yield put({ type: "SUBMIT_NEW_STOCK_TAKE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_TAKE_FAILED", payload: e.message });
  }
}

function* approveStockTake(action) {
  try {
    yield put({ type: "SHOW_STOCK_TAKE_LOADING" });
    let res = yield call(approveStockTakeApi, action.payload)
    yield put({ type: "APPROVE_STOCK_TAKE_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "APPROVE_STOCK_TAKE_FAILED", payload: e.message });
  }
}

function* loadLoopDataProductSold(action) {
  try {
    yield put({ type: "SHOW_STOCK_LOADING" });
    let res = yield call(fetchLoopDataApi, action.payload)
    yield put({ type: "FETCH_LOOP_DATA_PRODUCT_SOLD_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_LOOP_DATA_PRODUCT_SOLD_FAILED", payload: e.message });
  }
}

function* loadLoopTransactions(action) {
  try {
    yield put({ type: "SHOW_STOCK_LOADING" });
    let res = yield call(fetchLoopTransactionsApi, action.payload)
    yield put({ type: "FETCH_LOOP_TRANSACTIONS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_LOOP_TRANSACTIONS_FAILED", payload: e.message });
  }
}

function* loadStockTake(action) {
  try {
    yield put({ type: "SHOW_STOCK_TAKE_LOADING" });
    let res = yield call(fetchStockTakeApi, action.payload)
    yield put({ type: "FETCH_STOCK_TAKE_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_TAKE_FAILED", payload: e.message });
  }
}

function* loadLoopToken(action) {
  try {
    yield put({ type: "SHOW_STOCK_RECONCILE_LOADING" });
    let res = yield call(fetchLoopTokenApi, action.payload)
    yield put({ type: "FETCH_LOOP_TOKEN_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_LOOP_TOKEN_FAILED", payload: e.message });
  }
}

function* loadLoopManagementToken(action) {
  try {
    yield put({ type: "SHOW_STOCK_RECONCILE_LOADING" });
    let res = yield call(fetchLoopManagementTokenApi, action.payload)
    yield put({ type: "FETCH_LOOP_MANAGEMENT_TOKEN_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_LOOP_TOKEN_FAILED", payload: e.message });
  }
}

function* loadStockDestructions(action) {
  try {
    yield put({ type: "SHOW_STOCK_DESTRUCTION_LOADING" });
    let res = yield call(fetchStockDestructionsApi, action.payload)
    yield put({ type: "FETCH_STOCK_DESTRUCTIONS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_DESTRUCTIONS_FAILED", payload: e.message });
  }
}

function* loadUploadMasters(action) {
  try {
    yield put({ type: "SHOW_SCHEDULE_LOADING" });
    let res = yield call(fetchUploadMastersApi, action.payload)
    yield put({ type: "FETCH_UPLOAD_MASTERS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_UPLOAD_MASTERS_FAILED", payload: e.message });
  }
}

function* loadAllStaffMaster(action) {
  try {
    yield put({ type: "SHOW_SCHEDULE_LOADING" });
    let res = yield call(fetchAllStaffMasterApi, action.payload)
    yield put({ type: "FETCH_ALL_STAFF_MASTERS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_STAFF_MASTERS_FAILED", payload: e.message });
  }
}

function* loadScheduleDetail(action) {
  try {
    yield put({ type: "SHOW_SCHEDULE_LOADING" });
    let res = yield call(fetchScheduleDetail, action.payload)
    yield put({ type: "FETCH_SCHEDULE_DETAIL_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SCHEDULE_DETAIL_FAILED", payload: e.message });
  }
}

function* loadScheduleReport(action) {
  try {
    yield put({ type: "SHOW_SCHEDULE_LOADING" });
    let res = yield call(fetchScheduleReportApi, action.payload);
    yield put({ type: "FETCH_SCHEDULE_REPORT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SCHEDULE_REPORT_FAILED", payload: e.mesage })
  }
}

function* loadGoodsProduceReport(action) {
  try {
    yield put({ type: "SHOW_GOODS_PRODUCE_LOADING" });
    let res = yield call(fetchGoodsProduceReportApi, action.payload);
    yield put({ type: "FETCH_GOODS_PRODUCE_REPORT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_PRODUCE_REPORT_FAILED", payload: e.mesage })
  }
}

function* loadMaterialSalesReport(action) {
  try {
    yield put({ type: "SHOW_REPORT_LOADING" });
    let res = yield call(fetchMaterialSalesReportApi, action.payload);
    yield put({ type: "FETCH_MATERIAL_SALES_REPORT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIAL_SALES_REPORT_FAILED", payload: e.mesage })
  }
}

function* loadStockBalances(action) {
  try {
    yield put({ type: "SHOW_STOCK_BALANCE_LOADING" });
    let res = yield call(fetchStockBalancesApi, action.payload)
    yield put({ type: "FETCH_STOCK_BALANCES_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_BALANCES_FAILED", payload: e.message });
  }
}

function* loadStockReconciles(action) {

  try {
    yield put({ type: "SHOW_STOCK_RECONCILE_LOADING" });
    let res = yield call(fetchInventoryLostByFilter, action.payload)
    yield put({ type: "FETCH_STOCK_RECONCILES_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_RECONCILES_FAILED", payload: e.message });
  }
}

function* downloadStockReconcile(action) {
  try {
    yield put({ type: "SHOW_STOCK_RECONCILE_LOADING" });
    let res = yield call(fetchStockReconcileDetailApi, action.payload)
    yield put({ type: "FETCH_STOCK_RECONCILE_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_RECONCILE_DETAIL_FAILED", payload: e.message });
  }
}

function* submitNewStockBalance(action) {
  try {
    yield put({ type: "SHOW_STOCK_BALANCE_LOADING" });
    let res = yield call(createStockBalanceApi, action.payload)
    yield put({ type: "SUBMIT_NEW_STOCK_BALANCE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_STOCK_BALANCE_FAILED", payload: e.message });
  }
}

function* submitUpdateProductUnitPrice(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(updateProductUnitPriceApi, action.payload)
    yield put({ type: "UPDATE_PRODUCT_UNIT_PRICE_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_PRODUCT_UNIT_PRICE_FAILED", payload: e.message });
  }
}

function* loadStockBalanceDetail(action) {
  try {
    yield put({ type: "SHOW_STOCK_BALANCE_LOADING" });
    let res = yield call(fetchStockBalanceDetailApi, action.payload)
    yield put({ type: "FETCH_STOCK_BALANCE_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_BALANCE_DETAIL_FAILED", payload: e.message });
  }
}

function* deleteFormula(action) {
  try {
    yield put({ type: "SHOW_FORMULA_LOADING" });
    let res = yield call(callDeleteFormulaApi, action.payload)
    yield put({ type: "DELETE_FORMULA_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "DELETE_FORMULA_FAILED", payload: e.message });
  }
}

function* loadSite(action) {
  try {
    yield put({ type: "SHOW_SITE_LOADING" });
    let res = yield call(fetchSite, action.payload)
    yield put({ type: "FETCH_SITE_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SITE_FAILED", payload: e.message });
  }
}
function* loadSitePagination(action) {
  try {
    yield put({ type: "SHOW_SITE_LOADING" });
    let res = yield call(fetchSitePagination, action.payload)
    yield put({ type: "FETCH_SITE_PAGINATION_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SITE_PAGINATION_FAILED", payload: e.message });
  }
}
function* updateSite(action) {
  try {
    yield put({ type: "SHOW_SITE_LOADING" });
    let res = yield call(updateSiteApi, action.payload)
    yield put({ type: "UPDATE_SITE_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_SITE_FAILED", payload: e.message });
  }
}
function* loadStockDestructionDetail(action) {
  try {
    yield put({ type: "SHOW_STOCK_DESTRUCTION_LOADING" });
    let res = yield call(fetchStockDestructionDetailApi, action.payload)
    yield put({ type: "FETCH_STOCK_DESTRUCTION_DETAIL_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_DESTRUCTION_DETAIL_FAILED", payload: e.message });
  }
}

function* UpdateStatusStockDestruction(action) {
  try {
    yield put({ type: "SHOW_STOCK_DESTRUCTION_LOADING" });
    let res = yield call(updateStatusStockDestruction, action.payload)
    yield put({ type: "SUBMIT_UPDATE_STOCK_DESTRUCTION_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_DESTRUCTION_DETAIL_FAILED", payload: e.message });
  }
}

function* loadSalesReport(action) {
  try {
    yield put({ type: "SHOW_SALES_LOADING" });
    let res = yield call(fetchSalesReportApi, action.payload)
    yield put({ type: "FETCH_SALES_FILTER_REPORT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SALES_FILTER_REPORT_FAILED", payload: e.message });
  }
}

function* loadCashInReport(action) {
  console.log("Cash In Report", action.payload)
  try {
    yield put({ type: "SHOW_CASH_IN_LOADING" });

    let res = yield call(fetchCashInReportApi, action.payload)
    yield put({ type: "FETCH_CASH_IN_REPORT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_CASH_IN_REPORT_FAILED", payload: e.message });
  }
}

function* SubmitCashInReport(action) {
  console.log("Payload", action.payload)
  try {
    yield put({ type: "SHOW_CASH_IN_LOADING" });

    let res = yield call(updateCashInReportApi, action.payload)
    yield put({ type: "SUBMIT_CASH_IN_REPORT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_CASH_IN_REPORT_FAILED", payload: e.message });
  }
}

function* addUploadPaySlipVictor(action) {
  try {
    yield put({ type: "SHOW_CASH_IN_LOADING" });
    let res = yield call(uploadPaySlipVictory, action.payload)
    yield put({ type: "STATUS_UPLOAD_PAYSLIP_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_CASH_IN_REPORT_FAILED", payload: e.message });
  }
}

function* getMonthPaySlipVictory() {
  try {
    yield put({ type: "SHOW_CASH_IN_LOADING" });
    let res = yield call(fetchMonthPaySlip)
    yield put({ type: "SET_MONTH_PAYSLIP_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_CASH_IN_REPORT_FAILED", payload: e.message });
  }
}

function* getPaySlipDetailVictory(action) {
  try {
    yield put({ type: "SHOW_CASH_IN_LOADING" });
    let res = yield call(fetchPaySlipDetail, action.payload);
    yield put({ type: "FETCH_PAYSLIP_DETAIL_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_CASH_IN_REPORT_FAILED", payload: e.message });
  }
}



function* loadOrders(action) {
  try {
    yield put({ type: "SHOW_SALES_LOADING" });
    let res = yield call(fetchOrdersApi, action.payload)
    yield put({ type: "FETCH_ORDERS_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ORDERS_FAILED", payload: e.message });
  }
}

function* loadSaleFilterReport(action) {
  try {
    yield put({ type: "SHOW_SALES_LOADING" });
    let res = yield call(fetchSalesFilterReportApi, action.payload)
    yield put({ type: "FETCH_SALES_FILTER_REPORT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SALES_FILTER_REPORT_FAILED", payload: e.message });
  }
}

function* approveBill(action) {
  try {
    yield put({ type: "SHOW_SALES_LOADING" });
    let res = yield call(approveBillApi, action.payload)
    yield put({ type: "APPROVE_BILL_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "APPROVE_BILL_FAILED", payload: e.message });
  }
}

function* cancelBill(action) {
  try {
    yield put({ type: "SHOW_SALES_LOADING" });
    let res = yield call(cancelBillApi, action.payload)
    yield put({ type: "CANCEL_BILL_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CANCEL_BILL_FAILED", payload: e.message });
  }
}

function* hideProduct(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(hideProductApi, action.payload)
    yield put({ type: "HIDE_PRODUCT_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "HIDE_PRODUCT_FAILED", payload: e.message });
  }
}

function* loadStorePOSInfo(action) {
  try {
    yield put({ type: "SHOW_POS_LOADING" });
    let res = yield call(getStorePOSInfoApi, action.payload)
    yield put({ type: "GET_STORE_POS_INFO_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "GET_STORE_POS_INFO_FAILED", payload: e.message });
  }
}

function* updateStore(action) {
  try {
    yield put({ type: "SHOW_POS_LOADING" });
    let res = yield call(updateStoreApi, action.payload)
    yield put({ type: "UPDATE_STORE_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "UPDATE_STORE_FAILED", payload: e.message });
  }
}

function* updateCategoryOrderBy(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" });
    let res = yield call(updateCategoryOrderByApi, action.payload)
    yield put({ type: "UPDATE_CATEGORY_ORDER_BY_SUCCESS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "UPDATE_CATEGORY_ORDER_BY_FAILED", payload: e.message });
  }
}

function* submitNewFeedback(action) {
  try {
    yield put({ type: "SHOW_FEEDBACK_LOADING" });
    let res = yield call(createFeedbackApi, action.payload)
    yield put({ type: "SUBMIT_NEW_FEEDBACK_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_FEEDBACK_FAILED", payload: e.message });
  }
}
function* loadFeedbacks(action) {
  try {
    yield put({ type: "SHOW_FEEDBACK_LOADING" });
    let res = yield call(fetchFeedbackDataApi)
    yield put({ type: "FETCH_FEEDBACK_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_FEEDBACK_FAILED", payload: e.message });
  }
}
function* loadGetFeedbackDataDetail(action) {
  try {
    yield put({ type: "SHOW_FEEDBACK_LOADING" });
    let res = yield call(fetchGetFeedbackDataDetailApi, action.payload)
    yield put({ type: "FETCH_FEEDBACK_DETAIL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_FEEDBACK_DETAIL_FAILED", payload: e.message });
  }
}

function* loadVoucherOrders(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchVoucherOrders, action.payload)
    yield put({ type: "FETCH_VOUCHER_ORDERS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_VOUCHER_ORDERS_FAILED", payload: e.message });
  }
}

function* loadPOSVoucher() {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchPOSVoucher)
    yield put({ type: "FETCH_VOUCHER_POS_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_VOUCHER_ORDERS_FAILED", payload: e.message });
  }
}

function* loadAppVoucher() {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchAppVoucher)
    yield put({ type: "FETCH_VOUCHER_APP_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_VOUCHER_ORDERS_FAILED", payload: e.message });
  }
}

function* loadCategoriesFilter() {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchCategoriesFilter);
    yield put({ type: "FETCH_CATEGORIES_FILTER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_CATEGORIES_FILTER_FAILED", payload: e.message });
  }
}

function* loadMaterialsFilter() {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchMaterialsFilter);
    yield put({ type: "FETCH_MATERIALS_FILTER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIALS_FILTER_FAILED", payload: e.message });
  }
}

function* loadMaterialRedeemVoucher() {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchMaterialRedeemVoucher);
    yield put({ type: "FETCH_REDEEM_POINT_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIALS_FILTER_FAILED", payload: e.message });
  }
}

function* loadListUserVoucher() {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchListUserVoucher);
    yield put({ type: "FETCH_LIST_USER_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_MATERIALS_FILTER_FAILED", payload: e.message });
  }
}

function* cancelVoucherOrder(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(cancelVoucherOrders, action.payload)
    yield put({ type: "FETCH_APRROVE_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_APRROVE_VOUCHER_FAILED", payload: e.message });
  }
}

function* approveVoucherOrder(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(approveVoucherOrders, action.payload)
    yield put({ type: "FETCH_APRROVE_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_APRROVE_VOUCHER_FAILED", payload: e.message });
  }
}

function* approveVoucherOrderV2(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(approveVoucherOrdersV2, action.payload)
    yield put({ type: "FETCH_APRROVE_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_APRROVE_VOUCHER_FAILED", payload: e.message });
  }
}

function* getDiscountItems(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(getDiscountItemsApi, action.payload)
    yield put({ type: "FETCH_DISCOUNT_ITEMS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_DISCOUNT_ITEMS_FAILED", payload: e.message });
  }
}

function* createNewVoucherOrder(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(createNewVoucherOrderApi, action.payload);
    yield put({ type: "CREATED_VOUCHER_ORDER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_ORDER_FAILED", payload: e.message });
  }
}

function* updateVoucherOrder(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(updateVoucherOrderApi, action.payload)
    yield put({ type: "UPDATE_VOUCHER_ORDER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "UPDATE_VOUCHER_ORDER_FAILED", payload: e.message });
  }
}

function* loadPOSVoucherDetail(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchPOSVoucherDetail, action.payload)
    yield put({ type: "FETCH_VOUCHER_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_VOUCHER_DETAIL_FAILED", payload: e.message });
  }
}

function* loadAppVoucherDetail(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(fetchAppVoucherDetail, action.payload)
    yield put({ type: "FETCH_VOUCHER_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_VOUCHER_DETAIL_FAILED", payload: e.message });
  }
}

function* newPOSVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(createPOSVoucher, action.payload)
    yield put({ type: "CREATED_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_POS_FAILED", payload: e.message });
  }
}

function* newAppVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(createAppVoucher, action.payload)
    yield put({ type: "CREATED_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_POS_FAILED", payload: e.message });
  }
}

function* newRedeemVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(createRedeemVoucher, action.payload)
    yield put({ type: "CREATED_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_POS_FAILED", payload: e.message });
  }
}

function* updatedRedeemVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(updateRedeemVoucher, action.payload)
    yield put({ type: "CREATED_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_POS_FAILED", payload: e.message });
  }
}

function* updatedStatusRedeemVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(updateStatusRedeemVoucher, action.payload)
    yield put({ type: "STATUS_VOUCHER_POS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_POS_FAILED", payload: e.message });
  }
}

function* updatedAppVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(updateAppVoucher, action.payload)
    yield put({ type: "CREATED_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_POS_FAILED", payload: e.message });
  }
}


function* updatedPOSVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(updatePOSVoucher, action.payload)
    yield put({ type: "CREATED_VOUCHER_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATED_VOUCHER_POS_FAILED", payload: e.message });
  }
}

function* updatedStatusPOSVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(updateStatusPOSVoucher, action.payload)
    yield put({ type: "STATUS_VOUCHER_POS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "STATUS_VOUCHER_POS_FAILED", payload: e.message });
  }
}

function* updatedStatusAppVoucher(action) {
  try {
    yield put({ type: "SHOW_VOUCHER_LOADING" });
    let res = yield call(updateStatusAppVoucher, action.payload)
    yield put({ type: "STATUS_VOUCHER_POS_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "STATUS_VOUCHER_POS_FAILED", payload: e.message });
  }
}


function* loadGetSaleDataDetail(action) {
  try {
    yield put({ type: "SHOW_SALE_LOADING" });
    let res = yield call(fetchGetSaleDataDetailApi, action.payload)
    yield put({ type: "FETCH_SALE_DETAIL_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SALE_DETAIL_FAILED", payload: e.message });
  }
}

function* submitStockRequestWorkFlowAction(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_STOCK_REQUEST_WORK_FLOW_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_STOCK_REQUEST_WORK_FLOW_FAILED", payload: e.message });
  }
}

function* submitStockTakeWorkFlowAction(action) {
  try {
    yield put({ type: "SHOW_STOCK_TAKE_LOADING" });
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_STOCK_TAKE_WORK_FLOW_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_STOCK_TAKE_WORK_FLOW_FAILED", payload: e.message });
  }
}

function* submitPOWorkFlowAction(action) {
  try {
    yield put({ type: "SHOW_PURCHASE_ORDER_LOADING" });
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_PO_WORK_FLOW_SUCCEEDED", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_PO_WORK_FLOW_FAILED", payload: e.message })
  }
}

function* submitGoodsRecievedFlowAction(action) {
  try {
    yield put({ type: "SHOW_GOODS_RECEIVED_LOADING" });
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_GOODS_RECEIVED_WORK_FLOW_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_GOODS_RECEIVED_WORK_FLOW_FAILED", payload: e.message })
  }
}

function* submitStockTransferFlowAction(action) {
  try {
    yield put({ type: "SHOW_STOCK_TRANSFER_LOADING" });
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_STOCK_TRANSFER_WORK_FLOW_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_STOCK_TRANSFER_WORK_FLOW_FAILED", payload: e.message })
  }
}

function* sendEmailPaySlipVictory(action) {
  try {
    yield put({ type: "SHOW_CASH_IN_LOADING" });
    let res = yield call(sendEmailPayslipVictory, action.payload)
    yield put({ type: "STATUS_SEND_MAIL_PAYSLIP_SUCCESSED", payload: res.data })
  } catch (e) {
    yield put({ type: "STATUS_UPLOAD_PAYSLIP_FAILED", payload: e.message })
  }
}

function* submitStockDestructionFlowAction(action) {
  try {
    yield put({ type: "SHOW_STOCK_DESTRUCTION_LOADING" });
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_STOCK_DESTRUCTION_WORK_FLOW_SUCCEEDED", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_STOCK_DESTRUCTION_WORK_FLOW_FAILED", payload: e.message })
  }
}

function* submitGoodsProduceFlowAction(action) {
  try {
    yield put({ type: "SHOW_GOODS_PRODUCE_LOADING" });
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_GOODS_PRODUCE_WORK_FLOW_SUCCEEDED", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_STOCK_DESTRUCTION_WORK_FLOW_FAILED", payload: e.message })
  }
}

function* setOrderStatus(action) {
  try {
    yield put({ type: "SHOW_SALES_LOADING" });
    let res = yield call(setOrderStatusApi, action.payload)
    yield put({ type: "SET_ORDER_STATUS_SUCCEEDED", payload: res.data })
  } catch (e) {
    yield put({ type: "SET_ORDER_STATUS_FAILED", payload: e.message })
  }
}

function* loadShipperList() {
  try {
    yield put({ type: "SHOW_SHIPPER_LOADING" });
    let res = yield call(fetchShipperListApi)
    yield put({ type: "LOAD_SHIPPER_LIST_SUCCESS", payload: res.data.data })
  } catch (e) {
    yield put({ type: "LOAD_SHIPPER_LIST_FAILED", payload: e.message })
  }
}

function* setShipperStatus(action) {
  try {
    yield put({ type: "SHOW_SHIPPER_LOADING" });
    let res = yield call(setStatusShipper, action.payload)
    yield put({ type: "SET_SHIPPER_STATUS_SUCCESS", payload: res.data.data })
  } catch (e) {
    yield put({ type: "SET_SHIPPER_STATUS_FAILED", payload: e.message })
  }
}

function* loadOrderDetail(action) {
  try {
    yield put({ type: "SHOW_SALES_LOADING" });
    let res = yield call(fetchOrderDetailApi, action.payload)
    yield put({ type: "GET_ORDER_DETAIL_SUCCESS", payload: res.data.data })
  } catch (e) {
    yield put({ type: "GET_ORDER_DETAIL_FAILED", payload: e.message })
  }
}

function* loadFolders(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(fetchFoldersApi, action.payload);
    yield put({ type: "LOAD_FOLDERS_SUCCESS", payload: res.data.data })
  } catch (e) {
    yield put({ type: "LOAD_FOLDERS_FAILED", payload: e.message })
  }
}

function* defaultFolders() {
  try {
    yield put({ type: "DEFAULT_FOLDER" });
  } catch (e) {
    yield put({ type: "LOAD_FOLDERS_FAILED", payload: e.message })
  }
}

function* changeFolderName(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(changeNameFolderApi, action.payload)
    yield put({ type: "SUBMIT_NEW_FOLDER_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_FOLDER_SUCCESS", payload: e.message })
  }
}

function* changeFileName(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(changeNameFileApi, action.payload)
    yield put({ type: "SUBMIT_NEW_FOLDER_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_FOLDER_FAILED", payload: e.message })
  }
}

function* deleteFile(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(deleteFileApi, action.payload)
    yield put({ type: "SUBMIT_DELETE_FOLDER_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_DELETE_FOLDER_FAILED", payload: e.message })
  }
}

function* pinFile(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(pinNotifyApi, action.payload)
    console.log("DATA BACK",res)
    yield put({ type: "HIDE_HANDBOOK_LOADING" })
  } catch (e) {
    yield put({ type: "HIDE_HANDBOOK_LOADING" })
  }
}

function* deleteFolder(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(deleteFolderApi, action.payload)
    yield put({ type: "SUBMIT_DELETE_FOLDER_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_DELETE_FOLDER_FAILED", payload: e.message })
  }
}

function* submitNewFolder(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(createFolderApi, action.payload)
    yield put({ type: "SUBMIT_NEW_FOLDER_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_FOLDER_FAILED", payload: e.message })
  }
}

function* uploadNewFile(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(uploadFileApi, action.payload)
    yield put({ type: "UPLOAD_NEW_FILE_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "UPLOAD_NEW_FILE_FAILED", payload: e.message })
  }
}

function* uploadNewFolder(action) {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(uploadFolderApi, action.payload)
    yield put({ type: "UPLOAD_NEW_FOLDER_SUCCESS", payload: res.data })
  } catch (e) {
    yield put({ type: "UPLOAD_NEW_FOLDER_FAILED", payload: e.message })
  }
}

function* getGenaralInfomation() {
  try {
    yield put({ type: "SHOW_HANDBOOK_LOADING" });
    let res = yield call(fecthGeneralInfoHRM);
    yield put({ type: "GET_GENERAL_INFO_SUCESSED", payload: res.data.data })
  } catch (e) {
    yield put({ type: "GET_GENERAL_INFO_SUCESSED", payload: e.message })
  }
}


/* REVENUE  */
function* loadRevenueMonth(action) {
  try {
    let res = yield call(fetchRevenueMonth, action.payload)
    yield put({ type: "LOAD_REVENUE_MONTH_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_MONTH_FAILED", payload: e.message })
  }
}

function* loadRevenueWeek(action) {
  try {
    let res = yield call(fetchRevenueWeek, action.payload)
    yield put({ type: "LOAD_REVENUE_WEEK_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_WEEK_FAILED", payload: e.message })
  }
}

function* loadRevenueDate(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_DATE" });
    let res = yield call(fetchRevenueDate, action.payload)
    yield put({ type: "LOAD_REVENUE_DATE_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_DATE_FAILED", payload: e.message })
  }
}

function* loadRevenueDateOverview(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_DATE" });
    let res = yield call(fetchRevenueDate, action.payload)
    yield put({ type: "LOAD_REVENUE_DATE_SUCCESS_BEFORE", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_DATE_FAILED_BEFORE", payload: e.message })
  }
}

function* loadRevenueHour(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_HOUR" });
    let res = yield call(fecthRevenueHour, action.payload)
    yield put({ type: "LOAD_REVENUE_HOUR_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_HOUR_FAILED", payload: e.message })
  }
}

function* loadRevenueShift(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_SHIFT" });
    let res = yield call(fecthRevenueShift, action.payload);
    yield put({ type: "LOAD_REVENUE_SHIFT_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_SHIFT_FAILED", payload: e.message })
  }
}

function* loadRevenueShiftDetail(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_SHIFT" });
    let res = yield call(fecthRevenueShiftDetail, action.payload);
    yield put({ type: "LOAD_REVENUE_SHIFT_DETAIL_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_SHIFT_DETAIL_FAILED", payload: e.message })
  }
}

function* loadRevenueCategory(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_CATEGORY" });
    let res = yield call(fecthRevenueCategory, action.payload)
    yield put({ type: "LOAD_REVENUE_CATEGORY_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_CATEGORY_FAILED", payload: e.message })
  }
}

function* loadTotalFoodSold(action) {
  try {
    let res = yield call(fecthGetTotalFoodSold, action.payload)
    yield put({ type: "LOAD_TOTAL_FOOD_SOLD_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_TOTAL_FOOD_SOLD_SUCCESS", payload: e.message })
  }
}

function* loadRevenueCombo(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_COMBO" });
    let res = yield call(fecthRevenueCombo, action.payload)
    yield put({ type: "LOAD_REVENUE_COMBO_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_COMBO_FAILED", payload: e.message })
  }
}

function* loadRevenueServiceMode(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_SERVICE_MODE" });
    let res = yield call(fecthRevenueServiceMode, action.payload)
    yield put({ type: "LOAD_REVENUE_SERVICE_MODE_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_SERVICE_MODE_FAILED", payload: e.message })
  }
}

function* loadRevenuePayment(action) {
  try {
    yield put({ type: "SHOW_LOADING_REVENUE_PAYMENT" });
    let res = yield call(fecthRevenuePayments, action.payload)
    yield put({ type: "LOAD_REVENUE_PAYMENT_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_PAYMENT_FAILED", payload: e.message })
  }
}

function* loadRevenueQuarter(action) {
  try {
    let res = yield call(fetchRevenueQuarter, action.payload)
    yield put({ type: "LOAD_REVENUE_QUARTER_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_QUARTER_FAILED", payload: e.message })
  }
}

function* loadRevenueYear(action) {
  try {
    let res = yield call(fetchRevenueYear, action.payload)
    yield put({ type: "LOAD_REVENUE_YEAR_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_YEAR_FAILED", payload: e.message })
  }
}

function* loadRevenueGeneral(action) {
  try {
    let res = yield call(fetchRevenueGeneral, action.payload)
    yield put({ type: "LOAD_REVENUE_GENERAL_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_REVENUE_GENERAL_FAILED", payload: e.message })
  }
}

/* EMPLOYEE */
function* loadEmployee(action) {
  try {
    yield put({ type: "SHOW_EMPLOYEE_LOADING" });
    let res = yield call(fetchEmployee, action.payload)
    yield put({ type: "LOAD_EMPLOYEE_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_EMPLOYEE_FAILED", payload: e.message })
  }
}

function* loadEmployeeDetail(action) {
  try {
    yield put({ type: "SHOW_EMPLOYEE_DETAIL_LOADING" });
    let res = yield call(fetchEmployeeDetail, action.payload)
    yield put({ type: "LOAD_EMPLOYEE_DETAIL_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "LOAD_EMPLOYEE_DETAIL_FAILED", payload: e.message })
  }
}

function* createNewEmployee(action) {
  try {
    const createNew = action.payload
    yield put({ type: "SHOW_CREATE_NEW_EMPLOYEE_LOADING" });
    let res = yield call(createNewEmployeeApi, createNew)
    //yield put({ type: "CREATE_NEW_EMPLOYEE_SUCCESS", payload: res.data })
    const responseData = res.data
    yield put({ type: "CREATE_NEW_EMPLOYEE_SUCCESS", payload: { responseData, createNew } })
  } catch (e) {
    console.log(e);
    yield put({ type: "CREATE_NEW_EMPLOYEE_FAILED", payload: e.message })
  }
}

function* updateEmployee(action) {
  try {
    const updateData = action.payload
    console.log('updateData: ', updateData);

    yield put({ type: "SHOW_UPDATE_EMPLOYEE_LOADING" });
    let res = yield call(updateEmployeeApi, action.payload)
    const responseData = res.data
    yield put({ type: "UPDATE_EMPLOYEE_SUCCESS", payload: { responseData, updateData } })
  } catch (e) {
    console.log(e);
    yield put({ type: "UPDATE_EMPLOYEE_FAILED", payload: e.message })
  }
}

function* updateAvatarEmployee(action) {
  try {
    const updateData = action.payload
    console.log('updateData: ', updateData);

    yield put({ type: "SHOW_UPDATE_AVATAR_EMPLOYEE_LOADING" });
    let res = yield call(updateAvatarEmployeeApi, action.payload)
    const responseData = res.data
    yield put({ type: "UPDATE_AVATAR_EMPLOYEE_SUCCESS", payload: { responseData, updateData } })
  } catch (e) {
    console.log(e);
    yield put({ type: "UPDATE_AVATAR_EMPLOYEE_FAILED", payload: e.message })
  }
}

function* updateUserStatusEmployee(action) {
  try {
    const updateData = action.payload
    //console.log('updateData: ', updateData);

    yield put({ type: "SHOW_UPDATE_USER_STATUS_EMPLOYEE_LOADING" });
    let res = yield call(updateUserStatusEmployeeApi, action.payload)
    const responseData = res.data
    yield put({ type: "UPDATE_USER_STATUS_EMPLOYEE_SUCCESS", payload: { responseData, updateData } })
  } catch (e) {
    console.log(e);
    yield put({ type: "UPDATE_USER_STATUS_EMPLOYEE_FAILED", payload: e.message })
  }
}

/* EDIT EMPLOYEE */
function* loadTitle(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchTitleApi, action.payload)
    yield put({ type: "FETCH_TITLE_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_TITLE_FAILED", payload: e.message })
  }
}

function* loadBranch(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchBranchApi, action.payload)
    yield put({ type: "FETCH_BRANCH_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_BRANCH_FAILED", payload: e.message })
  }
}

function* loadDepartment(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchDepartmentApi, action.payload)
    yield put({ type: "FETCH_DEPARTMENT_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_DEPARTMENT_FAILED", payload: e.message })
  }
}

function* loadTypeContract(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchTypeContractApi, action.payload)
    yield put({ type: "FETCH_TYPE_CONTRACT_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_TYPE_CONTRACT_FAILED", payload: e.message })
  }
}

function* loadLevelAbility(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchLevelAbilityApi, action.payload)
    yield put({ type: "FETCH_LEVEL_ABILITY_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_LEVEL_ABILITY_FAILED", payload: e.message })
  }
}

function* loadReasonRecruitment(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchReasonRecruitmentApi, action.payload)
    yield put({ type: "FETCH_REASON_RECRUITMENT_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_REASON_RECRUITMENT_FAILED", payload: e.message })
  }
}

function* loadWorkHour(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchWorkHourApi, action.payload)
    yield put({ type: "FETCH_WORK_HOUR_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_WORK_HOUR_FAILED", payload: e.message })
  }
}

function* loadReasonEnd(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchReasonEndApi, action.payload)
    yield put({ type: "FETCH_REASON_END_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_REASON_END_FAILED", payload: e.message })
  }
}

function* loadTypeEnd(action) {
  try {
    yield put({ type: "SHOW_PROFILE_TYPE_LIST_LOADING" });
    let res = yield call(fetchTypeEndApi, action.payload)
    yield put({ type: "FETCH_TYPE_END_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_TYPE_END_FAILED", payload: e.message })
  }
}


/* WEBGROUP */
function* loadWebGroup(action) {
  try {
    yield put({ type: "SHOW_WEB_GROUP_LOADING" });
    let res = yield call(fetchWebGroup, action.payload)
    yield put({ type: "FETCH_WEB_GROUP_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_WEB_GROUP_FAILED", payload: e.message })
  }
}

function* loadMenuByWebGroup(action) {
  try {
    yield put({ type: "SHOW_MENU_BY_WEB_GROUP_LOADING" });
    let res = yield call(fetchMenuByWebGroup, action.payload)
    yield put({ type: "FETCH_MENU_BY_WEB_GROUP_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_MENU_BY_WEB_GROUP_FAILED", payload: e.message })
  }
}

function* loadStaffByWebGroup(action) {
  try {
    yield put({ type: "SHOW_STAFF_BY_WEB_GROUP_LOADING" });
    let res = yield call(fetchStaffByWebGroup, action.payload)
    yield put({ type: "FETCH_STAFF_BY_WEB_GROUP_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_STAFF_BY_WEB_GROUP_FAILED", payload: e.message })
  }
}

function* loadAppByWebGroup(action) {
  try {
    yield put({ type: "SHOW_APP_BY_WEB_GROUP_LOADING" });
    let res = yield call(fetchAppByWebGroup, action.payload)
    yield put({ type: "FETCH_APP_BY_WEB_GROUP_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_APP_BY_WEB_GROUP_FAILED", payload: e.message })
  }
}

function* loadEmployeeStatus1(action) {
  try {
    yield put({ type: "SHOW_LIST_ALL_EMPLOYEE_STATUS_1_LOADING" });
    let res = yield call(fetchEmployee, action.payload)
    yield put({ type: "FETCH_LIST_ALL_EMPLOYEE_STATUS_1_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_LIST_ALL_EMPLOYEE_STATUS_1_FAILED", payload: e.message })
  }
}

function* createUserGroupPermissionsByWebGroupId(action) {
  try {
    yield put({ type: "SHOW_CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_LOADING" });
    const createData = action.payload
    let res = yield call(createUserGroupPermissionsByWebGroupIdApi, action.payload)
    const responseData = res.data
    console.log(responseData);
    if(responseData.data === 'Success.'){
      yield put({ type: "CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_SUCCESS", payload: {createData, responseData} })
    }
    else{
      yield put({ type: "CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_FAILED" })
    }
  } catch (e) {
    console.log(e);
    yield put({ type: "CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_FAILED", payload: e.message })
  }
}

function* createUserGroupDataAccess(action) {
  try {
    yield put({ type: "SHOW_CREATE_USER_GROUP_DATA_ACCESS_LOADING" });
    let res = yield call(createUserGroupDataAccessApi, action.payload)
    yield put({ type: "CREATE_USER_GROUP_DATA_ACCESS_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "CREATE_USER_GROUP_DATA_ACCESS_FAILED", payload: e.message })
  }
}

function* updateUserGroupApp(action) {
  try {
    yield put({ type: "SHOW_UPDATE_USER_GROUP_APP_LOADING" });
    let res = yield call(updateUserGroupAppApi, action.payload)
    yield put({ type: "UPDATE_USER_GROUP_APP_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "UPDATE_USER_GROUP_APP_FAILED", payload: e.message })
  }
}

function* createWebGroup(action) {
  try {
    yield put({ type: "SHOW_CREATE_WEB_GROUP_LOADING" });
    const createData = action.payload
    let res = yield call(createWebGroupApi, action.payload)
    const responseData = res.data
    yield put({ type: "CREATE_WEB_GROUP_SUCCESS", payload: {responseData, createData} })
  } catch (e) {
    console.log(e);
    yield put({ type: "CREATE_WEB_GROUP_FAILED", payload: e.message })
  }
}

function* updateWebGroup(action) {
  try {
    yield put({ type: "SHOW_UPDATE_WEB_GROUP_LOADING" })
    const updateData = action.payload
    let res = yield call(updateWebGroupApi, action.payload)
    const responseData = res.data
    yield put({ type: "UPDATE_WEB_GROUP_SUCCESS",  payload: {responseData, updateData} })
  } catch (e) {
    console.log(e);
    yield put({ type: "UPDATE_WEB_GROUP_FAILED", payload: e.message })
  }
}

function* loadDataAccessStoreByWebGroupId(action) {
  try {
    yield put({ type: "SHOW_DATA_ACCESS_STORE_BY_WEB_GROUP_ID" })
    let res = yield call(fetchDataAccessStoreByWebGroupId, action.payload)
    yield put({ type: "FETCH_DATA_ACCESS_STORE_BY_WEB_GROUP_ID_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_DATA_ACCESS_STORE_BY_WEB_GROUP_ID_FAILED", payload: e.message })
  }
}

function* loadDataAccessWarehouseByWebGroupId(action) {
  try {
    yield put({ type: "SHOW_DATA_ACCESS_WAREHOUSE_BY_WEB_GROUP_ID" })
    let res = yield call(fetchDataAccessWarehouseByWebGroupId, action.payload)
    yield put({ type: "FETCH_DATA_ACCESS_WAREHOUSE_BY_WEB_GROUP_ID_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_DATA_ACCESS_WAREHOUSE_BY_WEB_GROUP_ID_FAILED", payload: e.message })
  }
}


function* loadApp(action) {
  try {
    yield put({ type: "SHOW_APP_LOADING" });
    let res = yield call(fetchApp, action.payload)
    yield put({ type: "FETCH_APP_SUCCESS", payload: res.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_APP_FAILED", payload: e.message })
  }
}

function* loadAllBanner() {
  try {
    let res = yield call(fecthAllPOSFile);
    yield put({ type: "FETCH_ALL_BANNER_SUCCESSED", payload: res.data.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FETCH_ALL_BANNER_FAILED", payload: e.message })
  }
}

function* fecthPOSFileSettings(action) {
  try {
    yield put({ type: "SHOW_CONFIG_BANNER_LOADING" });
    let res = yield call(fecthPOSFileSetting, action.payload);
    yield put({ type: "FECTH_CONFIG_BANNER_SUCCESSED", payload: res.data.data })
  } catch (e) {
    console.log(e);
    yield put({ type: "FECTH_CONFIG_BANNER_FAILED", payload: e.message })
  }
}

function* loadOrgChart(action) {
  try {
    yield put({ type: "SHOW_ORG_CHART_LOADING" });
    let res = yield call(fetchOrgChart);
    yield put({ type: "FETCH_ORG_CHART_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_ORG_CHART_FAILED", payload: e.message });
  }
}

function* loadListEmployee(action) {
  try {
    yield put({ type: "SHOW_LISTEMPLOYEE_LOADING" });
    let res = yield call(fetchListEmployee);

    yield put({ type: "FETCH_LISTEMPLOYEE_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_LISTEMPLOYEE_FAILED", payload: e.message });
  }
}

function* createOrgChart(action) {
  try {
    yield put({ type: "CREATE_ORG_CHART_LOADING" });
    let res = yield call(createOrgChartApi, action.payload);
    yield put({ type: "CREATE_ORG_CHART_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "CREATE_ORG_CHART_FAILED", payload: e.message });
  }
}



function* loadPaymentType() {
  try {
    let res = yield call(fetchAllPaymentType)
    yield put({ type: "FETCH_ALL_PAYMENTS_TYPE", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_PAYMENTS_TYPE_FAILED", payload: e.message });
  }
}

function* loadServiceMode() {
  try {
    let res = yield call(fetchAllServiceMode)
    yield put({ type: "FETCH_ALL_SERVICE_MODE", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_SERVICE_MODE_FAILED", payload: e.message });
  }
}

function* loadstatusPayment() {
  try {
    let res = yield call(fetchAllStatusPayment)
    yield put({ type: "FETCH_ALL_STATUS_PAYMENT", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_STATUS_PAYMENT_FAILED", payload: e.message });
  }
}

function* loadstatusTransaction() {
  try {
    let res = yield call(fetchAllStatusTransaction)
    yield put({ type: "FETCH_ALL_STATUS_TRANS", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_STATUS_TRANS_FAILED", payload: e.message });
  }
}

function* loadAllTransactionAsync(action) {
  try {
    yield put({ type: "SET_LOADING_TRANS" });
    let res = yield call(getAllTransactionAsync, action.payload);
    yield put({ type: "SET_FETCH_TRANS_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "SET_FETCH_TRANS_FAILED", payload: e.message });
  }
}

function* loadAllTransactionV2Async(action) {
  try {
    yield put({ type: "SET_LOADING_TRANS" });
    let res = yield call(getAllTransactionV2Async, action.payload);
    yield put({ type: "SET_FETCH_TRANS_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "SET_FETCH_TRANS_FAILED", payload: e.message });
  }
}

function* fetchTransactionInfiniteV2Async(action) {
  try {
    yield put({ type: "SET_LOADING_TRANS" });
    let res = yield call(getInfiniteTransactionV2Async, action.payload);
    yield put({ type: "SET_FETCH_TRANS_INFINITE_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SET_FETCH_TRANS_INFINITE_FAILED", payload: e.message });
  }
}

function* fetchTransactionByVoucherId(action) {
  try {
    yield put({ type: "SET_LOADING_TRANS" });
    let res = yield call(getTransactionV2ById, action.payload);
    yield put({ type: "FETCH_TRANSACTION_BY_VOUCHER_ORDER_ID_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_TRANSACTION_BY_VOUCHER_ORDER_ID_FAILED", payload: e.message });
  }
}

/* MATERIAL */
function* updateProductUnitPrice(action) {
  try {
    yield put({ type: "SHOW_UPDATE_PRODUCT_UNIT_PRICE_LOADING" })
    const updateData = action.payload
    console.log(updateData);
    let res = yield call(updateProductUnitPriceApi, action.payload)
    yield put({ type: "CHANGE_PRODUCT_UNIT_PRICE_SUCCESS", payload: {updateData} });
  } catch (e) {
    yield put({ type: "CHANGE_PRODUCT_UNIT_PRICE_FAILED", payload: e.message });
  }
}

function* loadAllAppStoreMaterials(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" })
    let res = yield call(fetchAllAppStoreMaterials, action.payload);
    yield put({ type: "FETCH_APP_STORE_MATERIAL_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_APP_STORE_MATERIAL_FAILED", payload: e.message });
  }
}

function* loadAppStoreMaterialDetail(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" })
    let res = yield call(fetchMaterialStoreAppDetail, action.payload);
    yield put({ type: "FETCH_APP_STORE_MATERIAL_DETAIL_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_APP_STORE_MATERIAL_FAILED", payload: e.message });
  }
}

function* updateAppStoreMaterials(action) {
  try {
    yield put({ type: "SHOW_MATERIAL_LOADING" })
    let res = yield call(updateMaterialStoreApps, action.payload);
    yield put({ type: "SUMIT_CREATE_MATERIAL_APP_STORE_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUMIT_CREATE_MATERIAL_APP_STORE_FAILED", payload: e.message });
  }
}

function* deleteAppStoreMaterials(action) {
  try {
    let res = yield call(deleteMaterialAppStores, action.payload);
    yield put({ type: "SUMIT_DELETE_MATERIAL_APP_STORE_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUMIT_DELETE_MATERIAL_APP_STORE_FAILED", payload: e.message });
  }
}


/*CREATE NEW CHECK*/
function* createNewCheck(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    let res = yield call(createNewInventoryCheck, action.payload)
    yield put({ type: "CREATE_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "CREATE_INVENTORY_CHECK_BY_FILTER_FAILED", payload: e.message });
  }
}

function* updateNewCheck(action) {

  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    console.log("ACTION",action);
    let res = yield call(updateCheckRequest, action.payload)
    yield put({ type: "UPDATE_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_INVENTORY_CHECK_BY_FILTER_FAILED", payload: e.message });
  }
}

function* approveRequestSendFunction(action) {
  try {
    yield put({ type: "SHOW_STOCK_REQUEST_LOADING" });
    console.log("Request Send",action);
    let res = yield call(approveRequestSend, action.payload)
    yield put({ type: "APPROVE_REQUEST_SEND_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "APPROVE_REQUEST_SEND_FAILED", payload: e.message });
  }
}


function* getContentType(action){
  try{
    yield put({ type: "CONFIG_POS_LOADING" });
    let res = yield call(fetchPosContentTypeApi, action.payload)
    yield put({type: "GET_CONTENT_TYPE_SUCCEEDED", payload: res.data});
  }
  catch(e){
    yield put({type: "GET_CONTENT_TYPE_FAILED", payload: e.message});
  }
}
function* getContentSetting(action){
  try{
    yield put({ type: "CONFIG_POS_LOADING" });
    let res = yield call(fetchPosContentSettingApi, action.payload)
    yield put({type: "GET_CONTENT_SETTING_SUCCEEDED", payload: res.data});
  }
  catch(e){
    yield put({type: "GET_CONTENT_TYPE_FAILED", payload: e.message});
  }
}
function* setPOSContentType(action){
  try{
    yield put({ type: "CONFIG_POS_LOADING" });
    let res = yield call(setPosContentTypeApi, action.payload)
    yield put({type: "SET_CONTENT_TYPE_SUCCEEDED", payload: res.data});
  }
  catch(e){
    yield put({type: "SET_CONTENT_TYPE_FAILED", payload: e.message});
  }
}
///api/HRApp/GetPOSFilesSetting
function* getPOSFileSettings(action){
  try{
    yield put({ type: "CONFIG_POS_LOADING" });
    let res = yield call(fetchPosFileSettingApi, action.payload)
    yield put({type: "GET_POS_FILE_SUCCEEDED", payload: res.data});
  }
  catch(e){
    yield put({type: "GET_POS_FILE_FAILED", payload: e.message});
  }
}

// SR Produces Saga
function* createNewSRProduceAPI(action){
  try{
    yield put({ type: "SHOW_SR_PRODUCE_LOADING" });
    let res = yield call(createNewSRProduce, action.payload)
    yield put({type: "SUBMIT_NEW_SR_PRODUCE_SUCCEEDED", payload: res.data});
  }
  catch(e){
    yield put({type: "SUBMIT_NEW_SR_PRODUCE_FAILED", payload: e.message});
  }
}


function* fetchSRProduceById(action){
  try{
    yield put({ type: "SHOW_SR_PRODUCE_LOADING" });
    let res = yield call(getSRProduceById, action.payload)
    yield put({type: "FETCH_SR_PRODUCE_DETAIL_SUCCEEDED", payload: res.data.data });
  }
  catch(e){
    yield put({type: "FETCH_SR_PRODUCE_DETAIL_FAILED", payload: e.message});
  }
}

function* approveStatusSRProduce(action){
  try{
    let workFlowPayload = {
      WorkflowTypeId: workFlowTypes.VIC_RMD_EXPORT_MATERIAL,
      TransactionId: action.payload.id,
      CreatedBy: action.payload.createdBy
    };
    let res = yield call(approveRequestApi, workFlowPayload);
    yield put({type: "APPROVE_STATUS_SR_PRODUCE_SUCCESSED", payload: res.data });
  }
  catch(e){
    yield put({type: "APPROVE_STATUS_SR_PRODUCE_FAILED", payload: e.message});
  }
}

function* approveTransactionSRProduce(action){
  try{
    let res = yield call(approveTransactionApi, action.payload);
    yield put({type: "APPROVE_STATUS_SR_PRODUCE_SUCCESSED", payload: res.data });
  }
  catch(e){
    yield put({type: "APPROVE_STATUS_SR_PRODUCE_FAILED", payload: e.message});
  }
}

function* updateSRProduceVIC(action){
  try{
    yield put({ type: "SHOW_SR_PRODUCE_LOADING" });
    let res = yield call(updateSRProduce, action.payload)
    yield put({type: "UPDATE_SR_PRODUCE_SUCCEEDED", payload: res.data.data });
  }
  catch(e){
    yield put({type: "UPDATE_SR_PRODUCE_FAILED", payload: e.message});
  }
}

// SAGAS VICTORY
function* loadSRProduce(action) {
  try {
    yield put({ type: "SHOW_SR_PRODUCE_LOADING" });
    let res = yield call(fetchSRProduceApi, action.payload);
    yield put({ type: "FETCH_SR_PRODUCE_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SR_PRODUCE_FAILED", payload: e.mesage })
  }
}

function* loadSRDeliver(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(fetchSRDeliverApi, action.payload);
    yield put({ type: "FETCH_SR_DELIVER_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SR_DELIVER_FAILED", payload: e.mesage })
  }
}

function* createNewSRDeliver(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(newSRDeliverApi, action.payload);
    yield put({ type: "SUBMIT_NEW_SR_DELIVER_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_SR_DELIVER_FAILED", payload: e.mesage })
  }
}

function* updateSRDeliver(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(updateSEDeliver, action.payload);
    yield put({ type: "UPDATE_SR_DELIVER_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "UPDATE_SR_DELIVER_FAILED", payload: e.mesage })
  }
}

function* getSRDeliverById(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(fetchSRDeliverById, action.payload);
    yield put({ type: "FETCH_SR_DELIVER_BY_ID_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SR_DELIVER_BY_ID_FAILED", payload: e.mesage })
  }
}

function* fetchListDriverWithPlate(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(fetchListDriverWithPlateApi, action.payload);
    yield put({ type: "FETCH_LIST_DRIVER_BY_PLATE_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_LIST_DRIVER_BY_PLATE_FAILED", payload: e.mesage })
  }
}

function* fetchDrivers(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(fetchDriversApi, action.payload);
    yield put({ type: "FETCH_LIST_DRIVERS_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_LIST_DRIVERS_FAILED", payload: e.mesage })
  }
}

function* createNewDrivers(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(createNewDriversApi, action.payload);
    yield put({ type: "SUBMIT_NEW_DRIVERS_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_DRIVERS_FAILED", payload: e.mesage })
  }
}

function* fetchDriversById(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(fetchDriversByIdApi, action.payload);
    yield put({ type: "FETCH_DRIVER_BY_ID_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_DRIVER_BY_ID_FAILED", payload: e.mesage })
  }
}

function* updateDrivers(action) {
  try {
    yield put({ type: "SHOW_SR_DELIVER_LOADING" });
    let res = yield call(updateDriversApi, action.payload);
    yield put({ type: "SUBMIT_UPDATE_DRIVERS_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_UPDATE_DRIVERS_FAILED", payload: e.mesage })
  }
}

function* loadSRReceived(action) {
  try {
    yield put({ type: "SHOW_SR_RECEIVED_LOADING" });
    let res = yield call(fetchSRReceivedApi, action.payload);
    yield put({ type: "FETCH_SR_RECEIVED_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SR_RECEIVED_FAILED", payload: e.mesage })
  }
}

function* loadGoodTransferList(action) {
  try {
    yield put({ type: "SHOW_GOOD_TRANSFER_LOADING" });
    let res = yield call(fetchGoodTransferListApi, action.payload);
    yield put({ type: "FETCH_GOOD_TRANSFER_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_GOOD_TRANSFER_FAILED", payload: e.mesage })
  }
}

function* loadGoodTransferDetail(action) {
  try {
    yield put({ type: "SHOW_GOOD_TRANSFER_LOADING" });
    let res = yield call(fetchGoodTransferDetailApi, action.payload);
    yield put({ type: "FETCH_GOOD_TRANSFER_BY_ID_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_GOOD_TRANSFER_BY_ID_FAILED", payload: e.mesage })
  }
}

function* newSRReceived(action) {
  try {
    yield put({ type: "SHOW_SR_RECEIVED_LOADING" });
    let res = yield call(createNewSRReceived, action.payload);
    yield put({ type: "SUBMIT_NEW_SR_RECEIVED_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_SR_RECEIVED_FAILED", payload: e.mesage })
  }
}

function* getSRReceivedById(action) {
  try {
    yield put({ type: "SHOW_SR_RECEIVED_LOADING" });
    let res = yield call(fetchSRReceivedById, action.payload);
    yield put({ type: "FETCH_SR_RECEIVED_BY_ID_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SR_RECEIVED_BY_ID_FAILED", payload: e.mesage })
  }
}

function* loadCommodityDelivery(action) {
  try {
    yield put({ type: "SHOW_LOADING_CMD_DELIVERY" });
    let res = yield call(fetchCommodityDeliveryApi, action.payload);
    yield put({ type: "FETCH_CMD_DELIVERY_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_CMD_DELIVERY_FAILED", payload: e.mesage })
  }
}

function* createNewCommodityDelivery(action) {
  try {
    yield put({ type: "SHOW_LOADING_CMD_DELIVERY" });
    let res = yield call(createNewCmdDelivery, action.payload);
    yield put({ type: "SUBMIT_NEW_CMD_DELIVERY_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_CMD_DELIVERY_FAILED", payload: e.mesage })
  }
}

function* fetchCommodityDeliveryById(action) {
  try {
    yield put({ type: "SHOW_LOADING_CMD_DELIVERY" });
    let res = yield call(fetchCmdDeliveryById, action.payload);
    yield put({ type: "FETCH_COMMODITY_DELIVERY_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_COMMODITY_DELIVERY_DETAIL_FAILED", payload: e.mesage })
  }
}

function* loadCommodityCancelation(action) {
  try {
    yield put({ type: "SHOW_LOADING_CMD_CANCELATE" });
    let res = yield call(fetchCommodityCancelationApi, action.payload);
    yield put({ type: "FETCH_CMD_CANCELATE_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_CMD_CANCELATE_FAILED", payload: e.mesage })
  }
}

function* createNewCommodityCancelation(action) {
  try {
    yield put({ type: "SHOW_LOADING_CMD_CANCELATE" });
    let res = yield call(createNewCmdCancelation, action.payload);
    yield put({ type: "SUBMIT_NEW_CMD_CANCELATE_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_CMD_CANCELATE_FAILED", payload: e.mesage })
  }
}

function* fetchCommodityCancelationById(action) {
  try {
    yield put({ type: "SHOW_LOADING_CMD_CANCELATE" });
    let res = yield call(fetchCmdCancelationById, action.payload);
    yield put({ type: "FETCH_COMMODITY_CANCELATE_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_COMMODITY_CANCELATE_DETAIL_FAILED", payload: e.mesage })
  }
}

function* loadGoodsReturning(action) {
  try {
    yield put({ type: "SHOW_LOADING_GOODS_RETURN" });
    let res = yield call(fetchGoodsReturningApi, action.payload);
    yield put({ type: "FETCH_GOODS_RETURN_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_RETURN_FAILED", payload: e.mesage })
  }
}

function* createNewGoodsReturning(action) {
  try {
    yield put({ type: "SHOW_LOADING_GOODS_RETURN" });
    let res = yield call(createNewGoodsReturn, action.payload);
    yield put({ type: "SUBMIT_NEW_GOODS_RETURN_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_GOODS_RETURN_FAILED", payload: e.mesage })
  }
}

function* fetchGoodsReturnByIds(action) {
  try {
    yield put({ type: "SHOW_LOADING_GOODS_RETURN" });
    let res = yield call(fetchGoodsReturnById, action.payload);
    yield put({ type: "FETCH_GOODS_RETURN_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_RETURN_DETAIL_FAILED", payload: e.mesage })
  }
}

function* fetchSaleVoucherOrders(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(fetchVoucherSaleOrdersApi, action.payload);
    yield put({ type: "FETCH_SALE_VOUCHER_ORDER_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SALE_VOUCHER_ORDER_FAILED", payload: e.mesage })
  }
}

function* callSaleVoucherOrderToDeliver(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(fetchVoucherSaleOrderToDeliver, action.payload);
    yield put({ type: "FETCH_SALE_VOUCHER_ORDER_TO_DELIVER_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SALE_VOUCHER_ORDER_TO_DELIVER_FAILED", payload: e.mesage })
  }
}

function* fetchListProductionDiary(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(fetchListProductionDiaryApi, action.payload);
    yield put({ type: "FETCH_PRODUCTION_DIARY_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_PRODUCTION_DIARY_FAILED", payload: e.mesage })
  }
}

function* createNewProductionDiary(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(createNewProductionDiaryApi, action.payload);
    yield put({ type: "CREATE_NEW_PRODUCTION_DIARY_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "CREATE_NEW_PRODUCTION_DIARY_FAILED", payload: e.mesage })
  }
}


function* fetchSaleOrders(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(fetchSaleOrderApi, action.payload);
    yield put({ type: "FETCH_SALE_ORDERS_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_SALE_ORDERS_FAILED", payload: e.mesage })
  }
}


function* fetchSaleOrderDetails(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(fetchSaleOrderById, action.payload);
    yield put({ type: "FETCH_SALE_ORDERS_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_SALE_ORDERS_DETAIL_FAILED", payload: e.mesage })
  }
}

function* fetchProductionDiaryDetail(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(fetchProductionDiaryByIdApi, action.payload);
    yield put({ type: "FETCH_PRODUCTION_DIARY_DETAIL_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_PRODUCTION_DIARY_DETAIL_FAILED", payload: e.mesage })
  }
}

function* updateProductionDiary(action) {
  try {
    yield put({ type: "SHOW_SALE_ORDER_LOADING" });
    let res = yield call(updateProductionDiaryApi, action.payload);
    yield put({ type: "UPDATED_PRODUCTION_DIARY_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "UPDATED_PRODUCTION_DIARY_FAILED", payload: e.mesage })
  }
}


function* fetchAllCustomers(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(fetchAllCustomer, action.payload);
    yield put({ type: "FETCH_ALL_CUSTOMERS_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_CUSTOMERS_FAILED", payload: e.mesage })
  }
}

function* fetchAllCustomerGroups(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(fecthAllCustomerGroup, action.payload);
    yield put({ type: "FETCH_ALL_CUSTOMERS_GROUP_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_ALL_CUSTOMERS_GROUP_FAILED", payload: e.mesage })
  }
}

function* createCustomer(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(createNewCustomer, action.payload);
    yield put({ type: "SUBMIT_CREATE_CUSTOMER_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_CREATE_CUSTOMER_FAILED", payload: e.mesage })
  }
}

function* fecthCustomerById(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(fecthDetailsCustomer, action.payload);
    yield put({ type: "FETCH_DETAILS_CUSTOMER_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_DETAILS_CUSTOMER_FAILED", payload: e.mesage })
  }
}

function* udpatedCustomer(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(updateCustomer, action.payload);
    yield put({ type: "SUBMIT_UPDATE_CUSTOMER_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_UPDATE_CUSTOMER_FAILED", payload: e.mesage })
  }
}

function* createNewGroupCustomer(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(createNewGroupCustomerApi, action.payload);
    yield put({ type: "SUBMIT_NEW_GROUP_CUSTOMER_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_NEW_GROUP_CUSTOMER_FAILED", payload: e.mesage })
  }
}

function* fetchDetailGroupCustomer(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(fecthDetailsGroupCustomerApi, action.payload);
    yield put({ type: "FETCH_DETAILS_GROUP_CUSTOMER_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_DETAILS_GROUP_CUSTOMER_FAILED", payload: e.mesage })
  }
}

function* updateGroupCustomer(action) {
  try {
    yield put({ type: "SET_CUSTOMERS_LOADING" });
    let res = yield call(updateGroupCustomerApi, action.payload);
    yield put({ type: "SUBMIT_UPDATE_GROUP_CUSTOMER_SUCCESSED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "SUBMIT_UPDATE_GROUP_CUSTOMER_FAILED", payload: e.mesage })
  }
}

function* getProductsWithCustomer(action) {
  try {
    yield put({ type: "SHOW_APP_LOADING" });
    let res = yield call(getProductsWithCustomerApi, action.payload);
    yield put({ type: "FETCH_LIST_PRODUCT_WITH_CUSTOMERS_SUCCESSED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_LIST_PRODUCT_WITH_CUSTOMERS_FAILED", payload: e.mesage })
  }
}




//----------------------------------------------
function* mySaga() {
  yield takeEvery("LOAD_CATEGORY", loadCategory);
  yield takeEvery("LOAD_CATEGORY_PAGINATION", loadCategoryPagination);
  yield takeEvery("LOAD_MATERIAL", loadMaterial);
  yield takeEvery("LOAD_MATERIAL_PAGINATION", loadMaterialPagination);
  yield takeEvery("LOAD_ACTIVE_MATERIAL", loadActiveMaterial);
  yield takeEvery("LOAD_FORMULA", loadFormula);
  yield takeEvery("LOAD_FORMULA_PAGINATION", loadFormulaPagination);
  yield takeEvery("LOAD_MATERIAL_BRAND", loadMaterialBrand);
  yield takeEvery("LOAD_MATERIAL_BRAND_PAGINATION", loadMaterialBrandPagination);
  yield takeEvery("LOAD_CONVERSION", loadConversion);
  yield takeEvery("LOAD_CONVERSION_PAGINATION", loadConversionPagination);
  yield takeEvery("LOAD_WAREHOUSE", loadWarehouse);
  yield takeEvery("LOAD_WAREHOUSE_PAGINATION", loadWarehousePagination);
  yield takeEvery("LOAD_ALL_WAREHOUSE", loadAllWarehouse);
  yield takeEvery("LOAD_ACTIVE_MATERIAL_WITH_WAREHOUSE", loadAllActiveMaterialWarehouse);
  yield takeEvery("LOAD_ACTIVE_MATERIAL_TP_WITH_WAREHOUSE", loadAllActiveMaterialTPWarehouse);

  yield takeEvery("LOAD_ACTIVE_MATERIAL_TP", loadActiveMaterialTP);

  yield takeEvery("LOAD_SITE", loadSite);
  yield takeEvery("LOAD_SITE_PAGINATION", loadSitePagination);
  yield takeEvery("UPDATE_SITE", updateSite);
  yield takeEvery("LOAD_SUPPLIER", loadSupplier);
  yield takeEvery("SAVE_NEW_SUPPLIER", submitNewSupplier);

  yield takeEvery("LOAD_MANUFACTURER", loadManufacturer);
  yield takeEvery("SAVE_NEW_MANUFACTURER", submitNewManufacturer);

  yield takeEvery("SUBMIT_NEW_GOODS_RECEIVE", submitNewGoodsReceived);
  yield takeEvery("SUBMIT_NEW_GOODS_RECEIVE_V2", submitNewGoodsReceivedV2);
  yield takeEvery("APPROVE_GOODS_RECEIVE_V2", approveGoodsReceived);

  yield takeEvery("UPDATE_GOODS_RECEIVED", updateGoodsReceived);

  yield takeEvery("LOAD_BRAND_BY_MATERIAL", loadBrandByMaterialId);
  yield takeEvery("LOAD_UOM", loadUomByMaterialAndBrandId);
  yield takeEvery("LOAD_UOM_BY_MATERIAL", loadUomByMaterial);
  yield takeEvery("LOAD_GOODS_RECEIVEDS", loadGoodsReceiveds);
  yield takeEvery("LOAD_GOODS_RECEIVEDS_V2", loadGoodsReceivedsV2);
  yield takeEvery("DELETE_GOODS_RECEIVEDS_V2", deleteGoodsReceivedV2);
  yield takeEvery("LOAD_GOODS_RECEIVED_DETAIL", loadGoodsReceivedDetail);
  yield takeEvery("LOAD_WAREHOUSE_STOCK", loadWarehouseStock);
  yield takeEvery("LOAD_LOST_STOCK", loadLostStock);
  yield takeEvery("LOAD_CHECK_STOCK", loadCheckStock);
  yield takeEvery("SUBMIT_NEW_STOCK_REQUEST", submitNewStockRequest);


  yield takeEvery("SUBMIT_NEW_STOCK_REQUEST_V1", newStockRequest);
  yield takeEvery("SUBMIT_APPROVE_REQUEST", approveRequest);

  yield takeEvery("UPDATE_STOCK_REQUEST", updateStockRequest);
  yield takeEvery("UPDATE_STOCK_REQUEST_V2", updateStockRequestApiV2);
  yield takeEvery("UPDATE_STOCK_REQUEST_V3", updateStockRequestApi_V3);
  yield takeEvery("DELETE_STOCK_REQUEST", deleteStockRequestApi);
  yield takeEvery("UPDATE_STATUS_STOCK_REQUEST", updateStockRequestStatus);

  yield takeEvery("LOAD_STOCK_REQUEST", loadStockRequest);
  yield takeEvery("LOAD_ALL_STATUS_STOCK_REQUEST", loadAllStockRequest);
  yield takeEvery("LOAD_MATERIAL_FILTER_REQUEST", loadMaterialFilter);
  yield takeEvery("LOAD_MATERIAL_FILTER_REQUEST_BY_CATEGORY", loadMaterialFilterByCategory);

  yield takeEvery("LOAD_STOCK_REQUEST_BY_FILTER", loadStockRequestByFilter);
  yield takeEvery("LOAD_INVENTORY_MANAGE_REQUEST_BY_FILTER", loadInventoryManageByFilter);
  yield takeEvery("LOAD_INVENTORY_CHECK_REQUEST_BY_FILTER", loadInventoryCheckByFilter);
  yield takeEvery("LOAD_INVENTORY_LOST_REQUEST_BY_FILTER", loadInventoryLostByFilter);
  yield takeEvery("LOAD_STOCK_REQUEST_BY_WAREHOUSE", loadStockRequestByWarehouse);
  yield takeEvery("LOAD_STOCK_REQUEST_DETAIL", loadStockRequestDetail);
  yield takeEvery("LOAD_CHECK_REQUEST_DETAIL", loadCheckRequestDetail);

  yield takeEvery('LOAD_STOCK_REQUEST_DETAIL_STOCK_TRANSFER', loadStockRequestDetailInStockTransfer)
  // yield takeEvery("SET_WAREHOUSE_ID", updateWarehouse);
  yield takeEvery("SUBMIT_NEW_STOCK_TRANSFER", submitNewStockTransfer);
  yield takeEvery("LOAD_STOCK_TRANSFER", loadStockTransfers);

  yield takeEvery("LOAD_STOCK_TRANSFER_DETAIL", loadStockTransferDetail);

  yield takeEvery("LOAD_MATERIAL_INSTANCES_BY_WAREHOUSE", loadMaterialInstancesByWarehouse);

  yield takeEvery("SUBMIT_NEW_STOCK_DESTRUCTION", submitNewStockDestruction);
  yield takeEvery("SUBMIT_NEW_STOCK_DESTRUCTION_V2", submitNewStockDestructionV2);
  yield takeEvery("SUBMIT_UPDATE_STOCK_DESTRUCTION_V2", updateNewStockDestructionV2);

  yield takeEvery("SUBMIT_NEW_SCHEDULE", submitNewSchedule);

  yield takeEvery("SUBMIT_NEW_PAYSLIP", submitNewPayslip);
  yield takeEvery('LOAD_PAYSLIPS', loadPayslip);
  yield takeEvery('LOAD_PAYSLIP_DETAILS', loadPayslipDetail);
  yield takeEvery('SEND_EMAIL_PAYSLIP', sendEmialPayslip)

  yield takeEvery("LOAD_ALL_UOMS", loadAllUoms);
  yield takeEvery("LOAD_ALL_UOMS_PAGINATION", loadAllUomsPagination);
  yield takeEvery("SAVE_NEW_UOM", submitNewUom);
  yield takeEvery("UPDATE_UOM", callUpdateUom);

  yield takeEvery("UPDATE_WAREHOUSE", callUpdateWarehouse);

  yield takeEvery("LOAD_ALL_BRANDS", loadAllBrands);
  yield takeEvery("LOAD_ALL_BRANDS_PAGINATION", loadAllBrandsPagination);
  yield takeEvery("SAVE_NEW_BRAND", submitNewBrand);
  yield takeEvery("UPDATE_BRAND", updateNewBrand);
  yield takeEvery("LOAD_PARENT_CATEGORIES", loadParentCategories);
  yield takeEvery("SAVE_NEW_CATEGORY", submitNewCategory)
  yield takeEvery("UPDATE_CATEGORY", callUpdateCategory)

  yield takeEvery("SAVE_NEW_MATERIAL", submitNewMaterial)
  yield takeEvery("UPDATE_MATERIAL", callUpdateMaterial)
  yield takeEvery("LOAD_GET_MATERIAL_DATA_DETAIL", loadGetMaterialDataDetail);

  yield takeEvery("SAVE_NEW_MATERIAL_BRAND", submitNewMaterialBrand)
  yield takeEvery("UPDATE_MATERIAL_BRAND", updateMaterialBrand)

  yield takeEvery("SAVE_NEW_CONVERSION", submitNewConversion)

  yield takeEvery("CREATED_NEW_MATERIAL_APP_STORES", createdNewMaterialAppStores)

  // yield takeEvery("MODAL_CHANGE_MATERIAL_CODE", submitUpdateMaterialCode)

  yield takeEvery("SAVE_NEW_FORMULA", submitNewFormula)
  yield takeEvery("UPDATE_FORMULA", updateFormula)
  yield takeEvery("LOAD_FORMULA_DETAIL", loadFormulaDetail)
  yield takeEvery("GET_UOM_MATERIAL", getUomOfMaterial)
  yield takeEvery("LOAD_LOOP_TOKEN", loadLoopToken)
  yield takeEvery("LOAD_LOOP_MANAGEMENT_TOKEN", loadLoopManagementToken)
  yield takeEvery("LOAD_LOOP_DATA_PRODUCT_SOLD", loadLoopDataProductSold)

  yield takeEvery("LOAD_LOOP_TRANSACTIONS", loadLoopTransactions)
  yield takeEvery("SUBMIT_NEW_LOOP_GET_DATA", submitNewLoopGetData)

  yield takeEvery("LOAD_STOCK_TAKE", loadStockTake)
  yield takeEvery("LOAD_STOCK_TAKE_DETAIL", loadStockTakeDetail);
  yield takeEvery("APPROVE_STOCK_TAKE", approveStockTake);

  yield takeEvery("LOAD_GET_LOOP_DATA_DETAIL", loadGetLoopDataDetail);

  yield takeEvery("LOAD_STOCK_DESTRUCTIONS", loadStockDestructions);

  yield takeEvery("LOAD_UPLOAD_MASTERS", loadUploadMasters);
  yield takeEvery("LOAD_SCHEDULE_DETAIL", loadScheduleDetail);
  yield takeEvery("FETCH_SCHEDULE_REPORT", loadScheduleReport);
  yield takeEvery('FETCH_GOODS_PRODUCE_REPORT', loadGoodsProduceReport);
  yield takeEvery("LOAD_ALL_STAFF_MASTER", loadAllStaffMaster);

  yield takeEvery("LOAD_STOCK_RECONCILES", loadStockReconciles);

  yield takeEvery("SUBMIT_NEW_STOCK_RECONCILE", submitNewStockReconciles);

  yield takeEvery("DOWNLOAD_STOCK_RECONCILE", downloadStockReconcile);

  yield takeEvery("SUBMIT_UPDATE_CONVERSION", submitUpdateConversion);

  yield takeEvery("SUBMIT_NEW_STOCK_TAKE", submitNewStockTake)
  yield takeEvery("SUBMIT_NEW_STOCK_BALANCE", submitNewStockBalance)

  yield takeEvery("LOAD_STOCK_BALANCES", loadStockBalances)
  yield takeEvery("LOAD_STOCK_BALANCE_DETAIL", loadStockBalanceDetail)

  yield takeEvery("LOAD_STOCK_DESTRUCTION_DETAIL", loadStockDestructionDetail)
  yield takeEvery("UPDATE_STATUS_STOCK_DESTRUCTION", UpdateStatusStockDestruction)

  yield takeEvery("DELETE_FORMULA", deleteFormula)

  yield takeEvery("LOAD_PRODUCT", loadProduct);
  yield takeEvery("UPDATE_PRODUCT_UNIT_PRICE", submitUpdateProductUnitPrice);

  yield takeEvery("FETCH_SALES_REPORT", loadSalesReport);
  yield takeEvery("CANCEL_BILL", cancelBill);
  yield takeEvery("APPROVE_BILL", approveBill);

  yield takeEvery("FETCH_ORDERS", loadOrders);

  yield takeEvery("HIDE_PRODUCT", hideProduct);

  yield takeEvery("LOAD_STORE_POS_INFO", loadStorePOSInfo);

  yield takeEvery("UPDATE_STORE", updateStore)

  yield takeEvery("SUBMIT_CATEGORY_ORDER_BY", updateCategoryOrderBy)

  yield takeEvery("LOAD_FEEDBACK", loadFeedbacks);
  yield takeEvery("LOAD_GET_FEEDBACK_DATA_DETAIL", loadGetFeedbackDataDetail);
  yield takeEvery("SUBMIT_NEW_FEEDBACK", submitNewFeedback);
  // yield takeEvery("SUBMIT_NEW_FEEDBACK_GET_DATA", submitNewFeedbackGetData)
  yield takeEvery("LOAD_STORE", loadStore);
  yield takeEvery("FETCH_SALES_FILTER", loadSaleFilterReport);
  yield takeEvery("LOAD_GET_SALE_DATA_DETAIL", loadGetSaleDataDetail);
  yield takeEvery("LOAD_VOUCHER_ORDERS", loadVoucherOrders);
  yield takeEvery("LOAD_POS_VOUCHER", loadPOSVoucher);
  yield takeEvery("LOAD_APP_VOUCHER", loadAppVoucher);
  yield takeEvery("LOAD_CATEGORIES_VOUCHER", loadCategoriesFilter);
  yield takeEvery("LOAD_MATERIALS_VOUCHER", loadMaterialsFilter);
  yield takeEvery("LOAD_REDEEM_POINT_VOUCHER", loadMaterialRedeemVoucher);
  yield takeEvery("LOAD_LIST_USER_VOUCHER", loadListUserVoucher);

  yield takeEvery("CANCEL_VOUCHER_ORDERS", cancelVoucherOrder);
  yield takeEvery("APPROVE_VOUCHER_ORDERS", approveVoucherOrderV2);
  yield takeEvery("FETCH_DISCOUNT_ITEMS", getDiscountItems);
  yield takeEvery("CREATE_NEW_VOUCHER_ORDER", createNewVoucherOrder);
  yield takeEvery("UPDATE_VOUCHER_ORDER", updateVoucherOrder);
  

  yield takeEvery("LOAD_POS_VOUCHER_DETAIL", loadPOSVoucherDetail);
  yield takeEvery("LOAD_APP_VOUCHER_DETAIL", loadAppVoucherDetail);
  yield takeEvery("CREATED_POS_VOUCHER", newPOSVoucher);
  yield takeEvery("UPDATED_POS_VOUCHER", updatedPOSVoucher);
  yield takeEvery("UPDATED_STATUS_POS_VOUCHER", updatedStatusPOSVoucher);
  yield takeEvery("CREATED_APP_VOUCHER", newAppVoucher);
  yield takeEvery("UPDATED_APP_VOUCHER", updatedAppVoucher);
  yield takeEvery("UPDATED_STATUS_APP_VOUCHER", updatedStatusAppVoucher);
  yield takeEvery("CREATED_REDEEM_VOUCHER", newRedeemVoucher);
  yield takeEvery("UPDATE_REDEEM_VOUCHER", updatedRedeemVoucher);
  yield takeEvery("UPDATE_STATUS_REDEEM_VOUCHER", updatedStatusRedeemVoucher);


  yield takeEvery("LOAD_STOCK_REQUEST_PENDING", loadingStockRequestPending)
  yield takeEvery("LOAD_PURCHASE_ORDER_DETAIL", loadPurchaseOrderDetail);
  yield takeEvery("LOAD_PURCHASE_ORDER", loadPurchaseOrder);
  yield takeEvery("SUBMIT_NEW_PURCHASE_ORDER", submitNewPurchaseOrder);

  yield takeEvery("SUBMIT_STOCK_REQUEST_WORK_FLOW_ACTION", submitStockRequestWorkFlowAction);

  yield takeEvery("SUBMIT_STOCK_TAKE_WORK_FLOW_ACTION", submitStockTakeWorkFlowAction)

  yield takeEvery("SUBMIT_PO_WORK_FLOW_ACTION", submitPOWorkFlowAction);

  yield takeEvery("SUBMIT_GOODS_RECIEVED_WORK_FLOW_ACTION", submitGoodsRecievedFlowAction);

  yield takeEvery("SUBMIT_STOCK_TRANSFER_WORK_FLOW_ACTION", submitStockTransferFlowAction);
  yield takeEvery("SUBMIT_SEND_EMAIL_PAYSLIP", sendEmailPaySlipVictory);

  yield takeEvery("SUBMIT_STOCK_DESTRUCTION_WORK_FLOW_ACTION", submitStockDestructionFlowAction);

  yield takeEvery("SUBMIT_GOODS_PRODUCE_WORK_FLOW_ACTION", submitGoodsProduceFlowAction);

  yield takeEvery("LOAD_FEEDBACK", loadFeedbacks);
  yield takeEvery("LOAD_GET_FEEDBACK_DATA_DETAIL", loadGetFeedbackDataDetail);
  yield takeEvery("SUBMIT_NEW_FEEDBACK", submitNewFeedback);
  // yield takeEvery("SUBMIT_NEW_FEEDBACK_GET_DATA", submitNewFeedbackGetData)
  // yield takeEvery("LOAD_STORE", loadStore);
  // yield takeEvery("FETCH_SALES_FILTER", loadSaleFilterReport);
  yield takeEvery("LOAD_GET_SALE_DATA_DETAIL", loadGetSaleDataDetail);
  yield takeEvery("LOAD_VOUCHER_ORDERS", loadVoucherOrders);

  yield takeEvery("SAVE_NEW_WAREHOUSE", callCreateWarehouse);

  yield takeEvery("LOAD_GOODS_PRODUCE_DETAIL", loadGoodsProduceDetail);
  yield takeEvery("SUBMIT_NEW_GOODS_PRODUCE", submitNewGoodsProduce);
  yield takeEvery("LOAD_GOODS_PRODUCES", loadGoodsProduces);
  yield takeEvery("LOAD_GOODS_PRODUCES_V2", loadGoodsProducesV2);
  yield takeEvery("LOAD_ALL_STATUS_GOODS_PRODUCE", loadAllStatusGoodsProduce);

  yield takeEvery('LOAD_STOCK_REQUEST_STOCK_TRANSFER', loadStockRequestInStockTransfer)

  yield takeEvery('SET_ORDER_STATUS', setOrderStatus);
  yield takeEvery('LOAD_STORE_FORMULA', loadStoreFormula);

  yield takeEvery('LOAD_SHIPPER_LIST', loadShipperList);
  yield takeEvery('SET_SHIPPER_STATUS', setShipperStatus);
  yield takeEvery('LOAD_ORDER_DETAIL', loadOrderDetail);

  yield takeEvery("FETCH_CASH_IN_REPORT", loadCashInReport);
  yield takeEvery("CONFIRM_APPROVE_POS_CUT", SubmitCashInReport);
  yield takeEvery("DEFAULT_FOLDES", defaultFolders);
  yield takeEvery("CHANGE_FOLDER_NAME", changeFolderName);
  yield takeEvery("CHANGE_FILE_NAME", changeFileName);
  yield takeEvery("DELETE_FILE", deleteFile);
  yield takeEvery("DELETE_FOLDER", deleteFolder);
  yield takeEvery("LOAD_FOLDERS", loadFolders);
  yield takeEvery("SUBMIT_NEW_FOLDER", submitNewFolder);
  yield takeEvery("UPLOAD_NEW_FILE", uploadNewFile);
  yield takeEvery("UPLOAD_NEW_FOLDER", uploadNewFolder);
  yield takeEvery("PIN_NOTIFY_FILE", pinFile);
  yield takeEvery("GET_GENARAL_INFOMATION", getGenaralInfomation);
  yield takeEvery("ADD_UPLOAD_PAYSLIP_VICTORY", addUploadPaySlipVictor);
  yield takeEvery("GET_MONTH_PAYSLIP_VICTORY", getMonthPaySlipVictory);
  yield takeEvery("GET_PAYSLIP_DETAIL_VICTORY", getPaySlipDetailVictory);

  /* REVENUE */
  yield takeEvery("LOAD_REVENUE_DATE", loadRevenueDate)
  yield takeEvery("LOAD_REVENUE_DATE_OVERVIEW", loadRevenueDateOverview)

  yield takeEvery("LOAD_REVENUE_WEEK", loadRevenueWeek)
  yield takeEvery("LOAD_REVENUE_MONTH", loadRevenueMonth)
  yield takeEvery("LOAD_REVENUE_QUARTER", loadRevenueQuarter)
  yield takeEvery("LOAD_REVENUE_YEAR", loadRevenueYear)
  yield takeEvery("LOAD_REVENUE_GENERAL", loadRevenueGeneral)
  yield takeEvery("LOAD_REVENUE_HOUR", loadRevenueHour)
  yield takeEvery("LOAD_REVENUE_CATEGORY", loadRevenueCategory)
  yield takeEvery("LOAD_REVENUE_SHIFT", loadRevenueShift)
  yield takeEvery("LOAD_REVENUE_SHIFT_DETAIL", loadRevenueShiftDetail)
  yield takeEvery("LOAD_REVENUE_SERVICE", loadRevenueServiceMode)
  yield takeEvery("LOAD_REVENUE_PAYMENT", loadRevenuePayment)

  yield takeEvery("LOAD_TOTAL_FOOD_SOLD", loadTotalFoodSold)
  yield takeEvery("LOAD_REVENUE_COMBO", loadRevenueCombo);

  /* EMPLOYEE */
  yield takeEvery("LOAD_EMPLOYEE", loadEmployee)
  yield takeEvery("LOAD_EMPLOYEE_DETAIL", loadEmployeeDetail)
  yield takeEvery("CREATE_NEW_EMPLOYEE", createNewEmployee)

  /* EDIT EMPLOYEE */
  yield takeEvery("UPDATE_EMPLOYEE", updateEmployee)
  yield takeEvery("UPDATE_AVATAR_EMPLOYEE", updateAvatarEmployee)
  yield takeEvery("UPDATE_USER_STATUS_EMPLOYEE", updateUserStatusEmployee)

  yield takeEvery('FETCH_TITLE', loadTitle);
  yield takeEvery('FETCH_BRANCH', loadBranch);
  yield takeEvery('FETCH_DEPARTMENT', loadDepartment);
  yield takeEvery('FETCH_TYPE_CONTRACT', loadTypeContract);
  yield takeEvery('FETCH_LEVEL_ABILITY', loadLevelAbility);
  yield takeEvery('FETCH_REASON_RECRUITMENT', loadReasonRecruitment);
  yield takeEvery('FETCH_WORK_HOUR', loadWorkHour);
  yield takeEvery('FETCH_REASON_END', loadReasonEnd);
  yield takeEvery('FETCH_TYPE_END', loadTypeEnd);

  /* STORE UID */
  yield takeEvery("LOAD_STORE_UID", loadStoreUId)

  yield takeEvery("LOAD_STORE_UID_V2", loadStoreUIdV2)

  yield takeEvery('FETCH_MATERIAL_SALES_REPORT', loadMaterialSalesReport);

  yield takeEvery('FETCH_SR_PRODUCE', loadSRProduce);
  yield takeEvery('FETCH_SR_DELIVER', loadSRDeliver);
  yield takeEvery('FETCH_SR_RECEIVED', loadSRReceived);
  yield takeEvery('FETCH_GOODS_TRANSFER_LIST', loadGoodTransferList);
  yield takeEvery('FETCH_GOODS_TRANSFER_DETAIL', loadGoodTransferDetail);
  


  yield takeEvery('CREATE_NEW_SR_DELIVER', createNewSRDeliver);
  yield takeEvery('UPDATE_SR_DELIVER', updateSRDeliver);
  yield takeEvery('FETCH_BY_ID_SR_DELIVER', getSRDeliverById);
  yield takeEvery('FETCH_LIST_DRIVER_WITH_PLATE', fetchListDriverWithPlate);
  yield takeEvery('FETCH_LIST_DRIVERS', fetchDrivers);
  yield takeEvery('CREATE_NEW_DRIVERS', createNewDrivers);
  yield takeEvery('UPDATE_DRIVERS', updateDrivers);
  yield takeEvery('FETCH_DRIVER_BY_ID', fetchDriversById);
  
  yield takeEvery('SUBMIT_NEW_SR_RECEIVED', newSRReceived);
  yield takeEvery('FETCH_SR_RECEIVED_BY_ID', getSRReceivedById);
  yield takeEvery('FETCH_CMD_DELIVERY', loadCommodityDelivery);
  yield takeEvery('CREATE_NEW_CMD_DELIVERY', createNewCommodityDelivery);
  yield takeEvery('FETCH_CMD_DELIVERY_BY_ID', fetchCommodityDeliveryById);
  yield takeEvery('FETCH_CMD_CANCELATION', loadCommodityCancelation);
  yield takeEvery('CREATE_NEW_CMD_CANCELATION', createNewCommodityCancelation);
  yield takeEvery('FETCH_CMD_CANCELATION_BY_ID', fetchCommodityCancelationById);
  yield takeEvery('FETCH_GOODS_RETURNING', loadGoodsReturning);
  yield takeEvery('SUBTMIT_NEW_GOODS_RETURNING', createNewGoodsReturning);
  yield takeEvery('FETCH_GOODS_RETURNING_BY_ID', fetchGoodsReturnByIds);
  yield takeEvery('FETCH_SALE_VOUCHER_ORDER', fetchSaleVoucherOrders);
  yield takeEvery('FETCH_SALE_VOUCHER_ORDER_TO_DELIVER', callSaleVoucherOrderToDeliver);
  yield takeEvery('FETCH_SALE_ORDERS', fetchSaleOrders);
  yield takeEvery('FETCH_SALE_ORDERS_DETAIL', fetchSaleOrderDetails);
  yield takeEvery('FETCH_PRODUCTION_DIARY_VIC', fetchListProductionDiary);
  yield takeEvery('CREATE_NEW_PRODUCTION_DIARY_VIC', createNewProductionDiary);
  yield takeEvery('FETCH_PRODUCTION_DIARY_DETAIL_VIC', fetchProductionDiaryDetail);
  yield takeEvery('UPDATE_PRODUCTION_DIARY_DETAIL_VIC', updateProductionDiary);
  
  /* WEBGROUP */
  yield takeEvery('FETCH_WEB_GROUP', loadWebGroup);
  yield takeEvery('FETCH_MENU_BY_WEB_GROUP', loadMenuByWebGroup);
  yield takeEvery('FETCH_STAFF_BY_WEB_GROUP', loadStaffByWebGroup);
  yield takeEvery('FETCH_APP_BY_WEB_GROUP', loadAppByWebGroup);
  yield takeEvery('FETCH_DATA_ACCESS_STORE_BY_WEB_GROUP_ID', loadDataAccessStoreByWebGroupId);
  yield takeEvery('FETCH_DATA_ACCESS_WAREHOUSE_BY_WEB_GROUP_ID', loadDataAccessWarehouseByWebGroupId);
  yield takeEvery("FETCH_EMPLOYEE_STATUS_1", loadEmployeeStatus1)

  yield takeEvery('CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID', createUserGroupPermissionsByWebGroupId);
  yield takeEvery('CREATE_USER_GROUP_DATA_ACCESS', createUserGroupDataAccess);
  yield takeEvery('UPDATE_USER_GROUP_APP', updateUserGroupApp);
  yield takeEvery('CREATE_WEB_GROUP', createWebGroup);
  yield takeEvery('UPDATE_WEB_GROUP', updateWebGroup);

  yield takeEvery('FETCH_APP', loadApp);
  yield takeEvery('FETCH_ALL_BANNER', loadAllBanner);
  yield takeEvery('FECTH_SETTING_CONFIG_BANNER', fecthPOSFileSettings);

  yield takeEvery("LOAD_ORG_CHART", loadOrgChart)
  yield takeEvery("LOAD_LISTEMPLOYEE", loadListEmployee)
  yield takeEvery("CREATE_ORG_CHART", createOrgChart)


  /* TRANSACTION MANAGEMENTS */
  yield takeEvery("LOAD_PAYMENTS_TYPE", loadPaymentType)
  yield takeEvery("LOAD_SERVICE_MODE", loadServiceMode)
  yield takeEvery("LOAD_STATUS_TRANSACTION", loadstatusTransaction)
  yield takeEvery("LOAD_STATUS_PAYMENT", loadstatusPayment)
  yield takeEvery("LOAD_ALL_TRANSACTION", loadAllTransactionAsync)
  yield takeEvery("LOAD_ALL_TRANSACTION_V2", loadAllTransactionV2Async)
  yield takeEvery("FETCH_INFINITE_TRANSACTION_V2", fetchTransactionInfiniteV2Async)
  yield takeEvery("FETCH_TRANSACTION_BY_VOUCHER_ID", fetchTransactionByVoucherId)
  
  
  /* MATERIAL */
  yield takeEvery("UPDATE_PRODUCT_UNIT_PRICE", updateProductUnitPrice);
  yield takeEvery("LOAD_ALL_APP_STORE_MATERIALS", loadAllAppStoreMaterials);
  yield takeEvery("LOAD_ALL_APP_STORE_MATERIALS_DETAIL", loadAppStoreMaterialDetail);
  yield takeEvery("UPDATE_APP_STORE_MATERIALS", updateAppStoreMaterials);
  yield takeEvery("DELETE_APP_STORE_MATERIALS", deleteAppStoreMaterials);

  yield takeEvery("GET_CONTENT_TYPE", getContentType);
  yield takeEvery("GET_POS_FILE_SETTINGS", getPOSFileSettings);
  yield takeEvery("SET_CONTENT_TYPE", setPOSContentType);
  yield takeEvery("GET_POS_CONTENT_SETTINGS", getContentSetting);
  /*CREATE A NEW CHECK */
  yield takeEvery("CREATE_NEW_CHECK", createNewCheck);
  yield takeEvery("UPDATE_NEW_CHECK_DETAIL", updateNewCheck);
  yield takeEvery("APPROVE_REQUEST_SEND", approveRequestSendFunction);
  /*CREATE A NEW CHECK */
  // yield takeEvery("CREATE_NEW_CHECK", createNewCheck);
  // yield takeEvery("UPDATE_NEW_CHECK", updateNewCheck);
  yield takeEvery("CREATE_SR_PRODUCE_VTR", createNewSRProduceAPI);
  yield takeEvery("FETCH_SR_PRODUCE_DETAIL_VTR", fetchSRProduceById);
  yield takeEvery("APPROVE_STATUS_SR_PRODUCE_VTR", approveStatusSRProduce);
  yield takeEvery("APPROVE_TRANSACTION_SR_PRODUCE_VTR", approveTransactionSRProduce);
  yield takeEvery("UPDATE_SR_PRODUCE_VTR", updateSRProduceVIC);
  yield takeEvery("FETCH_ALL_CUSTOMER_VTR", fetchAllCustomers);
  yield takeEvery("FETCH_ALL_CUSTOMER_GROUP_VTR", fetchAllCustomerGroups);
  yield takeEvery("CREATE_NEW_CUSTOMER_VTR", createCustomer);
  yield takeEvery("FETCH_CUSTOMER_BY_ID_VTR", fecthCustomerById);
  yield takeEvery("UPDATE_CUSTOMER_VTR", udpatedCustomer);
  yield takeEvery("CREATE_GROUP_CUSTOMER_VTR", createNewGroupCustomer);
  yield takeEvery("FETCH_DETAIL_GROUP_CUSTOMER_VTR", fetchDetailGroupCustomer);
  yield takeEvery("UPDATE_GROUP_CUSTOMER_VTR", updateGroupCustomer);
  yield takeEvery("GET_PRODUCT_WITH_CUSTOMERS", getProductsWithCustomer);
  
  yield all([
    orderSaga(),
    goodsTransferSaga(),
    goodsDestructionSaga(),
    inventoryStockSaga(),
  ]);
}


export default mySaga;
