const initialState = {
  loading: false,
  status: null
};

const orderWorkFlowActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION_LOADING':
      return {
        ...state,
        loading: true
      }

    case 'SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION_SUCCEEDED':
      return {
        ...state,
        status: 'success',
        loading: false,
      }

    case 'SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION_FAILED':
      return {
        ...state,
        status: 'failed',
        loading: false,
      }

    case 'CLEAR_SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION_STATUS':
      return {
        ...state,
        status: null,
      }

    default:
      return state
  }
};

export default orderWorkFlowActionReducer;
