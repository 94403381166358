const initialState = {
    loading: false,
    titleList: [],
    branchList: [],
    departmentList: [],
    typeContractList: [],
    levelAbilityList: [],
    reasonRecruitmentList: [],
    workHourList: [],
    reasonEndList: [],
    typeEndList: [],
}

const profileTypeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_PROFILE_TYPE_LIST_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'FETCH_TITLE_SUCCESS':
            return {
                ...state,
                titleList: action.payload.data,
                loading: false
            }

        case 'FETCH_BRANCH_SUCCESS':
            return {
                ...state,
                branchList: action.payload.data,
                loading: false
            }

        case 'FETCH_DEPARTMENT_SUCCESS':
            return {
                ...state,
                departmentList: action.payload.data,
                loading: false
            }

        case 'FETCH_TYPE_CONTRACT_SUCCESS':
            return {
                ...state,
                typeContractList: action.payload.data,
                loading: false
            }

        case 'FETCH_LEVEL_ABILITY_SUCCESS':
            return {
                ...state,
                levelAbilityList: action.payload.data,
                loading: false
            }

        case 'FETCH_REASON_RECRUITMENT_SUCCESS':
            return {
                ...state,
                reasonRecruitmentList: action.payload.data,
                loading: false
            }

        case 'FETCH_WORK_HOUR_SUCCESS':
            return {
                ...state,
                workHourList: action.payload.data,
                loading: false
            }

        case 'FETCH_REASON_END_SUCCESS':
            return {
                ...state,
                reasonEndList: action.payload.data,
                loading: false
            }

        case 'FETCH_TYPE_END_SUCCESS':
            return {
                ...state,
                typeEndList: action.payload.data,
                loading: false
            }
                
        default:
            return state
    }
}

export default profileTypeListReducer