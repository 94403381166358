import axios from 'axios'
import constants from '../reusable/constants'

export function createFeedbackApi(payload) {
    return axios.get(
        constants.api.url + 'api/FeedbackMaster/GetAllFeedbacksBetweenDates?fromDate=' + payload.fromDate + '&toDate=' + payload.toDate,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchFeedbackDetailApi(payload) {
    return axios.get(
        constants.api.url + 'api/feedback/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}


export function fetchFeedbackTransactionsApi(payload) {
    return axios.get(
        constants.api.url + 'api/feedback/getFeedbackTransactions/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchGetFeedbackDataDetailApi(payload) {
    return axios.get(
        constants.api.url + 'api/FeedbackMaster/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchFeedbackDataApi(payload) {
    return axios.get(
        constants.api.url + 'api/FeedbackMaster',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}