const initialState = {
    loading: false,
    SRProduce: [],
    totalPage: 0,
    submitNewSRProduceStatus: 0,
    updateSRProduceStatus: 0,
    approveSRProduceStatus: 0,
    SRProduceDetais: null,
}


const SRProduceReducer = (state = initialState, action) => { 
    switch (action.type) { 
        case "FETCH_SR_PRODUCE_DETAIL_SUCCEEDED":
            return {
               ...state,
               SRProduceDetais: action.payload,
               loading: false,
            };
        case "APPROVE_STATUS_SR_PRODUCE_SUCCESSED":
            return {
                ...state,
                approveSRProduceStatus: 1,
                loading: false
            };
        case "APPROVE_STATUS_SR_PRODUCE_FAILED":
            return {
                ...state,
                approveSRProduceStatus: 2,
                loading: false
            };
        case "UPDATE_SR_PRODUCE_SUCCEEDED":
            return {
                ...state,
                updateSRProduceStatus: 1,
                loading: false,
            };
        case "UPDATE_SR_PRODUCE_FAILED":
            return {
                ...state,
                updateSRProduceStatus: 2,
                loading: false
            };
        case "SUBMIT_NEW_SR_PRODUCE_SUCCEEDED":
            return {
                ...state,
                submitNewSRProduceStatus: 1,
                loading: false,
            };
        case "SUBMIT_NEW_SR_PRODUCE_FAILED":
            return {
                ...state,
                submitNewSRProduceStatus: 2,
                loading: false
            };
        case 'FETCH_SR_PRODUCE_SUCCESSED':
            return {
                ...state,
                loading: false,
                SRProduce: action.payload?.data,
                totalPage: action.payload?.totalCount,
            }
        case "SET_DEFAULT_STATUS_EDIT":
            return {
                ...state,
                submitNewSRProduceStatus: 0,
                approveSRProduceStatus: 0,
                updateSRProduceStatus: 0,
                loading: false
            };   
        case 'SHOW_SR_PRODUCE_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'DEFAULT_DATA_SR_PRODUCE':
            return initialState;

        default:
            return state
    }
}

export default SRProduceReducer;