const initialState = {
  showModal: false,
  loading: false,
  items: [],
  token: '',
  managementToken: '',
  loopTransactions: [],
  formValidationFailed: false,
  checkingDateFrom: '',
  checkingDateTo: '',
  checkingWarehouse: '',
  loopData: '',
  submitNewLoopGetDataStatus: '',
  reconcileResult: [],
  loopTransactionDetail: [],
  stockReconciles: [],
  loopTransaction: '',
  submitNewStockReconcileStatus: '',
  stockReconcileDetail: '',
  totalCount: 0,
}

const stockReconcileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CHECKING_DATE_FROM':
      return {
        ...state,
        checkingDateFrom: action.payload
      }

    case 'UPDATE_CHECKING_DATE_TO':
      return {
        ...state,
        checkingDateTo: action.payload
      }

    case 'FETCH_STOCK_RECONCILE_DETAIL_SUCCEEDED':
      return {
        ...state,
        stockReconcileDetail: action.payload,
        loading: false,

      }
    case 'CLEAR_STOCK_RECONCILE_DETAIL':
      return {...state, stockReconcileDetail: ''}
    case 'SUBMIT_NEW_STOCK_RECONCILE_SUCCEEDED':
      console.log("SUBMIT_NEW_STOCK_RECONCILE_SUCCEEDED", action.payload)
      return {
        ...state,
        submitNewStockReconcileStatus: 1,
        loading: false
      }
    case 'SUBMIT_NEW_STOCK_RECONCILE_FAILED':
      return {
        ...state,
        submitNewStockReconcileStatus: 2
      }
    case 'UPDATE_LOOP_TRANSACTION':
      return {
        ...state,
        loopTransaction: action.payload
      }
    case 'FETCH_STOCK_RECONCILES_SUCCEEDED':
      console.log('FETCH_STOCK_RECONCILES_SUCCEEDED', action.payload)
      return {
        ...state,
        loading: false,
        stockReconciles: action.payload.data,
        totalCount: action.payload.totalCount
      }
    case 'FETCH_INVENTORY_LOST_BY_FILTER_SUCCEEDED':
      console.log('FETCH_INVENTORY_LOST_BY_FILTER_SUCCEEDED', action.payload)
      return {
        ...state,
        loading: false,
        stockReconciles: action.payload.data,
        totalCount: action.payload.totalCount
      }
    case 'RECONCILE_STOCK_SUCCEEDED':
      return {
        ...state,
        reconcileResult: action.payload
      }

    case 'SUBMIT_NEW_LOOP_GET_DATA_SUCCEEDED':
      return {
        ...state,
        submitNewLoopGetDataStatus: 1,
        loading: false,
        formValidationFailed: false,
        checkingDate: '',
        checkingWarehouse: '',
        loopData: '',
      }

    case 'FETCH_LOOP_TRANSACTIONS_SUCCEEDED':
      return {
        ...state,
        loopTransactions: action.payload,
        loading: false
      }

    case 'FETCH_LOOP_DATA_DETAIL_SUCCEEDED':
      return {
        ...state,
        loopTransactionDetail: action.payload,
        loading: false
      }

    case 'FETCH_LOOP_DATA_PRODUCT_SOLD_SUCCEEDED':
      return {
        ...state,
        loopData: action.payload,
        loading: false
      }

    case 'FETCH_LOOP_MANAGEMENT_TOKEN_SUCCEEDED':
      return {
        ...state,
        managementToken: action.payload.token,
        loading: false
      }
    case 'FETCH_LOOP_TOKEN_SUCCEEDED':
      return {
        ...state,
        token: action.payload.token,
        loading: false
      }

    case 'CLEAR_DATA':
      return {
        ...initialState
      }

    case 'UPDATE_FORM_VALIDATION':
      return {
        ...state,
        formValidationFailed: action.payload
      }
    case 'UPDATE_CHECKING_WAREHOUSE':
      return {
        ...state,
        checkingWarehouse: action.payload
      }

    case 'SHOW_STOCK_RECONCILE_LOADING':
      return {
        ...state,
        loading: true
      }

    default:
      return state
  }
}

export default stockReconcileReducer
