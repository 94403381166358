import { changeKeyNameForEmployee } from "./rootWebGroupReducer"

const initialState = {
    listEmployeeStatus1: [],
    loading: false,
    message: '',
}



const webGroupEmployeeStatus1Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_LIST_ALL_EMPLOYEE_STATUS_1_LOADING':
            return{
                ...state,
                loading: true,
            }

        case 'FETCH_LIST_ALL_EMPLOYEE_STATUS_1_SUCCESS':
            return {
                ...state,
                listEmployeeStatus1: changeKeyNameForEmployee(action.payload.data),
                loading: false,
                message: ''
            }

        case 'FETCH_LIST_ALL_EMPLOYEE_STATUS_1_FAILED':
            return {
                ...state,
                loading: false,
                message: action.payload
            }

        default:
            return state
    }
}

export default webGroupEmployeeStatus1Reducer