import styled from 'styled-components';

export const LayoutModal = styled.div`
    display: none;
    position: fixed;
    bottom: 0;
    right: 24px;

    width: 362px;
    height: auto;
    
    z-index: 99999999;

    &.show {
        display: block;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 54px;
    background: #9E5EAA;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
`;

export const Title = styled.div`
    cursor: default;
    display: inline-block;
    font-weight: 500;
    overflow: hidden;
    padding: 0 24px;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: #fff;
    font-size: 16px;
    line-height: normal;
`;

export const GroupButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;

    width: auto;
`;

export const ButtonHeader = styled.div`
    width: 40px;
    height: 40px;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    user-select: none;
    transition: all .3s ease;

    svg path {
        stroke: #FFFFFF;
        transition: all .3s ease;
    }

    &.active svg{
        transform: rotate(180deg);
    }

    &:hover {
        svg path {
            stroke: #FFFFFF;
        }

        background: #d097da;
    }
`;

export const Container = styled.div`
    width: 100%;
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    max-height: 240px;
    height: auto;
    overflow: auto;
    transition: height .4s ease;

    &.hide {
        height: 0px;
    }
`;

export const Ul = styled.ul`
    width: 100%;
    height: 100%;
    list-style: none;

    padding: 0;
    margin: 0;
`;

export const Li = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 18px 24px;
    width: 100%;
    height: 100%;

    user-select:none;
    cursor: pointer;

    &:hover {
        background: #dfc9e2;
    }
`;

export const Image = styled.div`
    background: url('${props => props.url}');
    height: 16px;
    width: 16px;
    background-size: contain;

    flex-shrink: 0;
    margin-top: 4px;
`;

export const FileName = styled.div`
    display: -webkit-box;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    font-size: 16px;
    width: 230px;
    color: #9E5EAA;
    margin-left: 10px;
`;  