import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers/rootReducer'
import mySaga from './sagas';
import { HelmetProvider } from 'react-helmet-async';

React.icons = icons

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// use redux devtools extension
const composeEnhancers = typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] ?
   window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({}) : compose;
const enhancer = composeEnhancers(
   applyMiddleware(sagaMiddleware),
);

const store = createStore(
   rootReducer, enhancer
   // applyMiddleware(sagaMiddleware),
)

// then run the saga
sagaMiddleware.run(mySaga)

ReactDOM.render(
   <Provider store={store}>
      <HelmetProvider>
         <App />
      </HelmetProvider>
   </Provider>,
   document.getElementById('root')
);

serviceWorker.unregister();
