const initialModalState = {
  modal_id: null,
  modal_material_id: null,
  modal_material_name: null,
  modal_description: null,
  modal_status: null,
  modal_details_array: [],
  modal_details_id: null,
  modal_details_code: null,
  modal_details_name: null,
  modal_details_uom_name: null,
  modal_details_uom_id: null,
  modal_details_quantity: null,
};

const initialState = {
  loading: false,
  formulas: [],
  ...initialModalState,
  modalValidationFailed: false,
  submitNewFormulaStatus: "",
  updateFormulaCodeStatus: "",
  deleteFormulaCodeStatus: "",
  categoryComboboxItems: [],
  uomComboboxItems: [],
  comboboxItems: [],
  totalPage: 0,
  //filter
  currentPage: 1,
  pageSize: 10,
  status: 1,
  formulaGroupId: null,
  MaterialId: null,
  //list filter
  stores: [],
  uoms: [],
  materials: [],
};

const formulaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FORMULA_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "UPDATE_FORMULA_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "UPDATE_FORMULA_STORE":
      return {
        ...state,
        storeId: action.payload,
      };
    case "UPDATE_FORMULA_GROUP_ID":
      return {
        ...state,
        formulaGroupId: action.payload,
      };
    case "UPDATE_FORMULA_MATERIAL_ID":
      return {
        ...state,
        MaterialId: action.payload,
      };
    case "UPDATE_FORMULA_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "FETCH_MATERIAL_SUCCEEDED": {
      console.log("material list", action.payload);
      let array = action.payload;
      let newArr = array.map((item) => {
        return {
          value: {
            id: item.id,
            name: item.name,
            code: item.code,
          },
          name: item.name,
        };
      });
      return {
        ...state,
        materials: newArr,
        loading: false,
      };
    }
    case "LOAD_STORE_FORMULA_SUCCEEDED": {
      console.log("formula list", action.payload);
      let array = action.payload;
      let newArr = array.map((item) => {
        return {
          value: item.formulaGroupId,
          name: item.name,
        };
      });
      return {
        ...state,
        stores: newArr,
        loading: false,
      };
    }
    case "LOAD_STORE_FORMULA_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "DELETE_FORMULA_SUCCESS":
      return {
        ...state,
        deleteFormulaCodeStatus: 1,
        loading: false,
      };

    case "DELETE_FORMULA_FAILED":
      return {
        ...state,
        deleteFormulaCodeStatus: 2,
        loading: false,
      };

    case "FETCH_FORMULA_DETAIL_SUCCEEDED":
      return {
        modalDetailItemId: action.payload.id,
        modalDetailItemName: action.payload.itemName,
        modalDetailItemDescription: action.payload.description,
        modalDetailMaterials: action.payload.details,
        loading: false,
        showDetailModal: true,
      };
    case "GET_UOM_MATERIAL_SUCCESS":
      console.log("GET_UOM_MATERIAL_SUCCESS", action.payload);
      console.log("formula list", action.payload);
      let array = action.payload;
      let newArr = array.map((item) => {
        return {
          value: {
            id: item.id,
            name: item.name,
            code: item.code,
          },
          name: item.name,
        };
      });
      return {
        ...state,
        uoms: newArr,
      };
    case "GET_UOM_MATERIAL_FAILED":
      console.log("GET_UOM_MATERIAL_FAILED", action.payload);
      return {
        ...state,
      };
    case "GENERATE_ORIGINAL_ITEMS":
      let originalItems = [];
      let materials = action.payload;
      materials &&
        materials.forEach((item) => {
          originalItems.push({
            id: item.id,
            value: item.name + " (" + item.code + ") ",
            code: item.code,
            name: item.name,
            image: item.image,
            baseUomName: item.baseUomName,
          });
        });
      return {
        ...state,
        comboboxItems: originalItems,
        modalValidationFailed: false,
        formValidationFailed: false,
      };

    case "UPDATE_MODAL_NEW_FORMULA_CODE":
      return {
        ...state,
        modalNewCode: action.payload,
      };
    case "SHOW_MODIFY_CODE_MODAL":
      return {
        ...state,
        modalNewCode: action.payload.code,
        modalFormulaId: action.payload.id,
        showModifyCodeModal: true,
      };
    case "SHOW_FORMULA_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_FORMULA_SUCCEEDED":
      return {
        ...state,
        formulas: action.payload,
        loading: false,
      };
    case "FETCH_FORMULA_PAGINATION_SUCCEEDED":
      console.log("FETCH_FORMULA_PAGINATION_SUCCEEDED", action.payload);
      return {
        ...state,
        formulas: action.payload.data,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    case "CLEAR_DATA":
      return {
        ...state,
        submitNewFormulaStatus: "",
        updateFormulaCodeStatus: "",
        deleteFormulaCodeStatus: "",
        formulaGroupId: undefined,
        formulas: [],
      };
    case "UPDATE_MODAL_VALIDATION":
      return {
        ...state,
        modalValidationFailed: action.payload,
      };
    case "UPDATE_FORMULA_CODE_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        updateFormulaCodeStatus: 1,
        loading: false,
      };
    case "SAVE_NEW_FORMULA_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewFormulaStatus: 1,
        loading: false,
      };
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: true,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
        modalMaterials: [],
      };

    //update modal cases
    case "UPDATE_MODAL_FORMULA_TABLE_ID":
      return {
        ...state,

        modal_details_id: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_TABLE_CODE":
      return {
        ...state,

        modal_details_code: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_TABLE_NAME": {
      return {
        ...state,

        modal_details_name: action.payload,
      };
    }
    case "UPDATE_MODAL_FORMULA_MATERIAL_NAME":
      return {
        ...state,
        modal_material_name: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_TABLE_MATERIAL_CODE":
      return {
        ...state,
        modal_details_code: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_DESCRIPTION":
      return {
        ...state,
        modal_description: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_TABLE_UOM":
      return {
        ...state,
        modal_details_uom_id: action.payload.id,
        modal_details_uom_name: action.payload.name,
      };
    case "UPDATE_MODAL_FORMULA_TABLE_QUANTITY":
      return {
        ...state,
        modal_details_quantity: action.payload,
      };
    case "ADD_MATERIAL_TO_MATERIAL_TABLE":
      console.log("added", action.payload);
      if (state.modal_details_array === undefined) {
        state.modal_details_array = [];
        return {
          ...state,
          modal_details_array: [...state.modal_details_array, action.payload],
        };
      } else {
        return {
          ...state,
          modal_details_array: [...state.modal_details_array, action.payload],
        };
      }
    case "DELETE_MATERIAL_TO_MATERIAL_TABLE": {
      let newArr = state.modal_details_array;
      newArr.splice(action.payload, 1);
      return {
        ...state,
        modal_details_array: newArr,
      };
    }
    case "RESET_TABLE_FILTER": {
      return {
        ...state,
        modal_details_id: null,
        modal_details_code: null,
        modal_details_name: null,
        modal_details_uom_name: null,
        modal_details_uom_id: null,
        modal_details_quantity: null,
      };
    }
    case "UPDATE_MODAL_FORMULA_ID":
      return {
        ...state,
        modal_id: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_MATERIAL_ID":
      return {
        ...state,
        modal_material_id: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_GROUP":
      return {
        ...state,
        modalGroupId: action.payload,
      };
    case "UPDATE_MODAL_FORMULA_STATUS":
      return {
        ...state,
        modal_status: action.payload,
      };
    case "UPDATE_FORMULA_MODAL": {
      console.log("update modal", action.payload);
      return {
        ...state,
        modal_id: action.payload.modal_id,
        modal_material_id: action.payload.modal_material_id,
        modal_material_name: action.payload.modal_material_name,
        modal_description: action.payload.modal_description,
        modal_status: action.payload.modal_status,
        modal_details_array: action.payload.modal_details_array,
      };
    }
    case "UPDATE_FORMULA_SUCCEEDED":
      console.log("update formula", action.payload);
      return {
        ...state,
        updateFormulaCodeStatus: 1,
        loading: false,
      };
    case "UPDATE_FORMULA_FAILED":
      console.log("update formula failed", action.payload);
      return {
        ...state,
      };

    case "RESET_FORMULA_MODAL": {
      return {
        ...state,
        ...initialModalState,
      };
    }
    default:
      return state;
  }
};

export default formulaReducer;
