const initialModalState = {
    showModal: false,
    modalId: '',
    modalCode: '',
    modalName: '',
    modalDescription: '',
}

const initialState = {
    loading: false,
    users: [],
    allUsers: [],
    ...initialModalState,
    modalValidationFailed: false,
    submitNewUser: '',
    updateUser: '',
    mode: ''
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USER_BY_MATERIAL_BRAND_SUCCEEDED':
            return {
                ...state,
                users: action.payload,
                loading: false
            }
        case 'CLEAR_USER':
            return {
                ...state,
                users: []
            }
        case 'SHOW_USER_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'SHOW_USER_MODAL':
            return {
                ...state,
                showModal: true,
                mode: action.payload.mode,
                modalId: action.payload.mode ==='create' ? '' : action.payload.id,
                modalCode: action.payload.mode === 'create' ? '' : action.payload.code,
                modalName: action.payload.mode === 'create' ? '' : action.payload.name,
            }
        case 'HIDE_MODAL':
            return {
                ...state,
                ...initialModalState,
                modalValidationFailed: false
            }
        case 'FETCH_ALL_USERS_SUCCEEDED':
            return {
                ...state,
                allUsers: action.payload,
                loading: false
            }
        case 'CLEAR_DATA':
            return {
                ...state,
                submitNewUser: '',
                updateUser: '',
                mode: ''
            }
        case 'UPDATE_MODAL_VALIDATION':
            return {
                ...state,
                modalValidationFailed: action.payload
            }
        case 'SAVE_NEW_USER_SUCCEEDED':
            return {
                ...state,
                modalValidationFailed: false,
                submitNewUser: 1,
                loading: false
            }

        case 'SAVE_NEW_USER_FAILED':
            return {
                ...state,
                modalValidationFailed: false,
                submitNewUser: 2,
                loading: false
            }

        case 'UPDATE_USER_SUCCESS':
            return {
                ...state,
                modalValidationFailed: false,
                updateUser: 1,
                loading: false
            }

        case 'UPDATE_USER_FAILED':
            return {
                ...state,
                modalValidationFailed: false,
                updateUser: 2,
                loading: false
            }

        case 'UPDATE_MODAL_CODE':
            return {
                ...state,
                modalCode: action.payload
            }
        case 'UPDATE_MODAL_NAME':
            return {
                ...state,
                modalName: action.payload
            }
        case 'UPDATE_MODAL_DESCRIPTION':
            return {
                ...state,
                modalDescription: action.payload
            }
        default:
            return state
    }
}

export default userReducer