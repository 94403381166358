const initialState = {
    loading: false,
    customers: [],
    totalPage: 0,
    customerGroups: [],
    statusSubmitCustomer: 0,
    statusUpdateCustomer: 0,
    statusNewGroupCustomer: 0,
    statusUpdateGroupCustomer: 0,
    detailCustomer: null,
    detailGroupCustomer: null,
}

const customerReducer = (state = initialState, action) => {   
    switch (action.type) { 
        case 'SUBMIT_UPDATE_GROUP_CUSTOMER_SUCCESSED':
            return {
                ...state,
                loading: false,
                statusUpdateGroupCustomer: 1,
            }
        case 'SUBMIT_UPDATE_GROUP_CUSTOMER_FAILED':
            return {
                ...state,
                loading: false,
                statusUpdateGroupCustomer: 2,
            } 
        case 'FETCH_DETAILS_GROUP_CUSTOMER_SUCCESSED':
            return {
                ...state,
                loading: false,
                detailGroupCustomer: action.payload,
            }
        case 'SUBMIT_NEW_GROUP_CUSTOMER_SUCCESSED':
            return {
                ...state,
                loading: false,
                statusNewGroupCustomer: 1,
            }
        case 'SUBMIT_NEW_GROUP_CUSTOMER_FAILED':
            return {
                ...state,
                loading: false,
                statusNewGroupCustomer: 2,
            } 
        case 'SUBMIT_UPDATE_CUSTOMER_SUCCESSED':
            return {
                ...state,
                loading: false,
                statusUpdateCustomer: 1,
            }
        case 'SUBMIT_UPDATE_CUSTOMER_FAILED':
            return {
                ...state,
                loading: false,
                statusUpdateCustomer: 2,
            } 
        case 'FETCH_DETAILS_CUSTOMER_SUCCESSED':
            return {
                ...state,
                loading: false,
                detailCustomer: action.payload,
            }
        case 'SUBMIT_CREATE_CUSTOMER_SUCCESSED':
            return {
                ...state,
                loading: false,
                statusSubmitCustomer: 1,
            }
        case 'SUBMIT_CREATE_CUSTOMER_FAILED':
            return {
                ...state,
                loading: false,
                statusSubmitCustomer: 2,
            }
        case 'FETCH_ALL_CUSTOMERS_GROUP_SUCCESSED':
            return {
                ...state,
                loading: false,
                customerGroups: action.payload,
            }
        case 'FETCH_ALL_CUSTOMERS_SUCCESSED':
            return {
                ...state,
                loading: false,
                customers: action.payload?.data,
                totalPage: action.payload?.totalCount,
            }
        case 'SET_DEFAULT_STATUS':
            return {
                ...state,
                loading: false,
                statusSubmitCustomer: 0,
                statusUpdateCustomer: 0,
                statusNewGroupCustomer: 0,
                statusUpdateGroupCustomer: 0,
            }
        case 'SET_CUSTOMERS_LOADING':
            return {
                ...state,
                loading: true,
            }

        case 'DEFAULT_CUSTOMERS':
            return initialState;

        default:
            return state
    }
}

export default customerReducer;