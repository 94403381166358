import styled from "styled-components";
import colors from "src/reusable/colorConstants";
import { CustomeText } from "src/components/CustomeText";

export const Button = styled(CustomeText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  height: ${(props) => (props.height ? `${props.height}px` : "42px")};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "135px")};

  border-radius: 6px;

  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor.includes("#")
        ? props.backgroundColor
        : colors[props.backgroundColor]
      : colors.SUCCESS};
  color: ${(props) => (props.color ? props.color : "#ffffff")};

  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  &.disable,
  &.disable:hover {
    cursor: inherit;
    border: 1px solid ${colors.GREY_4};
    background: ${colors.WHITE};
    color: ${colors.GREY_1};
    font-weight: 500;
  }

  &.disabled,
  &.disabled:hover {
    cursor: inherit;
    background: ${colors.GREY_5};
    color: ${colors.GREY_3};
    font-weight: 500;
  }

  &.btn-back {
    background: ${colors.WHITE};
    color: ${colors.GREY_1};
    border: 1px solid ${colors.GREY_1};
    font-weight: 500;
  }

  &.btn-back:hover {
    opacity: 0.6 !important;
    background: ${colors.GREY_3};
  }

  svg {
    width: 16px;
    height: 17px;

    path {
      stroke: ${(props) => (props.fillStroke ? "red" : "#FFFFFF")};
    }

    circle {
      stroke: ${(props) => (props.fillStroke ? "red" : "#FFFFFF")};
    }
  }
`;

export const ButtonStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;

  &.success {
    background: ${colors.SUCCESS};
    border: 1px solid ${colors.SUCCESS};
  }

  &.warn {
    background: ${colors.WARNING};
    border: 1px solid ${colors.WARNING};
  }

  &.primary {
    background: ${colors.PRIMARY};
    border: 1px solid ${colors.PRIMARY};
  }

  &.error {
    background: ${colors.ERROR};
    border: 1px solid ${colors.ERROR};
  }

  &.complete {
    background: ${colors.COMPLETED};
    border: 1px solid ${colors.COMPLETED};
  }

  box-sizing: border-box;
  border-radius: 6px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5rem;

  letter-spacing: 0.002em;

  color: ${colors.WHITE};
`;

export const ButtonIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 9px 12px;

  background: ${props => props.color ? props.color : colors.ERROR};
  color: ${colors.WHITE};
  border-radius: 6px;

  cursor: pointer;
  user-select: none;

  &.disable {
    cursor: inherit;

    color: ${colors.GREY_3};
    background: ${props => props.color ? props.color : colors.GREY_5};
    border: 1px solid ${colors.GREY_5};
  }

  svg {
    margin-left: 8px;

    path {
      stroke: ${props => props.iconColor ? props.iconColor : colors.WHITE};
      fill: ${props => props.iconColor ? props.iconColor : colors.WHITE};
    }
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonExport = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 12px;
  color: ${colors.PRIMARY};
  background: ${colors.WHITE};
  border: 1px solid ${colors.PRIMARY};
  margin: 0;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.876rem;
  line-height: 1.5rem;

  div {
    width: auto;
    height: auto;
  }
  &:hover {
    background: ${colors.GREY_5};
  }
  svg path {
    transition: all 0.3s ease;
  }
  svg path img {
    stroke: ${colors.PRIMARY};
  }
  /*&:hover {
    background: colors.CHERRY_1;
    color: ${colors.WHITE};

    svg path {
      stroke: ${colors.WHITE};
    }
  }*/
`;

export const ButtonGoBack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  width: 42px;
  height: 42px;

  cursor: pointer;
  background: ${colors.WHITE};
  border: 1px solid ${colors.BLUE_2};

  box-sizing: border-box;
  border-radius: 6px;

  svg path {
    stroke: ${colors.BLUE_2};
  }

  &:hover {
    background: ${colors.BLUE_2};

    svg path {
      stroke: ${colors.WHITE};
    }
  }
`;

export const ButtonOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  width: 42px;
  height: 42px;

  cursor: pointer;
  background: ${colors.WHITE};
  border: 1px solid ${(props) => (props.color ? props.color : colors.BLUE_2)};

  box-sizing: border-box;
  border-radius: 6px;

  transition: all 0.35s ease;

  svg path {
    stroke: ${(props) => (props.color ? props.color : colors.BLUE_2)};
  }

  &:hover {
    background: ${(props) => (props.color ? props.color : colors.BLUE_2)};

    svg path {
      stroke: ${colors.WHITE};
    }
  }
`;

export const ButtonTableDrop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  background: ${colors.ORANGE_2};
  color: ${colors.WHITE};
  border-radius: 6px;
  height: 40px;
  font-size: 0.75rem;

  cursor: pointer;
  user-select: none;

  transition: all 0.35s ease;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 8px;
    transition: all 0.35s ease;

    path {
      stroke: ${colors.WHITE};
    }
  }

  &.active {
    background: ${colors.ERROR};

    svg {
      transform: rotate(180deg);
    }
  }
`;
export const ButtonDrop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  height: 40px;
  background: #ff5341;
  color: #ffffff;
  border-radius: 6px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 8px;

    path {
      stroke: #ffffff;
    }
  }
  ${(props) =>
    props.status &&
    `
      background: #ff8f50;
    `}
  span {
    font-size: 14px;
    color: #ffffff;
  }
`;
export const ButtonApprove = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  background: ${colors.SUCCESS};
  color: ${colors.WHITE};
  border-radius: 6px;

  height: 42px;
  cursor: pointer;
  user-select: none;

  transition: all 0.35s ease;

  &.enable,
  &.enable:hover {
    opacity: 0.6;
    cursor: inherit;
  }

  &.error {
    background: ${colors.ERROR};
    cursor: pointer;
  }

  &.error:hover {
    opacity: 0.6;
  }

  &.warn {
    background: ${colors.WARNING};
    cursor: pointer;
  }

  &.warn:hover {
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.6;
  }

  svg {
    margin-right: 8px;

    path {
      fill: ${colors.WHITE};
    }
  }
`;

export const ButtonImportFile = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  cursor: pointer;
  user-select: none;

  color: ${(props) => (props.color ? props.color : colors.CHERRY_1)};
  background: #ffffff;
  margin: 0;

  border: 1px solid ${(props) => (props.color ? props.color : colors.CHERRY_1)};
  box-sizing: border-box;
  border-radius: 6px;

  white-space: nowrap;
  transition: all 0.4s ease;

  div {
    width: auto;
    height: auto;

    svg path {
      stroke: colors.CHERRY_1;
    }
  }

  &:hover,
  &.active {
    background: ${(props) => (props.color ? props.color : colors.CHERRY_1)};
    color: ${colors.WHITE};

    svg path {
      stroke: ${colors.WHITE};
      transition: all 0.3s ease;
    }
  }
`;
export const ButtonNew = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 0.75rem 1.25rem;
  margin-left: 0.75rem;
  height: 42px;
  color: #ffffff;
  border-radius: 6px;

  cursor: pointer;
  user-select: none;

  &.disable {
    cursor: inherit;

    color: #acacac;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
  }

  svg {
    margin-left: 8px;

    path {
      stroke: #ffffff;
    }
  }

  &:hover {
    opacity: 0.8;
  }
  background: ${colors.BLUE_2};
  color: ${colors.WHITE};
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
export const ButtonDownload = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  width: 92px;
  height: 40px;
  left: 75.8px;
  top: 5px;
  border: none;
  background: #ff8f50;
  border-radius: 6px;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.002em;
  color: #ffffff;
`;
export const ButtonControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  width: auto;
  min-width: 176px;

  background: #ffffff;
  color: #fc473c;

  border: 1px solid #fd685b;
  box-sizing: border-box;
  border-radius: 6px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.002em;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;

  &.enable,
  &.enable:hover {
    opacity: 0.6 !important;
    cursor: inherit;
  }

  &:hover {
    background: #fc473c;
    color: #ffffff;
  }

  &.danger {
    background: #fc473c;
    color: #ffffff;

    &:hover {
      opacity: 0.8;
    }
  }

  &.success {
    background: #1cce66;
    border: none;
    color: #ffffff;

    &:hover {
      opacity: 0.8;
    }
  }
`;
export const ButtonBack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  width: 42px;
  height: 42px;

  cursor: pointer;
  background: #ffffff;
  border: 1px solid #fd685b;

  box-sizing: border-box;
  border-radius: 6px;

  svg path {
    stroke: #fd685b;
  }

  &:hover {
    background: #fd685b;

    svg path {
      stroke: #ffffff;
    }
  }
`;

export const ButtonPagination = styled.div`
  width: 24px;
  height: 24px;

  cursor: pointer;
  margin: 0px 8px;
`;
export const ButtonSubmit = styled(Button)`
  background: #f5f5f5;
  color: #acacac;

  box-sizing: border-box;

  &.active {
    cursor: pointer;
    background: #1cce66;
    color: #ffffff;
    border: 1px solid #1cce66;

    &:hover {
      opacity: 0.8;
      background: #1cce66;
    }
  }
`;
