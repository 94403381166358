const initialModalState = {
   modalMaterialId: "",
   modalMaterialCode: "",
   modalMaterialName: "",
   modalMaterialBaseUom: "",
   modalBrandId: "",
   modalBrandName: "",
   modalRequiredDate: "",
   modalUomId: "",
   modalUomName: "",
   modalBaseStockQuantity: "",
   modalRequestQuantity: "",
   modalRequestBaseQuantity: "",
   modalNote: "",
   modalUomQuantityType: "",
   modalUsedBaseUom: false,
   modalUsedBaseUomValue: "",
   modalUsedBaseUomKey: null,

   showModal: false,
};

const initialRequestWorkFlowModalState = {
   showRequestWorkFlowModal: false,
   modalDescription: "",
};

const initialState = {
   requestedDate: "",
   forWarehouse: "",
   requestedBy: "",
   requestDepartment: "",
   attachedFile: "",
   items: [],
   submitNewStockRequestStatus: 0,
   updateStockRequestStatus: 0,
   submitWorkFlowActionStatus: 0,
   description: "",
   ...initialModalState,
   ...initialRequestWorkFlowModalState,
   showModal: false,
   // showRequestWorkFlowModal: false,
   defaultBrandSelected: true,
   defaultUomSelected: true,
   comboboxItems: [],

   stockRequestList: [],
   stockRequestDetail: null,

   modalValidationFailed: false,
   formValidationFailed: false,
   approveFlag: 0,
   createFlag: 0,
   deleteFlag: 0,
   editFlag: 0,
   viewFlag: 0,
   loading: false,

   listStatusStockRequest: [],
   listMaterialFilter: [],
   stockRequest: {
      totalPages: 0,
      listStockRequest: [],
   },
   statusDeleted: 0,
};

const stockRequestReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_AUTHO_MODULE_STOCK_REQUEST':
         return {
            ...state,
            createFlag: action.payload.createFlag,
            approveFlag: action.payload.approveFlag,
            deleteFlag: action.payload.deleteFlag,
            editFlag: action.payload.editFlag,
            viewFlag: action.payload.viewFlag,
         }
      case "UPDATE_MODAL_BASE_QUANTITY_ON_HAND_STOCK_REQUEST":
         return {
            ...state,
            modalRequestBaseQuantity: action.payload,
         };
      case "UPDATE_MODAL_QUANTITY_ON_HAND_STOCK_REQUEST":
         return {
            ...state,
            modalRequestQuantity: action.payload,
         };
      case "UPDATE_MODAL_USED_BASE_UOM_STOCK_REQUEST":
         return {
            ...state,
            modalUsedBaseUom: !state.modalUsedBaseUom,
            modalUsedBaseUomValue: state.modalUsedBaseUom == true ? "" : "danger",
            modalRequestQuantity: "",
            modalRequestBaseQuantity: "",
         };

      case "SHOW_STOCK_REQUEST_LOADING":
         return {
            ...state,
            loading: true,
         };

      case "UPDATE_MODAL_VALIDATION_STOCK_REQUEST":
         return {
            ...state,
            modalValidationFailed: action.payload,
         };

      case "UPDATE_FORM_VALIDATION_STOCK_REQUEST":
         return {
            ...state,
            formValidationFailed: action.payload,
         };

      case "CLEAR_DATA_STOCK_REQUEST":
         return {
            ...initialState,
            submitNewStockRequestStatus: 0,
            updateStockRequestStatus: 0,
            submitWorkFlowActionStatus: 0,
            items: [],
         };
      case 'CLEAR_FIELDS_STOCK_REQUEST':
         return {
            ...state,
            submitNewStockRequestStatus: 0,
            updateStockRequestStatus: 0,
            submitWorkFlowActionStatus: 0,
            items: [],
            requestedDate: "",
            forWarehouse: "",
            requestedBy: "",
            requestDepartment: "",
            description: "",
            attachedFile: "",
         }

      case "GENERATE_ORIGINAL_ITEMS_STOCK_REQUEST":
         let originalItems = [];
         let materialState = action.payload;
         materialState && materialState.length > 0 &&
            materialState.forEach((item) => {
               originalItems.push({
                  id: item.id,
                  value: "(" + item.code + ") " + item.name,
                  code: item.code,
                  name: item.name,
                  image: item.image,
                  baseUomName: item.baseUomName,
               });
            });
         return {
            ...state,
            comboboxItems: originalItems,
            modalValidationFailed: false,
            formValidationFailed: false,
         };
      case "UPDATE_REQUESTED_DATE_STOCK_REQUEST":
         return {
            ...state,
            requestedDate: action.payload,
         };
      case "UPDATE_FOR_WAREHOUSE_STOCK_REQUEST":
         return {
            ...state,
            forWarehouse: parseInt(action.payload),
         };
      case "UPDATE_REQUESTED_BY_STOCK_REQUEST":
         return {
            ...state,
            requestedBy: action.payload,
         };
      case "UPDATE_REQUEST_DEPARTMENT_STOCK_REQUEST":
         return {
            ...state,
            requestDepartment: action.payload,
         };

      case "UPDATE_DESCRIPTION_STOCK_REQUEST":
         return {
            ...state,
            description: action.payload,
         };

      case "UPDATE_ATTACHED_FILE_STOCK_REQUEST":
         return {
            ...state,
            attachedFile: action.payload,
         };
      case "UPDATE_REQUEST_WORK_FLOW_MODAL_DESCRIPTION_STOCK_REQUEST":
         return {
            ...state,
            modalDescription: action.payload,
         };

      case "UPDATE_MODAL_MATERIAL_STOCK_REQUEST":
         return {
            ...state,
            modalMaterialId: action.payload.id,
            modalMaterialCode: action.payload.code,
            modalMaterialName: action.payload.name,
            modalMaterialBaseUom: action.payload.baseUom,
            defaultBrandSelected: true,
            defaultUomSelected: true,
         };
      case "UPDATE_MODAL_BRAND":
         return {
            ...state,
            modalBrandId: action.payload.id,
            modalBrandName: action.payload.name,
            modalUomId: "",
            modalUomName: "",
         };
      case "UPDATE_MODAL_UOM_STOCK_REQUEST":
         return {
            ...state,
            // modalUomName: action.payload.name,
            // modalUomId: action.payload.id,
            // defaultUomSelected: false
            modalUomName: action.payload.name,
            modalUomId: action.payload.id,
            modalUomCode: action.payload.code,
            modalUomQuantityType: action.payload.quantityType,
         };
      case "UPDATE_MODAL_BASE_STOCK_QUANTITY":
         return {
            ...state,
            modalBaseStockQuantity: action.payload,
         };

      case "UPDATE_MODAL_REQUEST_QUANTITY":
         return {
            ...state,
            modalRequestQuantity: action.payload,
         };
      case "UPDATE_REQUIRED_DATE_STOCK_REQUEST":
         return {
            ...state,
            modalRequiredDate: action.payload,
         };
      case "UPDATE_STOCK_REQUEST":
         return {
            ...state,
            modalNote: action.payload,
         };

      case "INSERT_ITEM_STOCK_REQUEST":
         let items = state.items;
         items.push({
            id: state.modalMaterialId,
            code: state.modalMaterialCode,
            name: state.modalMaterialName,
            brandId: state.modalBrandId,
            uomId: state.modalUomId,
            uomName: state.modalUomName,
            requestBaseQuantity: state.modalRequestBaseQuantity,
            requestQuantity: state.modalRequestQuantity,
            requiredDate: state.modalRequiredDate,
            note: state.modalNote,
         });
         return {
            ...state,
            items,
         };

      case "HIDE_MODAL_STOCK_REQUEST":
         return {
            ...state,
            ...initialModalState,
            ...initialRequestWorkFlowModalState,
            modalUsedBaseUomKey: Date.now(),
            modalValidationFailed: false,
            showRequestWorkFlowModal: false,
         };

      case "SHOW_MODAL_STOCK_REQUEST":
         return {
            ...state,
            showModal: true,
         };

      case "SHOW_REQUEST_WORK_FLOW_MODAL":
         return {
            ...state,
            showRequestWorkFlowModal: true,
         };
      case "CLEAR_MODAL_STOCK_REQUEST":
         return {
            ...state,
            modalMaterialId: "",
            modalMaterialCode: "",
            modalMaterialName: "",
            modalNote: "",
            modalLotNumber: "",
            modalExpiryDate: "",
            modalUomId: "",
            modalUomName: "",
            modalBaseQuantity: "",
            modalQuantity: "",
            modalBaseQuantityOnHand: "",
            modalQuantityOnHand: "",
            modalDescription: "",
            defaultUomSelected: true,
         };

      case "SUBMIT_NEW_STOCK_REQUEST_SUCCEEDED":
         return {
            ...state,
            submitNewStockRequestStatus: 1,
            loading: false,
         };

      case "UPDATE_STOCK_REQUEST_SUCCEEDED":
        return {
            ...state,
            updateStockRequestStatus: 1,
            loading: false,
        };

      case 'SUBMIT_NEW_STOCK_REQUEST_FAILED':
         return {
            ...state,
            submitNewStockRequestStatus: 2,
            loading: false
         }

      case "UPDATE_STOCK_REQUEST_FAILED":
        return {
            ...state,
            updateStockRequestStatus: 2,
            loading: false,
        };

      case "SUBMIT_STOCK_REQUEST_WORK_FLOW_SUCCESS":
         return {
            ...state,
            submitWorkFlowActionStatus: 1,
            loading: false
         };

      case "SUBMIT_STOCK_REQUEST_WORK_FLOW_FAILED":
         return {
            ...state,
            submitWorkFlowActionStatus: 2,
            loading: false
         };

      case "FETCH_STOCK_REQUEST_SUCCEEDED":
         // const newStockRequestList = action.payload.filter(stock => stock.stockRequestStatus === "1" || stock.stockRequestStatus === "2" || stock.stockRequestStatus === "3");
         return {
            ...state,
            stockRequestList: action.payload,
            loading: false,
         };

      case "FETCH_STOCK_REQUEST_DETAIL_SUCCEEDED":
         return {
            ...state,
            stockRequestDetail: action.payload,
            loading: false,
         };
      case "FETCH_STOCK_REQUEST_DETAIL_FAILED":
         return {
            ...state,
            loading: false,
         };
      case "FETCH_ALL_STOCK_REQUEST_SUCCEEDED_V2":
         return {
            ...state,
            listStatusStockRequest: action.payload,
            loading: false,
         };
      // case "FETCH_ALL_STOCK_REQUEST_SUCCEEDED":
      //    return {
      //       ...state,
      //       listStatusStockRequest: action.payload,
      //       loading: false,
      //    };
      case "FETCH_MATERIAL_FILTER_SUCCEEDED":
         return {
            ...state,
            listMaterialFilter: action.payload,
            loading: false,
         };
      case "FETCH_STOCK_REQUEST_BY_FILTER_SUCCEEDED":
         return {
            ...state,
            stockRequest: {
               totalCount: action.payload.totalCount,
               listStockRequest: action.payload.data,
            },
            loading: false,
         };
      case "SET_DEFAULT_STOCK_REQUEST":
         return initialState;

      case "FETCH_STOCK_REQUEST_DETAILT_FAILED":
         return initialState;
      case "SET_OFF_LOADING":
         return {
            ...state,
            submitNewStockRequestStatus: 1,
            loading: false,
         };
      case "SET_DEFAULT_UPDATE_VALUE":
         return {
            ...state,
            updateStockRequestStatus: 0,
            statusDeleted: 0,
         }
      case "SET_DELETED_SUCCESS_STOCK_REQUEST":
         return {
            ...state,
            statusDeleted: 1,
            loading: false,
         }

      default:
         return state;
   }
};

export default stockRequestReducer;
