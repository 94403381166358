import axios from 'axios'
import constants from '../reusable/constants'

export function fetchMaterialInstancesByWarehouseApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockDestruction/GetMaterialInstancesByWarehouseId?warehouseId=' + payload.warehouseId
      + '&materialId=' + payload.materialId
      + '&brandId=' + payload.brandId
      + '&uomId=' + payload.uomId,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function createStockDestructionApi(payload) {
   return axios.post(
      constants.api.url + 'api/stockDestruction',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function updateStockDestructionApi(payload) {
   return axios.put(
      constants.api.url + 'api/stockDestruction',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockDestructionsApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockDestruction?uId=' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockDestructionDetailApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockDestruction/' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

//MỚi
export function fetchStockDestructionByFilter({ UId, WarehouseId, FromDate, ToDate, StockRequestCode, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn}) {
   return axios.get(
      constants.api.url + 
      `api/stockDestruction?UId=${UId}&WarehouseId=${WarehouseId}&FromDate=${FromDate}&ToDate=${ToDate}&StockDestructionCode=${StockRequestCode}&MaterialId=${MaterialId}&Status=${Status}&page=${currentPage}&pageSize=${pageSize}&orderby=${orderBy}&nameColumn=${nameColumn}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }

 export function fetchListStatusStockDestruction() {
   return axios.get(
      constants.api.url + 
      `api/stockDestruction/GetListStatusDestruction`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }

 export function submitStockDestructionApi(payload) {
   const {id, uId} = payload
   if(id || id === 0){
     delete payload.uId
     return axios.put(
       constants.api.url + `api/StockDestruction?UId=${uId}`,
       payload,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
     );
   }
   
   return axios.post(
     constants.api.url + 'api/StockDestruction',
     payload,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function updateStatusStockDestruction(payload) {
   return axios.put(
      constants.api.url + 
      `api/StockDestruction/UpdateStatus`,
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }