import { ReactComponent as IconSetting } from "src/assets/icon/setting.svg";
import { ReactComponent as IconSetting3 } from "src/assets/icon/setting3.svg";
import { ReactComponent as IconPersonnel } from "src/assets/icon/personnel.svg";
import { ReactComponent as IconRecruitment } from "src/assets/icon/recruitment.svg";
import { ReactComponent as IconTimeKeeping } from "src/assets/icon/timekeeping.svg";
import { ReactComponent as IconApp } from "src/assets/icon/app.svg";
import { ReactComponent as IconTwoUser } from "src/assets/icon/two-user.svg";
import { ReactComponent as IconTwoUser2 } from "src/assets/icon/two-user2.svg";
import { ReactComponent as IconPOSMachine } from "src/assets/icon/pos-machine.svg";
import { ReactComponent as IconCalendar } from "src/assets/icon/calendar.svg";
import { ReactComponent as IconUpload } from "src/assets/icon/upload.svg";
import { ReactComponent as IconProject } from "src/assets/icon/project.svg";
import { ReactComponent as IconSearch1 } from "src/assets/icon/search-1.svg";
import { ReactComponent as IconLeft } from "src/assets/icon/left.svg";
import { ReactComponent as IconPlus1 } from "src/assets/icon/plus-1.svg";
import { ReactComponent as IconRemove } from "src/assets/icon/remove.svg";
import { ReactComponent as IconRemove1 } from "src/assets/icon/remove-1.svg";
import { ReactComponent as IconDropDown } from "src/assets/icon/dropdown.svg";
import { ReactComponent as IconDropUp} from "src/assets/icon/dropup.svg";
import { ReactComponent as IconWareHouse } from "src/assets/icon/ware-house.svg";
import { ReactComponent as IconPencil } from "src/assets/icon/pencil.svg";
import { ReactComponent as IconPencil2 } from "src/assets/icon/pencil2.svg";
import { ReactComponent as IconNext } from "src/assets/icon/next.svg";
import { ReactComponent as IconPrev } from "src/assets/icon/prev.svg";
import { ReactComponent as IconExport } from "src/assets/icon/export-excel.svg";
import { ReactComponent as IconUpload1 } from "src/assets/icon/upload-1.svg";
import { ReactComponent as IconSort } from "src/assets/icon/sort-up.svg";
import { ReactComponent as IconCheckbox } from "src/assets/icon/checkbox.svg";
import { ReactComponent as IconCheckboxRed } from "src/assets/icon/checkbox2.svg";
import { ReactComponent as IconUnCheckbox } from "src/assets/icon/unCheckbox.svg";
import { ReactComponent as IconEye } from "src/assets/icon/eye.svg";
import { ReactComponent as IconStore2 } from "src/assets/icon/store2.svg";
import { ReactComponent as IconFly } from "src/assets/icon/fly.svg";
import { ReactComponent as DefaultAvartar } from "src/assets/icon/default-avartar.svg";
import { ReactComponent as IconClock } from "src/assets/icon/clock.svg";

import { ReactComponent as IconStore } from "src/assets/icon/inventory/sidebar/store.svg";
import { ReactComponent as IconProduce } from "src/assets/icon/inventory/sidebar/produce.svg";
import { ReactComponent as IconOrder } from "src/assets/icon/inventory/sidebar/order.svg";
import { ReactComponent as IconReport } from "src/assets/icon/inventory/sidebar/report.svg";
import { ReactComponent as IconRoot } from "src/assets/icon/inventory/sidebar/root.svg";

import { ReactComponent as IconX } from "src/assets/icon/X.svg";
import { ReactComponent as IconV } from "src/assets/icon/V.svg";
import { ReactComponent as IconDownload } from "src/assets/icon/Download.svg";
import { ReactComponent as IconTick } from "src/assets/icon/tick.svg";
import { ReactComponent as IconTock } from "src/assets/icon/tock.svg";
import { ReactComponent as IconReject } from "src/assets/icon/reject.svg";
import { ReactComponent as IconApprove } from "src/assets/icon/approve.svg";
import { ReactComponent as IconEditRequest } from "src/assets/icon/edit-request.svg";
import { ReactComponent as IconUploadFile } from "src/assets/icon/upload-file.svg";
import { ReactComponent as IconManagerWarehouse } from "src/assets/icon/manager-warehouse.svg";
import { ReactComponent as IconQCApprove } from "src/assets/icon/qc-approve.svg";
import { ReactComponent as IconMenu } from "src/assets/icon/menu.svg";


const svg = {
  IconX,
  IconV,
  IconSetting,
  IconSetting3,
  IconPersonnel,
  IconRecruitment,
  IconTimeKeeping,
  IconApp,
  IconTwoUser,
  IconTwoUser2,
  IconPOSMachine,
  IconCalendar,
  IconUpload,
  IconProject,
  IconSearch1,
  IconLeft,
  IconPlus1,
  IconRemove,
  IconRemove1,
  IconDropDown,
  IconDropUp,
  IconWareHouse,
  IconPencil,
  IconPencil2,
  IconStore,
  IconProduce,
  IconOrder,
  IconReport,
  IconRoot,
  IconNext,
  IconPrev,
  IconExport,
  IconUpload1,
  IconSort,
  IconCheckbox,
  IconUnCheckbox,
  IconUnCheckbox,
  IconCheckboxRed,
  IconEye,
  IconStore2,
  IconTock,
  IconFly,
  IconDownload,
  DefaultAvartar,
  IconTick,
  IconClock,
  IconReject,
  IconApprove,
  IconEditRequest,
  IconUploadFile,
  IconManagerWarehouse,
  IconQCApprove,
  IconMenu,
};

export default svg;
