import { formLabelClasses } from "@material-ui/core";

const initialState = {
   requestedDate: "",
   attatchedFile: "",
   loading: false,
   uploadMasterList: [],
   scheduleDetail: null,
   submitNewScheduleStatus: 0,
   error: "",
   forWareHouse: "",
   formValidationFailed: false,
   selectedWarehouse: "",
   selectedDateFrom: "",
   selectedDateTo: "",
   scheduleReports: [],
   selectedStoreId: "",
   storeName: "",
   staffMaster: [],
};

const scheduleReducer = (state = initialState, action) => {
   switch (action.type) {
      case "SHOW_SCHEDULE_LOADING":
         return {
            ...state,
            loading: true,
         };

      case "HIDE_SCHEDULE_LOADING":
         return {
            ...state,
            loading: false,
         };

      case "FETCH_UPLOAD_MASTERS_SUCCEEDED":
         return {
            ...state,
            uploadMasterList: action.payload,
            loading: false,
         };

      case "FETCH_UPLOAD_MASTERS_FAILED":
         return {
            ...state,
            error: action.payload,
            loading: false,
         };

      case "FETCH_ALL_STAFF_MASTERS_SUCCEEDED":
         return {
            ...state,
            staffMaster: action.payload,
            loading: false,
         };

      case "FETCH_ALL_STAFF_MASTERS_FAILED":
         return {
            ...state,
            error: action.payload,
            loading: false,
         };

      case "FETCH_SCHEDULE_DETAIL_SUCCESS":
         return {
            ...state,
            scheduleDetail: action.payload,
            loading: false,
         };

      case "FETCH_SCHEDULE_DETAIL_FAILED":
         return {
            ...state,
            error: action.payload,
            loading: false,
         };
      case "FETCH_SCHEDULE_REPORT_SUCCESS":
         return {
            ...state,
            scheduleReports: action.payload,
            loading: false
         }
      case "FETCH_SCHEDULE_REPORT_FAILED":
         return {
            ...state,
            error: action.payload,
            loading: false
         }

      case "UPDATE_ATTACHED_FILE_SCHEDULE":
         return {
            ...state,
            attachedFile: action.payload,
         };
      case "UPDATE_FOR_WAREHOUSE_SCHEDULE":
         return {
            ...state,
            forWareHouse: parseInt(action.payload),
         };
      case "UPDATE_FORM_VALIDATION_SCHEDULE":
         return {
            ...state,
            formValidationFailed: action.payload,
         };
      case "SUBMIT_NEW_SCHEDULE_SUCCEEDED":
         return {
            ...state,
            submitNewScheduleStatus: 1,
         };

      case "SUBMIT_NEW_SCHEDULE_FAILED":
         return {
            ...state,
            submitNewScheduleStatus: 2,
         };
      case "UPDATE_STORE": {
         return {
            ...state,
            store: action.payload
         }
      }
      case "UPDATE_SELECTED_MONTH":
         return {
            ...state,
            monthId: action.payload,
         };
      case "UPDATE_SELECTED_WAREHOUSE":
         return {
            ...state,
            selectedWarehouse: action.payload,
         };
      case "UPDATE_SELECTED_STORE":
         return {
            ...state,
            selectedStoreId: action.payload.storeId,
            storeName: action.payload.storeName
         };
      case "UPDATE_SELECTED_DATE_FROM":
         return {
            ...state,
            selectedDateFrom: action.payload,
         };
      case "UPDATE_SELECTED_DATE_TO":
         return {
            ...state,
            selectedDateTo: action.payload,
         };
      case "CLEAR_DATA_SCHEDULE":
         return {
            ...initialState,
            submitNewScheduleStatus: 0,
         };
      default:
         return state;
   }
};

export default scheduleReducer;
