import axios from 'axios'
import constants from '../reusable/constants'

export function fetchWarehouseStockApi(payload) {
  console.log('fetchWarehouseStockApi payload: ', payload);
    return axios.get(
        constants.api.url + 'api/stock?warehouseId=' + payload.warehouseId + '&cutDate=' + payload.cutDate
    );
}
export function fetchInventoryLostStockApi(payload) {
    console.log('fetchWarehouseStockApi payload: ', payload);
    return axios.get(
        constants.api.url + 'api/stockReconcile/getStockReconciles/'+payload.warehouseId
    );
}
export function fetchInventoryCheckStockApi(payload) {
  console.log('fetchWarehouseStockApi payload: ', payload);
  return axios.get(
      constants.api.url + 'api/stockTake?UId='+payload.userId+'&pageSize=10'
  );
}
