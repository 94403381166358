import axios from 'axios'
import constants from '../reusable/constants'

export function fetchSite() {
    return axios.get(
        constants.api.url + 'api/site',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}
export function fetchSitePagination(payload) {
  return axios.get(
      constants.api.url + `api/Site/GetAllSites?page=${payload.page}&pageSize=${payload.pageSize}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export function updateSiteApi(payload){
  return axios.put(
      constants.api.url + `api/Site/UpdateSite`,
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  )
}
