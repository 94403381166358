const initialState = {
    loading: false,
    shipperList: [],
    showModal: false,
    chosenShipperId: undefined,
    setShipperStatus: undefined,
}

const shipperReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SHIPPER_STATUS_SUCCESS':
            return {
                ...state,
                loading: false,
                setShipperStatus: 1,
            };

        case 'SET_SHIPPER_STATUS_FAILED':
            return {
                ...state,
                loading: false,
                setShipperStatus: 2,
            };

        case 'SHOW_SHIPPER_IMAGES':
            return {
                ...state,
                showModal: true,
                chosenShipperId: action.payload,
            };

        case 'HIDE_SHIPPER_IMAGES':
            return {
                ...state,
                showModal: false,
                chosenShipperId: undefined,
            };

        case 'LOAD_SHIPPER_LIST_SUCCESS':
            return {
                ...state,
                loading: false,
                shipperList: action.payload,
            };

        case 'LOAD_SHIPPER_LIST_FAILED':
            return {
                ...state,
                loading: false,
            };

        case 'CLEAR_SHIPPER_DATA':
            return {
                ...state,
                shipperList: [],
                showModal: false,
                chosenShipperId: undefined,
                setShipperStatus: undefined,
            };

        case 'SHOW_SHIPPER_LOADING':
            return {
                ...state,
                loading: true,
            }

        default:
            return state
    }
}

export default shipperReducer