import axios from 'axios'
import constants from '../reusable/constants'

export function fetchShipperListApi() {
    return axios.get(
        constants.api.url + 'api/ProductOnline/GetShippers',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}


export function setStatusShipper(payload) {
    return axios.post(
        constants.api.url + 'api/ProductOnline/SetStatusShipper',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}