const initialState = {
    fromDate: undefined,
    toDate: undefined,
    storeId: undefined,
    loading: false,
    stores: [],
    reportingList: [],
    error: undefined,
    formValidationFailed: undefined,
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SHOW_REPORT_LOADING":
            return {
                ...state,
                loading: true,
            };
        case "REPORT_UPDATE_SELECTED_STORE":
            return {
                ...state,
                storeId: action.payload.storeId
            };

        case 'REPORT_UPDATE_SELECTED_DATE_FROM':
            return {
                ...state,
                fromDate: action.payload
            }
        case 'REPORT_UPDATE_SELECTED_DATE_TO':
            return {
                ...state,
                toDate: action.payload
            }

        case 'FETCH_MATERIAL_SALES_REPORT_SUCCESS':
            return {
                ...state,
                loading: false,
                reportingList: action.payload
            }
        case 'FETCH_MATERIAL_SALES_REPORT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case "REPORT_UPDATE_FORM_VALIDATION":
            return {
                ...state,
                formValidationFailed: action.payload,
            }
        default:
            return state;
    }
};

export default reportReducer;
