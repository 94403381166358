import axios from "axios";
import constants from "../reusable/constants";

export function fetchVoucherOrders(payload) {
  return axios.get(
    constants.api.url +
      "api/Voucher/GetVoucherOrders?fromDate=" +
      payload.fromDate +
      "&toDate=" +
      payload.toDate,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchPOSVoucher() {
  return axios.get(constants.api.url + `api/voucher/GetPOSVoucher`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function fetchAppVoucher() {
  return axios.get(constants.api.url + "api/voucher/GetAppVoucher", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function fetchCategoriesFilter() {
  return axios.get(constants.api.url + "api/voucher/GetCategories", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function cancelVoucherOrders({ id, userId }) {
  return axios.put(
    constants.api.url + `api/Voucher/CancelVoucherOrder/${id}?UId=${userId}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchMaterialsFilter() {
  return axios.get(constants.api.url + "api/voucher/GetMaterials", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function approveVoucherOrders({ id, userId }) {
  return axios.put(
    constants.api.url + `api/Voucher/ApproveVoucherOrder/${id}?UId=${userId}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
export function approveVoucherOrdersV2({ id, userId, paymentType }) {
  return axios.put(
    constants.api.url +
      `api/Voucher/ApproveVoucherOrderV2/${id}?paymentType=${paymentType}&UId=${userId}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
export function fetchPOSVoucherDetail({ id }) {
  return axios.get(constants.api.url + `api/Voucher/GetPosVoucherItem/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function fetchAppVoucherDetail({ id }) {
  return axios.get(constants.api.url + `api/voucher/GetAppVoucherItem/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function createPOSVoucher(payload) {
  return axios.post(
    constants.api.url + `api/voucher/CreatePosVoucher`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updatePOSVoucher(payload) {
  return axios.put(
    constants.api.url + `api/voucher/UpdatePosVoucher`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function createAppVoucher(payload) {
  return axios.post(
    constants.api.url + `api/voucher/CreateAppVoucher`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateAppVoucher(payload) {
  return axios.put(
    constants.api.url + `api/voucher/UpdateAppVoucher`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateStatusPOSVoucher(payload) {
  return axios.put(
    constants.api.url + `api/voucher/UpdateStatusPosVoucher`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateStatusAppVoucher(payload) {
  return axios.put(
    constants.api.url + `api/voucher/UpdateStatusAppVoucher`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchMaterialRedeemVoucher() {
  return axios.get(constants.api.url + "api/voucher/GetMaterialRedeemVoucher", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function fetchListUserVoucher() {
  return axios.get(constants.api.url + "api/voucher/GetListUser", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function createRedeemVoucher(payload) {
  return axios.post(
    constants.api.url + "api/voucher/CreateMaterialRedeemVoucher",
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateRedeemVoucher(payload) {
  return axios.put(
    constants.api.url + "api/voucher/UpdateMaterialRedeemVoucher",
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateStatusRedeemVoucher(payload) {
  return axios.put(
    constants.api.url + "api/voucher/UpdateStatusMaterialRedeemVoucher",
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}


export function approveBillVoucherOrder(payload) {
  return axios.put(
    constants.api.url + `api/voucher/ApproveBillVoucherOrder/${payload.voucherOrderId}?UId=${payload.userId}`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function approvePaidVoucherOrder(payload) {
  return axios.put(
    constants.api.url + `api/voucher/ApprovePaidVoucherOrder/${payload.voucherOrderId}?UId=${payload.userId}&PaymentType=${payload.paymentType}`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function getDiscountItemsApi() {
  return axios.get(
    constants.api.url + `api/Voucher/GetDiscountItems?storeId=1`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function createNewVoucherOrderApi(payload) {
  return axios.post(
    constants.api.url + `api/voucher/CreateVoucherOrder`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateVoucherOrderApi(payload) {
  return axios.put(
    constants.api.url + `api/voucher/UpdateVoucherOrder`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}