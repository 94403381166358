const colors = {
  // Purple
  PURPLE_7: "#6D1084",
  PURPLE_6: "#853797",
  PURPLE_5: "#9E5EAA",
  PURPLE_4: "#B685BD",
  PURPLE_3: "#CEACCF",
  PURPLE_2: "#E7D3E2",

  // Blue
  BLUE_1: "#121274",
  BLUE_2: "#046CFC",
  // Cheese
  CHEESE_5: "#FFDCB4",
  CHEESE_4: "#FFE3C4",
  CHEESE_3: "#FFEBD3",
  CHEESE_2: "#FFF2E3",
  CHEESE_1: "#FFF9F3",

  // Cherry
  CHERRY_1: "#fc473c",
  CHERRY_2: "#DA002B",
  MAIN_CHERRY: "#FF4236",
  CHERRY_6: "#FD685B",
  CHERRY_5: "#FE887A",
  CHERRY_4: "#FEA999",
  CHERRY_3: "#FFC9B8",

  // Gray Light
  GREY_1: "#333333",
  GREY_2: "#525252",
  GREY_3: "#ACACAC",
  GREY_4: "#D8D8D8",
  GREY_5: "#F5F5F5",

  // White
  WHITE: "#FFFFFF",
  // ORANGE
  ORANGE_1: "#FF9B00",
  ORANGE_2: "#FF8F50",
  ORANGE_POS: '#FFA500',

  // Color Status
  SUCCESS: "#1CCE66",
  WARNING: "#FFC700",
  PRIMARY: "#046CFC",
  ERROR: "#FF5341",
  COMPLETED: "#64e2e7",

  // Link secondary
  SECONDARY_LINK: "#046CFC",
  DROP_HOVER_COLOR: "#F7FDFF",
  DISABLE: "#525252",
};
export const ConstantVariables = {
  colorInputSelectHover: "#046CFC",
  colorInputSelectDefault: "#525252",
  colorInputSelectActive: "#046CFC",
};

export const Theme = {
  _HRM: {
    color: "#046CFC", // main color for input filter
    filterButtonColor: "#046CFC", // color for filter button
    colorFilter: "#046CFC",
    colorDateFilter: "#046CFC",
  },
  _INVENTORY: {
    color: "#046CFC", // main color for input filter
    filterButtonColor: "#046CFC", // color for filter button
    colorFilter: "#046CFC",
    colorDateFilter: "#046CFC",
  },
  _DASHBOARD: {
    color: "#046CFC",
    colorDateFilter: "#046CFC",
    filterButtonColor: "#046CFC", // color for filter button
    colorFilter: "#046CFC",
  },
  _POS: {
    color: "#046CFC", // main color for input filter
    filterButtonColor: "#046CFC", // color for filter button
    colorFilter: "#046CFC",
    colorDateFilter: "#046CFC",
    colorTextTab: "#046CFC",
    Config: {
      HeadingColor: "#525252",
      BorderColor:"#acacac",
      BackgroundColor:"#ffffff",
      PreviewBackgroundColor:"#FFF5C6",
      DivideColor:"#F5f5f5",
      colorFilter: "#046CFC",
    },
  },
};
export default colors;
