import axios from "axios";
import constants from "../reusable/constants";

export function fetchMaterialBrand() {
  return axios.get(constants.api.url + "api/materialBrand", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function fetchMaterialBrandPagination(payload) {
  const { page, pageSize, status, name, code, level } = payload;
  let attributes = [
    { text: "page", value: page },
    { text: "pageSize", value: pageSize },
    { text: "status", value: status },
    { text: "name", value: name },
    { text: "code", value: code },
    { text: "level", value: level },
  ];
  let newAttributes = attributes.filter(
    (item) => item.value !== null && item.value !== undefined
  );
  let urlAtrribute = "";
  newAttributes.forEach((item, index) => {
    urlAtrribute += item.text + "=" + item.value;
    if (index !== attributes.length - 1) {
      urlAtrribute += "&";
    }
  });
  return axios.get(
    constants.api.url +
      `api/MaterialBrand/GetAllMaterialBrands?${urlAtrribute}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function createNewMaterialBrand(payload) {
  return axios.post(constants.api.url + "api/materialBrand", payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function updateMaterialBrands(payload) {
  return axios.put(constants.api.url + "api/MaterialBrand/UpdateMaterialBrand", payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
