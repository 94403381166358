const initialModalState = {
  modal_material_name: null,
  modal_material_id: null,
  modal_from_uom_id: null,
  modal_from_uom_name: null,
  modal_rate: null,
  modal_quantityType: null,
  modal_decrement: null,
  modal_generic: null,
  modal_display: null,
  modal_description: null,
  modal_status: null,
  modal_to_uom_name: null,
  modal_to_uom_id: null,
  modal_operator: null,
  modal_id: null,
  modal_material_list: [],
  modal_uom_list: [],
  modal_brand_name: null,
};

const initialState = {
  loading: false,
  conversions: [],
  ...initialModalState,
  modalValidationFailed: false,
  submitNewConversionStatus: "",
  submitUpdateConversionStatus: "",
  materialBrandComboboxItems: [],
  uomComboboxItems: [],

  //filter
  currentPage: 1,
  pageSize: 10,
};

const conversionReducer = (state = initialState, action) => {
  switch (action.type) {
    //update case
    case "UPDATE_CONVERSION_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "UPDATE_CONVERSION_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "UPDATE_CONVERSION_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        submitUpdateConversionStatus: 1,
        loading: false,
      };

    case "UPDATE_CONVERSION_FAILED":
      return {
        ...state,
        modalValidationFailed: false,
        submitUpdateConversionStatus: 2,
        loading: false,
      };

    case "SHOW_CONVERSION_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_CONVERSION_SUCCEEDED":
      return {
        ...state,
        conversions: action.payload,
        loading: false,
      };
    case "FETCH_CONVERSION_PAGINATION_SUCCEEDED":
      return {
        ...state,
        conversions: action.payload.data,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    case "CLEAR_DATA":
      return {
        ...state,
        submitNewConversionStatus: "",
        submitUpdateConversionStatus: "",
      };
    case "UPDATE_MODAL_VALIDATION":
      return {
        ...state,
        modalValidationFailed: action.payload,
      };
    case "SAVE_NEW_CONVERSION_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewConversionStatus: 1,
        loading: false,
      };
    case "UPDATE_CONVERSION_ID": {
      return { ...state, modal_id: action.payload };
    }
    case "UPDATE_CONVERSION_MATERIAL_ID": {
      return { ...state, modal_material_id: action.payload };
    }
    case "UPDATE_CONVERSION_FROM_UOM_ID": {
      return { ...state, modal_from_uom_id: action.payload };
    }
    case "UPDATE_CONVERSION_TO_UOM_ID": {
      return { ...state, modal_to_uom_id: action.payload };
    }
    case "UPDATE_CONVERSION_RATE": {
      return { ...state, modal_rate: action.payload };
    }
    case "UPDATE_CONVERSION_QUANTITY_TYPE": {
      return { ...state, modal_quantityType: action.payload };
    }
    case "UPDATE_CONVERSION_STATUS": {
      return { ...state, modal_status: action.payload };
    }
    case "UPDATE_CONVERSION_DECREMENT": {
      return { ...state, modal_decrement: action.payload };
    }
    case "UPDATE_CONVERSION_GENERIC": {
      return { ...state, modal_generic: action.payload };
    }
    case "UPDATE_CONVERSION_DISPLAY": {
      return { ...state, modal_display: action.payload };
    }
    case "UPDATE_CONVERSION_DESCRIPTION": {
      return { ...state, modal_description: action.payload };
    }
    case "UPDATE_CONVERSION_OPERATOR": {
      return { ...state, modal_operator: action.payload };
    }
    case "UPDATE_CONVERSION_MODAL_DATA": {
      return {
        ...state,
        modal_material_name: action.payload.modal_material_name,
        modal_from_uom_name: action.payload.modal_from_uom_name,
        modal_to_uom_name: action.payload.modal_to_uom_name,
        modal_rate: action.payload.modal_rate,
        modal_quantityType: action.payload.modal_quantityType,
        modal_decrement: action.payload.modal_decrement,
        modal_generic: action.payload.modal_generic,
        modal_display: action.payload.modal_display,
        modal_description: action.payload.modal_description,
        modal_status: action.payload.modal_status,
        modal_id: action.payload.modal_id,
        modal_material_id: action.payload.modal_material_id,
        modal_from_uom_id: action.payload.modal_from_uom_id,
        modal_to_uom_id: action.payload.modal_to_uom_id,
        modal_operator: action.payload.modal_operator,
        modal_brand_name: action.payload.modal_brand_name,
      };
    }
    case "CLEAR_MODAL_CREATE_NEW_CONVERSION": {
      return {
        ...state,
        ...initialModalState,
      };
    }
    case "FETCH_MATERIAL_BRAND_SUCCEEDED": {
      let array = action.payload;
      let newArray = array.map((item) => {
        return {
          value: item.id,
          name: item.brandName + " - " + item.materialName,
        };
      });
      return { ...state, modal_material_list: newArray };
    }
    case "FETCH_ALL_UOMS_SUCCEEDED": {
      let array = action.payload;
      let newArray = array.map((item) => {
        return {
          value: item.id,
          name: item.name,
        };
      });

      return { ...state, modal_uom_list: newArray };
    }

    case "SHOW_CONVERSION_MODAL":
      if (action.payload.mode == "edit") {
        return {
          ...state,
          showModal: true,
          modalTitle: action.payload.title,
          modalMode: action.payload.mode,
          modalId: action.payload.id,
          modalMaterialName: action.payload.name,
          modalFromUomName: action.payload.fromUom,
          modalToUomName: action.payload.toUom,
          modalRate: action.payload.rate,
          modalOperand: action.payload.operand,
          modalDecrement: action.payload.decrement,
          modalIsGeneric: action.payload.isGeneric,
          modalIsDisplay: action.payload.isDisplay,
          modalQuantityType: action.payload.quantityType,
          modalDescription: action.payload.description,
        };
      } else {
        return {
          ...state,
          showModal: true,
          modalTitle: action.payload.title,
          modalMode: action.payload.mode,
          modalOperand: "*",
          modalDecrement: 0,
        };
      }
    case "HIDE_MODAL":
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
        modalIsGenericKey: Date.now,
        modalIsDisplayKey: Date.now,
        modalOperand: "*",
        modalDecrement: 0,
      };
    case "UPDATE_MODAL_MATERIAL_BRAND":
      return {
        ...state,
        modalMaterialBrand: action.payload,
      };
    case "UPDATE_MODAL_FROM_UOM":
      return {
        ...state,
        modalFromUom: action.payload,
      };
    case "UPDATE_MODAL_TO_UOM":
      return {
        ...state,
        modalToUom: action.payload,
      };
    case "UPDATE_MODAL_RATE":
      return {
        ...state,
        modalRate: action.payload,
      };
    case "UPDATE_MODAL_OPERAND":
      return {
        ...state,
        modalOperand: action.payload,
      };
    case "UPDATE_MODAL_DECREMENT":
      return {
        ...state,
        modalDecrement: action.payload,
      };
    case "UPDATE_MODAL_ISGENERIC":
      return {
        ...state,
        modalIsGeneric: !state.modalIsGeneric,
      };
    case "UPDATE_MODAL_ISDISPLAY":
      return {
        ...state,
        modalIsDisplay: !state.modalIsDisplay,
      };
    case "UPDATE_MODAL_QUANTITY_TYPE":
      return {
        ...state,
        modalQuantityType: action.payload,
      };
    case "UPDATE_MODAL_DESCRIPTION":
      return {
        ...state,
        modalDescription: action.payload,
      };
    case "GENERATE_UOM_COMBOBOX_ITEMS":
      let uomOriginalItems = [];
      let uoms = action.payload;
      uoms &&
        uoms.forEach((item) => {
          uomOriginalItems.push({
            id: item.id,
            value: item.name,
          });
        });
      return {
        ...state,
        uomComboboxItems: uomOriginalItems,
        modalValidationFailed: false,
      };

    case "GENERATE_MATERIAL_BRAND_COMBOBOX_ITEMS":
      let items = [];
      let materialBrands = action.payload;
      materialBrands &&
        materialBrands.forEach((item) => {
          items.push({
            id: item.id,
            value: item.brandName + " - " + item.materialName,
          });
        });
      return {
        ...state,
        materialBrandComboboxItems: items,
        modalValidationFailed: false,
      };

    case "UPDATE_MODAL_CATEGORY":
      return {
        ...state,
        modalCategoryId: action.payload,
      };
    case "UPDATE_MODAL_UOM":
      return {
        ...state,
        modalBaseUomId: action.payload,
      };
    case "UPDATE_MODAL_GROUP":
      return {
        ...state,
        modalGroupId: action.payload,
      };
    default:
      return state;
  }
};

export default conversionReducer;
