const initialState = {
  sumNetAmount: 0,
  details: []
}

const revenueMonthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_REVENUE_MONTH_SUCCESS':
      const { data: month } = action.payload
      if (month.details.length > 0) {
        const netAmountArr = month.details.map(item => item.netAmount)
        const prettyOrderMonthArr = month.details.map(item => item.prettyOrderMonth)
        return{
          ...month,
          storeName: month.details[0].storeName,
          netAmountArr,
          prettyOrderMonthArr
        }
      }
      return {...month}

    default:
      return state;
  }
};

export default revenueMonthReducer;
