const initialState = {
  loading: false,
  ReportWarehouseList: [], // danh sách các báo cáo tồn kho
  totalPage: undefined,
  file: undefined,
}

const InventoryStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INVENTORY_STOCK_REPORT_SUCCESSED':
      return {
        ...state,
        ReportWarehouseList: action.payload.data,
        loading: false,
        totalPage: action.payload.totalPage,
      }
    case 'FETCH_INVENTORY_STOCK_REPORT_FAILED':
      return {
        ...state,
        loading: false,
      }
    case 'SHOW_INVENTORY_STOCK_REPORT_LOADING':
      return {
        ...state,
        loading: action.payload,
      }
    case 'EXPORT_EXCEL_INVENTORY_STOCK_REPORT_SUCCESSED':
      console.log('URL', action.payload)
      return {
        ...state,
        file: action.payload.data,
      }
    case 'CLEAR_URL':
      return {
        ...state,
        file: undefined,
      }
    case 'CLEAR_ALL':
      return initialState
    // Mặc định
    default:
      return state
  }
}

export default InventoryStockReducer
