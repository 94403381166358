import { combineReducers } from "redux"
import goodsTransfer from '../goodsTransfer/goodsTransferReducer'
import flag from '../goodsTransfer/goodsTransferFlagReducer'
import statusList from '../goodsTransfer/goodsTransferStatusListReducer'
import stockRequestList from './goodsTransferStockRequestStatus34Reducer'
import stockRequestDetail from './goodsTransferStockRequestDetailReducer'
import submit from './goodsTransferSubmitReducer'
import detail from './goodsTransferDetailReducer'
import deleteGoodsTransfer from './goodsTransferDeleteReducer'
import approveRequest from './goodsTransferApproveRequestReducer'
import workFlow from './goodsTransferWorkFlowActionReducer'


const rootGoodsTransferReducer = combineReducers({
    goodsTransfer,
    flag,
    statusList,
    stockRequestList,
    stockRequestDetail,
    submit,
    detail,
    deleteGoodsTransfer,
    approveRequest,
    workFlow,
})

export default rootGoodsTransferReducer