import axios from 'axios'
import constants from '../reusable/constants'

export function fetchApp() {
    return axios.get(
        constants.api.url + 'api/ProductOnline/GetAppMasters',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function uploadPOSTFile(payload) {
    return axios.post(
        constants.api.url + 'api/HRApp/UploadPOSFile',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function fecthAllPOSFile() {
    return axios.get(
        constants.api.url + 'api/HRApp/GetPOSFiles',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function fecthPOSFileSetting(payload) {
    return axios.get(
        constants.api.url + `api/HRApp/GetPOSFilesSetting?appId=${payload.appId}&storeId=${payload.storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function updateUserPassWord(payload) {
    return axios.post(constants.api.url + 'api/UserRole/UpdateUserPassword', payload)
}

export function getProductsWithCustomerApi(payload) {
    return axios.get(
        constants.api.url + `api/ProductOnline/GetCategoriesWithProductsForCustomer?storeId=1&appId=1&customerCode=${payload}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function updateStatusOrderApi(payload) {
    return axios.post(
        constants.api.url + `api/ProductOnline/SetStatusOrder`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
    
}