const initState = {
  contentType: [],
  configData: [],
  loading: true,
  data: [],
  submitStatus: 0,
  dataSetting: [],
  dataDefault: [],
};
const configPosContentDataReducer = (state = initState, action) => {
  switch (action.type) {
    case "CONFIG_POS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_CONTENT_TYPE_SUCCEEDED":
      return {
        ...state,
        contentType: action.payload.data,
        loading: false,
      };
    case "GET_CONTENT_SETTING_SUCCEEDED":
      return {
        ...state,
        dataDefault: action.payload.data,
        loading: false,
      };
    case "GET_CONTENT_TYPE_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_CONFIG_POS_CONTENT_DATA": {
      return {
        ...state,
      };
    }
    case "UPDATE_CONFIG_DATA": {
      let newData = state.data;
      action.payload.forEach((item) => {
        let index = newData.findIndex((data) => data.id === item.id);
        if (index !== -1) {
          newData[index] = item;
        } else {
          newData.push(item);
        }
      });

      return {
        ...state,
        data: newData,
      };
    }
    case "SET_CONTENT_TYPE_SUCCEEDED": {
      return {
        ...state,
        loading: false,
        submitStatus: 1,
        data: [],
      };
    }
    case "SET_CONTENT_TYPE_FAILED": {
      return {
        ...state,
        loading: false,
        submitStatus: 2,
        data: [],
      };
    }
    case "GET_POS_FILE_SUCCEEDED": {
      return {
        ...state,
        loading: false,
        dataSetting: action.payload.data,
      };
    }
    case "GET_POS_FILE_FAILED": {
      return {
        ...state,
        loading: false,
      };
    }
    case "RESET_CONFIG_DATA": {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
};
export default configPosContentDataReducer;
