const initialState = {
    loading: false,
    commodityDelivery: [],
    totalPage: 0,
    submitNewcommodityDeliveryStatus: 0,
    updatecommodityDeliveryStatus: 0,
    approvecommodityDeliveryStatus: 0,
    commodityDeliveryDetais: null,
}

const commodityDeliveryReducer = (state = initialState, action) => {  
    switch (action.type) {
        case "FETCH_COMMODITY_DELIVERY_DETAIL_SUCCEEDED":
            return {
               ...state,
               commodityDeliveryDetais: action.payload,
               loading: false,
            };
        case 'SUBMIT_NEW_CMD_DELIVERY_SUCCESSED':
            return {
                ...state,
                loading: false,
                submitNewcommodityDeliveryStatus: 1,
            };
        case 'SUBMIT_NEW_CMD_DELIVERY_FAILED':
            return {
                ...state,
                loading: false,
                submitNewcommodityDeliveryStatus: 2,
            };
        case 'FETCH_CMD_DELIVERY_SUCCESSED':
            return {
                ...state,
                loading: false,
                commodityDelivery: action.payload?.data,
                totalPage: action.payload?.totalCount,
            };
        case "SET_DEFAULT_STATUS_EDIT_CMD_DELIVERY":
            return {
                ...state,
                submitNewcommodityDeliveryStatus: 0,
                updatecommodityDeliveryStatus: 0,
                approvecommodityDeliveryStatus: 0,
                loading: false
            };   
        case 'SHOW_LOADING_CMD_DELIVERY':
            return {
                ...state,
                loading: true
            };
        case 'DEFAULT_DATA_CMD_DELIVERY':
            return initialState;

        default:
            return state;
    }
}

export default commodityDeliveryReducer;