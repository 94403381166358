const initialState = {
  stockRequestList: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_STOCK_REQUEST_STATUS_34_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "FETCH_STOCK_REQUEST_STATUS_34_SUCCESS":
      return {
        ...state,
        stockRequestList: changeStockRequestList(action.payload.data),
        loading: false,
      }

    case "FETCH_STOCK_REQUEST_STATUS_34_FAILED":
      return {
        ...state,
        loading: false,
      }

    default:
      return state;
  }
};

export default reducer;


const changeStockRequestList = (arr = []) => {
  //thay đổi format để bỏ vào component <InputSelect/>
  let newArr = []
  if(arr && arr.length > 0){
    arr.forEach(item => {
      const obj = {
        name: `${item.code} - ${item.requestedBy} - ${item.warehouseName} - ${item.prettyRequestedDate}`,
        value: item.id,
      }
      newArr = [...newArr, obj]
    })
  }
  return newArr
}
