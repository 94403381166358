import {call, put, takeEvery} from 'redux-saga/effects'


import {
  fetchStockDestructionByFilter,
  fetchListStatusStockDestruction,
  submitStockDestructionApi,
  fetchStockDestructionDetailApi,
} from 'src/api/stockDestructionApi'

import { approveRequestApi, approveTransactionApi } from 'src/api/workFlowApi'


/* FUNCTION */
export function* loadGoodsDestructionByFilter(action) {
  try {
    yield put({ type: "SHOW_GOODS_DESTRUCTION_BY_FILTER_LOADING" });
    let res = yield call(fetchStockDestructionByFilter, action.payload)
    yield put({ type: "FETCH_GOODS_DESTRUCTION_BY_FILTER_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_DESTRUCTION_BY_FILTER_FAILED", payload: e.message });
  }
}

function* loadListStatusGoodsDestruction(action) {
  try {
    yield put({ type: "SHOW_LIST_STATUS_GOODS_DESTRUCTION_LOADING" });
    let res = yield call(fetchListStatusStockDestruction, action.payload)
    yield put({ type: "FETCH_LIST_STATUS_GOODS_DESTRUCTION_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_LIST_STATUS_GOODS_DESTRUCTION_FAILED", payload: e.message });
  }
}


function* submitGoodsDestruction(action) {
  try {
    yield put({ type: "SHOW_SUBMIT_GOODS_DESTRUCTION_LOADING" });
    let res = yield call(submitStockDestructionApi, action.payload)
    if(res.data.id !== 0){
      yield put({ type: "SUBMIT_GOODS_DESTRUCTION_SUCCESS", payload: res.data })
    }
    else{
      yield put({ type: "SUBMIT_GOODS_DESTRUCTION_FAILED"})
    }

  } catch (e) {
    yield put({ type: "SUBMIT_GOODS_DESTRUCTION_FAILED", payload: e.message })
  }
}

function* loadGoodsDestructionById(action) {
  try {
    yield put({ type: "SHOW_GOODS_DESTRUCTION_BY_ID_LOADING" });
    let res = yield call(fetchStockDestructionDetailApi, action.payload)
    yield put({ type: "FETCH_GOODS_DESTRUCTION_BY_ID_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_GOODS_DESTRUCTION_BY_ID_FAILED", payload: e.message });
  }
}

function* sumbitGoodsDestructionApproveRequest(action) {
  try {
    yield put({ type: "SHOW_SUBMIT_GOODS_DESTRUCTION_APPROVE_REQUEST_LOADING" })
    let res = yield call(approveRequestApi, action.payload)
    yield put({ type: "SUBMIT_GOODS_DESTRUCTION_APPROVE_REQUEST_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_GOODS_DESTRUCTION_APPROVE_REQUEST_FAILED", payload: e.message });
  }
}

function* submitGoodsDestructionWorkFlowAction(action) {
  try {
    yield put({ type: "SHOW_SUBMIT_GOODS_DESTRUCTION_WORK_FLOW_ACTION_LOADING" })
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_GOODS_DESTRUCTION_WORK_FLOW_ACTION_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_GOODS_DESTRUCTION_WORK_FLOW_ACTION_FAILED", payload: e.message });
  }
}


/* function* deleteGoodsTransfer(action) {
  //try {
    yield put({ type: "SHOW_DELETE_GOODS_DESTRUCTION_LOADING" });
    const idDelete = action.payload.id
    let res = yield call(deleteGoodsTransferApi, action.payload)
    //yield put({ type: "DELETE_GOODS_DESTRUCTION_SUCCESS", payload: res.data });
    //console.log(res);
    //try catch khi xóa thất bại nó chạy ko đúng??
    console.log(res.status);
    if(res.status !== 200){
      yield put({ type: "DELETE_GOODS_DESTRUCTION_FAILED"});  
    }
    else{
      //console.log(res);
      yield put({ type: "DELETE_GOODS_DESTRUCTION_SUCCESS", payload: idDelete });
    }
  //} catch (e) {
    //yield put({ type: "DELETE_GOODS_DESTRUCTION_FAILED", payload: e.message });
  //}
} */

export default function* rootSaga(){
  yield takeEvery("LOAD_GOODS_DESTRUCTION_BY_FILTER", loadGoodsDestructionByFilter);
  yield takeEvery("LOAD_LIST_STATUS_GOODS_DESTRUCTION", loadListStatusGoodsDestruction)

  yield takeEvery("SUBMIT_GOODS_DESTRUCTION", submitGoodsDestruction)
  yield takeEvery("FETCH_GOODS_DESTRUCTION_BY_ID", loadGoodsDestructionById)
  //yield takeEvery("DELETE_GOODS_DESTRUCTION", deleteGoodsTransfer)


  /* Approve goods destruction */
  yield takeEvery("SUBMIT_GOODS_DESTRUCTION_APPROVE_REQUEST", sumbitGoodsDestructionApproveRequest)
  yield takeEvery("SUBMIT_GOODS_DESTRUCTION_WORK_FLOW_ACTION", submitGoodsDestructionWorkFlowAction);
}