const initialState = {
  sumNetAmount: 0,
  details: []
}

const revenueYearReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'LOAD_REVENUE_YEAR_SUCCESS':
      const { data: year } = action.payload
      if (year.details.length > 0) {
        const netAmountArr = year.details.map(item => item.netAmount)
        const yearArr = year.details.map(item => item.year)
        return{
          ...year,
          storeName: year.details[0].storeName,
          netAmountArr,
          yearArr
        }
      }
      return {...year}
    default:
      return state;
  }
};

export default revenueYearReducer;
