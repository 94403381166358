const initialState = {
  revenueNetAmountDay: 0,
  revenueNetAmountWeek: 0,
  revenueNetAmountMonth: 0,
  revenueNetAmountQuater: 0,
  revenueNetAmountYear: 0,
}

const revenueGeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_REVENUE_GENERAL_SUCCESS':
      return {
        ...action.payload.data
      }  
    default:
      return state;
  }
};

export default revenueGeneralReducer;