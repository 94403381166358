import axios from 'axios'
import constants from '../reusable/constants'

export function fetchVoucherSaleOrdersApi(payload) {
    return axios.get(
        constants.api.url + 
        'api/SaleOrder/GetVoucherOrders?fromDateOrder=' 
        + payload.fromDateOrder + '&toDateOrder=' + payload.toDateOrder
        + '&fromDateReceived=' 
        + payload.fromDateReceived + '&toDateReceived=' + payload.toDateReceived
        + '&status=' + payload.status,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function getOrderDetailWithOrderIdApi(payload) {
    return axios.put(
        constants.api.url + 'api/SaleOrder/GetOrderDetailWithOrderId',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function createNewSaleOrderApi(payload) {
    return axios.post(
        constants.api.url + 'api/SaleOrder',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function checkCodeExistsSaleOrder(payload) {
    return axios.put(
        constants.api.url + 'api/SaleOrder/CheckCodeSaleOrderExist',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchSaleOrderApi(payload) {
    const url = constants.api.url + "api/SaleOrder"
    + `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}`
    +  `&MaterialId=${payload.MaterialId}&FromDate=${payload.FromDate}&ToDate=${payload.ToDate}`
    + `&Code=${payload.Code}&Status=${payload.Status}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function getMaterialForProduceById(payload) {
    return axios.get(
        constants.api.url + `api/SaleOrder/GetMaterialForProduceBySOId/${payload}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function updateStatusSaleOrders(payload) {
    return axios.put(
        constants.api.url + `api/SaleOrder/UpdateStatusSaleOrder`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchSaleOrderById(payload) {
    return axios.get(
        constants.api.url + `api/SaleOrder/${payload}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}


export function fetchVoucherSaleOrderToDeliver(payload) {
    return axios.get(
        constants.api.url + 
        'api/SaleOrder/GetVoucherOrdersToDeliver?fromDateOrder=' 
        + payload.fromDateOrder + '&toDateOrder=' + payload.toDateOrder
        + '&fromDateReceived=' 
        + payload.fromDateReceived + '&toDateReceived=' + payload.toDateReceived,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchListProductionDiaryApi(payload) {
    return axios.get(
        constants.api.url + `api/SaleOrder/GetListProductionDiary?page=${payload.page}&pageSize=${payload.pageSize}&FromDate=${payload.fromDate}&ToDate=${payload.toDate}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function createNewProductionDiaryApi(payload) {
    return axios.post(
        constants.api.url + `api/SaleOrder/CreateProductionDiary`, payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function updateProductionDiaryApi(payload) {
    return axios.put(
        constants.api.url + `api/SaleOrder/UpdateProductionDiary`, payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchProductionDiaryByIdApi(payload) {
    return axios.get(
        constants.api.url + `api/SaleOrder/GetProductionDiaryById/${payload}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}