import axios from "axios";
import constants from "../reusable/constants";

export function fetchGoodsReturningApi(payload) {
    const url = constants.api.url + "api/GoodReturning"
    + `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}`
    +  `&MaterialId=${payload.MaterialId}&FromDate=${payload.FromDate}&ToDate=${payload.ToDate}`
    + `&Code=${payload.Code || ''}&Status=${payload.Status}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function checkCodeExistGoodsReturn(payload) {
    const url = constants.api.url + "api/GoodReturning/CheckCodeGoodReturningExist";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}


export function createNewGoodsReturn(payload) {
    const url = constants.api.url + "api/GoodReturning";

    return axios.post(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchGoodsReturnById(payload) {
    const url = constants.api.url + `api/GoodReturning/${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateStatusGoodsReturn(payload) {
    const url = constants.api.url + `api/GoodReturning/UpdateStatusGoodReturning`;

    return axios.put(
        url, payload, 
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchCodeGoodsReturn() {
    const url = constants.api.url + `api/SRCode/GetCodeGoodReturningAsync`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}
