import { combineReducers } from "redux"
import goodsDestruction from './goodsDestructionReducer'
import flag from './goodsDestructionFlagReducer'
import statusList from './goodsDestructionStatusListReducer'
import submit from './goodsDestructionSubmitReducer'
import activeMaterial from './goodsDestructionActiveMaterialReducer'
import detail from './goodsDestructionDetailReducer'
import approveRequest from './goodsDestructionApproveRequestReducer'
import workFlow from './goodsDestructionWorkFlowActionReducer'
/* import stockRequestList from './goodsDestructionStockRequestStatus34Reducer'
import stockRequestDetail from './goodsDestructionStockRequestDetailReducer'
import submit from './goodsDestructionSubmitReducer'
import deleteGoodsDestruction from './goodsDestructionDeleteReducer'
 */


const rootGoodsDestructionReducer = combineReducers({
    goodsDestruction,
    flag,
    statusList,
    submit,
    activeMaterial,
    detail,
    approveRequest,
    workFlow,
    /* stockRequestList,
    stockRequestDetail,
    submit,
    deleteGoodsDestruction,
     */
})

export default rootGoodsDestructionReducer