const initialState = {
    menuLoading: false,
    menuList: [],
    message: '',
    listObjMenuAfterChange: {},
}

const isSubMenusArr = (obj) => {
    if (obj.subMenus && obj.subMenus.length > 0) {
      return obj.subMenus; //trả về lại mảng subMenus
    }
    return [obj]; //biến chính nó thành mảng subMenus
}

const handleChangeMenu = (arr = []) => {
    const newArr = [...arr]
    let newObj = {}
    newArr.forEach(menuLevel1 => {
        let webMenuRenderArr = []
        menuLevel1.subMenus.forEach(menuLevel2 => {
            const newMenuLevel3_Arr = isSubMenusArr(menuLevel2);
            //console.log('newMenuLevel3_Arr: ', newMenuLevel3_Arr);
            newMenuLevel3_Arr.forEach(menuLevel3 => {
                const obj = {
                    grandParentMenuId: menuLevel1.menuId,
                    grandParentMenuName: menuLevel1.menuName,
                    parentMenuId: menuLevel2.menuId,
                    parentMenuName: menuLevel2.menuName,
                    webMenuId: menuLevel3.menuId,
                    webMenuName: menuLevel3.menuName,
                    viewFlag: menuLevel3.viewFlag,
                    createFlag: menuLevel3.createFlag,
                    editFlag: menuLevel3.editFlag,
                    deleteFlag: menuLevel3.deleteFlag,
                    approveFlag: menuLevel3.approveFlag,
                }
                webMenuRenderArr.push(obj);
            });
        })
        //console.log('webMenuRenderArr: ', webMenuRenderArr);
        
        /* {[item.menuName] : []} */
        const obj = {[menuLevel1.menuName]: webMenuRenderArr}
        //console.log('obj: ', obj);
        newObj = {
            ...newObj,
            ...obj,
        }
    })
    //console.log('newObj: ', newObj);
    return newObj
}

const webGroupMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_MENU_BY_WEB_GROUP_LOADING':
            return {
                ...state,
                menuLoading: true
            }

        case 'FETCH_MENU_BY_WEB_GROUP_SUCCESS':
            return {
                ...state,
                menuList: action.payload.data,
                menuLoading: false,
                message: '',
                listObjMenuAfterChange: handleChangeMenu(action.payload.data)
            }

        case 'FETCH_MENU_BY_WEB_GROUP_FAILED':
            return {
                ...state,
                menuLoading: false,
                message: action.payload
            }
            
        default:
            return state
    }
}

export default webGroupMenuReducer