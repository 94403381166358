const initialState = {
  approveFlag: 0,
  createFlag: 0,
  deleteFlag: 0,
  editFlag: 0,
  viewFlag: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_AUTHO_MODULE_GOODS_DESTRUCTION":
      return {
        ...state,
        createFlag: action.payload?.createFlag,
        approveFlag: action.payload?.approveFlag,
        deleteFlag: action.payload?.deleteFlag,
        editFlag: action.payload?.editFlag,
        viewFlag: action.payload?.viewFlag,
      }

    default:
      return state
  }
};

export default reducer;
