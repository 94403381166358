const initialModalState = {
   modalMaterialId: "",
   modalMaterialCode: "",
   modalMaterialName: "",
   modalMaterialBaseUom: "",
   modalBrandId: "",
   modalBrandName: "",
   modalRequiredDate: "",
   modalUomId: "",
   modalUomName: "",
   modalBaseStockQuantity: "",
   modalRequestQuantity: "",
   modalRequestBaseQuantity: "",
   modalNote: "",
   modalUomQuantityType: "",
   modalUsedBaseUom: false,
   modalUsedBaseUomValue: "",
   modalUsedBaseUomKey: null,

   showModal: false,
};
const initialRequestWorkFlowModalState = {
   // showRequestWorkFlowModal: false,
   modalDescription: "",
};

const initialState = {
   loading: false,
   formValidationFailed: false,
   stockPendingRequestList: [],
   checkboxList: {},
   purchaseOrderList: [],
   purchaseOrderDetail: null,
   submitNewPurchaseOrderStatus: 0,
   requestedDate: "",
   requestedBy: "",
   description: "",
   attachedFile: "",
   submitWorkFlowActionStatus: 0,
   modalValidationFailed: false,
   showRequestWorkFlowModal: false,
   ...initialModalState,
   ...initialRequestWorkFlowModalState,

   approveFlag: 0,
   createFlag: 0,
   deleteFlag: 0,
   editFlag: 0,
   viewFlag: 0,
};

const purchaseOrderReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_AUTHO_MODULE_PURCHASE_ORDER':
         return {
            ...state,
            createFlag: action.payload?.createFlag,
            approveFlag: action.payload?.approveFlag,
            deleteFlag: action.payload?.deleteFlag,
            editFlag: action.payload?.editFlag,
            viewFlag: action.payload?.viewFlag,
         }
      case "SHOW_PURCHASE_ORDER_LOADING":
         return {
            ...state,
            loading: true,
         };
      case "HIDE_PURCHASE_ORDER_LOADING":
         return {
            ...state,
            loading: false,
         };
      case "UPDATE_CHECKBOX_VALUE_PO":
         if (state.checkboxList[action.payload]) {
            const key = action.payload;
            const value = state.checkboxList[action.payload];
            return {
               ...state,
               checkboxList: {
                  ...state.checkboxList,
                  [key]: !value,
               },
            };
         } else {
            const key = action.payload;
            return {
               ...state,
               checkboxList: {
                  ...state.checkboxList,
                  [key]: true,
               },
            };
         }
      case "SHOW_REQUEST_WORK_FLOW_MODAL_PO":
         return {
            ...state,
            showRequestWorkFlowModal: true,
         };
      case "HIDE_MODAL_PO":
         return {
            ...state,
            ...initialModalState,
            ...initialRequestWorkFlowModalState,
            modalUsedBaseUomKey: Date.now(),
            modalValidationFailed: false,
            showRequestWorkFlowModal: false,
         };
      case "UPDATE_REQUEST_WORK_FLOW_MODAL_DESCRIPTION_PO":
         return {
            ...state,
            modalDescription: action.payload,
         };
      case "UPDATE_REQUESTED_DATE_PO":
         return {
            ...state,
            requestedDate: action.payload,
         };
      case "UPDATE_REQUESTED_BY_PO":
         return {
            ...state,
            requestedBy: action.payload,
         };
      case "UPDATE_DESCRIPTION_PO":
         return {
            ...state,
            description: action.payload,
         };
      case "UPDATE_ATTACHED_FILE_PO":
         return {
            ...state,
            attachedFile: action.payload,
         };
      case "UPDATE_FORM_VALIDATION_PO":
         return {
            ...state,
            formValidationFailed: action.payload,
         };
      case "SUBMIT_NEW_PURCHASE_ORDER_SUCCEEDED":
         return {
            ...state,
            submitNewPurchaseOrderStatus: 1,
            loading: false,
         };
      case "UPDATE_MODAL_VALIDATION_PO":
         return {
            ...state,
            modalValidationFailed: action.payload,
         };
      case "CLEAR_MODAL_PO":
         return {
            ...state,
            modalDescription: "",
         };
      case "CLEAR_DATA_PO":
         return {
            ...state,
            requestDate: '',
            submitNewPurchaseOrderStatus: 0,
            submitWorkFlowActionStatus: 0,
            description: "",
            requestedBy: "",
            attachedFile: ""
         };
      case 'CLEAR_FIELDS_PO':
         return {
            ...state,
            requestedDate: '',
            submitNewPurchaseOrderStatus: 0,
            submitWorkFlowActionStatus: 0,
            description: "",
            requestedBy: "",
            attachedFile: "",
            checkboxList: {}
         }
      case "SUBMIT_PO_WORK_FLOW_SUCCEEDED": {
         return {
            ...state,
            submitWorkFlowActionStatus: 1,
            loading: false,
         }
      }
      case 'SUBMIT_PO_WORK_FLOW_FAILED':
         return {
            ...state,
            // submitNewStockRequestStatus: 2,
            submitWorkFlowActionStatus: 2,
            loading: false
         }

      case "SUBMIT_NEW_PURCHASE_ORDER_FAILED":
         return {
            ...state,
            submitNewPurchaseOrderStatus: 2,
         };
      case "FETCH_STOCK_PENDING_REQUEST_SUCCEEDED":
         return {
            ...state,
            stockPendingRequestList: action.payload,
            loading: false,
         };
      case 'FETCH_STOCK_PENDING_REQUEST_FAILED':
         return {
            ...state,
            loading: false
         }
      case "FETCH_PURCHASE_ORDER_DETAIL_SUCCESS":
         return {
            ...state,
            purchaseOrderDetail: action.payload,
            loading: false
         };
      case "FETCH_PURCHASE_ORDER_SUCCESS":
         return {
            ...state,
            purchaseOrderList: action.payload,
            loading: false
         };
      case "CLEAR_PURCHASE_ODER_DETAILS":
         return {
            ...state,
            purchaseOrderDetail: null,
            loading: false
         };
      default:
         return {
            ...state,
         };
   }
};

export default purchaseOrderReducer;
