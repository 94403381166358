const initialState = {
  loading: false,
  status: null,
  updateData: '',
  idChange: '',
}


const reducer = (state = initialState, action) => {
   switch (action.type) {
      case 'UPDATE_PRODUCT_UNIT_PRICE':
        return {
          ...state,
          idChange: action.payload.id
        }

      case 'SHOW_UPDATE_PRODUCT_UNIT_PRICE_LOADING':
        return {
          ...state,
          loading: true
        }

      case 'CHANGE_PRODUCT_UNIT_PRICE_SUCCESS':
        return {
          ...state,
          loading: false,
          updateData: action.payload.updateData,
          status: 'success',
        }

      case 'CHANGE_PRODUCT_UNIT_PRICE_FAILED':
        return {
          ...state,
          loading: false,
          updateData: '',
          status: 'failed'
        }

      case 'CLEAR_CHANGE_PRODUCT_UNIT_PRICE_STATUS':
        return initialState

      default:
         return state
   }
  
}

export default reducer
