import axios from 'axios';
import constants from '../reusable/constants';


export function fetchSRProduceApi(payload) {
    const url = constants.api.url + "api/SRProduce"
    + `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}`
    +  `&MaterialId=${payload.MaterialId}&FromDate=${payload.FromDate}&ToDate=${payload.ToDate}`
    + `&Code=${payload.Code || ''}&Status=${payload.Status}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function apiCheckCodeExist(payload) {
    const url = constants.api.url + "api/SRProduce/CheckCodeSRProduceExist";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}
    
export function createNewSRProduce(payload) {
    const url = constants.api.url + "api/SRProduce";

    return axios.post(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function getSRProduceById(payload) {
    const url = constants.api.url + `api/SRProduce/${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateStatusSRProduce(payload) {
    const url = constants.api.url + `api/SRProduce/UpdateStatusSRProduce`;

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateSRProduce(payload) {
    const url = constants.api.url + `api/SRProduce/UpdateSRProduce`;

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function getCodeSRProduce() {
    const url = constants.api.url + `api/SRCode/GetCodeSRProduceAsync`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}
