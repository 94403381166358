const initialState = {
    staffLoading: false,
    staffList: [],
    message: '',
}


const webGroupStaffReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_STAFF_BY_WEB_GROUP_LOADING':
            return {
                ...state,
                staffLoading: true
            }

        case 'FETCH_STAFF_BY_WEB_GROUP_SUCCESS':
            return {
                ...state,
                staffList: action.payload.data.detail,
                staffLoading: false,
                message: ''
            }

        case 'FETCH_STAFF_BY_WEB_GROUP_FAILED':
            return {
                ...state,
                staffLoading: false,
                message: action.payload
            }

        default:
            return state
    }
}

export default webGroupStaffReducer