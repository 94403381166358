import axios from "axios";
import constants from "../reusable/constants";

export function fetchFormula(payload) {
  console.log("loading formulas", payload);

  return axios.get(
    constants.api.url + `api/formula?formulaGroupId=${payload}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
export function fetchFormulaPagination(payload) {
  console.log("loading formulas", payload);
  const { page, pageSize, status, formulaGroupId, MaterialId } = payload;
  let attributes = [
    { text: "page", value: page },
    { text: "pageSize", value: pageSize },
    { text: "status", value: status },
    { text: "formulaGroupId", value: formulaGroupId },
    { text: "MaterialId", value: MaterialId },
  ];
  let newAttributes = attributes.filter(
    (item) => item.value !== null && item.value !== undefined
  );
  let urlAtrribute = "";
  newAttributes.forEach((item, index) => {
    urlAtrribute += item.text + "=" + item.value;
    if (index !== attributes.length - 1) {
      urlAtrribute += "&";
    }
  });
  return axios.get(
    constants.api.url + `api/Formula/GetAllFormulas?${urlAtrribute}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
export function fetchStoreFormulaApi() {
  return axios.get(constants.api.url + `api/store/getStoreFormulaGroupId`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function createNewFormula(payload) {
  return axios.post(constants.api.url + "api/formula", payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function updateFormulaApi(payload) {
  return axios.put(constants.api.url + "api/Formula/UpdateFormula", payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function fetchFormulaDetailApi(payload) {
  return axios.get(constants.api.url + "api/formula/" + payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function getUomOfMaterialApi(payload) {
  console.log("getUomOfMaterialApi", payload);
  return axios.get(
    constants.api.url +
      `api/Uom/GetUomOfMaterial?MaterialId=${payload}`
  );
}
export function callDeleteFormulaApi(payload) {
  return axios.delete(constants.api.url + "api/formula/" + payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
