const initialState = {
  sumNetAmount: 0,
  details: []
}

const revenueWeekReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_REVENUE_WEEK_SUCCESS':
      const { data: week } = action.payload
      if (week.details.length > 0) {
        const netAmountArr = week.details.map(item => item.netAmount)
        const weekOfYearArr = week.details.map(item => item.weekOfYear)
        return{
          ...week,
          storeName: week.details[0].storeName,
          netAmountArr,
          weekOfYearArr
        }
      }
      return {...week}

    default:
      return state;
  }
};

export default revenueWeekReducer;
