const initialState = {
    createUserGroupDataAccessLoading: false,
    createUserGroupDataAccessStatus: null,
    createUserGroupDataAccessResponse: null,
}

const webGroupCreateUserGroupDataAccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_CREATE_USER_GROUP_DATA_ACCESS_LOADING':
            return {
                ...state,
                createUserGroupDataAccessLoading: true
            }

        case 'CREATE_USER_GROUP_DATA_ACCESS_SUCCESS':
            return {
                ...state,
                createUserGroupDataAccessLoading: false,
                createUserGroupDataAccessStatus: 'success',
                createUserGroupDataAccessResponse: action.payload,
            }

        case 'CREATE_USER_GROUP_DATA_ACCESS_FAILED':
            return {
                ...state,
                createUserGroupDataAccessLoading: false,
                createUserGroupDataAccessStatus: 'failed',
                createUserGroupDataAccessResponse: action.payload,
            }
        
        case 'CLEAR_CREATE_USER_GROUP_DATA_ACCESS':
            return {
                ...state,
                createUserGroupDataAccessStatus: null,
            }

        default:
            return state
    }
}

export default webGroupCreateUserGroupDataAccessReducer