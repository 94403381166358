const initialModalState = {
  showModal: false,
  modalCode: "",
  modalName: "",
  modalManufacturerId: "",
  modalDescription: "",
};

const initialState = {
  loading: false,
  brands: [],
  allBrands: [],
  ...initialModalState,
  modalValidationFailed: false,
  submitNewBrandStatus: "",

  modal_name: null,
  modal_code: null,
  modal_description: null,
  modal_manufacturerName: null,
  modal_id: null,
  modal_status: 1,

  mannufacturers: [],

  totalPage: 0,
  pageSize: 10,
  currentPage: 1,
  code: null,
  name: null,
  status: 1,

  updateStatus: 0,
  createStatus: 0,
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_BRAND_STATUS": {
      return { ...state, status: action.payload };
    }
    case "UPDATE_BRAND_NAME": {
      return { ...state, name: action.payload };
    }
    case "UPDATE_BRAND_CODE": {
      return { ...state, code: action.payload };
    }
    case "UPDATE_BRAND_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "UPDATE_BRAND_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "RESET_BRAND_FILTER": {
      return {
        ...state,
        code: null,
        name: null,
        status: 1,
      };
    }
    case"SET_MODAL_CREATE_NEW_BRAND_CODE":{
      return{
        ...state,
        modal_code:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_BRAND_NAME":{
      return{
        ...state,
        modal_name:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_BRAND_DESCRIPTION":{
      return{
        ...state,
        modal_description:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_BRAND_STATUS":{
      return{
        ...state,
        modal_status:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_BRAND_MANU":{
      return{
        ...state,
        modal_manufacturerName:action.payload
      }
    }
    case"SET_MODAL_UPDATE_BRAND":{
      return{
        ...state,
        modal_name: action.payload.modal_name,
        modal_code: action.payload.modal_code,
        modal_description: action.payload.modal_description,
        modal_manufacturerName: action.payload.modal_manufacturerName,
        modal_id: action.payload.modal_id,
        modal_status: action.payload.modal_status,
      }
    }
    case"CLEAR_MODAL_CREATE_NEW_BRAND":{
      return{
        ...state,
        modal_name: null,
        modal_code: null,
        modal_description: null,
        modal_manufacturerName: null,
        modal_id: null,
        modal_status: 1,

      }
    }
    case "FETCH_BRAND_BY_MATERIAL_SUCCEEDED":
      console.log("success");
      return {
        ...state,
        brands: action.payload,
      };
    case "CLEAR_BRANDS":
      return {
        ...state,
        brands: [],
      };
    case "CLEAR_DATA":
      return {
        ...state,
        submitNewBrandStatus: "",
      };
    case "UPDATE_MODAL_VALIDATION":
      return {
        ...state,
        modalValidationFailed: action.payload,
      };
    case "SHOW_BRAND_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ALL_BRANDS_SUCCEEDED":
      return {
        ...state,
        allBrands: action.payload,
        loading: false,
      };
    case "FETCH_ALL_BRANDS_PAGINATION_SUCCEEDED":
      console.log("FETCH_ALL_BRANDS_PAGINATION_SUCCEEDED", action.payload);
      return {
        ...state,
        allBrands: action.payload.data,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    case "SAVE_NEW_BRAND_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewBrandStatus: 1,
        loading: false,
      };
      case"UPDATE_NEW_BRAND_SUCCEEDED":{
        console.log("UPDATE_NEW_BRAND_SUCCEEDED",action.payload);
        return{
          ...state,
          loading:false,
          updateStatus:1,
        }
      }
      case"UPDATE_NEW_BRAND_FAILED":{
        console.log("UPDATE_NEW_BRAND_FAILED",action.payload);
        return{
          ...state,
          loading:false,
          updateStatus:3,
        }
      }
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: true,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
      };
    case "UPDATE_MODAL_CODE":
      return {
        ...state,
        modalCode: action.payload,
      };
    case "UPDATE_MODAL_NAME":
      return {
        ...state,
        modalName: action.payload,
      };
    case "UPDATE_MODAL_DESCRIPTION":
      return {
        ...state,
        modalDescription: action.payload,
      };

    case "UPDATE_MODAL_MANUFACTURER":
      return {
        ...state,
        modalManufacturerId: parseInt(action.payload.id),
      };
    case "FETCH_MANUFACTURER_SUCCEEDED":
      return {
        ...state,
        mannufacturers: action.payload,
      }
    default:
      return state;
  }
};

export default brandReducer;
