const initialState = {
  loading: false,
  activeMaterialList: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_ALL_ACTIVE_MATERIAL_LOADING":
      return {
        loading: true,
      }

    case "FETCH_ALL_ACTIVE_MATERIAL_SUCCEEDED":
      return {
        activeMaterialList: changeFormatActiveMaterialsArr(action.payload),
        loading: false,
      }
    
    case "FETCH_MATERIAL_FAILED":
      return {
        loading: false,
      }

    default:
      return state
  }
};

export default reducer;

const changeFormatActiveMaterialsArr = (arr = []) =>{
  const newArr = []
  if(Array.isArray(arr)){
    arr.forEach(item => {
      const obj = {
        id: item.id,
        name: item.name,
        code: item.code,
      }
      newArr.push({
        id: obj,
        name: `(${item.code}) - ${item.name}`
      })
    })
  }
  return newArr
}
