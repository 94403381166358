import axios from 'axios'
import constants from '../reusable/constants'

export function fetchUomByMaterialAndBrandId(payload) {
    console.log('fetchUomByMaterialAndBrandId')
    return axios.get(
        constants.api.url + 'api/uom/fetchUomByMaterialAndBrandId?materialId=' + payload.materialId + '&brandId=' + payload.brandId + (payload.warehouseId === undefined ? '' : '&warehouseId=' + payload.warehouseId),
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}
export function fetchUomByMaterial(payload) {
  console.log('fetchUomByMaterial')
  return axios.get(
      constants.api.url + 'api/Uom/GetUomOfMaterial?MaterialId' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export function fetchAllUoms(payload) {
    return axios.get(
        constants.api.url + 'api/uom',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}
export function fetchAllUomsPagination(payload) {
  const { page, pageSize, status, name, code, level } = payload;
  let attributes = [
    { text: "page", value: page },
    { text: "pageSize", value: pageSize },
    { text: "status", value: status },
    { text: "name", value: name },
    { text: "code", value: code },
    { text: "level", value: level },
  ];
  let newAttributes = attributes.filter(
    (item) => item.value !== null && item.value !== undefined
  );
  let urlAtrribute = "";
  newAttributes.forEach((item, index) => {
    urlAtrribute += item.text + "=" + item.value;
    if (index !== attributes.length - 1) {
      urlAtrribute += "&";
    }
  });
  return axios.get(
      constants.api.url + `api/Uom/GetAllUoms?${urlAtrribute}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export function createNewUom(payload) {
    return axios.post(
        constants.api.url + 'api/uom',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function updateUom(payload) {
    return axios.put(
        constants.api.url + 'api/uom/'+ payload.id,
        {
          code: payload.code,
          name: payload.name,
          description: payload.description,

        },
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}
