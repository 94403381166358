import axios from 'axios'
import constants from '../reusable/constants'

export function fetchManufacturer() {
    return axios.get(
        constants.api.url + 'api/manufacturer',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function createNewManufacturer(payload) {
    return axios.post(
        constants.api.url + 'api/manufacturer',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}