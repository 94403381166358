const initialModalState = {
    showModal: false,
    modalCode: '',
    modalName: '',
    modalAddress: '',
    modalTelephone: '',
    modalDescription: '',
    modalTaxCode: ''
}

const initialState = {
    loading: false,
    suppliers: [],
    ...initialModalState,
    modalValidationFailed: false,
    submitNewSupplierStatus: ''
}

const supplierReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_DATA':
            return {
                ...state,
                submitNewSupplierStatus: ''
            }
        case 'UPDATE_MODAL_VALIDATION':
            return {
                ...state,
                modalValidationFailed: action.payload
            }
        case 'SHOW_SUPPLIER_LOADING': 
            return {
                ...state,
                loading: true
            }
        case 'FETCH_SUPPLIER_SUCCEEDED':
            return {
                ...state,
                suppliers: action.payload,
                loading: false
            }
        case 'SAVE_NEW_SUPPLIER_SUCCEEDED':
            return {
                ...state,
                modalValidationFailed: false,
                submitNewSupplierStatus: 1,
                loading: false
            }
        case 'SHOW_MODAL':
            return {
                ...state,
                showModal: true
            }
        case 'HIDE_MODAL':
            return {
                ...state,
                ...initialModalState,                
                modalValidationFailed: false    
            }
        case 'UPDATE_MODAL_CODE':
            return {
                ...state,
                modalCode: action.payload
            }
        case 'UPDATE_MODAL_NAME':
            return {
                ...state,
                modalName: action.payload
            }
        case 'UPDATE_MODAL_ADDRESS':
            return {
                ...state,
                modalAddress: action.payload
            }
        case 'UPDATE_MODAL_TELEPHONE':
            return {
                ...state,
                modalTelephone: action.payload
            }
        case 'UPDATE_MODAL_TAXCODE':
            return {
                ...state,
                modalTaxCode: action.payload
            }
        case 'UPDATE_MODAL_DESCRIPTION':
            return {
                ...state,
                modalDescription: action.payload
            }
        default:
            return state
    }
}

export default supplierReducer