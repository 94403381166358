import React, { useContext, useEffect, useState } from 'react';
import { Title, LayoutModal, Header, FileName, GroupButton, ButtonHeader, Container, Ul, Li, Image} from './modalUploadFile.style';
import svg from 'src/assets/icon/index';
import Images from 'src/assets/images/index';
import { MoonLoader } from 'react-spinners';
import { UploadFileContext } from 'src/contexts/fileContext';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import constants from 'src/reusable/constants';
import { AuthContext } from 'src/contexts/authContext';

const typeFile = [
    {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        src: Images.WordFile,
    },
    {
        type: "text/plain",
        src: Images.TextFile,
    },
    {
        type: "application/pdf",
        src: Images.PDFFile,
    }
];

function removeVietnameseFromString(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.toLowerCase();
    str = str
      .replace(/[&]/g, "-and-")
      .replace(/[^a-zA-Z0-9._-]/g, "-")
      .replace(/[-]+/g, "-")
      .replace(/-$/, "");
    return str;
  }

const ModalUpLoadFile = () => {
    const { user } = useContext(AuthContext);
    const { stateUpFile, dispatchUpFile} = useContext(UploadFileContext);
    const { isOpen, listFile, isLoading, indexSuccess} = stateUpFile;
    const [fileRender, setFileRender] = useState([]);
    const handbookState = useSelector((state) => state.handbookState);
    const { 
        currentFolder,
     } = handbookState;
    
    const [isActive, setIsActive] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if(listFile && listFile.length > 0) {
            setFileRender(listFile);
        }
    }, [listFile]);

    useEffect(() => {
        const sleep = () => {
            return new Promise(resolve => {
                setTimeout(resolve, 500);
            });
        }

        const asyncUploadFile = async formData => {
            await axios.post(constants.api.url + "api/HRApp/CreateFileAsync", formData, {
                headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                    "Content-Type": "multipart/form-data",
                },
            });
            await sleep();
            return;
        }
          
        async function uploadFile(payload) {
            let index = -1;
            for await (let file of payload)
            {
                index++;
                let fileName = removeVietnameseFromString(file.name);
                const formData = new FormData();
                formData.append("FileName", fileName);
                formData.append("UId", user.uid);
                formData.append("ParentFolderId", currentFolder.folderId);
                formData.append("file", file);

                await asyncUploadFile(formData);
                dispatchUpFile({
                    type: 'SET_INDEX_CHECKED',
                    payload: index,
                })
            }
        }

        if(fileRender && fileRender.length > 0)
        {
            uploadFile(fileRender);
        }
    }, [fileRender]);

    useEffect(() => {
        if(fileRender && fileRender.length > 0)
        {
            if(indexSuccess.length === fileRender.length) {
                dispatch({ type: "LOAD_FOLDERS", payload: { folderId: currentFolder.folderId } });
                dispatchUpFile({
                    type: 'SET_SUCCESS_UPLOAD',
                    payload: null,
                })
            }
        }
    }, [indexSuccess]);

    return (
        <LayoutModal className={isOpen ? 'show' : ''}>
            <Header>
                <Title>{ isLoading ? 'Đang xử lý ' : 'Đã tải xong ' } { fileRender.length } file lên</Title>
                <GroupButton>
                    { 
                        isLoading ? '' 
                        : <>
                            <ButtonHeader
                                className={isActive ? 'active' : ''}
                                onClick={() => setIsActive(!isActive)}
                            >
                                <svg.IconDropDown />
                            </ButtonHeader>
                            <ButtonHeader 
                                className="ml-2"
                                onClick={() => {
                                    dispatchUpFile({
                                        type: 'DEFAULT_VALUE',
                                        payload: null,
                                    });
                                }}
                            >
                                <svg.IconRemove />
                            </ButtonHeader>
                        </>
                    }
                </GroupButton>
            </Header>
            <Container className={isActive ? 'hide' : ''}>
                <Ul>
                    {
                        fileRender && fileRender.length > 0
                        && fileRender.map((item, index) => {
                            return  <Li key={index} >
                                        <div className="d-flex ">
                                            <Image url={ item.type.includes('image') ? Images.ImageFile : typeFile.filter(i => i.type === item.type).length > 0 ? typeFile.filter(i => i.type === item.type)[0].src : ''  }/>
                                            <FileName>{ item.name }</FileName>
                                        </div>
                                        {
                                            indexSuccess.filter(i => i === index).length > 0 ? <svg.IconCheckbox /> :  <MoonLoader size={20}/>
                                        }
                                       
                                    </Li>
                        })
                    }
                </Ul>
            </Container>
        </LayoutModal>
    );
};

export default ModalUpLoadFile;
