import {changeKeyName} from './rootWebGroupReducer'

const initialState = {
    listAppDataAccess: [],
    appLoading: false,
    message: '',
}

const webGroupAppReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_APP_BY_WEB_GROUP_LOADING':
            return {
                ...state,
                appLoading: true
            }

        case 'FETCH_APP_BY_WEB_GROUP_SUCCESS':
            return {
                ...state,
                listAppDataAccess: changeKeyName('appId', 'appName', action.payload.data),
                appLoading: false,
                message: ''
            }

        case 'FETCH_APP_BY_WEB_GROUP_FAILED':
            return {
                ...state,
                appLoading: false,
                message: action.payload
            }

        default:
            return state
    }
}

export default webGroupAppReducer