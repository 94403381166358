import axios from 'axios'
import constants from '../reusable/constants'

export function fetchRevenueMonth(obj = {}) {
    const { storeId, fromDate, toDate } = obj
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenue?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchRevenueWeek(obj = {}) {
    const { storeId, fromDate, toDate } = obj
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueWeek?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchRevenueQuarter(obj = {}) {
    const { storeId, fromDate, toDate } = obj
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueQuater?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchRevenueDate(obj = {}) {
    const { storeId, fromDate, toDate } = obj
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueDate?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchRevenueYear(obj = {}) {
    const { storeId, fromDate, toDate } = obj
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueYear?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchRevenueGeneral(obj = {}) {
    const { storeId, dateReport } = obj
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueGeneral?dateReport=${dateReport}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthRevenueHour({datetime, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueHour?dateReport=${datetime}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthRevenueCategory({fromDate, toDate, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueFood?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthRevenueCombo({fromDate, toDate, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueCombo?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthGetTotalFoodSold({fromDate, toDate, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetTotalFoodSold?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthRevenueShift({date, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueShift?dateReport=${date}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthRevenueShiftDetail({date, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueShiftDetail?dateReport=${date}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthRevenueServiceMode({fromDate, toDate, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenueServiceModeV2?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fecthRevenuePayments({fromDate, toDate, storeId}) {
    return axios.get(
        constants.api.url + `api/PortalV2/GetRevenuePaymentType?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}