const initialState = {
  detailLoading: false,
  detail: {
    stockRequests: [],
    requestedDate: '',
    prettyRequestedDate: '',
    requestedBy: '',
    description: '',
  },
};

const orderDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_PURCHASE_ORDER_BY_ID_LOADING':
      return {
        ...state,
        detailLoading: true
      }

    case 'FETCH_PURCHASE_ORDER_BY_ID_SUCCEEDED':
      return {
        ...state,
        detail: action.payload.data,
        detailLoading: false,
      }

    case 'FETCH_PURCHASE_ORDER_BY_ID_SUCCEEDED':
      return {
        ...state,
        detail: {
          stockRequests: [],
          requestedDate: '',
          prettyRequestedDate: '',
          requestedBy: '',
          description: '',
        },
        detailLoading: false,
      }

    default:
      return state
  }
};

export default orderDetailReducer;
