const initialModalState = {
  showModal: false,
  modalId: "",
  modalCode: "",
  modalName: "",
  modalDescription: "",
};

const initialState = {
  loading: false,
  uoms: [],
  allUoms: [],
  ...initialModalState,
  modalValidationFailed: false,
  submitNewUomStatus: "",
  updateUomStatus: "",
  mode: "",
  totalPage: 0,

  currentPage: 1,
  pageSize: 10,
  code: null,
  name: null,
  status: 1,

  modal_id: null,
  modal_name:null,
  modal_level:null,
  modal_code:null,
  modal_description:null,
  modal_parentCode:null,
  modal_status:null,
  modal_orderBy:null,
};

const uomReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_UOM_STATUS": {
      return { ...state, status: action.payload };
    }
    case "UPDATE_UOM_NAME": {
      return { ...state, name: action.payload };
    }
    case "UPDATE_UOM_CODE": {
      return { ...state, code: action.payload };
    }
    case "UPDATE_UOM_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,

      };
    }
    case "UPDATE_UOM_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "UPDATE_UOM_ORDERBY":{
      return {
        ...state,
        orderBy: action.payload
      }
    }
    case "UPDATE_UOM_ORDERID":{
      return {
        ...state,
        orderId: action.payload
      }
    }
    case "RESET_UOM_ORDERCHANGE":{
      return{
        ...state,
        orderBy:null,
        orderId:null,
        updateOrderByStatus:undefined,
      }
    }
    case "CLEAR_UOM_FILTER": {
      return {
        ...state,
        code: null,
        name: null,
        status: 1,
        level: null,
      };
    }
    case"SET_MODAL_CREATE_NEW_UOM_CODE":{
      return{
        ...state,
        modal_code:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_UOM_NAME":{
      return{
        ...state,
        modal_name:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_UOM_DESCRIPTION":{
      return{
        ...state,
        modal_description:action.payload
      }
    }
    case"SET_MODAL_UPDATE_UOM":{
      return{
        ...state,
        modal_id:action.payload.modal_id,
        modal_code:action.payload.modal_code,
        modal_name:action.payload.modal_name,
        modal_description:action.payload.modal_description,


        modal_status:action.payload.modal_status,

      }
    }
    case"CLEAR_MODAL_CREATE_NEW_UOM":{
      return{
        ...state,
        modal_code:null,
        modal_name:null,
        modal_description:null,

        modal_id:null,
        modal_status:null,

      }
    }
    case "FETCH_UOM_BY_MATERIAL_BRAND_SUCCEEDED":
      return {
        ...state,
        uoms: action.payload,
        loading: false,
      };
    case "CLEAR_OUMS":
      return {
        ...state,
        uoms: [],
      };
    case "SHOW_UOM_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "SHOW_UOM_MODAL":
      return {
        ...state,
        showModal: true,
        mode: action.payload.mode,
        modalId: action.payload.mode === "create" ? "" : action.payload.id,
        modalCode: action.payload.mode === "create" ? "" : action.payload.code,
        modalName: action.payload.mode === "create" ? "" : action.payload.name,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
      };
    case "FETCH_ALL_UOMS_SUCCEEDED":
      return {
        ...state,
        allUoms: action.payload,
        loading: false,
      };
    case "FETCH_ALL_UOMS_SUCCEEDED_PAGINATION":
      return {
        ...state,
        allUoms: action.payload.data,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    case "FETCH_ALL_UOMS_FAILED_PAGINATION":
      console.log("FETCH_ALL_UOMS_FAILED_PAGINATION", action.payload);
      return {
        ...state,

        loading: false,
      };
    case "CLEAR_DATA":
      return {
        ...state,
        submitNewUomStatus: "",
        updateUomStatus: "",
        mode: "",
      };
    case "UPDATE_MODAL_VALIDATION":
      return {
        ...state,
        modalValidationFailed: action.payload,
      };
    case "SAVE_NEW_UOM_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewUomStatus: 1,
        loading: false,
      };

    case "SAVE_NEW_UOM_FAILED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewUomStatus: 2,
        loading: false,
      };

    case "UPDATE_UOM_SUCCESS":
      return {
        ...state,
        modalValidationFailed: false,
        updateUomStatus: 1,
        loading: false,
      };

    case "UPDATE_UOM_FAILED":
      return {
        ...state,
        modalValidationFailed: false,
        updateUomStatus: 2,
        loading: false,
      };

    case "UPDATE_MODAL_CODE":
      return {
        ...state,
        modalCode: action.payload,
      };
    case "UPDATE_MODAL_NAME":
      return {
        ...state,
        modalName: action.payload,
      };
    case "UPDATE_MODAL_DESCRIPTION":
      return {
        ...state,
        modalDescription: action.payload,
      };
    default:
      return state;
  }
};

export default uomReducer;
