import axios from 'axios';
import constants from '../reusable/constants';

export function fetchSRReceivedApi(payload) {
    const url = constants.api.url + "api/SRReceived"
    + `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}`
    +  `&MaterialId=${payload.MaterialId}&FromDate=${payload.FromDate}&ToDate=${payload.ToDate}`
    + `&Code=${payload.Code || ''}&Status=${payload.Status}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}


export function checkCodeSRReceived(payload) {
    const url = constants.api.url + "api/SRReceived/CheckCodeSRReceivedExist";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function createNewSRReceived(payload) {
    const url = constants.api.url + "api/SRReceived";

    return axios.post(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchSRReceivedById(payload) {
    const url = constants.api.url + `api/SRReceived/${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}


export function updateStatusSRReceived(payload) {
    const url = constants.api.url + "api/SRReceived/UpdateStatusSRReceived";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateSRReceived(payload) {
    const url = constants.api.url + "api/SRReceived/UpdateSRReceived";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}


export function fecthCodeSRReceived() {
    const url = constants.api.url + `api/SRCode/GetCodeSRReceiveAsync`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}