const initialState = {
    isLoading: false,
    dataOverview: {},
    dataFoodSold: {},
}
  
const revenueGeneralReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_LOADING_REVENUE_CATEGORY':
            return {
                ...state,
                dataOverview: {},
                dataFoodSold: {},
                isLoading: true,
            }
        case 'LOAD_REVENUE_CATEGORY_SUCCESS':
            return {
                ...state,
                dataOverview: action.payload.data,
                isLoading: false,
            }  
        case 'LOAD_TOTAL_FOOD_SOLD_SUCCESS':
            return {
                ...state,
                dataFoodSold: action.payload.data,
                isLoading: false,
            }  
        default:
            return state;
    }
  };
  
  export default revenueGeneralReducer;