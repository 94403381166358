const initialModalState = {
  showModal: false,
  showBarCodeModal: false,
  modalId: "",
  modalCode: "",
  modalName: "",
  modalDescription: "",
  modalCategoryId: "",
  modalGroupId: "",
  modalBaseUomId: "",
  modalCategoryName: "",
  modalBaseUomName: "",
  modalStatus: "",
  modalStatusKey: "",
  modalImage: "",
  modalUnitPrice: "",
  modalImageName: "",
};

const initialState = {
  loading: false,
  materials: [],
  activeMaterials: [],
  ...initialModalState,
  //...initialModifyCodeModalState,
  modalValidationFailed: false,
  submitNewMaterialStatus: 0,
  updateMaterialStatus: 0,
  deleteMaterialStatus: 0,
  hideProductStatus: "",
  categoryComboboxItems: [],
  uomComboboxItems: [],
  mode: "",
  productList: undefined,
  showUnitPriceModal: false,

  updateUnitPriceStatus: undefined,
  modalUnitPriceValidationFailed: undefined,
  materialDetail: [],
  totalPage: 0,


  appStoreMaterialList: [],
  activeMaterialTP: [],
  appStoreMaterialDetail: null,
  //fitler
  pageSize: 10,
  currentPage: 1,
  categoryId: null,
  name: null,
  code: null,
  status: 1,
  unitPrice: null,

  categories: [],
  uoms:[],

  modal_name:null,
  modal_category_id:null,
  modal_code:null,
  modal_description:null,
  modal_status:null,
  modal_id:null,
  modal_uom_id:null,
  modal_item_cost:null,
  modal_uom_name:null,
  modal_category_name:null,
  modal_group_name:null,
  modal_img:null,
  modal_img_cdn:null,
};
// const initialModifyCodeModalState = {
//     showModifyCodeModal: false,
//     modalNewCode: '',
//     modalMaterialId: ''
// }

const materialReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_MATERIAL_UNIT_PRICE": {
      if(action.payload === ""){
        return { ...state, unitPrice: null };
      }
      else
        return { ...state, modal_item_cost: action.payload };
    }
    case "FETCH_APP_STORE_MATERIAL_DETAIL_SUCCESSED": {
      return {
        ...state,
        loading: false,
        appStoreMaterialDetail: action.payload,
      };
    }
    case "FETCH_APP_STORE_MATERIAL_SUCCESSED": {
      return {
        ...state,
        loading: false,
        appStoreMaterialList: action.payload.appMaterialItem,
        totalPage: action.payload.totalCount,
      };
    }
    case "FETCH_APP_STORE_MATERIAL_TP_SUCCESSED": {
      return {
        ...state,
        loading: false,
        activeMaterialTP: action.payload,
      };
    }



    case "UPDATE_MATERIAL_STATUS": {
      return { ...state, status: action.payload };
    }
    case "UPDATE_MATERIAL_NAME": {
      return { ...state, name: action.payload };
    }
    case "UPDATE_MATERIAL_CODE": {
      return { ...state, code: action.payload };
    }
    case "UPDATE_MATERIAL_CATEGORY_ID": {
      if(action.payload === ""){
        return { ...state, categoryId: null };
      }
      else
        return { ...state, categoryId: action.payload };
    }
    case "UPDATE_MATERIAL_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "UPDATE_MATERIAL_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "CLEAR_MATERIAL_FILTER": {
      return {
        ...state,
        code: null,
        name: null,
        status: 1,
        categoryID: null,
      };
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_CODE":{
      return{
        ...state,
        modal_code:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_NAME":{
      return{
        ...state,
        modal_name:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_DESCRIPTION":{
      return{
        ...state,
        modal_description:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_CATEGORY_ID":{
      return{
        ...state,
        modal_category_id:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_STATUS":{
      return{
        ...state,
        modal_status:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_UOM_ID":{
      return{
        ...state,
        modal_uom_id:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_ITEM_COST":{
      return{
        ...state,
        modal_item_cost:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_MATERIAL_IMG":{
      console.log("img api",action.payload)
      return{
        ...state,
        modal_img:action.payload
      }
    }
    case"SET_MODAL_UPDATE_MATERIAL":{
      return{
        ...state,
        modal_id:action.payload.modal_id,
        modal_code:action.payload.modal_code,
        modal_name:action.payload.modal_name,
        modal_description:action.payload.modal_description,
        modal_category_id:action.payload.modal_category_id,
        modal_status:action.payload.modal_status,
        modal_uom_id:action.payload.modal_uom_id,
        modal_item_cost:action.payload.modal_item_cost,
        modal_uom_name:action.payload.modal_uom_name,
        modal_category_name:action.payload.modal_category_name,
        modal_group_name:action.payload.modal_group_name,
        modal_img_cdn:action.payload.modal_img_cdn,
      }
    }
    case"CLEAR_MODAL_CREATE_NEW_MATERIAL":{
      return{
        ...state,
        modal_code:null,
        modal_name:null,
        modal_description:null,
        modal_level:null,
        modal_id:null,
        modal_status:null,
        modal_uom_id:null,
        modal_item_cost:null,
        modal_uom_name:null,
        modal_category_name:null,
        modal_group_name:null,
        updateMaterialStatus:0,
        modal_img_cdn:null,
        modal_category_id:null,

      }
    }
    case "FETCH_ALL_UOMS_SUCCEEDED":{
      let array = action.payload;
      let newArray = [];
      newArray=array.map(item => {
        return {
          value: item.id,
          name: item.name
        }
      })
      return{
        ...state,
        uoms: newArray
      }
    }
    case "FETCH_CATEGORY_SUCCEEDED":{
      let array = action.payload;
      let newArray = [];
      newArray=array.map(item => {
        return {
          value: item.id,
          name: item.name
        }
      })
      return{
        ...state,
        categories: newArray
      }
    }
    case "UPDATE_PRODUCT_UNIT_PRICE_SUCCESS":
      return {
        ...state,
        updateUnitPriceStatus: 1,
      };

    case "UPDATE_PRODUCT_UNIT_PRICE_FAILED":
      return {
        ...state,
        updateUnitPriceStatus: 2,
      };

    case "UPDATE_UNIT_PRICE_MODAL_VALIDATION":
      return {
        ...state,
        modalUnitPriceValidationFailed: action.payload,
      };

    case "UPDATE_UNIT_PRICE":
      return {
        ...state,
        modalUnitPrice: action.payload,
      };

    case "SHOW_UNIT_PRICE_MODAL":
      return {
        ...state,
        showUnitPriceModal: true,
        modalUnitPrice: action.payload.unitPrice,
        modalId: action.payload.id,
      };

    case "HIDE_UNIT_PRICE_MODAL":
      return {
        ...state,
        showUnitPriceModal: false,
        modalUnitPrice: undefined,
      };
    case "FETCH_MATERIAL_DETAIL_SUCCEEDED":
      return {
        ...state,
        materialDetail: action.payload,
        loading: false,
      };
    case "FETCH_PRODUCT_SUCCESS":
      return {
        ...state,
        productList: action.payload,
      };

    case "UPDATE_MATERIAL_MODAL_STATUS":
      return {
        ...state,
        modalStatus: state.modalStatus === 1 ? 0 : 1,
      };
    case "UPDATE_MATERIAL_MODAL_IMAGE":
      return {
        ...state,
        modalImage: action.payload,
      };

    case "UPDATE_MODAL_NEW_MATERIAL_CODE":
      return {
        ...state,
        modalNewCode: action.payload,
      };

    case "SHOW_MATERIAL_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_MATERIAL_SUCCEEDED":
      return {
        ...state,
        materials: action.payload,
        loading: false,
      };
    case "FETCH_MATERIAL_PAGINATION_SUCCEEDED":
      return {
        ...state,
        materials: action.payload.data,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    case "CLEAR_MATERIAL_SUCCEEDED":
      return {
        ...state,
        materials: [],
      };

    case "FETCH_ALL_ACTIVE_MATERIAL_SUCCEEDED":
      return {
        ...state,
        activeMaterials: action.payload,
        loading: false,
      };

    case "SUMIT_CREATE_MATERIAL_APP_STORE_SUCCESSED":
      return {
        ...state,
        submitNewMaterialStatus: 1,
      }

    case "SUMIT_DELETE_MATERIAL_APP_STORE_SUCCESSED":
      return {
        ...state,
        deleteMaterialStatus: 1,
      }

    case "CLEAR_DATA_MATERIAL":
      return {
        ...state,
        deleteMaterialStatus: 0,
        submitNewMaterialStatus: 0,
        updateUnitPriceStatus: undefined,
        modalUnitPriceValidationFailed: undefined,
        updateMaterialStatus: 0,
        hideProductStatus: "",
      };
    case "UPDATE_MODAL_VALIDATION_MATERIAL":
      return {
        ...state,
        modalValidationFailed: action.payload,
      };

    case "HIDE_PRODUCT_SUCCESS":
      return {
        ...state,
        hideProductStatus: 1,
        loading: false,
      };

    case "HIDE_PRODUCT_FAILED":
      return {
        ...state,
        modalValidationFailed: false,
        hideProductStatus: 2,
        loading: false,
      };

    case "UPDATE_MATERIAL_SUCCESS":
      console.log("UPDATE_MATERIAL_SUCCESS", action.payload);
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
        updateMaterialStatus: 1,
        loading: false,
      };

    case "UPDATE_MATERIAL_FAILED":
      console.log("UPDATE_MATERIAL_FAILED", action.payload);
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
        updateMaterialStatus: 2,
        loading: false,
      };

    case "SAVE_NEW_MATERIAL_SUCCEEDED":
      console.log("Create succes!",action.payload)
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
        submitNewMaterialStatus: 1,
        loading: false,
      };

    case "SAVE_NEW_MATERIAL_FAILED":
      console.log("fail",action.payload)
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
        submitNewMaterialStatus: 2,
        loading: false,
      };

    case "SHOW_MATERIAL_MODAL":
      return {
        ...state,
        showModal: true,
        mode: action.payload.mode,
        modalId: action.payload.id,
        modalCode: action.payload.code,
        modalName: action.payload.name,
        modalCategoryId: action.payload.categoryId,
        modalBaseUomId: action.payload.baseUomId,
        modalDescription: action.payload.description,
        modalCategoryName: action.payload.categoryName,
        modalBaseUomName: action.payload.baseUomName,
        modalStatus: action.payload.status,
        modalUnitPrice: action.payload.unitPrice,
        modalImageName: action.payload.image,
      };

    case "SHOW_BARCODE_MODAL":
      return {
        ...state,
        barcodes: [
          {
            id: action.payload.id,
            code: action.payload.code,
            name: action.payload.name,
          },
        ],
        showBarCodeModal: true,
      };

    case "HIDE_MODAL_MATERIAL":
      return {
        ...state,
        ...initialModalState,
        modalStatusKey: Date.now,
        modalValidationFailed: false,
      };

    case "HIDE_BARCODE_MODAL":
      return {
        ...state,
        showBarCodeModal: false,
      };

    // case 'HIDE_MODAL_MODIFY_CODE':
    //     return {
    //         ...state,
    //         ...initialModifyCodeModalState
    //     }

    case "UPDATE_MODAL_CODE_MATERIAL":
      return {
        ...state,
        modalCode: action.payload,
      };
    case "UPDATE_MODAL_NAME_MATERIAL":
      return {
        ...state,
        modalName: action.payload,
      };
    case "UPDATE_MODAL_DESCRIPTION_MATERIAL":
      return {
        ...state,
        modalDescription: action.payload,
      };

    case "GENERATE_UOM_COMBOBOX_ITEMS_MATERIAL":
      let uomOriginalItems = [];
      let uoms = action.payload;
      uoms &&
        uoms.forEach((item) => {
          uomOriginalItems.push({
            id: item.id,
            value: item.name,
          });
        });
      return {
        ...state,
        uomComboboxItems: uomOriginalItems,
        modalValidationFailed: false,
      };
    case "UPDATE_MODAL_CATEGORY_MATERIAL":
      return {
        ...state,
        modalCategoryId: action.payload,
      };
    case "UPDATE_MODAL_UOM_MATERIAL":
      return {
        ...state,
        modalBaseUomId: action.payload,
      };
    case "UPDATE_MODAL_GROUP":
      return {
        ...state,
        modalGroupId: action.payload,
      };
    default:
      return state;
  }
};

export default materialReducer;
