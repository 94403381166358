import axios from 'axios'
import constants from '../reusable/constants'

export function createStockTransferApi(payload) {
   return axios.post(
      constants.api.url + 'api/stockTransfer',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockTransfersApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockTransfer?uId=' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockTransferDetailApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockTransfer/' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

//MỚi
export function fetchStockTransferByFilter({ UId, WarehouseId, FromDate, ToDate, StockRequestCode, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn}) {
   return axios.get(
      constants.api.url + 
      `api/stockTransfer?UId=${UId}&WarehouseId=${WarehouseId}&FromDate=${FromDate}&ToDate=${ToDate}&code=${StockRequestCode}&MaterialId=${MaterialId}&Status=${Status}&page=${currentPage}&pageSize=${pageSize}&orderby=${orderBy}&nameColumn=${nameColumn}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }

export function fetchListStatusStockTransfer() {
   return axios.get(
      constants.api.url + 
      `api/StockTransfer/GetListStatusStockTransfer`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }

export function submitStockTransferApi(payload) {
   const {id, uId} = payload
   if(id || id === 0){
     delete payload.uId
     return axios.put(
       constants.api.url + `api/StockTransfer?UId=${uId}`,
       payload,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
     );
   }
   
   return axios.post(
     constants.api.url + 'api/StockTransfer',
     payload,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

 export function fetchStockTransferById({id}) {
   return axios.get(
      constants.api.url + `api/stockTransfer/${id}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

/* export function deletePurchaseOrderApi({id, uId}) {
   const putMethod = {
     method: 'DELETE',
     headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
     },
    }
   return fetch(constants.api.url + `api/PurchaseOrder/${id}?uId=${uId}`, putMethod);
 } */
 
 export function deleteGoodsTransferApi({id, uId}) {
   return axios.delete(
       constants.api.url + `api/StockTransfer/${id}?uId=${uId}`,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }

 export function fetchStockRequestPendingByIdApi({id}) {
   return axios.get(
       constants.api.url + `api/PurchaseOrder/GetPendingStockRequest?id=${id}`,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }
