import axios from 'axios'
import constants from '../reusable/constants'

export async function approveRequestApi(payload) {
    return await axios.post(
        constants.api.url + 'api/Workflow/ApproveRequest',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export async function approveTransactionApi(payload) {
  return await axios.post(
      constants.api.url + 'api/Workflow/ApproveTransaction',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export async function sendEmailPayslipVictory(payload) {
    return await axios.post(
        constants.api.url + `api/Workflow/SendEmailPayslipVictory/${payload}`,
        null,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
  }


