const initialState = {
  loading: false,
  totalCount: 0,
  purchaseOrderList: [],
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_PURCHASE_ORDER_BY_FILTER_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "FETCH_PURCHASE_ORDER_BY_FILTER_SUCCESS":
      return {
        ...state,
        purchaseOrderList: action.payload.data,
        totalCount: action.payload.totalCount,
        loading: false,
      }

    case "FETCH_PURCHASE_ORDER_BY_FILTER_FAILED":
      return {
        ...state,
        loading: false,
      }

    case 'DELETE_PURCHASE_ORDER_SUCCEEDED':
      //console.log(action.payload);
      const idDelete = action.payload
      return {
        ...state,
        purchaseOrderList:  [...state.purchaseOrderList].filter(item => item.id !== idDelete)
      }

    default:
      return state
  }
};

export default order;
