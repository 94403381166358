import { number } from "prop-types";

const initialState = {
  loading: false,
  categories: [],
  category: {},
  modalValidationFailed: false,
  submitNewCategoryStatus: "",
  updateCategoryStatus: "",
  mode: "",
  totalPage: 0,

  //filter
  currentPage: 1,
  pageSize: 10,
  code: null,
  name: null,
  status: 1,
  level: null,
  //list filter\

  orderBy:null,
  orderId:null,

  //
  modal_name:null,
  modal_level:null,
  modal_code:null,
  modal_description:null,
  modal_parentCode:null,
  modal_status:null,
  modal_orderBy:null,
};

// const initialModalState = {
//   showModal: false,
//   modalCode: "",
//   modalName: "",
//   modalDescription: "",
//   modalLevel: "",
//   modalParentCategories: [],
//   modalParentCategoryCode: "",

//   showOrderByModal: false,
//   modalOrderBy: undefined,
//   updateOrderByStatus: undefined,
//   modalOrderByValidationFailed: undefined,
// };

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    //update case
    case "UPDATE_CATEGORY_STATUS": {
      return { ...state, status: action.payload };
    }
    case "UPDATE_CATEGORY_NAME": {
      return { ...state, name: action.payload };
    }
    case "UPDATE_CATEGORY_CODE": {
      return { ...state, code: action.payload };
    }
    case "UPDATE_CATEGORY_LEVEL": {
      return { ...state, level: action.payload };
    }
    case "UPDATE_CATEGORY_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "UPDATE_CATEGORY_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "UPDATE_CATEGORY_ORDERBY":{
      return {
        ...state,
        orderBy: action.payload
      }
    }
    case "UPDATE_CATEGORY_ORDERID":{
      return {
        ...state,
        orderId: action.payload
      }
    }
    case "RESET_CATEGORY_ORDERCHANGE":{
      return{
        ...state,
        orderBy:null,
        orderId:null,
        updateOrderByStatus:undefined,
      }
    }
    case "CLEAR_CATEGORY_FILTER": {
      return {
        ...state,
        code: null,
        name: null,
        status: 1,
        level: null,
      };
    }

    case"SET_MODAL_CREATE_NEW_CATEGORY_CODE":{
      return{
        ...state,
        modal_code:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_CATEGORY_NAME":{
      return{
        ...state,
        modal_name:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_CATEGORY_DESCRIPTION":{
      return{
        ...state,
        modal_description:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_CATEGORY_LEVEL":{
      return{
        ...state,
        modal_level:action.payload
      }
    }
    case"SET_MODAL_CREATE_NEW_CATEGORY_PARENTCODE":{
      return{
        ...state,
        modal_parentCode:action.payload
      }
    }
    case"SET_MODAL_UPDATE_CATEGORY":{
      return{
        ...state,
        orderId:action.payload.orderId,
        modal_code:action.payload.modal_code,
        modal_name:action.payload.modal_name,
        modal_description:action.payload.modal_description,
        modal_level:action.payload.modal_level,
        modal_parentCode:action.payload.modal_parentCode,
        modal_status:action.payload.modal_status,
        orderBy:action.payload.orderBy,
      }
    }
    case"CLEAR_MODAL_CREATE_NEW_CATEGORY":{
      return{
        ...state,
        modal_code:null,
        modal_name:null,
        modal_description:null,
        modal_level:null,
        modal_parentCode:null,
        orderId:null,
        modal_status:null,
        orderBy:null,

      }
    }

    case "UPDATE_CATEGORY_ORDER_BY_SUCCESS":
      return {
        ...state,
        updateOrderByStatus: 1,
        loading: false,
        orderId:null,
      };

    case "UPDATE_CATEGORY_ORDER_BY_FAILED":
      return {
        ...state,
        updateOrderByStatus: 2,
        loading: false,
      };

    case "UPDATE_ORDER_BY_MODAL_VALIDATION":
      return {
        ...state,
        modalOrderByValidationFailed: action.payload,
      };

    case "UPDATE_CATEGORY_ORDER_BY":
      return {
        ...state,
        modalOrderBy: action.payload,
      };

    case "SHOW_ORDER_BY_MODAL":
      return {
        ...state,
        showOrderByModal: true,
        modalOrderBy: action.payload.orderBy,
        modalId: action.payload.id,
      };

    case "HIDE_ORDER_BY_MODAL":
      return {
        ...state,
        showOrderByModal: false,
        modalOrderBy: undefined,
      };

    case "CLEAR_DATA":
      return {
        ...state,
        submitNewCategoryStatus: "",
        showOrderByModal: false,
        modalOrderBy: undefined,
        updateOrderByStatus: undefined,
        modalOrderByValidationFailed: undefined,
        updateCategoryStatus: "",
      };
    case "UPDATE_MODAL_VALIDATION":
      return {
        ...state,
        modalValidationFailed: action.payload,
      };
    case "UPDATE_MODAL_PARENT_CATEGORY_CODE":
      return {
        ...state,
        modalParentCategoryCode: action.payload,
      };
    case "SHOW_CATEGORY_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "SHOW_CATEGORY_MODAL":
      return {
        ...state,
        showModal: true,
        mode: action.payload.mode,
        modalId: action.payload.mode === "create" ? "" : action.payload.id,
        modalCode: action.payload.mode === "create" ? "" : action.payload.code,
        modalName: action.payload.mode === "create" ? "" : action.payload.name,
        modalLevel:
          action.payload.mode === "create" ? "" : action.payload.level,
        modalDescription:
          action.payload.mode === "create" ? "" : action.payload.description,
        modalParentCategoryCode:
          action.payload.mode === "create" ? "" : action.payload.parentCode,
      };
    case "FETCH_CATEGORY_SUCCEEDED":
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case "FETCH_CATEGORY_PAGINATION_SUCCEEDED":
      console.log(action.payload);
      return {
        ...state,
        categories: action.payload.data,
        totalPage: action.payload.totalPage,
        isGetListFilter: true,
        loading: false,
      };

    case "FETCH_CATEGORY_PAGINATION_FAILED":
      return {
        ...state,

        loading: false,
      };
    case "FETCH_PARENT_CATEGORY_SUCCEEDED":
      return {
        ...state,
        loading: false,
        modalParentCategories: action.payload,
      };
    case "SAVE_NEW_CATEGORY_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewCategoryStatus: 1,
        loading: false,
      };
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: true,
      };

    case "SAVE_NEW_CATEGORY_FAILED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewCategoryStatus: 2,
        loading: false,
      };

    case "UPDATE_CATEGORY_SUCCESS":
      return {
        ...state,
        modalValidationFailed: false,
        updateCategoryStatus: 1,
        loading: false,
      };

    case "UPDATE_CATEGORY_FAILED":
      return {
        ...state,
        modalValidationFailed: false,
        updateCategoryStatus: 2,
        loading: false,
      };
    case "UPDATE_MODAL_CODE":
      return {
        ...state,
        modalCode: action.payload,
      };
    case "UPDATE_MODAL_NAME":
      return {
        ...state,
        modalName: action.payload,
      };
    case "UPDATE_MODAL_LEVEL":
      return {
        ...state,
        modalLevel: action.payload,
        modalParentCategories: [],
        modalParentCategoryCode: "",
      };
    case "UPDATE_MODAL_DESCRIPTION":
      return {
        ...state,
        modalDescription: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
