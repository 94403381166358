import axios from 'axios'
import constants from '../reusable/constants'

export function fetchEmployee(payload = {status: ''}) {
    if(payload && payload.status || payload.status === 0 ){
        return axios.get(
            constants.api.url + `api/HRApp/GetListEmployee?status=${payload.status}`,
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
        );
    }

    return axios.get(
        constants.api.url + 'api/HRApp/GetListEmployee',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchEmployeeDetail(payload = {}) {
    const { staffCode } = payload
    return axios.get(
        constants.api.url + `api/HRApp/GetEmployee?staffCode=${staffCode}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function createNewEmployeeApi(payload = {}) {
    return axios.post(
        constants.api.url + `api/HRApp/CreateNewEmployee`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function updateEmployeeApi(payload = {}) {
    const { data, about } = payload;
    return axios.post(
        constants.api.url + `api/HRApp/UpdateEmployee${about}`,
        data,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function updateAvatarEmployeeApi(payload) {
    return axios.put(
        constants.api.url + `api/HRApp/UploadAvatar`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function updateUserStatusEmployeeApi(payload) {
    return axios.post(
        constants.api.url + `api/HRApp/UpdateUserStatus`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}