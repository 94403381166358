import axios from 'axios'
import constants from '../reusable/constants'

export function fetchConversion() {
    return axios.get(
        constants.api.url + 'api/conversion',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}
export function fetchConversionPagination(payload) {
  return axios.get(
      constants.api.url + `api/Conversion/GetAllConversions?page=${payload.page}&pageSize=${payload.pageSize}&materialName=${payload.materialName}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function createNewConversion(payload) {
    return axios.post(
        constants.api.url + 'api/conversion',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function updateConversion(payload) {
    return axios.put(
        constants.api.url + 'api/conversion/' + payload.id,
        payload.data,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}
