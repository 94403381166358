import axios from 'axios'
import constants from '../reusable/constants'

export function fetchWebGroup() {
    return axios.get(
        constants.api.url + 'api/UserRole/GetWebGroup',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function createWebGroupApi(payload) {
    return axios.post(
        constants.api.url + `api/UserRole/CreateWebGroup`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function updateWebGroupApi(payload) {
    return axios.put(
        constants.api.url + `api/UserRole/UpdateWebGroup`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function fetchMenuByWebGroup(payload) {
    const { id } = payload
    return axios.get(
        constants.api.url + `api/UserRole/GetMenuByWebGroup?Id=${id}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function fetchStaffByWebGroup(payload) {
  const { webGroupId } = payload
  return axios.get(
      constants.api.url + `api/UserRole/GetStaffByWebGroup?webGroupId=${webGroupId}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function createUserGroupPermissionsByWebGroupIdApi(payload) {
    return axios.post(
        constants.api.url + `api/UserRole/CreateUserGroupPermissionsByWebGroupId`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function fetchDataAccessStoreByWebGroupId(payload) {
    const { id } = payload
    return axios.get(
        constants.api.url + `api/UserRole/GetDataAccessStoreByWebGroupId?Id=${id}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

  export function fetchDataAccessWarehouseByWebGroupId(payload) {
    const { id } = payload
    return axios.get(
        constants.api.url + `api/UserRole/GetDataAccessWarehouseByWebGroupId?Id=${id}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function createUserGroupDataAccessApi(payload) {
    return axios.post(
        constants.api.url + `api/UserRole/CreateUserGroupDataAccess`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}


export function fetchAppByWebGroup(payload) {
  const { webGroupId } = payload
  return axios.get(
        constants.api.url + `api/UserRole/GetUserGroupAppByWebGroup?webGroupId=${webGroupId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function updateUserGroupAppApi(payload) {
    return axios.post(
        constants.api.url + `api/UserRole/UpdateUserGroupApp`,
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}