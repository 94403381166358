const initialState = {
  loading: false,
  cashInList: [],
  formValidationFailed: false,
  approveFlag: 0,
  createFlag: 0,
  deleteFlag: 0,
  editFlag: 0,
  viewFlag: 0,
  reason: "",
  amount: "",
  totalCount: 0,
  pageSize: 10,
  page: 1,
  date: "",
  refresh: false,
  statusUpload: 0,
  mothPaySlip: [],
  payslip: [],
  statusSendMail: 0,
};

const cashInReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PAYSLIP_DETAIL_SUCCESSED":
      return {
        ...state,
        payslip: action.payload,
        loading: false,
      };
    case "SET_MONTH_PAYSLIP_SUCCESSED":
      return {
        ...state,
        mothPaySlip: action.payload,
        loading: false,
      };

    case "STATUS_SEND_MAIL_PAYSLIP_SUCCESSED":
      return {
        ...state,
        statusSendMail: 1,
        loading: false,
      };

    case "STATUS_UPLOAD_PAYSLIP_SUCCESSED":
      return {
        ...state,
        statusUpload: 1,
        loading: false,
      };

    case "SET_DEFAULT_STATUS_UPLOAD":
      return {
        ...state,
        statusUpload: 0,
        statusSendMail: 0,
        loading: false,
      };

    case "CLEAR_DATA_CASHINMANAGE":
      return {
        ...state,
        cashInList: [],
      };

    case "SET_AUTHO_MODULE_CASH_IN":
      return {
        ...state,
        createFlag: action.payload?.createFlag,
        approveFlag: action.payload?.approveFlag,
        deleteFlag: action.payload?.deleteFlag,
        editFlag: action.payload?.editFlag,
        viewFlag: action.payload?.viewFlag,
      };
    case "SHOW_CASH_IN_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_CASH_IN_FORM_VALIDATION":
      return {
        ...state,
        formValidationFailed: action.payload,
      };
    case "FETCH_CASH_IN_REPORT_SUCCESS":
      console.log("FETCH_CASH_IN_REPORT_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        cashInList: action.payload.items,
        totalCount: action.payload.totalCount,
        refresh: false,
      };
    case "FETCH_CASH_IN_REPORT_FAILED":
      console.log("FETCH_CASH_IN_REPORT_FAILED", action.payload);
      return { ...state, loading: false };
    case "SET_REASON":
      return {
        ...state,
        reason: action.payload,
      };
    case "SET_AMOUNT":
      return {
        ...state,
        amount: action.payload,
      };
    case "UPDATE_CASH_IN_DATE_VALIDATION":
      return {
        ...state,
        date: action.payload,
      };
    case "UPDATE_CURRENT_PAGE_POS_SHIFT":
      return {
        ...state,
        page: action.payload,
      };
    case "UPDATE_PAGE_SIZE_POS_SHIFT":
      return {
        ...state,
        pageSize: action.payload,
      };
    case "SUBMIT_CASH_IN_REPORT_SUCCESS":
      console.log("SUBMIT_CASH_IN_REPORT_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        refresh:true,
      };
    default:
      return state;
  }
};

export default cashInReducer;
