import axios from "axios";
import constants from "../reusable/constants";

export function fetchOrgChart() {
  return axios.get(constants.api.url + "api/UserRole/GetOrganizationalChart", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function createOrgChartApi(data) {
  return axios.post(
    constants.api.url + "api/UserRole/EmployeeOrganizationalChart",
    data,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchListEmployee() {
  return axios.get(constants.api.url + "api/HRApp/GetListEmployee", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}
