import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyBb1WeqLnVcVvPGAqNObH3SN8ZV6JOQWYY",
    authDomain: "eco-giong.firebaseapp.com",
    databaseURL: "https://eco-giong.firebaseio.com",
    projectId: "eco-giong",
    storageBucket: "eco-giong.appspot.com",
    messagingSenderId: "352848277484",
    appId: "1:352848277484:web:ff97ba8234bf48a8291ac9"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();