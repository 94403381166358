const initialState = {
  loading: false,
  detail: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_GOODS_TRANSFER_BY_ID_LOADING':
      return {
        ...state,
        loading: true
      }
    
    case 'FETCH_GOODS_TRANSFER_BY_ID_SUCCESS':
      return {
        ...state,
        loading: false,
        detail: action.payload.data
      }

    case 'FETCH_GOODS_TRANSFER_BY_ID_FAILED':
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
};

export default reducer;