const initialState = {
    loading: false,
    goodsReturn: [],
    totalPage: 0,
    submitNewgoodsReturnStatus: 0,
    updategoodsReturnStatus: 0,
    approvegoodsReturnStatus: 0,
    goodsReturnDetails: null,
}

const goodsReturnReducer = (state = initialState, action) => {   
    switch (action.type) {
        case "FETCH_GOODS_RETURN_DETAIL_SUCCEEDED":
            return {
               ...state,
               goodsReturnDetails: action.payload,
               loading: false,
            };
        case 'SUBMIT_NEW_GOODS_RETURN_SUCCESSED':
            return {
                ...state,
                loading: false,
                submitNewgoodsReturnStatus: 1,
            };
        case 'SUBMIT_NEW_GOODS_RETURN_FAILED':
            return {
                ...state,
                loading: false,
                submitNewgoodsReturnStatus: 2,
            };
        case 'FETCH_GOODS_RETURN_SUCCESSED':
            return {
                ...state,
                loading: false,
                goodsReturn: action.payload?.data,
                totalPage: action.payload?.totalCount,
            };
        case "SET_DEFAULT_STATUS_EDIT_GOODS_RETURN":
            return {
                ...state,
                submitNewgoodsReturnStatus: 0,
                updategoodsReturnStatus: 0,
                approvegoodsReturnStatus: 0,
                loading: false
            };   
        case 'SHOW_LOADING_GOODS_RETURN':
            return {
                ...state,
                loading: true
            };

        case 'DEFAULT_DATA_GOODS_RETURN':
            return initialState;

        default:
            return state;
    }
}

export default goodsReturnReducer;