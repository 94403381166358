const initialState = {
  loading: false,
  totalCount: 0,
  goodsDestructionList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_GOODS_DESTRUCTION_BY_FILTER_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "FETCH_GOODS_DESTRUCTION_BY_FILTER_SUCCESS":
      return {
        ...state,
        goodsDestructionList: action.payload.data,
        totalCount: action.payload.totalPage,
        loading: false,
      }

    case "FETCH_GOODS_DESTRUCTION_BY_FILTER_FAILED":
      return {
        ...state,
        loading: false,
      }

    case 'DELETE_GOODS_DESTRUCTION_SUCCESS':
      const idDelete = action.payload
      return {
        ...state,
        goodsDestructionList: [...state.goodsDestructionList].filter(item => item.id !== idDelete)
      }

    default:
      return state
  }
};

export default reducer;
