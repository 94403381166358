const initialState = {
   navList: [],
}

const authoModuleReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_NAV':
         let authoModules = [];
         action.payload.forEach(item => {
            let obj = {
               id: item.menuId,
               approveFlag: item.approveFlag,
               createFlag: item.createFlag,
               deleteFlag: item.deleteFlag,
               editFlag: item.editFlag,
               viewFlag: item.viewFlag,
               name: item.menuName
            }
            authoModules.push(obj)
            item.subMenus.forEach(sub => {
               let obj = {
                  id: sub.menuId,
                  approveFlag: sub.approveFlag,
                  createFlag: sub.createFlag,
                  deleteFlag: sub.deleteFlag,
                  editFlag: sub.editFlag,
                  viewFlag: sub.viewFlag,
                  name: sub.menuName
               }
               authoModules.push(obj)
               if (sub.subMenus.length !== 0) {
                  sub.subMenus.forEach(eSub => {
                     let obj = {
                        id: eSub.menuId,
                        approveFlag: eSub.approveFlag,
                        createFlag: eSub.createFlag,
                        deleteFlag: eSub.deleteFlag,
                        editFlag: eSub.editFlag,
                        viewFlag: eSub.viewFlag,
                        name: eSub.menuName
                     }
                     authoModules.push(obj)

                  })
               }
            })
         })
         return {
            ...state,
            navList: authoModules
         }

      default:
         return state
   }
}

export default authoModuleReducer;