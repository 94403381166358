import { combineReducers } from "redux";
import revenueDateReducer from "./revenueDateReducer";
import revenueWeekReducer from "./revenueWeekReducer";
import revenueMonthReducer from "./revenueMonthReducer";
import revenueQuarterReducer from "./revenueQuarterReducer";
import revenueYearReducer from "./revenueYearReducer";
import revenueGeneralReducer from "./revenueGeneralReducer";
import revenueHourReducer from "./revenueHourReducer";
import revenueCategoryReducer from "./revenueCategory";
import revenueComboReducer from "./revenueComboReducer";
import revenueShiftReducer from "./revenueShiftReducer";
import revenueServiceModeReducer from "./revenueServiceModeReducer";
import revenuePaymentReducer from "./revenuePaymentReducer";

const revenueReducer = combineReducers({
  date: revenueDateReducer,
  week: revenueWeekReducer,
  month: revenueMonthReducer,
  quarter: revenueQuarterReducer,
  year: revenueYearReducer,
  general: revenueGeneralReducer,
  hour: revenueHourReducer,
  category: revenueCategoryReducer,
  combo: revenueComboReducer,
  shift: revenueShiftReducer,
  service: revenueServiceModeReducer,
  payment: revenuePaymentReducer,
})

export default revenueReducer;
