import React, { useState } from "react";
import { Button } from "src/style-common/Button.Style";
import { Detail } from "./DataTable.Style";
import DataTableCustome from "./DataTableCustome";

const Page = () => {
  const DataRender = [
    {
      col1: "5",
      col2: "6",
    },
    {
      col1: "7",
      col3: "8",
    },
    {
      col1: "9",
      col3: "10",
    },
    {
      col1: "11",
      col4: "12",
    },
    {
      col1: "1",
      col2: "2",
    },
    {
      col1: "3",
      col2: "4",
    },
  ];
  const Column = [
    {
      key: "col1",
      title: "Column 1",
      // _props:{
      //   className: "col1 para-sub semi-bold",
      // },
      // _style: {
      //   backgroundColor: "yellow",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   color:"red",
      // },
      // _colStyle:{
      //   alignItems: "flex-end",
      // }
      sort: true,
    },
    {
      key: "col2",
      title: "Column 2",
    },
    {
      key: "col3",
      title: "Column 3",
      _props: {
        className: "col3",
      },
      sort: true,
    },
    {
      key: "col4",
      title: "Column 4",
    },
    {
      key: "show_detail",
      title: "Show Detail",
    },
  ];
  const [details, setDetails] = useState(null);
  const toggleDetail = (item) => {
    let pos = DataRender.findIndex((i) => i === item);
    if (pos === details && pos !== -1) setDetails(null);
    else setDetails(pos);
  };
  return (
    <div>
      <p>For test only</p>
      <DataTableCustome
        DataRender={DataRender}
        Column={Column}
        DefaultHeaderBackgroundColor="#FFF8F8"
        DefaultBodyBackgroundColor="#ffffff"
        DefaultHeaderFont="para-main semi-bold"
        DefaultBodyFont="para-main semi-bold"
        scopedColumns={{
          show_detail: (item) => {
            return (
              <Button
                style={{
                  width: "100px",
                }}
                onClick={() => {
                  toggleDetail(item);
                }}
              >
                {DataRender.findIndex((i) => i === item) === details
                  ? "Hide"
                  : "Show"}
              </Button>
            );
          },
          content_detail: (item) => {
            return (
              <Detail
                visitable={DataRender.findIndex((i) => i === item) === details}
              >
                helo
              </Detail>
            );
          },
        }}
        OnChange={(key) => {
          console.log(key);
        }}
      />
    </div>
  );
};

export default Page;
