const initialState = {
  loading: false,
  warehouses: [],
  warehouse: {},
  totalPage: 0,

  pageSize: 10,
  currentPage: 1,
  status: 1,

  modal_id: null,
  modal_uid: null,
  modal_name: null,
  modal_address: null,
  modal_description: null,
  modal_telephone: null,
  modal_status: null,
  modal_orderBy: null,
  modal_site_id: null,

  updateSuccess: 0,
};

const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MODAL_CREATE_NEW_WAREHOUSE_TELEPHONE": {
      return {
        ...state,
        modal_telephone: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_WAREHOUSE_CODE": {
      return {
        ...state,
        modal_code: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_WAREHOUSE_ADDRESS": {
      return {
        ...state,
        modal_address: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_WAREHOUSE_NAME": {
      return {
        ...state,
        modal_name: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_WAREHOUSE_DESCRIPTION": {
      return {
        ...state,
        modal_description: action.payload,
      };
    }
    case "SET_MODAL_UPDATE_WAREHOUSE": {
      return {
        ...state,
        modal_id: action.payload.modal_id,
        modal_code: action.payload.modal_code,
        modal_name: action.payload.modal_name,
        modal_description: action.payload.modal_description,
        modal_address: action.payload.modal_address,
        modal_telephone: action.payload.modal_telephone,
        modal_status: action.payload.modal_status,
      };
    }
    case "CLEAR_MODAL_CREATE_NEW_WAREHOUSE": {
      return {
        ...state,
        modal_code: null,
        modal_name: null,
        modal_description: null,
        modal_address: null,
        modal_telephone: null,
        modal_id: null,
        modal_status: null,
        modal_site_id: null,
      };
    }
    case "UPDATE_WAREHOUSE_STATUS": {
      return { ...state, status: action.payload };
    }
    case "UPDATE_WAREHOUSE_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "UPDATE_WAREHOUSE_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "CLEAR_WAREHOUSE_FILTER": {
      return {
        ...state,
        code: null,
        name: null,
        status: 1,
      };
    }
    case "SHOW_WAREHOUSE_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_WAREHOUSE_SUCCEEDED":
      return {
        ...state,
        warehouses: action.payload,
        loading: false,
      };
    case "FETCH_WAREHOUSE_PAGINATION_SUCCEEDED":
      return {
        ...state,
        warehouses: action.payload.data,
        loading: false,
        totalPage: action.payload.totalPage,
      };
    case "FETCH_WAREHOUSE_PAGINATION_FAILED":
      return {
        ...state,

        loading: false,
      };
    case "SET_WAREHOUSE_ID":
      return {
        ...state,
        warehouse: action.payload,
        loading: false,
      };
    case "FETCH_ALL_WAREHOUSE_SUCCEEDED":
      return {
        ...state,
        warehouses: action.payload,
        loading: false,
      };
    case "UPDATE_WAREHOUSE_SUCCESS":
      return {
        ...state,
        loading: false,
        updateSuccess: 1,
      };
    case "CREATE_WAREHOUSE_SUCCESS":{
      return {
        ...state,
        createSuccess: 1,
      };
    }
    case "SET_MODAL_CREATE_NEW_WAREHOUSE_SITE_ID": {
      return {
        ...state,
        modal_site_id: action.payload,
      };
    }
    case "UPDATE_WAREHOUSE_FAILED":
      return {
        ...state,
        loading: false,
        updateSuccess: 2,
      };

    default:
      return state;
  }
};

export default warehouseReducer;
