import axios from "axios";
import constants from "../reusable/constants";

export function createScheduleApi(payload) {
  return axios.post(
    constants.api.url +
      "api/Shift/AddUploadMaster?storeId=" +
      payload.storeId +
      "&createdBy=" +
      payload.createdBy,
    payload.data,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchUploadMastersApi(payload) {
  return axios.get(constants.api.url + "api/Shift/GetUploadMaster", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function fetchScheduleDetail(payload) {
  return axios.get(constants.api.url + "api/Shift/GetUploadMaster/" + payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function fetchScheduleReportApi(payload) {
  return axios.get(
    constants.api.url +
      "api/Shift/getShiftReport?fromDate=" +
      payload.fromDate +
      "&toDate=" +
      payload.toDate +
      "&storeId=" +
      payload.storeId,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function createPayslipApi(payload) {
  return axios.post(
    constants.api.url +
      "api/Shift/AddUploadPayslip?createdBy=" +
      payload.createdBy,
    payload.data,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchPayslipMasterApi() {
  return axios.get(constants.api.url + "api/Shift/GetMonthHRPayslipMasters", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function fetchPayslipDetailsApi(payload) {
  return axios.get(
    constants.api.url + "api/Shift/GetHRPayslipDetailsById/" + payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateStatusFileApi(payload) {
  return axios.post(
    constants.api.url + "api/Shift/UpdateStatusFilePayslip?id=" + payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateStatusFileVictoryApi(payload) {
  return axios.post(
    constants.api.url + "api/Shift/UpdateStatusFilePayslipVictory?id=" + payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchPayslipFileById(payload) {
  return axios.get(
    constants.api.url + "api/Shift/getPayslipFileById?id=" + payload.id,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchPayslipVictoryFileById(payload) {
  return axios.get(
    constants.api.url + "api/Shift/GetPayslipVictoryFileById?id=" + payload.id,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function sendEmailPayslipApi(payload) {
  return axios.post(
    constants.api.url + "api/workflow/SendEmailPayslip/" + payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchCashInReportApi(payload) {
  return axios.get(
    constants.api.url +
      "api/Shift/GetCashInReport?selectedDate=" +
      payload.selectedDate+"&page="+payload.page+"&pageSize="+payload.pageSize,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchAllStaffMasterApi() {
  return axios.get(constants.api.url + "api/Shift/GetAllStaffMaster", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function updateCashInReportApi(payload) {
  return axios.put(
    constants.api.url + "api/Shift/UpdateStatusShift",payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function uploadPaySlipVictory(payload) {
  return axios.post(
    constants.api.url + `api/Shift/AddUploadPayslipVictory?createdBy=${payload.userId}`, payload.data,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function fetchMonthPaySlip() {
  return axios.get(
    constants.api.url + "api/Shift/GetMonthHRPayslipVictoryMasters",
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
} 

export function fetchPaySlipDetail(payload) {
  return axios.get(
    constants.api.url + `api/Shift/GetHRPayslipVictoryDetailsById/${payload}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
} 