const initialState = {
  checkedList: [],
  requestedDate: '',
  requestedBy: '',
  description: '',
  attachedFile: null,
  loading: false,
  status: null,
};

const orderSubmitReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_CHECKBOX_VALUE_PO':
      return {
        ...state,
        checkedList: updateAfterArr(action.payload.receiveObj, state.checkedList)
      }

    case 'CHANGE_REQUESTED_DATE_PO':
      return {
        ...state,
        requestedDate: action.payload.requestedDate
      }

    case 'CHANGE_REQUESTED_BY_PO':
      return {
        ...state,
        requestedBy: action.payload.requestedBy
      }

    case 'CHANGE_DESCRIPTION_PO':
      return {
        ...state,
        description: action.payload.description
      }

    case 'CHANGE_ATTACHED_FILE_PO':
      return {
        ...state,
        attachedFile: action.payload.attachedFile
      }

    case 'SHOW_SUBMIT_PURCHASE_ORDER_LOADING':
      return {
        ...state,
        loading: true
      }
    
    case 'SUBMIT_PURCHASE_ORDER_SUCCEEDED':
      return {
        ...state,
        status: 'success',
        loading: false,
      }

    case 'SUBMIT_PURCHASE_ORDER_FAILED':
      return {
        ...state,
        status: 'failed',
        loading: false,
      }

    case 'CLEAR_SUBMIT_PURCHASE_ORDER':
      return {
        ...state,
        checkedList: [],
        requestedDate: '',
        //requestedBy: '',
        description: '',
        attachedFile: null,
        status: null,
      }

    case 'FETCH_PURCHASE_ORDER_BY_ID_SUCCEEDED':
      return {
        ...state,
        checkedList: action.payload.data.stockRequests,
        requestedDate: action.payload.data.requestedDate,
        requestedBy: action.payload.data.requestedBy,
        description: action.payload.data.description,
        //attachedFile: action.payload,
      }
    
    case 'FECTH_PURCHASE_ORDER_BY_ID_FAILED':
      return {
        ...state,
        requestedDate: '',
        requestedBy: '',
        description: '',
      }

    default:
      return state
  }
};

export default orderSubmitReducer;


const updateAfterArr = (receiveObj, arr = []) => {
  if(Array.isArray(arr)){
    const newArr = [...arr]
    const {id} = receiveObj
    const isExist = newArr.find(item => item.id === id)
    if(isExist){
      return newArr.filter(item => item.id !== id)
    }
    else{
      return [...newArr, receiveObj]
    }
  }
  return []
}