import { call, put, takeEvery } from 'redux-saga/effects'

import { 
  fetchStockRequestPendingByIdApi,
  deletePurchaseOrderApi,
  submitPurchaseOrderApi,
  fetchPurchaseOrderByFilter,
  fetchListStatusPurchaseOrder,
  fetchPurchaseOrderById
} from 'src/api/purchaseOrderApi'

import { approveRequestApi, approveTransactionApi } from 'src/api/workFlowApi'


function* loadPurchaseOrderByFilter(action) {
  try {
    yield put({ type: "SHOW_PURCHASE_ORDER_BY_FILTER_LOADING" });
    let res = yield call(fetchPurchaseOrderByFilter, action.payload)
    yield put({ type: "FETCH_PURCHASE_ORDER_BY_FILTER_SUCCESS", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_PURCHASE_ORDER_BY_FILTER_FAILED", payload: e.message });
  }
}

function* loadListStatusPurchaseOrder(action) {
  try {
    yield put({ type: "SHOW_LIST_STATUS_PURCHASE_ORDER_LOADING" });
    let res = yield call(fetchListStatusPurchaseOrder, action.payload)
    yield put({ type: "FETCH_LIST_STATUS_PURCHASE_ORDER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_LIST_STATUS_PURCHASE_ORDER_FAILED", payload: e.message });
  }
}

function* submitPurchaseOrder(action) {
  try {
    yield put({ type: "SHOW_SUBMIT_PURCHASE_ORDER_LOADING" });
    let res = yield call(submitPurchaseOrderApi, action.payload)
    yield put({ type: "SUBMIT_PURCHASE_ORDER_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_PURCHASE_ORDER_FAILED", payload: e.message });
  }
}

function* loadPurchaseOrderById(action) {
  try {
    yield put({ type: "SHOW_PURCHASE_ORDER_BY_ID_LOADING" });
    let res = yield call(fetchPurchaseOrderById, action.payload)
    yield put({ type: "FETCH_PURCHASE_ORDER_BY_ID_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "FETCH_PURCHASE_ORDER_BY_ID_FAILED", payload: e.message });
  }
}

function* deletePurchaseOrder(action) {
  //try {
    yield put({ type: "SHOW_DELETE_PURCHASE_ORDER_LOADING" });
    const idDelete = action.payload.id
    let res = yield call(deletePurchaseOrderApi, action.payload)
    //yield put({ type: "DELETE_PURCHASE_ORDER_SUCCEEDED", payload: res.data });
    //console.log(res);
    //try catch khi xóa thất bại nó chạy ko đúng??
    console.log(res.status);
    if(res.status !== 200){
      yield put({ type: "DELETE_PURCHASE_ORDER_FAILED"});  
    }
    else{
      //console.log(res);
      yield put({ type: "DELETE_PURCHASE_ORDER_SUCCEEDED", payload: idDelete });
    }
  //} catch (e) {
    //yield put({ type: "DELETE_PURCHASE_ORDER_FAILED", payload: e.message });
  //}
}

function* loadingStockRequestPendingById(action) {
  try {
    yield put({ type: "SHOW_STOCK_PENDING_REQUEST_BY_ID_LOADING" });
    let res = yield call(fetchStockRequestPendingByIdApi, action.payload)
    yield put({ type: "FETCH_STOCK_PENDING_REQUEST_BY_ID_SUCCEEDED", payload: res.data.data });
  } catch (e) {
    yield put({ type: "FETCH_STOCK_PENDING_REQUEST_BY_ID_FAILED", payload: e.message });
  }
}

function* sumbitPurchaseOrderApproveRequest(action) {
  try {
    yield put({ type: "SHOW_SUBMIT_PURCHASE_ORDER_APPROVE_REQUEST_LOADING" })
    let res = yield call(approveRequestApi, action.payload)
    yield put({ type: "SUBMIT_PURCHASE_ORDER_APPROVE_REQUEST_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_PURCHASE_ORDER_APPROVE_REQUEST_FAILED", payload: e.message });
  }
}

function* submitPurchaseOrderWorkFlowAction(action) {
  try {
    yield put({ type: "SHOW_SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION_LOADING" })
    let res = yield call(approveTransactionApi, action.payload)
    yield put({ type: "SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION_SUCCEEDED", payload: res.data });
  } catch (e) {
    yield put({ type: "SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION_FAILED", payload: e.message });
  }
}


export default function* rootSaga(){
  yield takeEvery("LOAD_PURCHASE_ORDER_BY_FILTER", loadPurchaseOrderByFilter);
  yield takeEvery("LOAD_LIST_STATUS_PURCHASE_ORDER", loadListStatusPurchaseOrder)
  yield takeEvery("SUBMIT_PURCHASE_ORDER", submitPurchaseOrder)
  yield takeEvery("FETCH_PURCHASE_ORDER_BY_ID", loadPurchaseOrderById)
  yield takeEvery("DELETE_PURCHASE_ORDER", deletePurchaseOrder)
  
  yield takeEvery("LOAD_STOCK_REQUEST_PENDING_BY_ID", loadingStockRequestPendingById)

  /* Approve purchase order */
  yield takeEvery("SUBMIT_PURCHASE_ORDER_APPROVE_REQUEST", sumbitPurchaseOrderApproveRequest)
  yield takeEvery("SUBMIT_PURCHASE_ORDER_WORK_FLOW_ACTION", submitPurchaseOrderWorkFlowAction);
}