const initialState = {
  loading: false,
  status: null,

  requestedDate: '',
  forWarehouse: '',
  requestedBy: '',
  requestDepartment: '',
  description: '',
  attachedFile: null,
  details: [
    /* {
      BrandId: 42
        Description: null
        MaterialId: 1043
        MaterialInstanceId: 7874
        RequestBaseQuantity: 0
        RequestQuantity: 2
        UomId: 117 
    }*/
  ],
  materialInstancesByWarehouse: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_REQUESTED_DATE_GOODS_DESTRUCTION':
      return {
        ...state,
        requestedDate: action.payload.requestedDate
      }

    case 'CHANGE_FOR_WAREHOUSE_GOODS_DESTRUCTION':
      return {
        ...state,
        forWarehouse: action.payload.forWarehouse
      }

    case 'CHANGE_REQUESTED_BY_GOODS_DESTRUCTION':
      return {
        ...state,
        requestedBy: action.payload.requestedBy
      }

    case 'CHANGE_REQUEST_DEPARTMENT_GOODS_DESTRUCTION':
      return {
        ...state,
        requestDepartment: action.payload.requestDepartment
      }

    case 'CHANGE_DESCRIPTION_GOODS_DESTRUCTION':
      return {
        ...state,
        description: action.payload.description
      }

    case 'CHANGE_ATTACHED_FILE_GOODS_DESTRUCTION':
      return {
        ...state,
        attachedFile: action.payload.attachedFile
      }

    case 'CHANGE_DETAILS_MATERIAL_GOODS_DESTRUCTION':
      return {
        ...state,
        details: [
          ...state.details,
          action.payload.receivedObj
        ]
      }

    case 'FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_SUCCEEDED':
      return {
        ...state,
        materialInstancesByWarehouse: changeFormatMaterialInstancesByWarehouse(action.payload)
        //materialInstancesByWarehouse: action.payload
      }

    case 'CLEAR_MATERIAL_INSTANCES_BY_WAREHOUSE':
      return {
        ...state,
        materialInstancesByWarehouse: []
      }

    case 'SHOW_SUBMIT_GOODS_DESTRUCTION_LOADING':
      return {
        ...state,
        loading: true
      }
    
    case 'SUBMIT_GOODS_DESTRUCTION_SUCCESS':
      return {
        ...state,
        status: 'success',
        loading: false,
      }

    case 'SUBMIT_GOODS_DESTRUCTION_FAILED':
      return {
        ...state,
        status: 'failed',
        loading: false,
      }

    case 'CLEAR_SUBMIT_GOODS_DESTRUCTION':
      return {
        ...state,
        requestedDate: '',
        forWarehouse: '',
        requestDepartment: '',
        description: '',
        attachedFile: null,
        details: [],

        status: null
      }

    case 'FETCH_GOODS_DESTRUCTION_BY_ID_SUCCESS':
      const {data} = action.payload
      return {
        ...state,
        requestedDate: changeFormatDate(data.prettyRequestedDate),
        forWarehouse: data.warehouseId,
        requestDepartment: data.requestDepartment,
        description: data.description,
        requestedBy: data.requestedBy,
        details: [...data.details],
      }

    default:
      return state
  }
};
      /* BrandId: 42
      Description: null
      MaterialId: 1043
      MaterialInstanceId: 7874
      RequestBaseQuantity: 0
      RequestQuantity: 2
      UomId: 117  */
export default reducer;

const changeFormatMaterialInstancesByWarehouse = (arr = []) => {
  const newArr = []
  if(arr && arr.length > 0){
    arr.forEach(item => {
      newArr.push({
        id: item.id,
        name: item.sku
      })
    })
  }
  return newArr
}

const changeFormatDate = (dateString) => {
  //hiện tại dd/mm/yyyy --> chuyển thành yyyy/mm/dd
  if(dateString){
      const twoDateLetter = dateString.slice(0, 2);
      const twoMonthLetter = dateString.slice(3, 5);
      const fourYearLetter = dateString.slice(6);
      //return `${twoMonthLetter}/${twoDateLetter}/${fourYearLetter}`;
      return `${fourYearLetter}-${twoMonthLetter}-${twoDateLetter}`
  }
  return null
}

/* const newMaterialArr = (arr = []) =>{
  newArr = []
  arr.forEach(item => {
    newArr.push({
      materialId
    })
  })
} */