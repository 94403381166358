import axios from 'axios'
import constants from 'src/reusable/constants'

export function fetchInventoryStockReportApi(payload) {
  const url =
    constants.api.url +
    'api/stock/GetReportWarehouse' +
    `?page=${payload?.page || 1}&pageSize=${payload?.pageSize || 10}&FromDate=${
      payload?.fromDate
    }` +
    `&ToDate=${payload?.toDate}&WarehouseId=${payload?.warehouseId || 0}` +
    `&MaterialId=${payload?.materialId || 0}`
  return axios.get(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

export function exportExcelInventoryStockReportApi(payload) {
  const url =
    constants.api.url +
    'api/stock/ExportReportWarehouse' +
    `?FromDate=${payload?.fromDate}` +
    `&ToDate=${payload?.toDate}&WarehouseId=${payload?.warehouseId || 0}` +
    `&MaterialId=${payload?.materialId || 0}`
  return axios.get(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}
