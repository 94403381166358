const initialState = {
  idFolder: null,
  loading: false,
  rootFolders: [],
  subFolders: [],
  currentFolder: null,
  submitNewFolderStatus: 0,
  uploadNewFileStatus: 0,
  uploadNewFolderStatus: 0,
  deleteFolderStatus: 0,
  genaralInfomation: {
    employeeCount: 0,
    notificationCount: 0,
    fileCount: 0,
    recruitmentCount: 0,
  }
};

const handbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_HANDBOOK_LOADING":
      return { ...state, loading: true };
    case "HIDE_HANDBOOK_LOADING":
      return { ...state, loading: false };
    case "DEFAULT_FOLDER":
      return initialState;
    case "LOAD_FOLDERS_FAILED": {
      return {
        ...state,
        loading: false,
      }
    };
    case "LOAD_FOLDERS_SUCCESS":
      let _rootFolders = [];
      const isExisted = state.rootFolders.findIndex(
        (root) => root.folderId == action.payload.folderId
      );
      if (isExisted == -1) {
        _rootFolders = [...state.rootFolders, action.payload];
      } else {
        _rootFolders = state.rootFolders.slice(0, isExisted + 1);
      }
      return {
        ...state,
        loading: false,
        currentFolder: action.payload,
        rootFolders: _rootFolders,
        subFolders: action.payload.subFolders,
        subFiles: action.payload.subFiles,
      };
    case "SUBMIT_NEW_FOLDER_SUCCESS":
      return { ...state, loading: false, submitNewFolderStatus: 1, idFolder: action.payload.data.id};
    case "UPLOAD_NEW_FILE_SUCCESS":
      return { ...state, loading: false, uploadNewFileStatus: 1 };
    case "UPLOAD_NEW_FOLDER_SUCCESS":
      return { ...state, loading: false, uploadNewFolderStatus: 1 };
    case "SUBMIT_NEW_FOLDER_FAILED":
      return { ...state, loading: false, submitNewFolderStatus: 2, idFolder: null};
    case "UPLOAD_NEW_FILE_FAILED":
      return { ...state, loading: false, uploadNewFileStatus: 2 };
    case "UPLOAD_NEW_FOLDER_FAILED":
      return { ...state, loading: false, uploadNewFolderStatus: 2 };
    case "SUBMIT_NEW_FOLDER_RESET":
      return { ...state, submitNewFolderStatus: 0 };
    case "UPLOAD_NEW_FILE_RESET":
      return { ...state, uploadNewFileStatus: 0 };
    case "UPLOAD_NEW_FOLDER_RESET":
      return { ...state, uploadNewFolderStatus: 0 };
    case "DELETE_FOLDER_RESET":
      return { ...state, deleteFolderStatus: 0 };
    case "SUBMIT_DELETE_FOLDER_SUCCESS":
      return { ...state, loading: false, deleteFolderStatus: 1 };
    case "SUBMIT_DELETE_FOLDER_FAILED":
      return { ...state, loading: false, deleteFolderStatus: 2 };
    case "GET_GENERAL_INFO_SUCESSED":
      return { ...state, loading: false, genaralInfomation: {
        ...action.payload,
      } };
    // case "UPLOAD_NEW_FILE_FAILED":
    //   return { ...state, loading: false, uploadNewFileStatus: 2 };
    // case "UPLOAD_NEW_FOLDER_FAILED":
    //   return { ...state, loading: false, uploadNewFolderStatus: 2 };
    default:
      return state;
  }
};

export default handbookReducer;
