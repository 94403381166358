const initialModalState = {
   modalMaterialId: "",
   modalMaterialCode: "",
   modalMaterialName: "",
   modalMaterialBaseUom: "",
   modalBrandId: "",
   modalBrandName: "",
   modalLotNumber: "",
   modalManufacturedDate: "",
   modalExpiryDate: "",
   modalUomId: "",
   modalUomName: "",
   modalUomQuantityType: "",
   modalBaseQuantity: "",
   modalQuantity: "",
   modalBaseQuantityOnHand: "",
   modalQuantityOnHand: "",
   modalDescription: "",

   modalUsedBaseUom: false,
   modalUsedBaseUomValue: "",
   modalUsedBaseUomKey: null,
   showRequestWorkFlowModal: false,

   showModal: false,
};

const initialState = {
   produceDate: "",
   produceType: "0",
   forWareHouse: "",
   warehouseName: "",
   produceBy: "",
   referenceNumber: "",
   attachedFile: null,
   AttachedFileKey: null,
   items: [],
   submitNewGoodsProduceStatus: 0,
   submitWorkFlowActionStatus: 0,
   description: "",

   selectedStockTransfer: "",
   selectedStockRequest: "",
   selectedPurchaseOrder: "",
   selectedWareHouse: "",

   selectedStoreId: "",
   storeName: "",
   fromDate: "",
   toDate: "",

   ...initialModalState,

   comboboxItems: [],
   suppliersComboboxItems: [],
   goodsProduceList: [],
   goodsProduceDetail: "",
   reportingList: [],

   modalValidationFailed: false,
   formValidationFailed: false,
   error: "",

   loading: false,

   showPrintAllBarcodesModal: false,
   barcodes: [],
   statusList: [],

   approveFlag: 0,
   createFlag: 0,
   deleteFlag: 0,
   editFlag: 0,
   viewFlag: 0,
};

const goodsProduceReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_AUTHO_MODULE_GOODS_PRODUCE':
         return {
            ...state,
            createFlag: action.payload.createFlag,
            approveFlag: action.payload.approveFlag,
            deleteFlag: action.payload.deleteFlag,
            editFlag: action.payload.editFlag,
            viewFlag: action.payload.viewFlag,
         }
      case "SET_STATUS_LIST":
         return {
            ...state,
            statusList: action.payload,
         };
      case "SHOW_GOODS_PRODUCE_LOADING":
         return {
            ...state,
            loading: true,
         };
      case 'FETCH_GOODS_PRODUCE_REPORT_SUCCESS':
         return {
            ...state,
            loading: false,
            reportingList: action.payload
         }
      case 'FETCH_GOODS_PRODUCE_REPORT_FAILED':
         return {
            ...state,
            loading: false,
            error: action.payload
         }
      case "FETCH_GOODS_PRODUCES_SUCCEEDED":
         return {
            ...state,
            goodsProduceList: action.payload,
            loading: false,
         };
      case "FETCH_GOODS_PRODUCE_DETAIL_SUCCEEDED":
         return {
            ...state,
            goodsProduceDetail: action.payload,
            loading: false,
         };
      case "SUBMIT_NEW_GOODS_PRODUCE_SUCCEEDED":
         return {
            ...state,
            submitNewGoodsProduceStatus: 1,
            loading: false,
         };

      case "SUBMIT_NEW_GOODS_PRODUCE_FAILED":
         return {
            ...state,
            submitNewGoodsProduceStatus: 2,
            loading: false
         };
      case 'UPDATE_SELECTED_DATE_FROM_GOODS_PRODUCE':
         return {
            ...state,
            fromDate: action.payload
         }
      case 'UPDATE_SELECTED_DATE_TO_GOODS_PRODUCE':
         return {
            ...state,
            toDate: action.payload
         }

      case "CLEAR_DATA_GOODS_PRODUCE":
         return {
            ...state,
            submitWorkFlowActionStatus: 0,
            submitNewGoodsProduceStatus: 0,
            forWareHouse: "",
            items: [],
            produceDate: "",
            produceBy: "",
            description: "",
            attachedFile: "",
         };

      case "CLEAR_MODAL_GOODS_PRODUCE":
         return {
            ...state,
            modalDescription: "",
         };

      case "SHOW_REQUEST_WORK_FLOW_MODAL_GOODS_PRODUCE":
         return {
            ...state,
            showRequestWorkFlowModal: true,
         };

      case "UPDATE_MODAL_VALIDATION_GOODS_PRODUCE":
         return {
            ...state,
            modalValidationFailed: action.payload,
         };

      case "INSERT_GOODS_PRODUCE_ITEM":
         let items = state.items;
         items.push({
            id: state.modalMaterialId,
            code: state.modalMaterialCode,
            name: state.modalMaterialName,
            brandId: state.modalBrandId,
            uomId: state.modalUomId,
            uomCode: state.modalUomCode,
            uomName: state.modalUomName,
            quantity: state.modalQuantityOnHand,
            quantityOnHand: state.modalQuantityOnHand,
         });

         return {
            ...state,
            items,
         };

      case "UPDATE_MODAL_QUANTITY_ON_HAND_GOODS_PRODUCE":
         return {
            ...state,
            modalQuantityOnHand: action.payload,
         };

      case "UPDATE_REQUEST_WORK_FLOW_MODAL_DESCRIPTION_GOODS_PRODUCE": {
         return {
            ...state,
            modalDescription: action.payload,
         }
      }

      case "UPDATE_MODAL_MATERIAL_GOODS_PRODUCE":
         return {
            ...state,
            modalMaterialId: action.payload.id,
            modalMaterialCode: action.payload.code,
            modalMaterialName: action.payload.name,
            modalMaterialBaseUom: action.payload.baseUom,
         };

      case "SUBMIT_GOODS_PRODUCE_WORK_FLOW_SUCCEEDED": {
         return {
            ...state,
            submitWorkFlowActionStatus: 1,
         }
      }
      case "SUBMIT_STOCK_DESTRUCTION_WORK_FLOW_FAILED": {
         return {
            ...state,
            submitWorkFlowActionStatus: 2,
         }
      }

      case "UPDATE_MODAL_UOM_GOODS_PRODUCE":
         return {
            ...state,
            modalUomName: action.payload.name,
            modalUomId: action.payload.id,
            modalUomCode: action.payload.code,
            modalUomQuantityType: action.payload.quantityType,
         };

      case "UPDATE_MODAL_BRAND":
         return {
            ...state,
            modalBrandId: action.payload.id,
            modalBrandName: action.payload.name,
            modalUomId: "",
            modalUomName: "",
         };

      case "UPDATE_PRODUCE_BY":
         return {
            ...state,
            produceBy: action.payload,
         };

      case "UPDATE_PRODUCE_DATE":
         return {
            ...state,
            produceDate: action.payload,
         };

      case "UPDATE_DESCRIPTION_GOODS_PRODUCE":
         return {
            ...state,
            description: action.payload,
         };

      case "UPDATE_FOR_WAREHOUSE_GOODS_PRODUCE":
         return {
            ...state,
            forWareHouse: parseInt(action.payload),
         };
      case "UPDATE_FORM_VALIDATION_GOODS_PRODUCE":
         return {
            ...state,
            formValidationFailed: action.payload,
         }

      case "UPDATE_ATTACHED_FILE_GOODS_PRODUCE":
         return {
            ...state,
            attachedFile: action.payload,
         };

      case "GENERATE_ORIGINAL_ITEMS_GOODS_PRODUCE":
         let originalItems = [];
         let materials = action.payload;
         materials &&
            materials.forEach((item) => {
               originalItems.push({
                  id: item.id,
                  value: item.name + " (" + item.code + ") ",
                  code: item.code,
                  name: item.name,
                  image: item.image,
                  baseUomName: item.baseUomName,
               });
            });
         return {
            ...state,
            comboboxItems: originalItems,
            modalValidationFailed: false,
            formValidationFailed: false,
         };

      case "SHOW_MODAL_GOODS_PRODUCE":
         return {
            ...state,
            showModal: true,
            modalManufacturedDate: "2021-01-01",
            modalExpiryDate: "2021-12-31",
            modalLotNumber: "LOT010621",
         };
      case "HIDE_MODAL_GOODS_PRODUCE":
         return {
            ...state,
            ...initialModalState,
            modalUsedBaseUomKey: Date.now(),
            modalValidationFailed: false,
         };
      case 'UPDATE_SELECTED_WAREHOUSE_GOODS_PRODUCE':
         return {
            ...state,
            forWareHouse: action.payload.warehouseId,
            warehouseName: action.payload.warehouseName,
         }

      default:
         return state;
   }
};

export default goodsProduceReducer;
