const initialState = {
  isLoading: false,
  averageTotalBill: 0,
  sumGrossAmount: 0,
  sumDiscountAmount: 0,
  sumNetAmount: 0,
  details: []
}

const revenueDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_LOADING_REVENUE_DATE': 
      return {
        ...state,
        isLoading: true,
      }
    case 'LOAD_REVENUE_DATE_SUCCESS': 
      return {
        ...action.payload.data,
        isLoading: false,
      }
    
    case 'LOAD_REVENUE_DATE_SUCCESS_BEFORE':
      const { data: date } = action.payload
      if (date.details.length > 0) {
        const netAmountArr = date.details.map(item => item.netAmount)
        const prettyOrderDateArr = date.details.map(item => item.prettyOrderDate)
        return{
          ...date,
          storeName: date.details[0].storeName,
          netAmountArr,
          prettyOrderDateArr
        }
      }
      return {...date}

    default:
      return state;
  }
};

export default revenueDateReducer;
