const initialModalState = {
   showModal: false,
   modalMaterialId: "",
   modalMaterialCode: "",
   modalMaterialName: "",
   modalMaterialBaseUom: "",
   modalBrandId: "",
   modalBrandName: "",
   modalLotNumber: "",
   modalRequiredDate: "",
   modalUomId: "",
   modalUomName: "",
   modalStockTakeQuantity: "",
   modalStockTakeBaseQuantity: "",
   modalNote: "",
   modalMaterialInstanceId: "",
   modalMaterialInstanceSKU: "",
   modalDescription: "",
   showRequestWorkFlowModal: false,
};

const initialState = {
   loading: false,
   ...initialModalState,
   modalValidationFailed: false,
   stockTakeWarehouse: "",
   materialInstancesByWarehouse: [],
   items: [],
   stockTakeDate: "",
   stockTakeType: "",
   description: "",
   approveStockTakeStatus: 0,
   stockTakeDetail: "",
   formValidationFailed: false,
   submitWorkFlowActionStatus: 0,
   submitNewStockTakeStatus: 0,

   approveFlag: 0,
   createFlag: 0,
   deleteFlag: 0,
   editFlag: 0,
   viewFlag: 0,
};

const stockTakeReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_AUTHO_MODULE_STOCK_TAKE':
         return {
            ...state,
            createFlag: action.payload.createFlag,
            approveFlag: action.payload.approveFlag,
            deleteFlag: action.payload.deleteFlag,
            editFlag: action.payload.editFlag,
            viewFlag: action.payload.viewFlag,
         }
      case "FETCH_STOCK_TAKE_DETAIL_SUCCEEDED":
         return {
            ...state,
            stockTakeDetail: action.payload,
            loading: false,
         };
      case "FETCH_STOCK_TAKE_SUCCEEDED":
         return {
            ...state,
            stockTakes: action.payload,
            loading: false,
         };
      case "UPDATE_STOCK_TAKE_TYPE":
         return {
            ...state,
            stockTakeType: action.payload,
         };
      case "UPDATE_STOCK_TAKE_DATE":
         return {
            ...state,
            stockTakeDate: action.payload,
         };
      case "SUBMIT_NEW_STOCK_TAKE_SUCCEEDED":
         return {
            ...state,
            submitNewStockTakeStatus: 1,
            loading: false
         };
      case 'SUBMIT_NEW_STOCK_TAKE_FAILED':
         return {
            ...state,
            submitNewStockTakeStatus: 2,
            loading: false
         }
      case 'SUBMIT_STOCK_TAKE_WORK_FLOW_SUCCEEDED':
         return {
            ...state,
            submitWorkFlowActionStatus: 1,
            loading: false,
         }
      case 'SUBMIT_STOCK_TAKE_WORK_FLOW_FAILED':
         return {
            ...state,
            submitWorkFlowActionStatus: 2,
            loading: false,
         }
      case "APPROVE_STOCK_TAKE_SUCCESS":
         return {
            ...state,
            approveStockTakeStatus: 1,
            submitWorkFlowActionStatus: 3,
            loading: false
         };
      case 'APPROVE_STOCK_TAKE_FAILED': {
         return {
            ...state,
            approveStockTakeStatus: 2,
            loading: false,
         }
      }
      case "SHOW_STOCK_TAKE_LOADING":
         return {
            ...state,
            loading: true,
         };
      case "UPDATE_MODAL_STOCK_TAKE_QUANTITY":
         return {
            ...state,
            modalStockTakeQuantity: action.payload,
         };
      case "UPDATE_MODAL_STOCK_TAKE_BASE_QUANTITY":
         return {
            ...state,
            modalStockTakeBaseQuantity: action.payload,
         };
      case "UPDATE_MODAL_MATERIAL_INSTANCE_SKU_STOCK_TAKE":
         return {
            ...state,
            modalMaterialInstanceSKU: action.payload.sku,
            modalMaterialInstanceId: action.payload.id,
         };
      case "FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_SUCCEEDED":
         console.log("FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_SUCCEEDED", action.payload);
         return {
            ...state,
            materialInstancesByWarehouse: action.payload,
         };
      case "FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_FAILED":
         console.log("FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_FAILED", action.payload);
         break;
      case "UPDATE_WAREHOUSE":
         return {
            ...state,
            stockTakeWarehouse: parseInt(action.payload),
            items: []
         };
      case "UPDATE_MODAL_BRAND":
         return {
            ...state,
            modalBrandId: action.payload.id,
            modalBrandName: action.payload.name,
            modalUomId: "",
            modalUomName: "",
         };
      case "UPDATE_MODAL_UOM_STOCK_TAKE":
         return {
            ...state,
            modalUomName: action.payload.name,
            modalUomId: action.payload.id,
            defaultUomSelected: false,
         };
      case "UPDATE_MODAL_MATERIAL_STOCK_TAKE":
         return {
            ...state,
            modalMaterialId: action.payload.id,
            modalMaterialCode: action.payload.code,
            modalMaterialName: action.payload.name,
            modalMaterialBaseUom: action.payload.baseUom,
            defaultBrandSelected: true,
            defaultUomSelected: true,
         };
      case "GENERATE_ORIGINAL_ITEMS_STOCK_TAKE":
         let originalItems = [];
         let materialState = action.payload;
         materialState &&
            materialState.forEach((item) => {
               originalItems.push({
                  id: item.id,
                  value: "(" + item.code + ") " + item.name,
                  code: item.code,
                  name: item.name,
                  image: item.image,
                  baseUomName: item.baseUomName,
               });
            });
         return {
            ...state,
            comboboxItems: originalItems,
            modalValidationFailed: false,
            formValidationFailed: false,
         };
      case "SHOW_REQUEST_WORK_FLOW_MODAL_STOCK_TAKE":
         return {
            ...state,
            showRequestWorkFlowModal: true,
         };
      case "CLEAR_DATA_STOCK_TAKE":
         return {
            ...initialState,
            submitNewStockTakeStatus: 0,
            submitWorkFlowActionStatus: 0,
            approveStockTakeStatus: 0,
            items: [],
         };
      case "CLEAR_FIELDS_STOCK_TAKE":
         return {
            ...state,
            stockTakeDate: "",
            description: "",
            stockTakeWarehouse: "",
            stockTakeType: "",
            items: [],
         }
      case "UPDATE_MODAL_VALIDATION_STOCK_TAKE":
         return {
            ...state,
            modalValidationFailed: action.payload,
         };
      case 'UPDATE_REQUEST_WORK_FLOW_MODAL_DESCRIPTION_STOCK_REQUEST':
         return {
            ...state,
            modalDescription: action.payload
         }
      case 'CLEAR_MODAL_STOCK_TAKE':
         return {
            ...state,
         }
      case "SHOW_MODAL_STOCK_TAKE":
         return {
            ...state,
            showModal: true,
         };
      case "HIDE_MODAL_STOCK_TAKE":
         return {
            ...state,
            ...initialModalState,
            modalValidationFailed: false,
         };

      case "INSERT_STOCK_TAKE_ITEM":
         let items = state.items;
         items.push({
            id: state.modalMaterialId,
            code: state.modalMaterialCode,
            name: state.modalMaterialName,
            brandId: state.modalBrandId,
            brandName: state.modalBrandName,
            uomId: state.modalUomId,
            uomName: state.modalUomName,
            stockTakeBaseQuantity: state.modalStockTakeBaseQuantity,
            stockTakeQuantity: state.modalStockTakeQuantity,
            note: state.modalNote,
            sku: state.modalMaterialInstanceSKU,
            materialInstanceId: state.modalMaterialInstanceId,
         });
         return {
            ...state,
            items,
         };
      case "UPDATE_MODAL_NOTE_STOCK_TAKE":
         return {
            ...state,
            modalNote: action.payload,
         };
      case "UPDATE_DESCRIPTION":
         return {
            ...state,
            description: action.payload,
         };
      case 'UPDATE_FORM_VALIDATION_STOCK_TAKE': {
         return {
            ...state,
            formValidationFailed: action.payload
         }
      }
      default:
         return state;
   }
};

export default stockTakeReducer;
