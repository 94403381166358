const initialState = {
    loading: false,
    saleOrder: [],
    saleVoucherOrder: [],
    totalPage: 0,
    submitNewsaleOrderStatus: 0,
    updatesaleOrderStatus: 0,
    approvesaleOrderStatus: 0,
    saleOrderDetais: null,
    voucherOrderToDeliver: [],
    productionDiary: [],
    statusNewProdDiary: 0,
    statusUpdateProdDiary: 0,
    productionDiaryDetail: null,
}

const saleOrderReducer = (state = initialState, action) => {  
    switch (action.type) {
        case "FETCH_PRODUCTION_DIARY_DETAIL_SUCCEEDED":
            return {
               ...state,
               productionDiaryDetail: action.payload,
               loading: false,
            };
        case "UPDATED_PRODUCTION_DIARY_SUCCEEDED":
            return {
                ...state,
                statusUpdateProdDiary: 1,
                loading: false,
            };
        case "UPDATED_PRODUCTION_DIARY_FAILED":
            return {
                ...state,
                statusUpdateProdDiary: 2,
                loading: false,
            };
        case "CREATE_NEW_PRODUCTION_DIARY_SUCCEEDED":
            return {
               ...state,
               statusNewProdDiary: 1,
               loading: false,
            };
        case "CREATE_NEW_PRODUCTION_DIARY_FAILED":
            return {
                ...state,
                statusNewProdDiary: 2,
                loading: false,
            };
        case "FETCH_PRODUCTION_DIARY_SUCCEEDED":
            return {
               ...state,
               productionDiary: action.payload.data,
               totalPage: action.payload.totalCount,
               loading: false,
            };
        case "FETCH_SALE_ORDERS_DETAIL_SUCCEEDED":
            return {
               ...state,
               saleOrderDetais: action.payload,
               loading: false,
            };
        case 'FETCH_SALE_ORDERS_SUCCESSED':
            return {
                ...state,
                loading: false,
                saleOrder: action.payload?.data,
                totalPage: action.payload?.totalCount,
            }
        case 'FETCH_SALE_VOUCHER_ORDER_SUCCESSED':
            return {
                ...state,
                loading: false,
                saleVoucherOrder: action.payload,
            }
        case 'FETCH_SALE_VOUCHER_ORDER_TO_DELIVER_SUCCESSED':
            return {
                ...state,
                loading: false,
                voucherOrderToDeliver: action.payload,
            }
        case 'SHOW_SALE_ORDER_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'SET_DEFAULT_STATUS_SALE_ORDERS':
            return {
                ...state,
                statusNewProdDiary: 0,
                statusUpdateProdDiary: 0,
                submitNewsaleOrderStatus: 0,
                updatesaleOrderStatus: 0,
                approvesaleOrderStatus: 0,
                loading: false,
            }

        case 'DEFAULT_DATA_SALE_ORDER':
            return initialState;

        default:
            return state
    }
}

export default saleOrderReducer;