const initialState = {
    isLoading: false,
    sumRevenueGrossAmount: 0,
    sumRevenueNetAmount: 0,
    sumRevenueDiscount: 0,
    voucherOrder: 0,
    details: [],
}
  
const revenueGeneralReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_LOADING_REVENUE_HOUR':
            return {
                ...state,
                isLoading: true,
            }  
        case 'LOAD_REVENUE_HOUR_SUCCESS':
            return {
                ...action.payload.data,
                isLoading: false,
            }  
        default:
            return state;
    }
  };
  
  export default revenueGeneralReducer;