const initialState = {
  stockRequestDetail: {
    details: []
  },
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "FETCH_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER_SUCCESS":
      return {
        ...state,
        stockRequestDetail: action.payload.data,
        loading: false,
      }

    case "FETCH_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER_FAILED":
      return {
        ...state,
        loading: false,
      }

    case 'CLEAR_SUBMIT_GOODS_TRANSFER':
      return {
        ...state,
        stockRequestDetail: {
          details: []
        }
      }

    default:
      return state;
  }
};

export default reducer;