import axios from 'axios'
import constants from '../reusable/constants'

export function fetchMaterialSalesReportApi(payload) {
    return axios.get(
        constants.api.url + 'api/MaterialSalesReport/GetMaterialSalesReport?fromDate=' + payload.fromDate + '&toDate=' + payload.toDate + '&storeId=' + payload.storeId,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    )
}


