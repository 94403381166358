import axios from "axios";
import constants from "../reusable/constants";

export function fetchFoldersApi(payload) {
  return axios.get(
    constants.api.url + "api/HRApp/GetFolderAsync?folderId=" + payload.folderId,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function createFolderApi(payload) {
  return axios.post(
    constants.api.url + "api/HRApp/CreateFolderAsync",
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function uploadFileApi(payload) {
  return axios.post(constants.api.url + "api/HRApp/CreateFileAsync", payload, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadFolderApi(payload) {
  return axios.post(constants.api.url + "api/HRApp/UploadFolder", payload, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function changeNameFolderApi(payload) {
  return axios.post(constants.api.url + "api/HRApp/ChangeNameFolderAsync", JSON.stringify(payload), {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
}

export function changeNameFileApi(payload) {
  return axios.post(constants.api.url + "api/HRApp/RenameFile", JSON.stringify(payload), {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
}

export function deleteFileApi(payload) {
  return axios.post(constants.api.url + "api/HRApp/DeleteFile?id=" + payload.fileId, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}

export function deleteFolderApi(payload) {
  return axios.post(constants.api.url + "api/HRApp/DeleteFolder?id=" + payload.folderId, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}

export function pinNotifyApi(payload) {
  console.log("IT WORKS");
  return axios.post(constants.api.url + "api/HRApp/PinNotification?id=" + payload.fileId, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}

export function fecthGeneralInfoHRM() {
  return axios.get(constants.api.url + "api/HRApp/GetGeneralInformationHRM", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
}
