import { combineReducers } from "redux"
import webGroup from './webGroupReducer'
import menu from './webGroupMenuReducer'
import staff from './webGroupStaffReducer'
import app from './webGroupAppReducer'
import listEmployeeStatus1 from './webGroupEmployeeStatus1Reducer'
import store from './webGroupStoreReducer'
import warehouse from './webGroupWarehouseReducer'
import createUseGroupPermission from './webGroupCreateUseGroupPermissionReducer'
import createUserGroupDataAccess from './webGroupCreateUserGroupDataAccessReducer'
import updateUserGroupApp from './webGroupUpdateUserGroupAppReducer'



const rootWebGroupReducer = combineReducers({
    webGroup,
    menu,
    staff,
    app,
    listEmployeeStatus1,
    store,
    warehouse,
    createUseGroupPermission,
    createUserGroupDataAccess,
    updateUserGroupApp,
})

export default rootWebGroupReducer

export const changeKeyName = (keyId, keyName, arr) =>{
    //thay đổi key 'warehouseId' và 'warehouseName' thành 'id' và 'name
    //để dễ xử lý chung ở component
    if(Array.isArray(arr)){
        const newArr = []
        arr.forEach(item => {
            newArr.push({
                id: item[keyId],
                name: item[keyName]
            })
        })
        return newArr
    }
    else{
        console.log('parameter must be a array');
    }
}

export const changeKeyNameForEmployee = (arr = []) => {
    if(Array.isArray(arr)){
        const newArr = []
        arr.forEach(item => {
            newArr.push({
                uId: item.uId,
                displayName: `${item.lastName} ${item.firstName}`
            })
        })
        return newArr
    }
    else{
        console.log('parameter must be a array');
    }
}