const initialState = {
    loading: false,
    appList: [],
    listProducts: [],
    searchProducts: '',
    statusMenu: false,
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STATUS_MENU':
            return {
                ...state,
                statusMenu: action.payload,
            }
        case 'SET_SEARCH_PRODUCTS':
            return {
                ...state,
                searchProducts: action.payload,
            }
        case 'CLEAR_LIST_PRODUCT_WITH_CUSTOMER':
            return {
                ...state,
                listProducts: [],
            }
        case 'FETCH_LIST_PRODUCT_WITH_CUSTOMERS_SUCCESSED':
            return {
                ...state,
                listProducts: action.payload[0]?.products,
                loading: false,
            }
        case 'SHOW_APP_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'FETCH_APP_SUCCESS':
            return {
                ...state,
                appList: action.payload.data,
                loading: false,
                message: ''
            }

        case 'FETCH_APP_FAILED':
            return {
                ...state,
                loading: false,
                message: action.payload
            }

        default:
            return state
    }
}

export default appReducer