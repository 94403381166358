import axios from 'axios'
import constants from '../reusable/constants'

export function fetchStockTakeApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockTake?uId=' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockTakeDetailApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockTake/' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function createStockTakeApi(payload) {
   return axios.post(
      constants.api.url + 'api/stockTake',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function approveStockTakeApi(payload) {
  console.log("DATA APPROVE", payload)
   return axios.post(
      constants.api.url + 'api/stockTake/ApproveStockTake',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function approveRequestSend(payload){
    return axios.post(
        constants.api.url + 'api/Workflow/ApproveRequest',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}
