const initialState = {
  stockPendingRequestList: [],
  loading: false,
}

const newStockPendingRequestList = (arr = []) => {
  const checkedArr = arr.filter(item => item.checked === true)
  const noCheckedArr = arr.filter(item => item.checked === false)
  return [...checkedArr, ...noCheckedArr]
}

const pendingStockRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_STOCK_PENDING_REQUEST_LOADING":
    case 'SHOW_STOCK_PENDING_REQUEST_BY_ID_LOADING':
      return {
        ...state,
        loading: true,
      }

    case "FETCH_STOCK_PENDING_REQUEST_SUCCEEDED":
      return {
        ...state,
        stockPendingRequestList: action.payload,
        loading: false,
      }

    case "FETCH_STOCK_PENDING_REQUEST_FAILED":
    case 'FETCH_STOCK_PENDING_REQUEST_BY_ID_FAILED':
      return {
        ...state,
        loading: false,
      }

    case 'FETCH_STOCK_PENDING_REQUEST_BY_ID_SUCCEEDED':
      return {
        ...state,
        stockPendingRequestList: newStockPendingRequestList(action.payload),
        loading: false,
      }

    default:
      return state;
  }
};

export default pendingStockRequestReducer;
