export const workFlowTypes = {
   'STOCK_REQUEST': 1,
   'PURCHASE_ORDER': 2,
   'GOODS_RECEIVED': 3,
   'STOCK_TAKE': 4,
   'STOCK_TRANSFER': 5,
   'STOCK_DESTRUCTION': 6,
   'GOODS_PRODUCE': 7,

   'GOODS_TRANSFER': 5,
   'GOODS_DESTRUCTION': 6,
   'VIC_RMD_EXPORT_MATERIAL': 8,
}