const initialState = {
  loading: false,
  status: null
};

const orderDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_DELETE_PURCHASE_ORDER_LOADING':
      return {
        ...state,
        loading: true
      }

    case 'DELETE_PURCHASE_ORDER_SUCCEEDED':
      return {
        ...state,
        status: 'success',
        loading: false,
      }

    case 'DELETE_PURCHASE_ORDER_FAILED':
      return {
        ...state,
        status: 'failed',
        loading: false,
      }

    case 'CLEAR_DELETE_PURCHASE_ORDER_STATUS':
      return {
        ...state,
        status: null,
      }

    default:
      return state
  }
};

export default orderDeleteReducer;
