import React, { createContext, useReducer } from 'react';

export const UploadFileContext = createContext();

export const UploadFileContextProvider = (props) => {
    const initialState = {
        isOpen: false,
        listFile: [],
        isLoading: false,
        indexSuccess: [],
    };

    const [stateUpFile, dispatchUpFile] = useReducer((state, action) => {
        switch (action.type) {
            case "SET_LIST_FILE":
                return {
                    ...state,
                    isOpen: true,
                    isLoading: true,
                    listFile: action.payload,
                };
            case "SET_INDEX_CHECKED":
                return {
                    ...state,
                    indexSuccess: [...state.indexSuccess, action.payload],
                };
            case "SET_SUCCESS_UPLOAD":
                return {
                    ...state,
                    isLoading: false,
                }
            case "DEFAULT_VALUE":
                return initialState;
            default:
                return state;
        }
    }, initialState)

    return (
        <UploadFileContext.Provider value={{stateUpFile, dispatchUpFile}}>
            { props.children }
        </UploadFileContext.Provider>
    )
}