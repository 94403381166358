import {changeKeyName} from './rootWebGroupReducer'

const initialState = {
    listDataAccessStore: [],
    storeLoading: false,
    message: '',
}

const webGroupStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_DATA_ACCESS_STORE_BY_WEB_GROUP_ID':
            return {
                ...state,
                storeLoading: true,
            }

        case 'FETCH_DATA_ACCESS_STORE_BY_WEB_GROUP_ID_SUCCESS':
            return {
                ...state,
                listDataAccessStore: changeKeyName('storeId', 'storeName', action.payload.data),
                storeLoading: false,
                message: ''
            }

        case 'FETCH_DATA_ACCESS_STORE_BY_WEB_GROUP_ID_FAILED':
            return {
                ...state,
                storeLoading: false,
                message: action.payload
            }

        default:
            return state
    }
}

export default webGroupStoreReducer