import axios from 'axios'
import constants from 'src/reusable/constants'

export function fetchGoodTransferListApi(payload) {
  const url =
    constants.api.url +
    'api/GoodsReceived/GetGoodsTransfers' +
    `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}` +
    `&fromWarehouse=${payload.fromWarehouse || 0}&toWarehouse=${
      payload.toWarehouse || 0
    }`

  return axios.get(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

export function fetchGoodTransferDetailApi(payload) {
  const url =
    constants.api.url +
    'api/GoodsReceived/GetGoodsTransferDetail/' +
    `${payload}`
  return axios.get(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

// GET ALL WAREHOUSE BY UID
export function fetchAllWarehouseByUIdApi(payload) {
  const url =
    constants.api.url +
    'api/GoodsReceived/GetAllWarehousesTP?UId=' +
    `${payload}`
  return axios.get(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

// GET ALL PRODUCT BY WAREHOUSE TP (Lấy tất cả sản phẩm để chuyển kho)
export function fetchAllProductsTPApi() {
  const url = constants.api.url + 'api/GoodsReceived/GetProductsTP'
  return axios.get(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

// CREATE NEW GOOD TRANSFER (Tạo mới phiếu chuyển hàng)
export function submitNewGoodTransferApi(payload) {
  const url = constants.api.url + 'api/GoodsReceived/CreateGoodsTransfer'
  return axios.post(url, payload, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}
