const initialModalState = {
  modalMaterialId: "",
  modalMaterialCode: "",
  modalMaterialName: "",
  modalMaterialBaseUom: "",
  modalBrandId: "",
  modalBrandName: "",
  modalRequiredDate: "",
  modalUomId: "",
  modalUomName: "",
  modalBaseStockQuantity: "",
  modalRequestQuantity: "",
  modalRequestBaseQuantity: "",
  modalNote: "",
  modalUomQuantityType: "",
  modalUsedBaseUom: false,
  modalUsedBaseUomValue: "",
  modalUsedBaseUomKey: null,

  showModal: false,
};

const initialRequestWorkFlowModalState = {
  showRequestWorkFlowModal: false,
  modalDescription: "",
};

const initialState = {
  requestedDate: "",
  forWarehouse: "",
  requestedBy: "",
  requestDepartment: "",
  attachedFile: "",
  items: [],
  submitNewStockRequestStatus: 0,
  updateStockRequestStatus: 0,
  submitWorkFlowActionStatus: 0,
  description: "",
  ...initialModalState,
  ...initialRequestWorkFlowModalState,
  showModal: false,
  // showRequestWorkFlowModal: false,
  defaultBrandSelected: true,
  defaultUomSelected: true,
  comboboxItems: [],
  stockRequestList: [],
  stockRequestDetail: null,
  inventoryCheckDetail: null,
  modalValidationFailed: false,
  formValidationFailed: false,
  approveFlag: 0,
  createFlag: 0,
  deleteFlag: 0,
  editFlag: 0,
  viewFlag: 0,
  loading: false,
  listStatusStockRequest: [],
  listMaterialFilter: [],
  inventoryCheckRequest: {
    totalPages: 0,
    listInventoryCheckRequest: [],
  },
  statusDeleted: 0,
};

const inventoryCheckStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTHO_MODULE_STOCK_REQUEST':
      return {
        ...state,
        createFlag: action.payload.createFlag,
        approveFlag: action.payload.approveFlag,
        deleteFlag: action.payload.deleteFlag,
        editFlag: action.payload.editFlag,
        viewFlag: action.payload.viewFlag,
      }
    case "UPDATE_MODAL_BASE_QUANTITY_ON_HAND_STOCK_REQUEST":
      return {
        ...state,
        modalRequestBaseQuantity: action.payload,
      };
    case "UPDATE_MODAL_QUANTITY_ON_HAND_STOCK_REQUEST":
      return {
        ...state,
        modalRequestQuantity: action.payload,
      };
    case "UPDATE_MODAL_USED_BASE_UOM_STOCK_REQUEST":
      return {
        ...state,
        modalUsedBaseUom: !state.modalUsedBaseUom,
        modalUsedBaseUomValue: state.modalUsedBaseUom == true ? "" : "danger",
        modalRequestQuantity: "",
        modalRequestBaseQuantity: "",
      };
    case "APPROVE_STOCK_TAKE_SUCCESS":
      return {
        ...state,
        approveStockTakeStatus: 1,
        submitWorkFlowActionStatus: 3,
        loading: false
      };
    case 'APPROVE_STOCK_TAKE_FAILED': {
      return {
        ...state,
        approveStockTakeStatus: 2,
        loading: false,
      }
    }
    case 'SUBMIT_STOCK_TAKE_WORK_FLOW_SUCCEEDED':
      return {
         ...state,
         submitWorkFlowActionStatus: 1,
         loading: false,
      }
    // case "SHOW_STOCK_REQUEST_LOADING":
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    //   case "OFF_STOCK_REQUEST_LOADING":
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    // case "UPDATE_MODAL_VALIDATION_STOCK_REQUEST":
    //   return {
    //     ...state,
    //     modalValidationFailed: action.payload,
    //   };

    // case "UPDATE_FORM_VALIDATION_STOCK_REQUEST":
    //   return {
    //     ...state,
    //     formValidationFailed: action.payload,
    //   };

    case "CLEAR_DATA_STOCK_REQUEST":
      return {
        //  ...initialState,
        //  submitNewStockRequestStatus: 0,
        //  updateStockRequestStatus: 0,
        ...state,
         submitWorkFlowActionStatus: 0,
        //  items: [],
      };
    case 'FETCH_INVENTORY_CHECK_BY_FILTER_SUCCEEDED':
      console.log("FETCH_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", action.payload);
      return {
        ...state,
        inventoryCheckRequest: {
          totalPage: action.payload.totalPage,
          listInventoryCheckRequest: action.payload.data,
        },
        loading: false,
      };
    case 'FETCH_CHECK_INVENTORY_SUCCEEDED':
      console.log("FETCH_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", action.payload);
      return {
        ...state,
        inventoryCheckRequest: {
          totalPage: action.payload.totalPage,
          listInventoryCheckRequest: action.payload.data,
        },
        loading: false,
      };
    case 'FETCH_CHECK_INVENTORY_FAILED':
      console.log("FETCH_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", action.payload);
      break
    case 'FETCH_INVENTORY_CHECK_BY_FILTER_FAILED':
      console.log(action.payload)
      break
    case 'CREATE_INVENTORY_CHECK_BY_FILTER_SUCCEEDED':
      console.log("CREATE_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", action.payload);
      return { ...state, submitNewStockRequestStatus: 1, loading: false }
    case 'CREATE_INVENTORY_CHECK_BY_FILTER_FAILED':
      console.log("CREATE_INVENTORY_CHECK_BY_FILTER_FAILED", action.payload);
      break
    case 'FETCH_CHECK_REQUEST_DETAIL_SUCCEEDED':
      console.log("FETCH_CHECK_REQUEST_DETAIL_SUCCEEDED", action.payload);
      return {
        ...state,
        inventoryCheckDetail: action.payload,
        loading: false,
      }
    case 'FETCH_CHECK_REQUEST_DETAIL_FAILED':
      console.log("FETCH_CHECK_REQUEST_DETAIL_FAILED", action.payload);
      break
    case 'UPDATE_INVENTORY_CHECK_BY_FILTER_SUCCEEDED':
      console.log("UPDATE_INVENTORY_CHECK_BY_FILTER_SUCCEEDED", action.payload);
      return { ...state, submitNewStockRequestStatus: 1, loading: false }
    case 'UPDATE_INVENTORY_CHECK_BY_FILTER_FAILED':
      console.log("UPDATE_INVENTORY_CHECK_BY_FILTER_FAILED", action.payload);
      break
    case 'SET_DEFAULT_INVENTORY_CHECK_DETAIL':
      return { ...state, inventoryCheckDetail: null, submitNewStockRequestStatus: 0 }
    case 'APPROVE_REQUEST_SEND_SUCCEEDED':
      console.log("APPROVE_REQUEST_SEND_SUCCEEDED", action.payload);
      return { ...state, submitNewStockRequestStatus: 1, loading: false }
    case 'APPROVE_REQUEST_SEND_FAILED':
      console.log("APPROVE_REQUEST_SEND_FAILED", action.payload);
      break
      case "SHOW_STOCK_TAKE_LOADING":
        return {
           ...state,
           loading: true,
        };
    default:
      return state;
  }
};

export default inventoryCheckStockReducer;
