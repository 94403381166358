const initialModalState = {
  modal_brand_name: null,
  modal_code: null,
  modal_material_name: null,
  modal_material_id: null,
  modal_brand_id: null,
  modal_id: null,
  modal_description: null,
  modal_status: null,

  materialList: [],
  brandList: [],
};

const initialState = {
  loading: false,
  materialBrands: [],
  ...initialModalState,
  modalValidationFailed: false,
  submitNewMaterialBrandStatus: "",
  materialComboboxItems: [],
  brandComboboxItems: [],
  totalPage: 0,

  //filter
  pageSize: 10,
  currentPage: 1,
  name: null,
  code: null,
  status: 1,
};

const materialBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MATERIAL_SUCCEEDED": {

      let array = action.payload;
      let newArr = array.map((item) => {
        return {
          value: item.id,
          name: item.name,
        };
      });
      return {
        ...state,
        materialList: newArr,
      };
    }

    case "FETCH_ALL_BRANDS_SUCCEEDED":
      let array = action.payload;
      let newArr = array.map((item) => {
        return {
          value: item.id,
          name: item.name,
        };
      });
      return {
        ...state,
        brandList: newArr,
      };

    case "SET_MODAL_CREATE_NEW_BRAND_MATERIAL_CODE": {
      return {
        ...state,
        modal_code: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_BRAND_MATERIAL_BRAND_ID": {
      return {
        ...state,
        modal_brand_id: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_BRAND_MATERIAL_MATERIAL_ID": {
      return {
        ...state,
        modal_material_id: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_BRAND_MATERIAL_DESCRIPTION": {
      return {
        ...state,
        modal_description: action.payload,
      };
    }
    case "SET_MODAL_CREATE_NEW_BRAND_MATERIAL_STATUS": {
      return {
        ...state,
        modal_status: action.payload,
      };
    }
    case "SET_MODAL_UPDATE_BRAND_MATERIAL": {
      return {
        ...state,
        modal_brand_name: action.payload.modal_brand_name,
        modal_code: action.payload.modal_code,
        modal_material_name: action.payload.modal_material_name,
        modal_material_id: action.payload.modal_material_id,
        modal_brand_id: action.payload.modal_brand_id,
        modal_id: action.payload.modal_id,
        modal_description: action.payload.modal_description,
        modal_status: action.payload.modal_status,
      };
    }
    case "CLEAR_MODAL_CREATE_NEW_BRAND_MATERIAL": {
      return {
        ...state,
        modal_brand_name: null,
        modal_code: null,
        modal_material_name: null,
        modal_material_id: null,
        modal_brand_id: null,
        modal_id: null,
        modal_description: null,
        modal_status: null,

      };
    }
    case "UPDATE_MATERIAL_BRAND_SUCCEEDED":{
      console.log("UPDATE_MATERIAL_BRAND_SUCCEEDED");
      return {
        ...state,
        loading: false,
      }
    }
    case "UPDATE_MATERIAL_BRAND_STATUS": {
      return { ...state, status: action.payload };
    }
    case "UPDATE_MATERIAL_BRAND_NAME": {
      if (action.payload === "") {
        return { ...state, name: null };
      } else {
        return { ...state, name: action.payload };
      }
    }
    case "UPDATE_MATERIAL_BRAND_CODE": {
      if (action.payload === "") {
        return { ...state, code: null };
      } else {
        return { ...state, code: action.payload };
      }
    }
    case "UPDATE_MATERIAL_BRAND_LEVEL": {
      return { ...state, level: action.payload };
    }
    case "UPDATE_MATERIAL_BRAND_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "UPDATE_MATERIAL_BRAND_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case "SHOW_MATERIAL_BRAND_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_MATERIAL_BRAND_SUCCEEDED":
      return {
        ...state,
        materialBrands: action.payload,
        loading: false,
      };
    case "FETCH_MATERIAL_BRAND_PAGINATION_SUCCEEDED":
      return {
        ...state,
        materialBrands: action.payload.data,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    case "FETCH_MATERIAL_BRAND_PAGINATION_FAILED":
      console.log("FETCH_MATERIAL_BRAND_PAGINATION_FAILED", action.payload);
      return {
        ...state,

        loading: false,
      };
    case "CLEAR_MATERIAL_BRAND_FILTER": {
      return {
        ...state,
        code: null,
        name: null,
        status: 1,
      };
    }
    case "CLEAR_DATA":
      return {
        ...state,
        submitNewMaterialBrandStatus: "",
      };
    case "UPDATE_MODAL_VALIDATION":
      return {
        ...state,
        modalValidationFailed: action.payload,
      };
    case "SAVE_NEW_MATERIAL_BRAND_SUCCEEDED":
      return {
        ...state,
        modalValidationFailed: false,
        submitNewMaterialBrandStatus: 1,
        loading: false,
      };
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: true,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        ...initialModalState,
        modalValidationFailed: false,
      };
    case "UPDATE_MODAL_MATERIAL":
      return {
        ...state,
        modalMaterial: action.payload,
      };
    case "UPDATE_MODAL_BRAND":
      return {
        ...state,
        modalBrand: action.payload,
      };
    case "UPDATE_MODAL_DESCRIPTION":
      return {
        ...state,
        modalDescription: action.payload,
      };
    case "GENERATE_MATERIAL_COMBOBOX_ITEMS":
      let materialOriginalItems = [];
      let materials = action.payload;
      materials &&
        materials.forEach((item) => {
          materialOriginalItems.push({
            id: item.id,
            value: item.name,
          });
        });
      return {
        ...state,
        materialComboboxItems: materialOriginalItems,
        modalValidationFailed: false,
      };
    case "GENERATE_BRAND_COMBOBOX_ITEMS":
      let brandOriginalItems = [];
      let brands = action.payload;
      brands &&
        brands.forEach((item) => {
          brandOriginalItems.push({
            id: item.id,
            value: item.name,
          });
        });
      return {
        ...state,
        brandComboboxItems: brandOriginalItems,
        modalValidationFailed: false,
      };
    case "UPDATE_MODAL_CATEGORY":
      return {
        ...state,
        modalCategoryId: action.payload,
      };
    case "UPDATE_MODAL_UOM":
      return {
        ...state,
        modalBaseUomId: action.payload,
      };
    case "UPDATE_MODAL_GROUP":
      return {
        ...state,
        modalGroupId: action.payload,
      };
    default:
      return state;
  }
};

export default materialBrandReducer;
