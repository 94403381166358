import Logo from "./logo.png";
import Image_1 from "./image_1.png";
import Image_2 from "./image_inventory.png";
import Image_3 from "./image_hrm.png";
import Image_4 from "./image_4.png";
import Image_5 from "./image_5.png";
import ImageBagang from "./image_bagang.png";
import ImageCMC from "./image_CMC.png";
import ImageGiong from "./image_giong.png";
import ImagePOS from "./image_pos.png";
import ImageShipping from "./image_shipping.png";
import LogoPersonnel from "./logo_personnel.png";
import LogoPOS from "./logo_POS.png";
import LogoInventory from "./logo_inventory.png";
import Avartar from "./Avartar.png";
import EmptyTable from "./table-empty.png";
import Chart from "./Chart.png";
import NoneData from "./none-data.png";
import NoneData_1 from "./none-data-1.png";
import NoneData_2 from "./none-data-2.png";
import ImageFile from "./type_image.png";
import PDFFile from "./type_pdf.png";
import TextFile from "./type_text.png";
import WordFile from "./type_word.png";
import ImageUpLoad from "./upload.png";
import PreviewBg from "./previewbg.png";
import DefaultImage from "./default_image.png";
import QRPreview from './qrPreview.png'
import SignaltureGraphic from './signaltureGraphic.png'
import Cafe from './cafe.png'
import EmptyLibraryImage from "./empty_library_image.png";
import LogoVictory from "./Logo-Victory.png";
import AccessDenied from "./access-denied.png";

const Images = {
  Logo,
  Image_1,
  Image_2,
  Image_3,
  ImageBagang,
  ImageCMC,
  ImageGiong,
  ImagePOS,
  ImageShipping,
  LogoPersonnel,
  LogoPOS,
  LogoInventory,
  Avartar,
  Image_4,
  Image_5,
  EmptyTable,
  Chart,
  NoneData,
  NoneData_1,
  NoneData_2,
  ImageFile,
  PDFFile,
  TextFile,
  WordFile,
  ImageUpLoad,
  PreviewBg,
  QRPreview,
  Cafe,
  SignaltureGraphic,
  DefaultImage,
  EmptyLibraryImage,
  LogoVictory,
  AccessDenied,
};

export default Images;
