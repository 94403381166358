const initialState = {
    loading: false,
    commodityCancelate: [],
    totalPage: 0,
    submitNewcommodityCancelateStatus: 0,
    updatecommodityCancelateStatus: 0,
    approvecommodityCancelateStatus: 0,
    commodityCancelateDetails: null,
}

const commodityCancelationReducer = (state = initialState, action) => {  
    switch (action.type) {
        case "FETCH_COMMODITY_CANCELATE_DETAIL_SUCCEEDED":
            return {
               ...state,
               commodityCancelateDetails: action.payload,
               loading: false,
            };
        case 'SUBMIT_NEW_CMD_CANCELATE_SUCCESSED':
            return {
                ...state,
                loading: false,
                submitNewcommodityCancelateStatus: 1,
            };
        case 'SUBMIT_NEW_CMD_CANCELATE_FAILED':
            return {
                ...state,
                loading: false,
                submitNewcommodityCancelateStatus: 2,
            };
        case 'FETCH_CMD_CANCELATE_SUCCESSED':
            return {
                ...state,
                loading: false,
                commodityCancelate: action.payload?.data,
                totalPage: action.payload?.totalCount,
            };
        case "SET_DEFAULT_STATUS_EDIT_CMD_CANCELATE":
            return {
                ...state,
                submitNewcommodityCancelateStatus: 0,
                updatecommodityCancelateStatus: 0,
                approvecommodityCancelateStatus: 0,
                loading: false
            };   
        case 'SHOW_LOADING_CMD_CANCELATE':
            return {
                ...state,
                loading: true
            };
        case 'DEFAULT_DATA_CMD_CANCELATE':
            return initialState;

        default:
            return state;
    }
}

export default commodityCancelationReducer;