const initialModalState = {
   showModal: false,
   showSaleModal: false,
   modalMaterialId: '',
   modalMaterialCode: '',
   modalMaterialName: '',
   modalMaterialBaseUom: '',
   modalBrandId: '',
   modalBrandName: '',
   modalLotNumber: '',
   modalRequiredDate: '',
   modalUomId: '',
   modalUomName: '',
   modalBaseStockQuantity: '',
   modalRequestQuantity: '',
   modalRequestBaseQuantity: '',
   modalNote: '',
   modalMaterialInstanceId: '',
   modalMaterialInstanceSKU: '',
}

const initialState = {
   requestedDate: '',
   forWarehouse: '',
   requestedBy: '',
   requestDepartment: '',
   attachedFile: '',
   items: [],
   submitNewStockRequestStatus: 0,
   updateStockRequest: 0,
   description: '',
   showRequestWorkFlowModal: false,
   submitWorkFlowActionStatus: 0,
   message: '',

   ...initialModalState,
   modalDescription: "",

   defaultBrandSelected: true,
   defaultUomSelected: true,
   comboboxItems: [],

   stockRequestDetail: null,
   modalValidationFailed: false,
   formValidationFailed: false,

   loading: false,
   stockDestructionList: [],
   stockDestructionDetail: null,
   materialInstancesByWarehouse: [],

   approveFlag: 0,
   createFlag: 0,
   deleteFlag: 0,
   editFlag: 0,
   viewFlag: 0,
}

const stockDestructionReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_AUTHO_MODULE_STOCK_DESTRUCTIONS':
         return {
            ...state,
            createFlag: action.payload.createFlag,
            approveFlag: action.payload.approveFlag,
            deleteFlag: action.payload.deleteFlag,
            editFlag: action.payload.editFlag,
            viewFlag: action.payload.viewFlag,
         }
      case 'FETCH_STOCK_DESTRUCTIONS_SUCCEEDED':
         return {
            ...state,
            stockDestructionList: action.payload,
            loading: false
         }

      case 'FETCH_STOCK_DESTRUCTION_DETAIL_SUCCESS':
         return {
            ...state,
            stockDestructionDetail: action.payload,
            loading: false
         }

      case 'SHOW_STOCK_DESTRUCTION_LOADING':
         return {
            ...state,
            loading: true
         }

      case 'UPDATE_FORM_VALIDATION_STOCK_DESTRUCTION':
         return {
            ...state,
            formValidationFailed: action.payload
         }

      case 'UPDATE_MODAL_VALIDATION':
         return {
            ...state,
            modalValidationFailed: action.payload
         }

      case 'CLEAR_DATA_STOCK_DESTRUCTION':
         return {
            ...state,
            submitNewStockDestructionStatus: 0,
            submitWorkFlowActionStatus: 0,
            updateStockRequest: 0,
            items: [],
            requestedDate: "",
            description: "",
            forWarehouse: "",
            requestDepartment: "",
            requestedBy: "",
            attachedFile: "",
         }

      case 'GENERATE_ORIGINAL_ITEMS_STOCK_DESTRUCTION':
         let originalItems = []
         let materialState = action.payload
         materialState && materialState.forEach(item => {
            originalItems.push({
               id: item.id,
               value: '(' + item.code + ') ' + item.name,
               code: item.code,
               name: item.name,
               image: item.image,
               baseUomName: item.baseUomName
            })
         })
         return {
            ...state,
            comboboxItems: originalItems,
            modalValidationFailed: false,
            formValidationFailed: false
         }
      case 'UPDATE_REQUESTED_DATE_STOCK_DESTRUCTION':
         return {
            ...state,
            requestedDate: action.payload
         }
      case 'UPDATE_FOR_WAREHOUSE_STOCK_DESTRUCTION':
         return {
            ...state,
            forWarehouse: parseInt(action.payload)
         }
      case 'UPDATE_REQUESTED_BY_STOCK_DESTRUCTION':
         return {
            ...state,
            requestedBy: action.payload
         }
      case 'UPDATE_REQUEST_DEPARTMENT_STOCK_DESTRUCTION':
         return {
            ...state,
            requestDepartment: action.payload
         }

      case 'UPDATE_DESCRIPTION_STOCK_DESTRUCTION':
         return {
            ...state,
            description: action.payload
         }

      case 'UPDATE_ATTACHED_FILE_STOCK_DESTRUCTION':
         return {
            ...state,
            attachedFile: action.payload
         }

      case 'UPDATE_MODAL_MATERIAL_STOCK_DESTRUCTION':
         return {
            ...state,
            modalMaterialId: action.payload.id,
            modalMaterialCode: action.payload.code,
            modalMaterialName: action.payload.name,
            modalMaterialBaseUom: action.payload.baseUom,
            defaultBrandSelected: true,
            defaultUomSelected: true
         }
      case 'UPDATE_MODAL_BRAND':
         return {
            ...state,
            modalBrandId: action.payload.id,
            modalBrandName: action.payload.name,
            modalUomId: '',
            modalUomName: '',
         }
      case 'UPDATE_MODAL_UOM':
         return {
            ...state,
            modalUomName: action.payload.name,
            modalUomId: action.payload.id,
            defaultUomSelected: false
         }
      case 'UPDATE_MODAL_BASE_STOCK_QUANTITY_STOCK_DESTRUCTION':
         return {
            ...state,
            modalBaseStockQuantity: action.payload
         }

      case 'UPDATE_MODAL_REQUEST_QUANTITY_STOCK_DESTRUCTION':
         return {
            ...state,
            modalRequestQuantity: action.payload
         }
      case 'UPDATE_MODAL_REQUEST_BASE_QUANTITY_STOCK_DESTRUCTION':
         return {
            ...state,
            modalRequestBaseQuantity: action.payload
         }
      case 'UPDATE_REQUIRED_DATE':
         return {
            ...state,
            modalRequiredDate: action.payload
         }
      case 'UPDATE_MODAL_NOTE_STOCK_DESTRUCTION':
         return {
            ...state,
            modalNote: action.payload
         }

      case 'INSERT_ITEM_STOCK_DESTRUCTION':
         let items = state.items
         items.push({
            id: state.modalMaterialId || null,
            code: state.modalMaterialCode || null,
            name: state.modalMaterialName || null,
            brandId: state.modalBrandId || null,
            brandName: state.modalBrandName || "",
            uomId: state.modalUomId || null,
            uomName: state.modalUomName || "",
            requestBaseQuantity: state.modalRequestBaseQuantity || "",
            requestQuantity: state.modalRequestQuantity || 0,
            requiredDate: state.modalRequiredDate || null,
            note: state.modalNote || null,
            sku: state.modalMaterialInstanceSKU || "",
            materialInstanceId: state.modalMaterialInstanceId || null,
         })
         return {
            ...state,
            items
         }

      case 'HIDE_MODAL_STOCK_DESTRUCTION':
         return {
            ...state,
            ...initialModalState,
            modalValidationFailed: false,
            showRequestWorkFlowModal: false,
            showModal: false
         }
      case 'UPDATE_REQUEST_WORK_FLOW_MODAL_DESCRIPTION_STOCK_DESTRUCTION':
         return {
            ...state,
            modalDescription: action.payload
         }
      case "UPDATE_MODAL_VALIDATION_STOCK_DESTRUCTION":
         return {
            ...state,
            modalValidationFailed: action.payload,
         };
      case 'SHOW_MODAL_STOCK_DESTRUCTION':
         return {
            ...state,
            showModal: true
         }

      case 'SHOW_SALE_MODAL_STOCK_DESTRUCTION':
         return {
            ...state,
            showSaleModal: true
         }
      case 'SHOW_REQUEST_WORK_FLOW_MODAL_STOCK_DESTRUCTION':
         return {
            ...state,
            showRequestWorkFlowModal: true,
         }

      case 'CLEAR_MODAL_STOCK_DESTRUCTION':
         return {
            ...state,
            modalMaterialId: "",
            modalMaterialCode: "",
            modalMaterialName: "",
            modalDescription: "",
            modalLotNumber: "",
            modalExpiryDate: "",
            modalUomId: "",
            modalUomName: "",
            modalBaseQuantity: "",
            modalQuantity: "",
            modalBaseQuantityOnHand: "",
            modalQuantityOnHand: "",
            defaultUomSelected: true,
            submitWorkFlowActionStatus: 0,
         }

      case 'SUBMIT_NEW_STOCK_DESTRUCTION_SUCCEEDED':
         return {
            ...state,
            submitNewStockDestructionStatus: 1,
            loading: false
         }

      case 'SUBMIT_UPDATE_STOCK_DESTRUCTION_SUCCEEDED':
         return {
            ...state,
            updateStockRequest: 1,
            loading: false
         }

      case 'SUBMIT_NEW_STOCK_DESTRUCTION_FAILED':
         console.log('-------------------------------- pay', JSON.stringify(action.payload));
         return {
            ...state,
            submitNewStockDestructionStatus: 2,
            message: action.payload,
            loading: false
         }
      case 'SUBMIT_STOCK_DESTRUCTION_WORK_FLOW_SUCCEEDED':
         return {
            ...state,
            submitWorkFlowActionStatus: 1,
            loading: false,
         }
      case 'SUBMIT_STOCK_DESTRUCTION_WORK_FLOW_FAILED':
         return {
            ...state,
            submitWorkFlowActionStatus: 2,
            loading: false
         }

      case 'FETCH_STOCK_REQUEST_DETAIL_SUCCEEDED':
         return {
            ...state,
            stockRequestDetail: action.payload.details
         }

      case 'CLEAR_MATERIAL_INSTANCES_BY_WAREHOUSE':
         return {
            ...state,
            materialInstancesByWarehouse: []
         }

      case 'FETCH_MATERIAL_INSTANCES_BY_WAREHOUSE_SUCCEEDED':
         return {
            ...state,
            materialInstancesByWarehouse: action.payload
         }

      case 'UPDATE_MODAL_MATERIAL_INSTANCE_SKU_STOCK_DESTRUCTION':
         return {
            ...state,
            modalMaterialInstanceSKU: action.payload.sku,
            modalMaterialInstanceId: action.payload.id
         }

      case 'UPDATE_MODAL_FORMULA_STORE_ID':
         return {
            ...state,
            modalFormulaStoreId: action.payload
         }

      default:
         return state
   }
}

export default stockDestructionReducer
