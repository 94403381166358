const initialModalState = {
    showModal: false,
    modalCode: '',
    modalName: '',
    modalDescription: '',
}

const initialState = {
    loading: false,
    manufacturers: [],
    ...initialModalState,
    modalValidationFailed: false,
    submitNewManufacturerStatus: ''
}

const manufacturerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_DATA':
            return {
                ...state,
                submitNewManufacturerStatus: ''
            }
        case 'UPDATE_MODAL_VALIDATION':
            return {
                ...state,
                modalValidationFailed: action.payload
            }
        case 'SHOW_MANUFACTURER_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_MANUFACTURER_SUCCEEDED':
            return {
                ...state,
                manufacturers: action.payload,
                loading: false
            }
        case 'SAVE_NEW_MANUFACTURER_SUCCEEDED':
            return {
                ...state,
                modalValidationFailed: false,
                submitNewManufacturerStatus: 1,
                loading: false
            }
        case 'SHOW_MODAL':
            return {
                ...state,
                showModal: true
            }
        case 'HIDE_MODAL':
            return {
                ...state,
                ...initialModalState,
                modalValidationFailed: false
            }
        case 'UPDATE_MODAL_CODE':
            return {
                ...state,
                modalCode: action.payload
            }
        case 'UPDATE_MODAL_NAME':
            return {
                ...state,
                modalName: action.payload
            }
        case 'UPDATE_MODAL_ADDRESS':
            return {
                ...state,
                modalAddress: action.payload
            }
        case 'UPDATE_MODAL_TELEPHONE':
            return {
                ...state,
                modalTelephone: action.payload
            }
        case 'UPDATE_MODAL_TAXCODE':
            return {
                ...state,
                modalTaxCode: action.payload
            }
        case 'UPDATE_MODAL_DESCRIPTION':
            return {
                ...state,
                modalDescription: action.payload
            }
        default:
            return state
    }
}

export default manufacturerReducer