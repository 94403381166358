import axios from 'axios'
import constants from '../reusable/constants'

export function fetchMaterial() {
   return axios.get(
      constants.api.url + 'api/material',
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}
export function fetchMaterialPagination(payload) {
  const { page, pageSize, status, name, code, categoryId } = payload;
  let attributes = [
    { text: "page", value: page },
    { text: "pageSize", value: pageSize },
    { text: "status", value: status },
    { text: "name", value: name },
    { text: "code", value: code },
    { text: "categoryId", value: categoryId },
  ];
  let newAttributes = attributes.filter(
    (item) => item.value !== null && item.value !== undefined
  );
  let urlAtrribute = "";
  newAttributes.forEach((item, index) => {
    urlAtrribute += item.text + "=" + item.value;
    if (index !== attributes.length - 1) {
      urlAtrribute += "&";
    }
  });
  console.log("urlAtrribute", urlAtrribute);
  return axios.get(
     constants.api.url + `api/Material/GetAllMaterials?${urlAtrribute}`,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export function fetchActiveMaterial() {
   return axios.get(
      constants.api.url + 'api/material/GetAllActiveMaterials',
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchActiveMaterialsTP() {
   return axios.get(
      constants.api.url + 'api/Material/GetAllActiveMaterialsTP',
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function createMaterialAppStores(payload) {
   return axios.post(
      constants.api.url + 'api/Material/SetViewMaterial',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}


export function fetchProduct() {
   return axios.get(
      constants.api.url + 'api/material/GetAllProducts',
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGetMaterialDataDetailApi(payload) {
   return axios.get(
      constants.api.url + 'api/Material/' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function createNewMaterial(payload) {
  console.log("payload",payload)
   return axios.post(
      constants.api.url + 'api/material',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function updateMaterial(payload) {
  console.log("payload", payload);
   return axios.put(
      constants.api.url + 'api/material/' + payload.id,
      {
         code: payload.code,
         name: payload.name,
         baseUomId: payload.baseUomId,
         categoryId: payload.categoryId,
         description: payload.description,
         status: payload.status,
         image: payload.image,
         unitPrice: payload.unitPrice
      },
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function hideProductApi(payload) {
   return axios.put(
      constants.api.url + 'api/material/HideProduct/' + payload.id, {},
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}


export function updateProductUnitPriceApi(payload) {
   return axios.put(
      constants.api.url + 'api/material/UpdateProductUnitPrice/' + payload.id,
      {
         "unitPrice": payload.unitPrice,
      },
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function getAllActiveMaterialWithWarehouse(payload) {
   return axios.get(
      constants.api.url + `api/Material/GetAllActiveMaterialsWithWarehouse?warehouseId=${payload}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function getAllActiveMaterialTPWithWarehouse(payload) {
   return axios.get(
      constants.api.url + `api/Material/GetAllActiveMaterialsTPWithWarehouse?warehouseId=${payload}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchAllAppStoreMaterials(payload) {
   const { Page, PageSize, MaterialId, CategoryId, AppId, StoreId, Status } = payload;
   
   return axios.get(
      constants.api.url + `api/Material/GetAllAppStoreMaterial?Page=${Page}&PageSize=${PageSize}&MaterialId=${MaterialId}&CategoryId=${CategoryId}&AppId=${AppId}&StoreId=${StoreId}&Status=${Status}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchMaterialStoreAppDetail({ materialId }) {
   return axios.get(
      constants.api.url + `api/Material/GetViewMaterial?MaterialId=${materialId}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function updateMaterialStoreApps(payload) {
   return axios.put(
      constants.api.url + `api/Material/UpdateViewMaterial`,
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function deleteMaterialAppStores(payload) {
   const deleteMethod = {
      method: 'DELETE',
      headers: {
       'Content-type': 'application/json; charset=UTF-8',
       'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
     }
   return fetch(constants.api.url + `api/Material/DeleteViewMaterial?id=${payload}`, deleteMethod);
}