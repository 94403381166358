import axios from 'axios'
import constants from '../reusable/constants'

export async function createStockRequestApi(payload) {
   return await axios.post(
      constants.api.url + 'api/stockRequest?uid=' + payload.UserId,
      payload.data,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export async function updateStockRequestApi(payload) {
  return await axios.put(
     constants.api.url + 'api/stockRequest?uid=' + payload.UserId,
     payload.data,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export async function updateStockRequestApiV3(payload) {
   return await axios.put(
      constants.api.url + 'api/stockRequest/UpdateStockRequest?UId=' + payload.userId,
      payload.data,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }

export function fetchStockRequestsApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockRequest?uId=' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockRequestsByWarehouseApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockRequest/GetStockRequestsByWarehouse?warehouseId=' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockRequestDetailApi(payload) {
   return axios.get(
      constants.api.url + 'api/stockRequest/' + payload.id + (payload.cutDate == null ? '' : ('?cutDate=' + payload.cutDate)),
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchListStatusStockRequest() {
   return axios.get(
      constants.api.url + 'api/stockRequest/GetListStatusStockRequest',
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchListMateritalFilter() {
   return axios.get(
      constants.api.url + 'api/stockRequest/GetListMateritalFilter',
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchListMateritalFilterByCategory(payload) {
   return axios.get(
      constants.api.url + `api/StockRequest/GetListMateritalFilterId/${payload}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockRequestByFilter({ UId, WarehouseId, FromDate, ToDate, StockRequestCode, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn}) {
   return axios.get(
      constants.api.url +
      `api/stockRequest?UId=${UId}&WarehouseId=${WarehouseId}&FromDate=${FromDate}&ToDate=${ToDate}&StockRequestCode=${StockRequestCode}&MaterialId=${MaterialId}&Status=${Status}&page=${currentPage}&pageSize=${pageSize}&orderby=${orderBy}&nameColumn=${nameColumn}`
      ,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}
export function fetchInventoryManageByFilter({ UId, WarehouseId, CutDate, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn}) {
  console.log("fetchInventoryManageByFilter", { UId, WarehouseId, CutDate, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn})
  return axios.get(
     constants.api.url +
     `api/stock?UId=${UId}&WarehouseId=${WarehouseId}&CutDate=${CutDate}&MaterialId=${MaterialId}&Status=${Status}&page=${currentPage}&pageSize=${pageSize}&orderby=${orderBy}&nameColumn=${nameColumn}`
     ,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export function fetchInventoryCheckByFilter({ UId, WarehouseId, CutDate, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn}) {
  console.log("fetchInventoryCheckByFilter", { UId, WarehouseId, CutDate, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn})
  return axios.get(
     constants.api.url +
     `api/stockTake?uId=${UId}&WarehouseId=${WarehouseId}&CutDate=${CutDate}&MaterialId=${MaterialId}&Status=${Status}&page=${currentPage}&pageSize=${pageSize}&orderby=${orderBy}&nameColumn=${nameColumn}`
     ,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export function fetchInventoryLostByFilter({ WarehouseId, FromDate,ToDate, MaterialId, currentPage, pageSize, orderBy, nameColumn}) {
  console.log("fetchInventoryLostByFilter", {  WarehouseId, FromDate,ToDate, MaterialId, currentPage, pageSize, orderBy, nameColumn})
  return axios.get(
     constants.api.url +
     `api/stockReconcile/getStockReconciles/${WarehouseId}?FromDate=${FromDate}&ToDate=${ToDate}&page=${currentPage}&pageSize=${pageSize}&orderby=${orderBy}&nameColumn=${nameColumn}`
     ,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export async function updateStockRequestV2(payload) {
  console.log("update", payload)
  return axios.put(
     constants.api.url + 'api/stockTake',
     payload.data,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export async function updateCheckRequest(payload) {
  console.log("updateNewInventoryCheck", payload)
  return axios.put(
     constants.api.url + 'api/stockTake',
     payload,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
export function deleteStockRequest(payload) {
   const deleteMethod = {
      method: 'DELETE',
      headers: {
       'Content-type': 'application/json; charset=UTF-8',
       'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
      body: JSON.stringify(payload)
     }
   return fetch(constants.api.url + 'api/stockRequest/DeleteStockRequest', deleteMethod);
}

export function updateStatusStockRequest(payload) {
   return axios.put(
      constants.api.url + 'api/stockRequest/UpdateStatus',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchStockRequestStatus34() {
   return axios.get(
      constants.api.url +
      `api/stockRequest/GetAllStockRequest`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
 }

export function createNewInventoryCheck(payload) {
  console.log("createNewInventoryCheck", payload)
  return axios.post(
     constants.api.url + 'api/stockTake',
     payload.data,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function fetchStockCheckDetailApi(payload) {
  return axios.get(
     constants.api.url + 'api/stockTake/' + payload.id ,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}
