import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../services/firebaseService'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(false)
  const [userProfile, setUserProfile] = useState(null)
  //const [userProfile, setUserProfile] = useState(true)
  const [userCustomClaims, setUserCustomClaims] = useState(null)
  useEffect(() => {
    auth.onAuthStateChanged(setUser)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userProfile,
        setUserProfile,
        userCustomClaims,
        setUserCustomClaims,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
