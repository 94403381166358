import axios from "axios";
import constants from "../reusable/constants";

export function fetchPosContentTypeApi() {
  return axios.get(constants.api.url + "api/HRApp/GetContentTypes", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function fetchPosFileSettingApi(payload) {
  return axios.get(constants.api.url + `api/HRApp/GetPOSFilesSetting?appId=${payload.appId}&storeId=${payload.storeId}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function fetchPosContentSettingApi(payload) {
  return axios.get(constants.api.url + `api/HRApp/GetPOSContentSetting?appId=${payload.appId}&storeId=${payload.storeId}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function setPosContentTypeApi(payload) {
  return axios.post(
    constants.api.url + "api/HRApp/SetPOSContentType",
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
