import axios from "axios";
import constants from "../reusable/constants";

export function fetchCommodityCancelationApi(payload) {
    const url = constants.api.url + "api/CommodityCancelation"
    + `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}`
    +  `&MaterialId=${payload.MaterialId}&FromDate=${payload.FromDate}&ToDate=${payload.ToDate}`
    + `&Code=${payload.Code || ''}&Status=${payload.Status}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function checkCodeExistCmdCancelation(payload) {
    const url = constants.api.url + "api/CommodityCancelation/CheckCodeCommodityCancelationExist";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}


export function createNewCmdCancelation(payload) {
    const url = constants.api.url + "api/CommodityCancelation";

    return axios.post(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}


export function fetchCmdCancelationById(payload) {
    const url = constants.api.url + `api/CommodityCancelation/${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateStatusCmdCancelation(payload) {
    const url = constants.api.url + `api/CommodityCancelation/UpdateStatusCommodityCancelation`;

    return axios.put(
        url, payload, 
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateCmdCancelation(payload) {
    const url = constants.api.url + `api/CommodityCancelation/UpdateCommodityCancelation`;

    return axios.put(
        url, payload, 
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchCodeCmdCancelation() {
    const url = constants.api.url + `api/SRCode/GetCodeCommodityCancelationAsync`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}