const initialState = {
    showModal: false,
    loading: false,
    items: [],
    token: '',
    managementToken: '',
    loopTransactions: [],
    formValidationFailed: false,
    checkingDate: '',
    checkingWarehouse: '',
    loopData: '',
    submitNewLoopGetDataStatus: '',
    reconcileResult: [],
    loopTransactionDetail: [],
    stockBalances: [],
    loopTransaction: '',
    submitNewStockBalanceStatus: '',
    stockBalanceDetail: ''
}

const stockBalanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CHECKING_DATE':
            return {
                ...state,
                checkingDate: action.payload
            }

        case 'FETCH_STOCK_BALANCE_DETAIL_SUCCEEDED':
            return {
                ...state,
                stockBalanceDetail: action.payload,
                loading: false
            }
        case 'SUBMIT_NEW_STOCK_BALANCE_SUCCEEDED':
            return {
                ...state,
                submitNewStockBalanceStatus: 1,
                loading: false
            }
        case 'SUBMIT_NEW_STOCK_BALANCE_FAILED':
            return {
                ...state,
                submitNewStockBalanceStatus: 2
            }
        case 'UPDATE_LOOP_TRANSACTION':
            return {
                ...state,
                loopTransaction: action.payload
            }
        case 'FETCH_STOCK_BALANCES_SUCCEEDED':
            return {
                ...state,
                stockBalances: action.payload,
                loading: false
            }
        case 'BALANCE_STOCK_SUCCEEDED':
            return {
                ...state,
                reconcileResult: action.payload
            }

        case 'SUBMIT_NEW_LOOP_GET_DATA_SUCCEEDED':
            return {
                ...state,
                submitNewLoopGetDataStatus: 1,
                loading: false,
                formValidationFailed: false,
                checkingDate: '',
                checkingWarehouse: '',
                loopData: '',
            }

        case 'FETCH_LOOP_TRANSACTIONS_SUCCEEDED':
            return {
                ...state,
                loopTransactions: action.payload,
                loading: false
            }

        case 'FETCH_LOOP_DATA_DETAIL_SUCCEEDED':
            return {
                ...state,
                loopTransactionDetail: action.payload,
                loading: false
            }

        case 'FETCH_LOOP_DATA_PRODUCT_SOLD_SUCCEEDED':
            return {
                ...state,
                loopData: action.payload,
                loading: false
            }

        case 'FETCH_LOOP_MANAGEMENT_TOKEN_SUCCEEDED':
            return {
                ...state,
                managementToken: action.payload.token,
                loading: false
            }
        case 'FETCH_LOOP_TOKEN_SUCCEEDED':
            return {
                ...state,
                token: action.payload.token,
                loading: false
            }

        case 'CLEAR_DATA':
            return {
                ...initialState
            }

        case 'UPDATE_FORM_VALIDATION':
            return {
                ...state,
                formValidationFailed: action.payload
            }
        case 'UPDATE_CHECKING_WAREHOUSE':
            return {
                ...state,
                checkingWarehouse: action.payload
            }

        case 'SHOW_STOCK_BALANCE_LOADING':
            return {
                ...state,
                loading: true
            }

        default:
            return state
    }
}

export default stockBalanceReducer