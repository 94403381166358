import axios from "axios";
import constants from "../reusable/constants";

export function fetchCategory() {
  return axios.get(constants.api.url + "api/category", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function fetchCategoryPagination(payload) {
  const { page, pageSize, status, name, code, level } = payload;
  let attributes = [
    { text: "page", value: page },
    { text: "pageSize", value: pageSize },
    { text: "status", value: status },
    { text: "name", value: name },
    { text: "code", value: code },
    { text: "level", value: level },
  ];
  let newAttributes = attributes.filter(
    (item) => item.value !== null && item.value !== undefined
  );
  let urlAtrribute = "";
  newAttributes.forEach((item, index) => {
    urlAtrribute += item.text + "=" + item.value;
    if (index !== attributes.length - 1) {
      urlAtrribute += "&";
    }
  });
  return axios.get(
    constants.api.url + `api/Category/GetAllCategories?${urlAtrribute}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
export function fetchParentCategory(payload) {
  return axios.get(
    constants.api.url + "api/category/GetParentCategories?level=" + payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function createNewCategory(payload) {
  console.log("Payload", payload);
  return axios.post(constants.api.url + "api/category", payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function updateCategoryOrderByApi(payload) {
  return axios.put(
    constants.api.url + "api/category/updateCategoryOrderBy/" + payload.id,
    { orderBy: payload.orderBy },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}

export function updateCategory(payload) {
  console.log("UPDATE_CATEGORY====>", payload);

  return axios.put(
    constants.api.url + "api/Category/UpdateCategory",
    {
      id: +payload.id,
      code: payload.code,
      name: payload.name,
      description: payload.description,
      level: payload.level,
      parentCode: payload.parentCode,
      status: payload.status,
    },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
