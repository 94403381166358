import axios from 'axios';
import constants from '../reusable/constants';

export function fetchSRDeliverApi(payload) {
    const url = constants.api.url + "api/SRDeliver"
    + `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}`
    +  `&MaterialId=${payload.MaterialId}&FromDate=${payload.FromDate}&ToDate=${payload.ToDate}`
    + `&Code=${payload.Code || ''}&Status=${payload.Status}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function newSRDeliverApi(payload) {
    const url = constants.api.url + "api/SRDeliver";

    return axios.post(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function checkCodeStatusExists(payload) {
    const url = constants.api.url + "api/SRDeliver/CheckCodeSRDeliverExist";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateStatusSEDeliver(payload) {
    const url = constants.api.url + "api/SRDeliver/UpdateStatusSRDeliver";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchSRDeliverById(payload) {
    const url = constants.api.url + `api/SRDeliver/${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateSEDeliver(payload) {
    const url = constants.api.url + "api/SRDeliver/UpdateSRDeliver";
    
    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchCodeSRDeliver() {
    const url = constants.api.url + `api/SRCode/GetCodeSRDeliveryAsync`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchListDriverWithPlateApi(payload) {
    const url = constants.api.url + `api/SRDeliver/GetListDriverWithPlate?licensePlate=${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchDriversApi(payload) {
    const url = constants.api.url + `api/SRDeliver/GetDrivers?page=${payload.page}&pagesize=${payload.pageSize}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function createNewDriversApi(payload) {
    const url = constants.api.url + `api/SRDeliver/CreateDriver`;

    return axios.post(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchDriversByIdApi(payload) {
    const url = constants.api.url + `api/SRDeliver/GetDriverById/${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateDriversApi(payload) {
    const url = constants.api.url + `api/SRDeliver/UpdateDriver`;

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}