const initialState = {
    webGroupId: null,
    webGroupName: null,
    webGroupLoading: false,
    webGroupList: [],
    message: '',
    createWebGroup: '',
    createWebGroupLoading: false,
    createWebGroupStatus: null,
    updateWebGroup: '',
    updateWebGroupLoading: false,
    updateWebGroupStatus: null,
    createUserGroupPermissionData: null,
}

const showWebGroupName = (id, arr = []) =>{
    if (arr && arr.length > 0) {
        let result = arr.find(item => item.webGroupId === id);
        if(result){
            //console.log(result.webGroupName);
            return result.webGroupName
        }  
    }
    return null
}

/* const afterUpdateWebGroupArr  = (id, name, arr = []) => {
    if (arr && arr.length > 0) {
        const index = arr.findIndex(item => item.webGroupId === id)
        if(index !== -1){
            //arr[index].webGroupId = id
            arr[index].webGroupName = name
            return [...arr]
        }  
    }
    return []
}
 */
const afterUpdateWebGroupArr  = (updateData, arr = []) => {
    if (arr && arr.length > 0) {
        const {id, name} = updateData
        const index = arr.findIndex(item => item.webGroupId === id)
        if(index !== -1){
            //arr[index].webGroupId = id
            arr[index].webGroupName = name
            return [...arr]
        }  
    }
    return []
}


const afterCreateUserGroupPermissionpArr  = (createData, arr = []) => {
    if (arr && arr.length > 0) {
        const {webGroupId, listEmployee} = createData
        const index = arr.findIndex(item => item.webGroupId === webGroupId)

        if(index !== -1){
            arr[index].totalUser = listEmployee.length
            return [...arr]
        }  
    }
    return []
}

const webGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CURRENT_WEB_GROUP_ID':
            const {webGroupId} = action.payload
            return {
                ...state,
                webGroupId,
                webGroupName: showWebGroupName(webGroupId, state.webGroupList),
            }

        case 'SHOW_WEB_GROUP_LOADING':
            return {
                ...state,
                webGroupLoading: true
            }

        case 'FETCH_WEB_GROUP_SUCCESS':
            return {
                ...state,
                webGroupList: action.payload.data,
                webGroupName: showWebGroupName(state.webGroupId, action.payload.data),
                webGroupLoading: false,
                message: ''
            }

        case 'FETCH_WEB_GROUP_FAILED':
            return {
                ...state,
                webGroupLoading: false,
                message: action.payload
            }

        case 'SHOW_CREATE_WEB_GROUP_LOADING':
            return {
                ...state,
                createWebGroupLoading: true
            }

        case 'CREATE_WEB_GROUP_SUCCESS':
            return {
                ...state,
                createWebGroup: action.payload,
                createWebGroupLoading: false,
                createWebGroupStatus: 'success',
                webGroupList: [
                    ...state.webGroupList,
                    {
                        webGroupId: action.payload.responseData.data,
                        webGroupName: action.payload.createData.groupName,
                        totalUser: 0
                    }
                ],
                message: ''
            }

        case 'CREATE_WEB_GROUP_FAILED':
            return {
                ...state,
                createWebGroupLoading: false,
                createWebGroupStatus: 'failed',
                message: action.payload
            }
        
        case 'CLEAR_CREATE_WEB_GROUP':
            return {
                ...state,
                createWebGroupStatus: null
            }

        case 'SHOW_UPDATE_WEB_GROUP_LOADING':
            return {
                ...state,
                updateWebGroupLoading: true
            }

        case 'UPDATE_WEB_GROUP_SUCCESS':
            return {
                ...state,
                updateWebGroup: action.payload,
                updateWebGroupLoading: false,
                updateWebGroupStatus: 'success',
                webGroupList: afterUpdateWebGroupArr(action.payload.updateData, state.webGroupList),
                webGroupName: action.payload.updateData.name,
                message: ''
            }

        case 'UPDATE_WEB_GROUP_FAILED':
            return {
                ...state,
                updateWebGroupLoading: false,
                updateWebGroupStatus: 'failed',
                message: action.payload
            }

        case 'CLEAR_UPDATE_WEB_GROUP':
            return {
                ...state,
                updateWebGroupStatus: null
            }

        case 'CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_SUCCESS':
            return {
                ...state,
                webGroupList: afterCreateUserGroupPermissionpArr(action.payload.createData, state.webGroupList)
            }

        default:
            return state
    }
}

export default webGroupReducer