const initFilterState = {
  page: 1,
  pageSize: 10,
}

const initFilterDetailsState = {
  MaterialId: null, // Mã hàng hóa
  Quantity: null, // Số lượng
  fromWarehouseId: null, // Kho chuyển
  toWarehouseId: null, // Kho nhận
}

const initialState = {
  loading: false,
  GoodTransferList: [],
  GoodTransferDetails: null,
  totalPage: 0,
  submitNewSRReceivedStatus: 0,
  updateSRReceivedStatus: 0,
  approveSRReceivedStatus: 0,
  SRReceivedDetais: null,

  submitNewTPStatus: 0,

  // LIST OF WAREHOUSE (Danh sách kho cho người dùng chọn khi tạo mới chuyển hàng)
  listWarehouse: [],
  // LIST OF PRODUCT (Danh sách hàng hóa cho người dùng chọn khi tạo mới chuyển hàng)
  listProduct: [],
  // SELECTED PRODUCT (Danh sách hàng hóa được chọn khi khởi tạo ở trang tạo mới chuyển hàng)
  selectedProduct: null,
  // FILTER STATE (Quản lý bộ lọc trang chuyển hàng chính)
  filterState: initFilterState,
  // FILTER DETAILS STATE (Quản lý bộ lọc  trang chuyển hàng chi tiết)
  filterDetailsState: initFilterDetailsState,
}

const GoodTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_GOOD_TRANSFER_BY_ID_SUCCESSED':
      return {
        ...state,
        GoodTransferDetails: action.payload.data,
        loading: false,
      }
    case 'SUBMIT_NEW_GOOD_TRANSFER_SUCCESSED':
      return {
        ...state,
        loading: false,
        submitNewSRReceivedStatus: 1,
      }
    case 'SUBMIT_NEW_GOOD_TRANSFER_FAILED':
      return {
        ...state,
        loading: false,
        submitNewSRReceivedStatus: 2,
      }
    case 'SET_DEFAULT_STATUS_EDIT_GOOD_TRANSFER':
      return {
        ...state,
        submitNewSRReceivedStatus: 0,
        updateSRReceivedStatus: 0,
        approveSRReceivedStatus: 0,
        loading: false,
      }
    case 'FETCH_GOOD_TRANSFER_SUCCESSED':
      return {
        ...state,
        loading: false,
        GoodTransferList: action.payload?.data,
        totalPage: action.payload?.totalPage,
      }
    case 'SHOW_GOOD_TRANSFER_LOADING':
      return {
        ...state,
        loading: true,
      }
    case 'RESET_SUBMIT_NEW_TP_STATUS':
      return {
        ...state,
        submitNewTPStatus: 0,
      }
    case 'DEFAULT_DATA_SR_PRODUCE':
      return initialState
    // CÁC STATE CHUNG CHUNG CHO TRANG CHUYỂN HÀNG
    // LIST OF WAREHOUSE
    case 'FETCH_LIST_WAREHOUSE_BY_ID_SUCCESS':
      return {
        ...state,
        listWarehouse: action.payload.data,
      }
    // LIST OF PRODUCT
    case 'FETCH_LIST_PRODUCTS_TP_SUCCESS':
      return {
        ...state,
        listProduct: action.payload.data,
      }
    // SELECTED PRODUCT
    case 'SET_SELECTED_PRODUCT':
      return {
        ...state,
        selectedProduct: action.payload,
      }

    // CLEAR SELECTED PRODUCT
    case 'CLEAR_SELECTED_PRODUCT':
      return {
        ...state,
        selectedProduct: null,
      }

    // CREATE NEW GOOD TRANSFER
    case 'CREATE_GOODS_TRANSFER_SUCCESS':
      return {
        ...state,
        filterDetailsState: initFilterDetailsState,
        selectedProduct: null,
        loading: false,
        submitNewTPStatus: 1,
      }

    // FILTER STATE (Quản lý bộ lọc trang chuyển hàng chính)
    // SET FILTER STATE
    case 'SET_FILTER_STATE':
      console.log('SET_FILTER_STATE', action.payload)
      return {
        ...state,
        filterState: {
          ...state.filterState,
          ...action.payload,
        },
      }
    // FILTER DETAILS STATE (Quản lý bộ lọc  trang chuyển hàng chi tiết)
    case 'SET_FILTER_DETAIL_STATE':
      return {
        ...state,
        filterDetailsState: {
          ...state.filterDetailsState,
          ...action.payload,
        },
      }
    // CLEAR PRODUCT SELECTED FORM (chỉ xóa hàng hóa, và số lượng)
    case 'CLEAR_PRODUCT_SELECTED_FORM':
      return {
        ...state,
        filterDetailsState: {
          ...state.filterDetailsState,
          MaterialId: null,
          Quantity: null,
        },
      }
    // CLEAR FILTER STATE (Xóa toàn bộ bộ lọc trang chuyển hàng chi tiết)
    case 'CLEAR_FILTER_DETAIL_STATE':
      return {
        ...state,
        filterDetailsState: initFilterDetailsState,
      }

    // CLEAR ALL
    case 'CLEAR_ALL':
      return initialState
    // Mặc định
    default:
      return state
  }
}

export default GoodTransferReducer
