import React, { Component, useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import PrivateRoute from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./contexts/authContext";
import firebase from "firebase";
import { firebaseConfigBD, firebaseConfigHCM } from "src/reusable/constants";
import { MenuContextProvider } from "./contexts/menuContext";
import ModalUpLoadFile from "src/components/ModalUploadFile/index";
import { UploadFileContextProvider } from "src/contexts/fileContext";
import DataTableCustome from "./custom-fields/DataTableCustome/DataTableCustome";
import Page from "./custom-fields/DataTableCustome/Page";
import constants from 'src/reusable/constants';
import handleGetURL from 'src/reusable/handleCheckURL';
import axios from 'axios';
import { TFAContextProvider } from "./custom-fields/TFA/Contexts/tfaContext";

export const firebaseHCM = firebase.initializeApp(
  firebaseConfigHCM,
  "firebaseHCM"
);
export const firebaseBD = firebase.initializeApp(
  firebaseConfigBD,
  "firebaseBD"
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ViewPdf = React.lazy(() =>
  import("./views/pages/view-payslip-pdf/ViewPayslipPdf")
);
const ViewVictoryPdf = React.lazy(() =>
  import("./views/pages/view-payslip-victory-pdf/ViewPaySlipVictoryPdf")
);

function App() {
  // test
  // const setUrl = () => {
  //   axios
  //   .get(constants.api.url + "api/UserRole/GetUser", {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //     },
  //   })
  //   .then((res) => {
  //     constants.api.url = handleGetURL(0);
  //   });
  // }
  
  // useEffect(() => {
  //   setUrl();
  // }, []);

  return (
    <AuthProvider>
      <MenuContextProvider>
        <UploadFileContextProvider>
          <TFAContextProvider>
          <ModalUpLoadFile />
          <HashRouter>
            <React.Suspense fallback={loading}>
              <ToastContainer />
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exac
                  path="/viewPDF"
                  name="Bảng lương"
                  render={(props) => {
                    return <ViewPdf {...props} />;
                  }}
                />
                <Route
                  exac
                  path="/viewVictoryPDF"
                  name="Bảng lương Victory"
                  render={(props) => {
                    return <ViewVictoryPdf {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  exact
                  path="/test"
                  name="Test"
                  render={(props) => <Page {...props} />}
                />
                <PrivateRoute path="/" component={TheLayout} />
              </Switch>
            </React.Suspense>
          </HashRouter>
          </TFAContextProvider>
        </UploadFileContextProvider>
      </MenuContextProvider>
    </AuthProvider>
  );
}

export default App;
