import { call, put, takeEvery } from 'redux-saga/effects'

import {
  fetchStockTransferByFilter,
  fetchListStatusStockTransfer,
  submitStockTransferApi,
  fetchStockTransferById,
  deleteGoodsTransferApi,
} from 'src/api/stockTransferApi'

import {
  fetchStockRequestStatus34,
  fetchStockRequestDetailApi,
} from 'src/api/stockRequestApi'

import { approveRequestApi, approveTransactionApi } from 'src/api/workFlowApi'
import { fetchAllProductsTPApi, fetchAllWarehouseByUIdApi, submitNewGoodTransferApi } from 'src/api/GoodTransferApi'

/* FUNCTION */
export function* loadGoodsTransferByFilter(action) {
  try {
    yield put({ type: 'SHOW_GOODS_TRANSFER_BY_FILTER_LOADING' })
    let res = yield call(fetchStockTransferByFilter, action.payload)
    yield put({
      type: 'FETCH_GOODS_TRANSFER_BY_FILTER_SUCCESS',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'FETCH_GOODS_TRANSFER_BY_FILTER_FAILED',
      payload: e.message,
    })
  }
}

function* loadListStatusGoodsTransfer(action) {
  try {
    yield put({ type: 'SHOW_LIST_STATUS_GOODS_TRANSFER_LOADING' })
    let res = yield call(fetchListStatusStockTransfer, action.payload)
    yield put({
      type: 'FETCH_LIST_STATUS_GOODS_TRANSFER_SUCCESS',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'FETCH_LIST_STATUS_GOODS_TRANSFER_FAILED',
      payload: e.message,
    })
  }
}

function* loadStockRequestStatus34(action) {
  try {
    yield put({ type: 'SHOW_STOCK_REQUEST_STATUS_34_LOADING' })
    let res = yield call(fetchStockRequestStatus34, action.payload)
    yield put({
      type: 'FETCH_STOCK_REQUEST_STATUS_34_SUCCESS',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'FETCH_STOCK_REQUEST_STATUS_34_FAILED',
      payload: e.message,
    })
  }
}

function* loadStockRequestDetailInGoodsTransfer(action) {
  try {
    yield put({ type: 'SHOW_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER_LOADING' })
    let res = yield call(fetchStockRequestDetailApi, action.payload)
    yield put({
      type: 'FETCH_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER_SUCCESS',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'FETCH_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER_FAILED',
      payload: e.message,
    })
  }
}

function* submitGoodsTransfer(action) {
  try {
    yield put({ type: 'SHOW_SUBMIT_GOODS_TRANSFER_LOADING' })
    let res = yield call(submitStockTransferApi, action.payload)
    if (res.data.id !== 0) {
      yield put({ type: 'SUBMIT_GOODS_TRANSFER_SUCCESS', payload: res.data })
    } else {
      yield put({ type: 'SUBMIT_GOODS_TRANSFER_FAILED' })
    }
  } catch (e) {
    yield put({ type: 'SUBMIT_GOODS_TRANSFER_FAILED', payload: e.message })
  }
}

function* loadGoodsTransferById(action) {
  try {
    yield put({ type: 'SHOW_GOODS_TRANSFER_BY_ID_LOADING' })
    let res = yield call(fetchStockTransferById, action.payload)
    yield put({ type: 'FETCH_GOODS_TRANSFER_BY_ID_SUCCESS', payload: res.data })
  } catch (e) {
    yield put({ type: 'FETCH_GOODS_TRANSFER_BY_ID_FAILED', payload: e.message })
  }
}

function* sumbitGoodsTransferApproveRequest(action) {
  try {
    yield put({ type: 'SHOW_SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST_LOADING' })
    let res = yield call(approveRequestApi, action.payload)
    yield put({
      type: 'SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST_SUCCESS',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST_FAILED',
      payload: e.message,
    })
  }
}

function* submitGoodsTransferWorkFlowAction(action) {
  try {
    yield put({ type: 'SHOW_SUBMIT_GOODS_TRANSFER_WORK_FLOW_ACTION_LOADING' })
    let res = yield call(approveTransactionApi, action.payload)
    yield put({
      type: 'SUBMIT_GOODS_TRANSFER_WORK_FLOW_ACTION_SUCCESS',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'SUBMIT_GOODS_TRANSFER_WORK_FLOW_ACTION_FAILED',
      payload: e.message,
    })
  }
}

function* deleteGoodsTransfer(action) {
  //try {
  yield put({ type: 'SHOW_DELETE_GOODS_TRANSFER_LOADING' })
  const idDelete = action.payload.id
  let res = yield call(deleteGoodsTransferApi, action.payload)
  //yield put({ type: "DELETE_GOODS_TRANSFER_SUCCESS", payload: res.data });
  //console.log(res);
  //try catch khi xóa thất bại nó chạy ko đúng??
  console.log(res.status)
  if (res.status !== 200) {
    yield put({ type: 'DELETE_GOODS_TRANSFER_FAILED' })
  } else {
    //console.log(res);
    yield put({ type: 'DELETE_GOODS_TRANSFER_SUCCESS', payload: idDelete })
  }
  //} catch (e) {
  //yield put({ type: "DELETE_GOODS_TRANSFER_FAILED", payload: e.message });
  //}
}

// Get list of warehouse by id
function* loadListWarehouseById(action) {
  try {
    let res = yield call(fetchAllWarehouseByUIdApi, action.payload)
    yield put({ type: 'FETCH_LIST_WAREHOUSE_BY_ID_SUCCESS', payload: res.data })
  } catch (e) {
    yield put({ type: 'FETCH_LIST_WAREHOUSE_BY_ID_FAILED', payload: e.message })
  }
}


// Get list of product
function* loadListAllProductsTP(action) {
  try {
    let res = yield call(fetchAllProductsTPApi, action.payload)
    yield put({ type: 'FETCH_LIST_PRODUCTS_TP_SUCCESS', payload: res.data })
  } catch (e) {
    yield put({ type: 'FETCH_LIST_PRODUCTS_TP_FAILED', payload: e.message })
  }
}

// Create goods transfer
function* createGoodsTransfer(action) {
  try {
    yield put({ type: 'SHOW_GOOD_TRANSFER_LOADING' })
    let res = yield call(submitNewGoodTransferApi, action.payload)
    yield put({ type: 'CREATE_GOODS_TRANSFER_SUCCESS', payload: res.data })
  } catch (e) {
    yield put({ type: 'CREATE_GOODS_TRANSFER_FAILED', payload: e.message })
  }
}

export default function* rootSaga() {
  yield takeEvery('LOAD_GOODS_TRANSFER_BY_FILTER', loadGoodsTransferByFilter)
  yield takeEvery(
    'LOAD_LIST_STATUS_GOODS_TRANSFER',
    loadListStatusGoodsTransfer,
  )
  yield takeEvery('LOAD_STOCK_REQUEST_STATUS_34', loadStockRequestStatus34)
  yield takeEvery(
    'LOAD_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER',
    loadStockRequestDetailInGoodsTransfer,
  )

  yield takeEvery('SUBMIT_GOODS_TRANSFER', submitGoodsTransfer)
  yield takeEvery('FETCH_GOODS_TRANSFER_BY_ID', loadGoodsTransferById)
  yield takeEvery('DELETE_GOODS_TRANSFER', deleteGoodsTransfer)

  /* Approve goods transfer */
  yield takeEvery(
    'SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST',
    sumbitGoodsTransferApproveRequest,
  )
  yield takeEvery(
    'SUBMIT_GOODS_TRANSFER_WORK_FLOW_ACTION',
    submitGoodsTransferWorkFlowAction,
  )

  /* Get list of warehouse by id */
  yield takeEvery('LOAD_LIST_WAREHOUSE_BY_ID', loadListWarehouseById)

  /* Get list of product (lấy danh sách hàng hóa cần chuyển kho) */
  yield takeEvery('LOAD_LIST_PRODUCT_TP', loadListAllProductsTP)

  /* Create new good transfer */
  yield takeEvery('CREATE_NEW_GOODS_TRANSFER', createGoodsTransfer)
}
