const initialState = { 
    loading: false,
    statusCreateConfig: 0,
    allBanner: [],
    configBanner: null,
}

const bannerReducer = (state = initialState, action) => { 
    switch (action.type) { 
        case "FECTH_CONFIG_BANNER_SUCCESSED": {
            return {
                ...state,
                loading: false,
                configBanner: action.payload,
            };
        }
        case "SHOW_CONFIG_BANNER_LOADING": {
            return {
                ...state,
                loading: true,
            };
        }
        case "SET_DEFAULT_STATUS_ACTION": {
            return { 
                ...state, 
                loading: false,
                statusCreateConfig: 0,
            };
        }
        case "FETCH_ALL_BANNER_SUCCESSED": {
            return { ...state, allBanner: action.payload };
        }
        default:
            return state;
    }
}

export default bannerReducer;