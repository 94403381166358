import { combineReducers } from "redux"
import order from './orderReducer'
import flag from './orderFlagReducer'
import pendingStockRequest from './pendingStockRequestReducer'
import statusList from './orderStatusListReducer'
import submit from './orderSubmitReducer'
import detail from './orderDetailReducer'
import deleteOrder from './orderDeleteReducer'
import approveRequest from './orderApproveRequestReducer'
import workFlow from './orderWorkFlowActionReducer'


const rootOrderReducer = combineReducers({
    order,
    flag,
    pendingStockRequest,
    statusList,
    submit,
    detail,
    deleteOrder,
    approveRequest,
    workFlow,
})

export default rootOrderReducer