const initialState = {
    createUserGroupPermissionLoading: false,
    createUserGroupPermissionStatus: null,
    createUserGroupPermissionResponse: null,
    createUserGroupPermissionData: null,
}

const webGroupCreateUseGroupPermissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_LOADING':
            return {
                ...state,
                createUserGroupPermissionLoading: true
            }

        case 'CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_SUCCESS':
            return {
                ...state,
                createUserGroupPermissionLoading: false,
                createUserGroupPermissionStatus: 'success',
                createUserGroupPermissionResponse: action.payload.responseData,
                createUserGroupPermissionData: action.payload.createData,
            }

        case 'CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID_FAILED':
            return {
                ...state,
                createUserGroupPermissionLoading: false,
                createUserGroupPermissionStatus: 'failed',
            }
        
        case 'CLEAR_CREATE_USER_GROUP_PERMISSION_BY_WEB_GROUP_ID':
            return {
                ...state,
                createUserGroupPermissionStatus: null,
            }

        default:
            return state
    }
}

export default webGroupCreateUseGroupPermissionReducer