const initialState = {
    isLoading: false,
    totalGrossAmount: 0,
    totalDiscountAmount: 0,
    totalNetAmount: 0,
    detailByDate: [],
}
  
const revenueGeneralReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_LOADING_REVENUE_SERVICE_MODE':
            return {
                ...state,
                isLoading: true,
            }  
        case 'LOAD_REVENUE_SERVICE_MODE_SUCCESS':
            return {
                ...action.payload.data,
                isLoading: false,
            }  
        default:
            return state;
    }
  };
  
  export default revenueGeneralReducer;