import axios from 'axios'
import constants from '../reusable/constants'

export function fetchAllCustomer(payload) {
  return axios.get(
    constants.api.url +
      `api/Customer?Code=${payload.code||''}&Name=${payload.name||''}&page=${
        payload.page
      }&pageSize=${payload.pageSize}&StoreId=${payload.storeId || 0}`,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    },
  )
}

export function fecthAllCustomerGroup() {
  return axios.get(constants.api.url + `api/Customer/GetCustomerGroups`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

export function createNewCustomer(payload) {
  return axios.post(constants.api.url + `api/Customer`, payload, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

export function updateCustomer(payload) {
  return axios.put(constants.api.url + `api/Customer/UpdateCustomer`, payload, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

export function fecthDetailsCustomer(payload) {
  return axios.get(constants.api.url + `api/Customer/${payload}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  })
}

export function createNewGroupCustomerApi(payload) {
  return axios.post(
    constants.api.url + `api/Customer/CreateCustomerGroup`,
    payload,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    },
  )
}

export function fecthDetailsGroupCustomerApi(payload) {
  return axios.get(
    constants.api.url + `api/Customer/GetCustomerGroupById/${payload}`,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    },
  )
}

export function updateGroupCustomerApi(payload) {
  return axios.put(
    constants.api.url + `api/Customer/UpdateCustomerGroup`,
    payload,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    },
  )
}

export function checkCodeCustomerExistApi(payload) {
  return axios.put(
    constants.api.url + `api/Customer/CheckCodeCustomerExist`,
    payload,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    },
  )
}
