import React, { createContext, useReducer } from 'react';

export const MenuContext = createContext();

export const MenuContextProvider = (props) => {
    const initialState = [];

    const [stateMenu, dispatchMenu] = useReducer((state, action) => {
        switch (action.type) {
            case "SET_SUB_MENU":
                return action.payload;
            case "SET_DEFAULT_MENU":
                return initialState;
            default:
                return state;
        }
    }, initialState)

    return (
        <MenuContext.Provider value={{stateMenu, dispatchMenu}}>
            { props.children }
        </MenuContext.Provider>
    )
}