const initialState = {
    loading: false,
    storeId: undefined,
    storeAddress: undefined,
    storePhoneNumber: undefined,
    storeToiletCode: undefined,
    storeWifiCode: undefined,
    updateStoreStatus: undefined,
    formValidationFailed: false,
    storeBarPrinterIP: undefined,
}

const configPOSReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_DATA':
            return {
                ...state,
                loading: false,
                updateStoreStatus: undefined,
                formValidationFailed: false,
            };

        case 'UPDATE_STORE_SUCCESS':
            return {
                ...state,
                updateStoreStatus: 1,
                loading: false,
            };

        case 'UPDATE_STORE_FAILED':
            return {
                ...state,
                updateStoreStatus: 2,
                loading: false,
            };

        case 'UPDATE_STORE_FORM_VALIDATION':
            return {
                ...state,
                formValidationFailed: action.payload,
            };

        case 'UPDATE_STORE_ADDRESS':
            return {
                ...state,
                storeAddress: action.payload,
            };

        case 'UPDATE_STORE_PHONE_NUMBER':
            return {
                ...state,
                storePhoneNumber: action.payload,
            };

        case 'UPDATE_WIFI_CODE':
            return {
                ...state,
                storeWifiCode: action.payload,
            };

        case 'UPDATE_TOILET_CODE':
            return {
                ...state,
                storeToiletCode: action.payload,
            };

        case 'UPDATE_BAR_PRINTER_IP':
            return {
                ...state,
                storeBarPrinterIP: action.payload,
            };

        case 'RESET_DATA':
            return {
                ...state,
                storeId: undefined,
                storeAddress: undefined,
                storePhoneNumber: undefined,
                storeToiletCode: undefined,
                storeWifiCode: undefined,
                storeBarPrinterIP: undefined,
            }
        case 'GET_STORE_POS_INFO_SUCCESS':
            console.log('GET_STORE_POS_INFO_SUCCESS', action.payload);      

            return {
                ...state,
                loading: false,
                storeAddress: action.payload.address === null ? '' : action.payload.address,
                storePhoneNumber: action.payload.phoneNumber === null ? '' : action.payload.phoneNumber,
                storeWifiCode: action.payload.wifiCode === null ? '' : action.payload.wifiCode,
                storeToiletCode: action.payload.toiletCode === null ? '' : action.payload.toiletCode,
                storeBarPrinterIP: action.payload.barPrinterIP === null ? '' : action.payload.barPrinterIP,
            };

        case 'GET_STORE_POS_INFO_FAILED':
            return {
                ...state,
            };

        case 'SHOW_POS_LOADING':
            return {
                ...state,
                loading: true,
            }

        case 'UPDATE_STORE_ID': 
            return {
                ...state,
                storeId: action.payload,
            }

        default:
            return state
    }
}

export default configPOSReducer