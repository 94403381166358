import { call, put, takeEvery } from 'redux-saga/effects'
import { exportExcelInventoryStockReportApi, fetchInventoryStockReportApi } from 'src/api/InventoryStockApi'

/* FUNCTION */
function* loadReportWarehouseStock(action) {
  try {
    yield put({ type: 'SHOW_INVENTORY_STOCK_LOADING' })
    let res = yield call(fetchInventoryStockReportApi, action.payload)
    yield put({
      type: 'FETCH_INVENTORY_STOCK_REPORT_SUCCESSED',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'FETCH_INVENTORY_STOCK_REPORT_FAILED',
      payload: e.message,
    })
  }
}


function* exportExcelReportWarehouseStock(action) {
  try {
    yield put({ type: 'SHOW_INVENTORY_STOCK_LOADING' })
    let res = yield call(exportExcelInventoryStockReportApi, action.payload)
    yield put({
      type: 'EXPORT_EXCEL_INVENTORY_STOCK_REPORT_SUCCESSED',
      payload: res.data,
    })
  } catch (e) {
    yield put({
      type: 'EXPORT_EXCEL_INVENTORY_STOCK_REPORT_FAILED',
      payload: e.message,
    })
  }
}

export default function* rootSaga() {

  yield takeEvery('LOAD_INVENTORY_REPORT_STOCK', loadReportWarehouseStock)

  yield takeEvery('EXPORT_EXCEL_INVENTORY_REPORT_STOCK', exportExcelReportWarehouseStock)
  
}
