import materialReducer from "./materialReducer";
import warehouseReducer from "./warehouseReducer";
import goodsReceivedReducer from "./goodsReceivedReducer";
import { combineReducers } from "redux";
import supplierReducer from "./supplierReducer";
import brandReducer from "./brandReducer";
import uomReducer from "./uomReducer";
import stockReducer from "./stockReducer";
import stockRequestReducer from "./stockRequestReducer";
import stockTransferReducer from "./stockTransferReducer";
import stockDestructionReducer from "./stockDestructionReducer";
import sidebarShowReducer from "./sidebarShowReducer";
import manufacturerReducer from "./manufacturerReducer";
import categoryReducer from "./categoryReducer";
import materialBrandReducer from "./materialBrandReducer";
import conversionReducer from "./conversionReducer";
import formulaReducer from "./formulaReducer";
import stockTakeReducer from "./stockTakeReducer";
import stockReconcileReducer from "./stockReconcileReducer";
import stockBalanceReducer from "./stockBalanceReducer";
import purchaseOrderReducer from "./purchaseOrderReducer";
import siteReducer from "./siteReducer";
import salesReducer from "./pos/salesReducer";
import configPOSReducer from "./pos/configPOSReducer";
import userReducer from "./userReducer";
import feedbackReducer from "./feedbackReducer";
import storeReducer from "./storeReducer";
import voucherReducer from "./voucherReducer";
import scheduleReducer from "./scheduleReducer";
import goodsProduceReducer from "./goodsProduceReducer";
import authoModuleReducer from "./authoModuleReducer";
import payslipReducer from "./payslipReducer";
import shipperReducer from "./shipper/shipperReducer";
import cashInReducer from "./cashInReducer";
import handbookReducer from "./handbookReducer";
import revenueReducer from "./revenueReducer";
import employeeReducer from "./employeeReducer";
import employeeDetailReducer from "./employeeDetailReducer";
import storeUIdReducer from "./storeUIdReducer";
import reportReducer from "./reportReducer";
import profileTypeListState from "./profileTypeListReducer";
import orgReducer from "./orgReducer";
import webGroupState from './webGroup/rootWebGroupReducer'
import appState from './appReducer'
import orderState from './order/rootOrderReducer'
import transactionReducer from './pos/transManagementReducer';
import goodsTransferState from './goodsTransfer/rootGoodsTransferReducer';
import goodsDestructionState from './goodsDestruction/rootGoodsDestructionReducer';
import newMaterialState from './newMaterialReducer'
import bannerReducer from './bannerReducer';

import inventoryManageStockReducer from "./inventory/inventoryManageStockReducer";
import inventoryCheckStockReducer from "./inventory/inventoryCheckStockReducer";
import inventoryLostStockReducer from "./inventory/inventoryLostStockReducer";
import configPosContentDataReducer from "./configPos/PosContent/Data/configPosContentDataReducer";
import SRProduceReducer from './SRProduceReducer';
import SRDeliverReducer from './SRDeliverReducer';
import SRReceivedReducer from './SRReceivedReducer';
import commodityDeliveryReducer from './commodityDeliveryReducer';
import commodityCancelationReducer from './commodityCancelReducer';
import goodsReturnReducer from './goodsReturnReducer';
import saleOrderReducer from './saleOrderReducer';
import customerReducer from './customerReducer';
import GoodTransferReducer from "./GoodTransferReducer";
import InventoryStockReducer from "./InventoryReportReducer";

// import inventoryLostReducer from './inventory/inventoryLostReducer'
const rootReducer = combineReducers({
  materialState: materialReducer,
  goodsReceivedState: goodsReceivedReducer,
  warehouseState: warehouseReducer,
  //  inventoryLostState: inventoryLostReducer
   supplierState: supplierReducer,
   brandState: brandReducer,
   uomState: uomReducer,
   feedbackState: feedbackReducer,
   userState: userReducer,
   stockState: stockReducer,
   stockRequestState: stockRequestReducer,
   inventoryManageStockState:inventoryManageStockReducer,
   inventoryCheckStockState:inventoryCheckStockReducer,
   inventoryLostStockState:inventoryLostStockReducer,
   stockTransferState: stockTransferReducer,
   stockDestructionState: stockDestructionReducer,
   sidebarState: sidebarShowReducer,
   manufacturerState: manufacturerReducer,
   categoryState: categoryReducer,
   materialBrandState: materialBrandReducer,
   conversionState: conversionReducer,
   formulaState: formulaReducer,
   stockTakeState: stockTakeReducer,
   stockReconcileState: stockReconcileReducer,
   stockBalanceState: stockBalanceReducer,
   purchaseOrderState: purchaseOrderReducer,
   siteState: siteReducer,
   salesState: salesReducer,
   storesState: storeReducer,
   configPOSState: configPOSReducer,
   voucherState: voucherReducer,
   scheduleState: scheduleReducer,
   goodsProduceState: goodsProduceReducer,
   authoModuleState: authoModuleReducer,
   payslipState: payslipReducer,
   shipperState: shipperReducer,
   cashInState: cashInReducer,
   handbookState: handbookReducer,
   revenueState: revenueReducer,
   employeeState: employeeReducer,
   employeeDetailState: employeeDetailReducer,
   storesUIdState: storeUIdReducer,
   reportState: reportReducer,
   profileTypeListState,
   orgState: orgReducer,
   webGroupState,
   appState,
   orderState,
   transactionState: transactionReducer,
   goodsTransferState,
   newMaterialState,
   goodsDestructionState,
   bannerState: bannerReducer,
   configPosContentDataState :configPosContentDataReducer,
   SRProduceState: SRProduceReducer,
   SRDeliverState: SRDeliverReducer,
   SRReceivedState: SRReceivedReducer,
   cmdDeliveryState: commodityDeliveryReducer,
   cmdCancelationState: commodityCancelationReducer,
   goodsReturnState: goodsReturnReducer,
   saleOrderState: saleOrderReducer,
   customerState: customerReducer,
   goodTransferState :GoodTransferReducer,
   inventoryStockState:InventoryStockReducer
})

export default rootReducer;
