import axios from 'axios'
import constants, {APPS} from '../reusable/constants'

export function fetchSalesReportApi(payload) {
    return axios.get(
        constants.api.url + 'api/voucher/GetAllOrdersBetweenDates?fromDate=' + payload.fromDate + '&toDate=' + payload.toDate + '&storeId=' + payload.storeId,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchOrdersApi(payload) {
    let fromDate = payload.fromDate === undefined ? '' : '&fromDate=' + payload.fromDate;
    let toDate = payload.toDate === undefined ? '' : '&fromDate=' + payload.toDate;
    return axios.get(
        constants.api.url + 'api/voucher/GetOrders?appId=' + APPS.CO_UT + fromDate + toDate,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchOrderDetailApi(payload) {
    return axios.get(
        constants.api.url + 'api/voucher/GetOrderDetail?orderId=' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchSalesFilterReportApi(payload) {
    return axios.get(
        constants.api.url + 'api/voucher/GetAllTransactionReport?fromDate=' + payload.fromDate + '&toDate=' + payload.toDate + '&serviceMode=' + payload.serviceMode + '&paymentType=' + payload.paymentType + '&prettyOrderStatus=' + payload.prettyOrderStatus + '&transactionId=' + payload.transactionId,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchGetSaleDataDetailApi(payload) {
    return axios.get(
        constants.api.url + 'api/voucher/GetTransactionReportDetails?id='+ payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function getStorePOSInfoApi(payload) {
    return axios.get(
        constants.api.url + 'api/store/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function approveBillApi(payload) {
    return axios.put(
        constants.api.url + 'api/voucher/ApproveVoucherOrder/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function updateStoreApi(payload) {
    return axios.put(
        constants.api.url + 'api/store/' + payload.storeId,
        payload.postData,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function cancelBillApi(payload) {
    return axios.put(
        constants.api.url + 'api/voucher/CancelVoucherOrder/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function setOrderStatusApi(payload) {
   return axios.post(
      constants.api.url + 'api/ProductOnline/SetStatusOrder',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}