import styled from "styled-components";
export const CustomeText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  span {
    color: red;
  }

  &.h1-main {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 3.063rem;
    letter-spacing: 0.002em;
  }
  &.h1-sub {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.563rem;
    letter-spacing: 0.002em;
  }
  &.h2-main {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.75rem;
    letter-spacing: 0.002em;
  }
  &.h2-sub {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.125rem;
    letter-spacing: 0.002em;
  }
  &.h3-main {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.688rem;
    letter-spacing: 0.002em;
  }
  &.h3-sub {
    font-size: 1.13rem;
    font-weight: 400;
    line-height: 1.563rem;
    letter-spacing: 0.002em;
  }
  &.h4-main {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.002em;
  }
  &.h4-sub {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.002em;
  }
  &.bold {
    font-weight: 700;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 300;
  }
  &.italic {
    font-style: italic;
  }
  &.para-large {
    font-size: 1rem;
    line-height: 1.875rem;
  }
  &.para-main {
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  &.para-sub {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  &.para-ter {
    font-size: 0.63rem;
    line-height: 1rem;
  }
  &.center{
    text-align: center;
  }
  &.left{
    text-align: left;
  }
  &.right{
    text-align: right;
  }
  ${(props) => props.color && `color: ${props.color}!important;`}
  ${(props) => props.colorHover && `&:hover {color: ${props.colorHover};}`}
  word-break: break-word;
`;
export const isUseCorrectClassName = (className) => {
  if (className !== undefined && className !== null) {
    const classNameArr = [
      "h1-main",
      "h1-sub",
      "h2-main",
      "h2-sub",
      "h3-main",
      "h3-sub",
      "h4-main",
      "h4-sub",
      "bold",
      "semi-bold",
      "medium",
      "regular",
      "italic",
      "para-large",
      "para-main",
      "para-sub",
      "para-ter",
    ];
    for (let i = 0; i < classNameArr.length; i++) {
      if (className.includes(classNameArr[i])) {
        return true;
      }
    }
    return false;
  }
};
