import {changeKeyName} from './rootWebGroupReducer'

const initialState = {
    listDataAccessWarehouse: [],
    message: '',
    warehouseLoading: true,
}


const webGroupWarehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_DATA_ACCESS_STORE_BY_WEB_GROUP_ID':
            return {
                ...state,
                warehouseLoading: true,
            }

        case 'FETCH_DATA_ACCESS_WAREHOUSE_BY_WEB_GROUP_ID_SUCCESS':
            return {
                ...state,
                listDataAccessWarehouse: changeKeyName('warehouseId', 'warehouseName', action.payload.data),
                warehouseLoading: false,
                message: ''
            }

        case 'FETCH_DATA_ACCESS_WAREHOUSE_BY_WEB_GROUP_ID_FAILED':
            return {
                ...state,
                warehouseLoading: false,
                message: action.payload
            }

        default:
            return state
    }
}

export default webGroupWarehouseReducer