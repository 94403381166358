const initialState = {
  sumNetAmount: 0,
  details: []
}

const revenueQuarterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_REVENUE_QUARTER_SUCCESS':
      const { data: quarter } = action.payload
      if (quarter.details.length > 0) {
        const netAmountArr = quarter.details.map(item => item.netAmount)
        const quarterArr = quarter.details.map(item => item.quater)
        return{
          ...quarter,
          storeName: quarter.details[0].storeName,
          netAmountArr,
          quarterArr
        }
      }
      return {...quarter}
      
    default:
      return state;
  }
};

export default revenueQuarterReducer;
