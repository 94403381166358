import axios from 'axios'
import constants from '../reusable/constants';
import moment from 'moment';

export function fetchRevenue(obj = {}) {
    const fromDate = obj.fromDate
    const toDate = obj.toDate
    const storeId = obj.storeId

    return axios.get(
        //constants.api.url + 'api/PortalV2/GetRevenue?fromDate=2021-01-01&toDate=2021-10-11&storeId=2',
        constants.api.url + `api/PortalV2/GetRevenue?fromDate=${fromDate}&toDate=${toDate}&storeId=${storeId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchAllPaymentType() {
    return axios.get(
        constants.api.url + `api/PortalV2/GetAllPaymentType`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchAllServiceMode() {
    return axios.get(
        constants.api.url + `api/PortalV2/GetAllServiceMode`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchAllStatusTransaction() {
    return axios.get(
        constants.api.url + `api/PortalV2/GetAllStatusTransaction`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchAllStatusPayment() {
    return axios.get(
        constants.api.url + `api/PortalV2/GetAllStatusPayment`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function getAllTransactionAsync(payload) {
    return axios.get(
        constants.api.url + 
        `api/PortalV2/GetAllTransactionAsync?fromDate=${payload.fromDate}&toDate=${payload.toDate}&storeId=${payload.storeId}&ServiceMode=${payload.serviceMode}&PaymentType=${payload.paymentType}&StatusTransaction=${payload.statusTrans}&StatusPayment=${payload.statusPayment}&VoucherOrderId=${payload.voucherOrderId}&UId=${payload.userId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function getAllTransactionV2Async(payload) {
    return axios.get(
        constants.api.url + 
        `api/PortalV2/GetAllTransactionV2Async?fromDate=${payload.fromDate}&toDate=${payload.toDate}&storeId=${payload.storeId}&ServiceMode=${payload.serviceMode}&PaymentType=${payload.paymentType}&StatusTransaction=${payload.statusTrans}&StatusPayment=${payload.statusPayment}&VoucherOrderId=${payload.voucherOrderId}&UId=${payload.userId}&page=${payload.page}&pagesize=${payload.pageSize}&statusInvoice=${payload.statusInvoice}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function getInfiniteTransactionV2Async(payload) {
    console.log('getInfiniteTransactionV2Async payload ========>', payload);

    return axios.get(
        constants.api.url + 
        `api/PortalV2/GetAllTransactionV2Async?fromDate=2022-01-01&toDate=${moment(new Date()).format('YYYY-MM-DD')}&storeId=${payload.storeId || 0}&ServiceMode=0&PaymentType=0&StatusTransaction=0&StatusPayment=1&VoucherOrderId=0&UId=${payload.userId}&page=${payload.page}&pagesize=${payload.pageSize}&statusInvoice=0`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function getTransactionV2ById(payload) {
    return axios.get(
        constants.api.url + 
        `api/PortalV2/GetAllTransactionV2Async?fromDate=2022-01-01&toDate=${moment(new Date()).format('YYYY-MM-DD')}&storeId=0&ServiceMode=0&PaymentType=0&StatusTransaction=0&StatusPayment=1&VoucherOrderId=${payload.voucherOrderId}&UId=&page=0&pagesize=0&statusInvoice=0`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}


