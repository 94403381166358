const initialState = {
    isLoading: false,
    storeId: 0,
    storeName: '',
    sumRevenueNetAmount: '',
    count: 0,
    details: [],
 }
   
 const revenueComboReducer = (state = initialState, action) => {
     switch (action.type) {
        case 'SHOW_LOADING_REVENUE_COMBO':
            return {
                isLoading: true,
            }  
         case 'LOAD_REVENUE_COMBO_SUCCESS':
             return {
                 ...action.payload.data,
                 isLoading: false,
             }  
         default:
             return state;
     }
   };
   
   export default revenueComboReducer;