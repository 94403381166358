const initialState = {
  loading: false,
  stores: [],
}

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_STORE_LOADING':
      return {
        ...state,
        loading: true,
      }

    case 'FETCH_STORE_SUCCEEDED':
      return {
        ...state,
        stores: action.payload,
        loading: false,
      }

    case 'FETCH_STORE_UID_V2_SUCCEEDED':
      return {
        ...state,
        stores: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default storeReducer
