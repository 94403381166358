const initialState = {
    loading: false,
    employeeList: [],
    message: '',
    createNew: null,
    createNewStatus: null,
    updateUserStatusLoading: false,
    updateUserStatusStatus: null,
}

const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_EMPLOYEE_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'LOAD_EMPLOYEE_SUCCESS':
            return {
                ...state,
                employeeList: action.payload.data,
                loading: false,
                message: ''
            }
        case 'LOAD_EMPLOYEE_FAILED':
            return {
                ...state,
                employeeList: [],
                loading: false,
                message: action.payload
            }

        case 'SHOW_CREATE_NEW_EMPLOYEE_LOADING':
            return {
                ...state,
                loading: true,
                createNew: null,
                createNewStatus: null
            }

        case 'CREATE_NEW_EMPLOYEE_SUCCESS':
            return {
                ...state,
                loading: false,
                message: '',
                createNew: action.payload,
                createNewStatus: 'success',
                employeeList: [...state.employeeList, action.payload.createNew]
            }

        case 'CREATE_NEW_EMPLOYEE_FAILED':
            return {
                ...state,
                loading: false,
                message: action.payload,
                createNew: null,
                createNewStatus: 'failed'
            }

        case 'CLEAR_CREATE_NEW_EMPLOYEE':
            return {
                ...state,
                createNewStatus: null
            }

        case 'SHOW_UPDATE_USER_STATUS_EMPLOYEE_LOADING':
            return {
                ...state,
                updateUserStatusLoading: true,
                updateUserStatusStatus: null,   
            }

        case 'UPDATE_USER_STATUS_EMPLOYEE_SUCCESS':
            const { staffCode, userStatus } = action.payload.updateData
            const newArr = [...state.employeeList]
            const result = newArr.find(employee => employee.staffCode === staffCode)
            result.userStatus = userStatus
            //tìm cái thằng nào có staffCode giống cái update, sau đó gán lại giá trị userStatus mới update

            return {
                ...state,
                message: '',
                updateUserStatusLoading: false,
                updateUserStatusStatus: 'success', 
                employeeList: newArr
            }

        case 'UPDATE_USER_STATUS_EMPLOYEE_FAILED':
            return {
                ...state,
                message: action.payload,
                updateUserStatusLoading: false,
                updateUserStatusStatus: 'failed'
            }

        case 'CLEAR_UPDATE_USER_STATUS_EMPLOYEE':
            return {
                ...state,
                updateUserStatusStatus: null
            }

        default:
            return state
    }
}

export default employeeReducer