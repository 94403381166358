const initialState = {
    loading: false,
    items: [],
    token: '',
    managementToken: '',
    feedbackData: [],
    formValidationFailed: false,
    checkingDateFrom: '',
    checkingDateTo: '',
    checkingWarehouse: '',
    feedbackResult: [],
    feedbackDetail: [],
    feedbacks: [],
    feedbackTransaction: '',
    submitNewFeedbackGetDataStatus: ''
}

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_FEEDBACK_SUCCEEDED':
            return {
                ...state,
                feedbackData: action.payload, 
                loading: false
            }

        case 'UPDATE_CHECKING_DATE_FROM':
            return {
                ...state,
                checkingDateFrom: action.payload
            }

        case 'UPDATE_CHECKING_DATE_TO':
            return {
                ...state,
                checkingDateTo: action.payload
            }

        case 'SUBMIT_NEW_FEEDBACK_SUCCEEDED':
            return {
                ...state,
                submitNewFeedbackGetDataStatus: 1,
                feedbackData: action.payload,
                loading: false
            }
        case 'SUBMIT_NEW_FEEDBACK_FAILED':
            return {
                ...state,
                submitNewFeedbackGetDataStatus: 2
            }
        // case 'UPDATE_FEEDBACK_TRANSACTION':
        //     return {
        //         ...state,
        //         feedbackTransaction: action.payload
        //     }
        // case 'FETCH_FEEDBACKS_SUCCEEDED':
        //     return {
        //         ...state,
        //         feedbacks: action.payload
        //     }
        // case 'FEEDBACK_SUCCEEDED':
        //     return {
        //         ...state,
        //         feedbackResult: action.payload
        //     }

        // case 'SUBMIT_NEW_FEEDBACK_GET_DATA_SUCCEEDED':
        //     return {
        //         ...state,
        //         submitNewFeedbackGetDataStatus: 1,
        //         loading: false,
        //         formValidationFailed: false,
        //         checkingDate: '',
        //         checkingWarehouse: '',
        //         feedbackData: '',
        //     }

        // case 'FETCH_FEEDBACK_TRANSACTIONS_SUCCEEDED':            
        //     return {
        //         ...state,
        //         feedbackTransactions: action.payload,
        //         loading: false
        //     }

        case 'FETCH_FEEDBACK_DETAIL_SUCCEEDED':
            return {
                ...state,
                feedbackDetail: action.payload,
                loading: false
            }

        // case 'FETCH_FEEDBACK_DATA_PRODUCT_SOLD_SUCCEEDED':
        //     return {
        //         ...state,
        //         feedbackData: action.payload,
        //         loading: false
        //     }

        // case 'FETCH_FEEDBACK_MANAGEMENT_TOKEN_SUCCEEDED':
        //     return {
        //         ...state,
        //         managementToken: action.payload.token,
        //         loading: false
        //     }
        // case 'FETCH_FEEDBACK_TOKEN_SUCCEEDED':
        //     return {
        //         ...state,
        //         token: action.payload.token,
        //         loading: false
        //     }

        case 'CLEAR_DATA':
            return {
                ...initialState
            }

        // case 'UPDATE_FORM_VALIDATION':
        //     return {
        //         ...state,
        //         formValidationFailed: action.payload
        //     }
        case 'UPDATE_CHECKING_WAREHOUSE':
            return {
                ...state,
                checkingWarehouse: action.payload
            }

        case 'SHOW_FEEDBACK_LOADING':
            return {
                ...state,
                loading: true
            }

        default:
            return state
    }
}

export default feedbackReducer