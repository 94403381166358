import axios from 'axios'
import constants from '../reusable/constants'

export function createStockReconcileApi(payload) {
    return axios.post(
        constants.api.url + 'api/stockReconcile',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchStockReconcilesApi(payload) {
  console.log('payload', payload)
    return axios.get(
        constants.api.url + 'api/stockReconcile/getStockReconciles/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchStockReconcileDetailApi(payload) {
    return axios.get(
        constants.api.url + 'api/stockReconcile/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function createNewLoopGetDataApi(payload) {
    return axios.post(
        constants.api.url + 'api/stockReconcile/createLoopGetData',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}


export function fetchLoopTransactionsApi(payload) {
    return axios.get(
        constants.api.url + 'api/stockReconcile/getLoopTransactions/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchGetLoopDataDetailApi(payload) {
    return axios.get(
        constants.api.url + 'api/stockReconcile/GetLoopTransactionById/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchLoopTokenApi() {
    return axios.post(
        constants.loopApi.login.url,
        {
            "phone": "0908914105",
            "password": "tieulam266158+"
        }
    )
}

export function fetchLoopManagementTokenApi(payload) {
    const config = {
        headers: { Authorization: 'Bearer ' + payload }
    }

    return axios.post(
        constants.loopApi.loginToManagement,
        {
            "shopRoleId": "279600cd-d723-41ac-b86a-a076f4b5beaa"
        },
        config
    )
}

export function fetchLoopDataApi(payload) {
    const config = {
        headers: { Authorization: 'Bearer ' + payload.managementToken }
    }

    let shopId = ''

    switch (payload.checkingWarehouse) {
        case "1":
            shopId = ''
            break
        case "2":
            shopId = '9bfa4928-25d1-47d9-8809-998a6268b0d5'
            break
        case "3":
            shopId = '0a6a31c0-3e37-42c3-a85c-7788d07a702e'
            break
    }

    return axios.post(
        constants.loopApi.productSold,
        {
            "fromDate": payload.checkingDateFrom,
            "shopId": shopId,
            "toDate": payload.checkingDateTo
        },
        config
    );
}
