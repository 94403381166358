const initialState = {
  showModal: false,
  loading: false,
  items: [],
  token: '',
  managementToken: '',
  showModal: false,
  formValidationFailed: false,
}

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_DATA':
      return {
        ...initialState
      }

    case 'UPDATE_FORM_VALIDATION':
      return {
        ...state,
        formValidationFailed: action.payload
      }
    case 'UPDATE_CHECKING_WAREHOUSE':
      return {
        ...state,
        checkingWarehouse: action.payload
      }


    case 'SHOW_STOCK_LOADING':
      return {
        ...state,
        loading: true
      }

    case 'FETCH_WAREHOUSE_STOCK_SUCCEEDED':
      console.log('FETCH_WAREHOUSE_STOCK_SUCCEEDED', action.payload)
      return {
        ...state,
        items: action.payload,
        loading: false
      }
    case 'FETCH_LOST_INVENTORY_SUCCEEDED':
      console.log('FETCH_LOST_INVENTORY_SUCCEEDED', action.payload)
      return {
        ...state,
        items: action.payload,
        loading: false
      }
    case 'FETCH_LOST_INVENTORY_FAILED':
      console.log('FETCH_LOST_INVENTORY_FAILED', action.payload)
      break
    case 'FETCH_CHECK_INVENTORY_SUCCEEDED':
      console.log('FETCH_CHECK_INVENTORY_SUCCEEDED', action.payload)
      return {
        ...state,
        items: action.payload,
        loading: false
      }
    case 'FETCH_CHECK_INVENTORY_FAILED':
      console.log('FETCH_LOST_INVENTORY_FAILED', action.payload)
      break
    case 'SHOW_BARCODE_MODAL':
      return {
        ...state,
        barcodes: [
          {
            upc: action.payload.upc,
            sku: action.payload.sku
          }
        ],
        showModal: true
      }

    case 'SHOW_ALL_BARCODES_MODAL':
      return {
        ...state,
        barcodes: action.payload,
        showModal: true
      }

    case 'HIDE_MODAL':
      return {
        ...state,
        showModal: false
      }

    default:
      return state
  }
}

export default stockReducer
