const initialState = {
  loading: false,
  orgCharts: [],
  employees: [],
};
// const initialModifyCodeModalState = {
//     showModifyCodeModal: false,
//     modalNewCode: '',
//     modalMaterialId: ''
// }

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_ORG_CHART_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ORG_CHART_SUCCEEDED":
      return {
        ...state,
        orgCharts: action.payload,
        loading: false,
      };
    case "FETCH_LISTEMPLOYEE_SUCCEEDED":
      return {
        ...state,
        employees: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default orgReducer;
