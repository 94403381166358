import axios from 'axios'
import constants from '../reusable/constants'

export function fetchStore() {
    return axios.get(
        constants.api.url + 'api/store',
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function fetchStoreUId(payload) {
    const { uId } = payload
    return axios.get(
        /* constants.api.url + 'api/store', */
        constants.api.url + `api/Store/GetAllStores?UId=${uId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}

export function fetchStoreUIdV2(payload) {
    const { uId } = payload
    return axios.get(
        /* constants.api.url + 'api/store', */
        constants.api.url + `api/Store/GetAllStoresV2?UId=${uId}`,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
}