const initialState = {
   loading: false,
   payslips: [],
   submitNewPayslipStatus: 0,
   formValidationFailed: false,
   attachedFile: "",
   errorMessage: "",
   month: '',
   payslipDetails: [],
   sendEmailPayslipStatus: 0,
   defualtValueMonth: ''
}

const payslipReducer = (state = initialState, { type, payload }) => {
   switch (type) {
      case 'SHOW_PAYSLIP_LOADING':
         return {
            ...state,
            loading: true,
         }
      case 'SHOW_PAYSLIP_LOADING_SUCCEEDED':
         return {
            ...state,
            loading: false,
            submitNewPayslipStatus: 1
         }
      case 'SHOW_PAYSLIP_LOADING_FAILED':
         return {
            ...state,
            loading: false,
            submitNewPayslipStatus: 2
         }
      case 'UPDATE_ATTACHED_FILE_PAYSLIP':
         return {
            ...state,
            attachedFile: payload
         }
      case 'CLEAR_DATA_PAYSLIP':
         return {
            ...initialState,
            submitNewPayslipStatus: 0,
            sendEmailPayslipStatus: 0,
         }
      case 'LOAD_PAYSLIP_SUCCEEDED':
         return {
            ...state,
            payslips: payload,
            loading: false,
         }
      case 'LOAD_PAYSLIP_FAILED':
         return {
            ...state,
            errorMessage: payload,
            loading: false
         }
      case 'UPDATE_PAYSLIP_MONTH':
         return {
            ...state,
            month: payload
         }
      case 'LOAD_PAYSLIP_DETAILS_SUCCEEDED':
         return {
            ...state,
            payslipDetails: payload,
            loading: false
         }
      case 'LOAD_PAYSLIP_DETAILS_FAILED':
         return {
            ...state,
            errorMessage: payload,
            loading: false
         }
      case 'SEND_EMAIL_PAYSLIP_SUCCEEDED':
         return {
            ...state,
            loading: false,
            sendEmailPayslipStatus: 1,
            payslipDetails: payload
         }
      case 'SEND_EMAIL_PAYSLIP_FAILED':
         return {
            ...state,
            loading: false,
            sendEmailPayslipStatus: 2
         }
      case 'RESET_SEND_EMAIL_PAYSLIP_STATUS':
         return {
            ...state,
            loading: false,
            sendEmailPayslipStatus: 0
         }
      default: {
         return state
      }
   }
}

export default payslipReducer;