const initialState = {
  statusList: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_LIST_STATUS_GOODS_DESTRUCTION_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "FETCH_LIST_STATUS_GOODS_DESTRUCTION_SUCCESS":
      return {
        ...state,
        statusList: action.payload.data,
        loading: false,
      }

    case "FETCH_LIST_STATUS_GOODS_DESTRUCTION_FAILED":
      return {
        ...state,
        loading: false,
      }

    default:
      return state;
  }
};

export default reducer;
