import React, { createContext, useReducer } from 'react'

export const TFAContext = createContext()

export const TFAContextProvider = (props) => {
  const initialState = {
    TFAChecked: null,
  }

  const [stateTFA, dispatchTFA] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_TFA_CHECKED':
        return {
          ...state,
          TFAChecked: action.payload,
        }
      default:
        return state
    }
  }, initialState)

  return (
    <TFAContext.Provider value={{ stateTFA, dispatchTFA }}>
      {props.children}
    </TFAContext.Provider>
  )
}
