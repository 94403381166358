import axios from "axios";
import constants from "../reusable/constants";

export function fetchCommodityDeliveryApi(payload) {
    const url = constants.api.url + "api/CommodityDelivery"
    + `?page=${payload.page}&pageSize=${payload.pageSize}&UId=${payload.UId}`
    +  `&MaterialId=${payload.MaterialId}&FromDate=${payload.FromDate}&ToDate=${payload.ToDate}`
    + `&Code=${payload.Code || ''}&Status=${payload.Status}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function checkCodeExistCmdDelivery(payload) {
    const url = constants.api.url + "api/CommodityDelivery/CheckCodeCommodityDeliveryExist";

    return axios.put(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function createNewCmdDelivery(payload) {
    const url = constants.api.url + "api/CommodityDelivery";

    return axios.post(
        url, payload,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchCmdDeliveryById(payload) {
    const url = constants.api.url + `api/CommodityDelivery/${payload}`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateStatusCmdDelivery(payload) {
    const url = constants.api.url + `api/CommodityDelivery/UpdateStatusCommodityDelivery`;

    return axios.put(
        url, payload, 
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function updateCmdDelivery(payload) {
    const url = constants.api.url + `api/CommodityDelivery/UpdateCommodityDelivery`;

    return axios.put(
        url, payload, 
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}

export function fetchCodeCmdDelivery() {
    const url = constants.api.url + `api/SRCode/GetCodeCommodityDeliverysAsync`;

    return axios.get(
        url,
        { 
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } 
        }
    );
}