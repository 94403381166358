import { select } from "redux-saga/effects"
import { fetchSalesFilterReportApi } from "src/api/salesApi"

const initialState = {
   transferredDate: null,
   description: '',
   attachedFile: null,
   stockRequestId: 0,
   items: [],
   submitNewStockTransferStatus: 0,
   submitStockStransferWorkFlow: 0,
   selectedMaterialInstances: [],
   modalValidationFailed: false,
   submitWorkFlowActionStatus: 0,

   showRequestWorkFlowModal: false,
   modalMaterialId: null,
   modalMaterialCode: null,
   modalMaterialName: null,
   modalMaterialBaseUom: null,
   modalBrandId: null,
   modalBrandName: null,
   modalLotNumber: null,
   modalRequiredDate: null,
   modalUomId: null,
   modalUomName: null,
   modalBaseStockQuantity: null,
   modalRequestQuantity: null,
   modalNote: null,

   showModal: false,
   modalDescription: "",
   defaultBrandSelected: true,
   defaultUomSelected: true,
   comboboxItems: [],
   stockTransferDetail: null,
   stockRequestList: [],

   approveFlag: 0,
   createFlag: 0,
   deleteFlag: 0,
   editFlag: 0,
   viewFlag: 0,
}

const stockTransferReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_AUTHO_MODULE_STOCK_TRANSFER':
         return {
            ...state,
            createFlag: action.payload.createFlag,
            approveFlag: action.payload.approveFlag,
            deleteFlag: action.payload.deleteFlag,
            editFlag: action.payload.editFlag,
            viewFlag: action.payload.viewFlag,
         }
      case 'SHOW_STOCK_TRANSFER_LOADING':
         return {
            ...state,
            loading: true
         }

      case 'CLEAR_FIELDS_STOCK_TRANSFER':
         return {
            ...state,
            transferredDate: null,
            submitNewStockTransferStatus: 0,
            submitStockStransferWorkFlow: 0,
            submitWorkFlowActionStatus: 0,
            description: "",
            stockTransferDetail: null,
            attachedFile: null
         }
      case 'FETCH_STOCK_REQUEST_STOCK_TRANSFER_SUCCEEDED':
         console.log('REQUEST LIST +>>>>>>>>>>>', action.payload)
         return {
            ...state,
            stockRequestList: action.payload,
            loading: false,
         }
      case 'FETCH_STOCK_REQUEST_STOCK_TRANSFER_FAILED':
         return {
            ...state,
            loading: false,
         }
      case 'SUBMIT_STOCK_TRANSFER_WORK_FLOW_SUCCESS':
         return {
            ...state,
            submitWorkFlowActionStatus: 1
         }
      case 'SUBMIT_STOCK_TRANSFER_WORK_FLOW_FAILED':
         return {
            ...state,
            submitWorkFlowActionStatus: 2
            // submitNewStockTransferStatus: 2
         }
      case 'GENERATE_ORIGINAL_ITEMS_STOCK_TRANSFER':
         let originalItems = []
         let materialState = action.payload
         materialState && materialState.forEach(item => {
            originalItems.push({
               id: item.id,
               value: '(' + item.code + ') ' + item.name,
               code: item.code,
               name: item.name,
               image: item.image,
               baseUomName: item.baseUomName
            })
         })
         return {
            ...state,
            comboboxItems: originalItems
         }
      case 'UPDATE_TRANSFERRED_DATE_STOCK_TRANSFER':
         return {
            ...state,
            transferredDate: action.payload
         }

      case 'UPDATE_DESCRIPTION_STOCK_TRANSFER':
         return {
            ...state,
            description: action.payload
         }

      case 'UPDATE_STOCK_REQUEST_ID':
         return {
            ...state,
            stockRequestId: action.payload
         }

      case 'UPDATE_ATTACHED_FILE_STOCK_TRANSFER':
         return {
            ...state,
            attachedFile: action.payload
         }

      case 'UPDATE_SELECTED_MATERIAL_INSTANCES':
         let payload = action.payload
         let selected = state.selectedMaterialInstances
         let founds = selected.filter(item => {
            return item.id === payload.id && item.warehouseId === payload.warehouseId
         })
         if (founds.length > 0) {
            let index = selected.indexOf(founds[0])
            selected.splice(index, 1)
         }
         else {
            selected.push({
               id: payload.id,
               warehouseId: payload.warehouseId
            })
         }
         return {
            ...state,
            selectedMaterialInstances: selected
         }

      case 'UPDATE_TRANSFER_BASE_QUANTITY':
         let selected2 = state.selectedMaterialInstances
         let founds2 = selected2.filter(item => {
            return item.id === action.payload.id && item.warehouseId === action.payload.warehouseId
         })
         if (founds2.length > 0) {
            let index = selected2.indexOf(founds2[0])
            selected2.splice(index, 1)
            selected2.push({
               id: action.payload.id,
               warehouseId: action.payload.warehouseId,
               baseQuantity: parseFloat(action.payload.value),
               quantity: 0,
            })
         }
         return {
            ...state,
            selectedMaterialInstances: selected2
         }

      case 'UPDATE_TRANSFER_QUANTITY':
         let selected3 = state.selectedMaterialInstances
         let founds3 = selected3.filter(item => {
            return item.id === action.payload.id && item.warehouseId === action.payload.warehouseId
         })
         if (founds3.length > 0) {
            let index = selected3.indexOf(founds3[0])
            selected3.splice(index, 1)
            selected3.push({
               id: action.payload.id,
               warehouseId: action.payload.warehouseId,
               quantity: parseFloat(action.payload.value),
               baseQuantity: 0,
            })
         }
         return {
            ...state,
            selectedMaterialInstances: selected3
         }


      case 'CLEAR_SELECTED_MATERIAL_INSTANCE':
         return {
            ...state,
            selectedMaterialInstances: []
         }

      case 'UPDATE_MODAL_MATERIAL':
         return {
            ...state,
            modalMaterialId: action.payload.id,
            modalMaterialCode: action.payload.code,
            modalMaterialName: action.payload.name,
            modalMaterialBaseUom: action.payload.baseUom,
            defaultBrandSelected: true,
            defaultUomSelected: true
         }
      case 'UPDATE_MODAL_BRAND':
         return {
            ...state,
            modalBrandId: action.payload.id,
            modalBrandName: action.payload.name,
            modalUomId: '',
            modalUomName: '',
         }
      case 'UPDATE_MODAL_UOM':
         return {
            ...state,
            modalUomName: action.payload.name,
            modalUomId: action.payload.id,
            defaultUomSelected: false
         }
      case 'UPDATE_MODAL_BASE_STOCK_QUANTITY':
         return {
            ...state,
            modalBaseStockQuantity: action.payload
         }

      case 'UPDATE_MODAL_REQUEST_QUANTITY':
         return {
            ...state,
            modalRequestQuantity: action.payload
         }
      case 'UPDATE_REQUIRED_DATE':
         return {
            ...state,
            modalRequiredDate: action.payload
         }
      case 'UPDATE_MODAL_NOTE_STOCK_REQUEST':
         return {
            ...state,
            modalNote: action.payload
         }

      case 'INSERT_ITEM':
         let items = state.items
         items.push({
            id: state.modalMaterialId,
            code: state.modalMaterialCode,
            name: state.modalMaterialName,
            brandId: state.modalBrandId,
            uomId: state.modalUomId,
            uomName: state.modalUomName,
            baseStockQuantity: state.modalBaseStockQuantity,
            requestQuantity: state.modalRequestQuantity,
            requiredDate: state.modalRequiredDate,
            note: state.modalNote
         })
         return {
            ...state,
            items
         }

      case 'HIDE_MODAL_STOCK_TRANSFER':
         return {
            ...state,
            showModal: false,
            showRequestWorkFlowModal: false,
         }

      case 'SHOW_MODAL':
         console.log("transfer")
         return {
            ...state,
            showModal: true
         }
      case 'CLEAR_MODAL_STOCK_TRANSFER':
         return {
            ...state,
            modalMaterialId: "",
            modalMaterialCode: "",
            modalMaterialName: "",
            modalDescription: "",
            modalLotNumber: "",
            modalExpiryDate: "",
            modalUomId: "",
            modalUomName: "",
            modalBaseQuantity: "",
            modalQuantity: "",
            modalBaseQuantityOnHand: "",
            modalQuantityOnHand: "",
            defaultUomSelected: true
         }
      case 'SHOW_REQUEST_WORK_FLOW_MODAL_STOCK_TRANSFER':
         return {
            ...state,
            showRequestWorkFlowModal: true,
         }

      case "UPDATE_REQUEST_WORK_FLOW_MODAL_DESCRIPTION_STOCK_TRANSFER":
         return {
            ...state,
            modalDescription: action.payload,
         };

      case "UPDATE_MODAL_VALIDATION_STOCK_TRANSFER":
         return {
            ...state,
            modalValidationFailed: action.payload,
         };

      case 'SUBMIT_NEW_STOCK_TRANSFER_SUCCEEDED':
         return {
            ...state,
            submitNewStockTransferStatus: 1,
            loading: false
         }

      case 'SUBMIT_NEW_STOCK_TRANSFER_FAILED':
         return {
            ...state,
            submitNewStockTransferStatus: 2,
            loading: false
         }

      case 'FETCH_STOCK_TRANSFERS_SUCCEEDED':
         return {
            ...state,
            loading: false,
            stockTransferList: action.payload
         }

      case 'FETCH_STOCK_TRANSFER_DETAIL_SUCCEEDED':
         return {
            ...state,
            loading: false,
            stockTransferDetail: action.payload
         }
      case 'FETCH_STOCK_TRANSFER_DETAIL_FAILED':
         return {
            ...state,
            loading: false,
         }
      case 'CLEAR_STOCK_TRANSFER_DETAIL':
         return {
            ...state,
            stockTransferDetail: null
         }
      default:
         return state
   }
}

export default stockTransferReducer