
import axios from 'axios'
import constants from '../reusable/constants'

export function fetchStockRequestPendingApi(payload) {
  return axios.get(
      constants.api.url + 'api/PurchaseOrder/GetPendingStockRequest',
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function fetchPurchaseOrderDetailApi(payload) {
  return axios.get(
      constants.api.url + 'api/PurchaseOrder/' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function fetchPurchaseOrderApi(payload) {
  return axios.get(
      constants.api.url + 'api/PurchaseOrder?uId='+payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function createPurchaseOrderApi(payload) {
  return axios.post(
      constants.api.url + 'api/PurchaseOrder',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}


//MỚi
export function fetchPurchaseOrderByFilter({ UId, WarehouseId, FromDate, ToDate, StockRequestCode, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn}) {
  return axios.get(
     constants.api.url + 
     `api/PurchaseOrder?UId=${UId}&WarehouseId=${WarehouseId}&FromDate=${FromDate}&ToDate=${ToDate}&StockRequestCode=${StockRequestCode}&MaterialId=${MaterialId}&Status=${Status}&page=${currentPage}&pageSize=${pageSize}&orderby=${orderBy}&nameColumn=${nameColumn}`
     ,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function fetchListStatusPurchaseOrder() {
  return axios.get(
     constants.api.url + 
     `api/PurchaseOrder/GetListStatusPurchaseOrder`,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function fetchPurchaseOrderById({id}) {
  return axios.get(
     constants.api.url + 
     `api/PurchaseOrder/GetPurchaseOrderV2?id=${id}`,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

export function submitPurchaseOrderApi(payload) {
  if(payload.id || payload.id === 0){
    return axios.put(
      constants.api.url + 'api/PurchaseOrder',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
  }
  
  return axios.post(
    constants.api.url + 'api/PurchaseOrder',
    payload,
    { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}

/* export function deletePurchaseOrderApi({id, uId}) {
  return axios.delete(
     constants.api.url + 
     `api/PurchaseOrder/${id}?uId=${uId}`,
     { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
} */

export function deletePurchaseOrderApi({id, uId}) {
  const putMethod = {
    method: 'DELETE',
    headers: {
     'Content-type': 'application/json; charset=UTF-8',
     'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
    },
   }
  return fetch(constants.api.url + `api/PurchaseOrder/${id}?uId=${uId}`, putMethod);
}

export function fetchStockRequestPendingByIdApi({id}) {
  return axios.get(
      constants.api.url + `api/PurchaseOrder/GetPendingStockRequest?id=${id}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
  );
}