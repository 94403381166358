import axios from 'axios'
import constants from '../reusable/constants'

export function createGoodsReceivedApi(payload) {
   return axios.post(
      constants.api.url + 'api/goodsReceived',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function updateGoodsReceivedApi(payload) {
   return axios.put(
      constants.api.url + 'api/goodsReceived',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGoodsReceivedsApi(payload) {
   return axios.get(
      constants.api.url + 'api/goodsReceived?uId=' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGoodsReceivedDetailApi(payload) {
   return axios.get(
      constants.api.url + 'api/goodsReceived/' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGoodsReceivedsV2({userId, WarehouseId, FromDate, ToDate, StockRequestCode, MaterialId, Status, currentPage, pageSize, orderBy, nameColumn}) {
   return axios.get(
      constants.api.url + `api/GoodsReceived?UId=${userId}&page=${currentPage}&pageSize=${pageSize}&WarehouseId=${WarehouseId}&MaterialId=${MaterialId}&FromDate=${FromDate}&ToDate=${ToDate}&GoodsReceivedCode=${StockRequestCode}&Status=${Status}&nameColumn=${nameColumn}&orderby=${orderBy}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function deleteGoodsReceived(payload) {
   const deleteMethod = {
      method: 'DELETE',
      headers: {
       'Content-type': 'application/json; charset=UTF-8',
       'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
     }
   return fetch(constants.api.url + `api/GoodsReceived/${payload.id}?UId=${payload.userId}`, deleteMethod);
}