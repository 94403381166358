import axios from 'axios'
import constants from '../reusable/constants'

export function createGoodsProduceApi(payload) {
   return axios.post(
      constants.api.url + 'api/GoodsProduce',
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGoodsProducesApi(payload) {
   return axios.get(
      constants.api.url + `api/goodsProduce/GetGoodsProduce?uId=${payload.uid}&page=0&pageSize=0&warehouseId=${payload.warehouseId}&status=${payload.status}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGoodsProducesApiV2(payload) {
   return axios.get(
      constants.api.url + `api/goodsProduce/GetGoodsProduce?uId=${payload.uid}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGoodsProduceDetailApi(payload) {
   return axios.get(
      constants.api.url + 'api/goodsProduce/' + payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function fetchGoodsProduceReportApi(payload) {
   return axios.get(
      constants.api.url + 'api/goodsProduce/GetAllProducesBetweenDates?fromDate=' + payload.fromDate + '&toDate=' + payload.toDate + '&forWarehouse=' + payload.forWareHouse,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   )
}

export function getAllStatusGoodsProduce() {
   return axios.get(
      constants.api.url + `api/GoodsProduce/GetAllStatus`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}


export function updateGoodsProduce(payload) {
   return axios.put(
      constants.api.url + `api/GoodsProduce/UpdateGoodsProduce`,
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function getMaterialForGoodsProduce(payload) {
   return axios.get(
      constants.api.url + `api/GoodsProduce/GetMaterialForProduceBySOId/${payload}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}

export function updateStatusGoodsProduce(payload) {
   return axios.put(
      constants.api.url + `api/GoodsProduce/UpdateStatusGoodsProduce`,
      payload,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
   );
}
