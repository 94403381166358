const initialState = {
    loading: false,
    updateLoading: false,
    detail: {},
    message: '',
    updateDataStatus: null,
    updateAvatarLoading: false,
    updateAvatarStatus: null,
}

const employeeDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_EMPLOYEE_DETAIL_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'LOAD_EMPLOYEE_DETAIL_SUCCESS':
            return {
                ...state,
                detail: action.payload.data,
                loading: false,
                message: ''
            }
        case 'LOAD_EMPLOYEE_DETAIL_FAILED':
            return{
                ...state,
                loading: true,
                detail: {},
                message: action.payload
            }

        case 'SHOW_UPDATE_EMPLOYEE_LOADING':
            return {
                ...state,
                updateLoading: true,
                updateDataStatus: null
            }

        case 'UPDATE_EMPLOYEE_SUCCESS':
            const { updateData } = action.payload
            /* console.log(action.payload);
            console.log(responseData);
            console.log(updateData); */
            return {
                ...state,
                updateLoading: false,
                message: '',
                detail: {
                    ...state.detail,
                    ...updateData.data
                },
                updateDataStatus: 'success',
            }

        case 'UPDATE_EMPLOYEE_FAILED':
            return {
                ...state,
                updateLoading: false,
                message: action.payload,
                updateDataStatus: 'failed'
            }

        case 'CLEAR_UPDATE_EMPLOYEE':
            return {
                ...state,
                updateDataStatus: null
            }

        case 'SHOW_UPDATE_AVATAR_EMPLOYEE_LOADING':
            return {
                ...state,
                updateAvatarLoading: true,
                updateAvatarStatus: null
            }

        case 'UPDATE_AVATAR_EMPLOYEE_SUCCESS':
            //console.log(action.payload);
            return {
                ...state,
                updateAvatarLoading: false,
                message: '',
                detail: {
                    ...state.detail,
                    ...action.payload.updateData
                },
                updateAvatarStatus: 'success',
            }

        case 'UPDATE_AVATAR_EMPLOYEE_FAILED':
            return {
                ...state,
                updateAvatarLoading: false,
                message: action.payload,
                updateAvatarStatus: 'failed'
            }

        case 'CLEAR_UPDATE_AVATAR_EMPLOYEE':
            return {
                ...state,
                updateAvatarStatus: null
            }
        default:
            return state
    }
}

export default employeeDetailReducer