import axios from 'axios'
import constants from '../reusable/constants'

export function createStockBalanceApi(payload) {
    return axios.post(
        constants.api.url + 'api/stockBalance',
        payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchStockBalancesApi(payload) {
    return axios.get(
        constants.api.url + 'api/stockBalance?warehouseId=' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}

export function fetchStockBalanceDetailApi(payload) {
    return axios.get(
        constants.api.url + 'api/stockBalance/' + payload,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
    );
}