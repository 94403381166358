const initialState = {
    isLoading: false,
    sumRevenueNetAmount: 0,
    sumRevenueDiscount: 0,
    countShift: 0,
    countFood: 0,
    countVoucher: 0,
    data: [],
}
  
const revenueShiftReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_LOADING_REVENUE_SHIFT':
            return {
                ...state,
                isLoading: true,
            }  
        case 'LOAD_REVENUE_SHIFT_SUCCESS':
            return {
                ...state,
                ...action.payload.data,
                isLoading: false,
            }  
        case 'LOAD_REVENUE_SHIFT_DETAIL_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
            }  
        default:
            return state;
    }
  };
  
  export default revenueShiftReducer;