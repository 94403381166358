const initialState = {
  attachedFile: null,
  description: '',
  selectedMaterialInstances: [],
  stockRequestId: '',
  transferredDate: '',
  loading: false,
  status: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CHANGE_TRANSFERRED_DATE_GOODS_TRANSFER':
      return {
        ...state,
        transferredDate: action.payload.transferredDate
      }

    case 'FETCH_STOCK_REQUEST_DETAIL_IN_GOODS_TRANSFER_SUCCESS':
      return {
        ...state,
        stockRequestId: action.payload.data.id
      }

    case 'CHANGE_DESCRIPTION_GOODS_TRANSFER':
      return {
        ...state,
        description: action.payload.description
      }

    case 'CHANGE_ATTACHED_FILE_GOODS_TRANSFER':
      return {
        ...state,
        attachedFile: action.payload.attachedFile
      }

    case 'CHANGE_CHECKBOX_GOODS_TRANSFER':
      return {
        ...state,
        selectedMaterialInstances: updateCheckboxArr(action.payload.receiveObj, state.selectedMaterialInstances)
      }
    
    case 'CHANGE_QUANTITY_GOODS_TRANSFER':
      return {
        ...state,
        selectedMaterialInstances: updateQuantityArr(action.payload.receiveObj, state.selectedMaterialInstances)
      }

    case 'SHOW_SUBMIT_GOODS_TRANSFER_LOADING':
      return {
        ...state,
        loading: true
      }
    
    case 'SUBMIT_GOODS_TRANSFER_SUCCESS':
      return {
        ...state,
        status: 'success',
        loading: false,
      }

    case 'SUBMIT_GOODS_TRANSFER_FAILED':
      return {
        ...state,
        status: 'failed',
        loading: false,
      }

    case 'CLEAR_SUBMIT_GOODS_TRANSFER':
      return {
        ...state,
        attachedFile: null,
        description: '',
        selectedMaterialInstances: [],
        stockRequestId: '',
        transferredDate: '',
        status: null,
      }

    case 'CLEAR_STOCK_REQUEST_DETAIL_QUANTITY':
      return {
        ...state,
        selectedMaterialInstances: [],
      }

    case 'FETCH_GOODS_TRANSFER_BY_ID_SUCCESS':
      const {data} = action.payload
      //console.log(data);
      return {
        ...state,
        transferredDate: changeFormatDate(data.prettyTransferredDate),
        description: data.description,
        selectedMaterialInstances: changeReceiveMaterialInstances(data.details)
      }

    default:
      return state
  }
};

export default reducer;

const updateCheckboxArr = (receiveObj, arr = []) => {
  if(Array.isArray(arr)){
    let newArr = [...arr]
    const {id} = receiveObj
    const index = newArr.findIndex(item => item.id === id)
    if(index !== -1){
      //nếu có trong mảng rồi thì toggle cái checked, 
      //còn chưa có thì thêm vào cộng với checked: true
      const isChecked = newArr[index].checked
      //console.log(isChecked);
      if(isChecked === true){
        //chỉnh số lượng = 0 khi bỏ tích
        newArr[index].checked = false
        newArr[index].baseQuantity = 0
        newArr[index].quantity = 0
      }
      else{
        newArr[index].checked = true
      }
    }
    else{
      newArr = [...newArr, {...receiveObj, checked: true}]
    }
    return newArr
  }
  return []
}

const updateQuantityArr = (receiveObj, arr = []) => {
  if(Array.isArray(arr)){
    let newArr = [...arr]
    const {id} = receiveObj
    const index = newArr.findIndex(item => item.id === id)
    if(index !== -1){
      //baseQuantity và quantity chỉ được chọn 1 trong 2,
      //nếu cái kia có giá trị, cái còn lại phải bằng 0
      const {baseQuantity,  quantity} = receiveObj
      if(baseQuantity || baseQuantity === 0){
        newArr[index].quantity = 0
        newArr[index].baseQuantity = parseFloat(baseQuantity)
        newArr[index].checked = parseFloat(baseQuantity) !== 0 ? true : false
      }
      else if(quantity || quantity === 0){
        newArr[index].baseQuantity = 0
        newArr[index].quantity = parseFloat(quantity)
        newArr[index].checked = parseFloat(quantity) !== 0 ? true : false
      }
    }
    else{
      const {baseQuantity,  quantity} = receiveObj
      receiveObj.baseQuantity = baseQuantity ? parseFloat(baseQuantity) : 0
      receiveObj.quantity = quantity ? parseFloat(quantity) : 0
      newArr.push({...receiveObj, checked: true})
    }

    return newArr
  }
  return []
}

const changeFormatDate = dateString => {
  //hiện tại dd/mm/yyyy --> chuyển thành mm/dd/yyyy để có thể so sánh và sắp xếp
  if(dateString){
      const twoDateLetter = dateString.slice(0, 2);
      const twoMonthLetter = dateString.slice(3, 5);
      const fourYearLetter = dateString.slice(6);
      return `${twoMonthLetter}/${twoDateLetter}/${fourYearLetter}`;
  }
  return null
}

/* {
  "id": 3206,
  "baseQuantity": 10,
  "warehouseId": 2,
  "quantity": 0,
  "checked": true
} */
const changeReceiveMaterialInstances = (arr = []) =>{
  const newArr = []
  if(Array.isArray(arr) && arr.length > 0){
    arr.forEach(item => {
      newArr.push({
        id: item.materialInstanceId,
        warehouseId: item.stockWarehouseId,
        baseQuantity: item.baseQuantity,
        quantity: item.quantity,
        checked: true
      })
    })
  }
  return newArr
}