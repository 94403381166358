const initialState = {
    loading: false,
    SRDeliver: [],
    totalPage: 0,
    submitNewSRDeliverStatus: 0,
    updateSRDeliverStatus: 0,
    approveSRDeliverStatus: 0,
    SRDeliverDetais: null,
    listDriver: [],
    drivers: [],
    submitNewDrivers: 0,
    updateDrivers: 0,
    driverDetail: null,
}

const SRDeliverReducer = (state = initialState, action) => {  
    switch (action.type) {  
        case 'FETCH_DRIVER_BY_ID_SUCCESSED':
            return {
                ...state,
                loading: false,
                driverDetail: action.payload,
            }
        case 'SUBMIT_UPDATE_DRIVERS_FAILED':
            return {
                ...state,
                loading: false,
                updateDrivers: 2,
            }
        case 'SUBMIT_UPDATE_DRIVERS_SUCCESSED':
            return {
                ...state,
                loading: false,
                updateDrivers: 1,
            }
        case 'SUBMIT_NEW_DRIVERS_FAILED':
            return {
                ...state,
                loading: false,
                submitNewDrivers: 2,
            }
        case 'SUBMIT_NEW_DRIVERS_SUCCESSED':
            return {
                ...state,
                loading: false,
                submitNewDrivers: 1,
            }
        case 'FETCH_LIST_DRIVERS_SUCCESSED':
            return {
                ...state,
                loading: false,
                drivers: action.payload.data,
                totalPage: action.payload.totalCount,
            }
        case 'FETCH_LIST_DRIVER_BY_PLATE_SUCCESSED':
            return {
                ...state,
                loading: false,
                listDriver: action.payload,
            }
        case 'FETCH_SR_DELIVER_BY_ID_SUCCESSED':
            return {
                ...state,
                loading: false,
                SRDeliverDetais: action.payload,
            }
        case 'UPDATE_SR_DELIVER_FAILED':
            return {
                ...state,
                loading: false,
                updateSRDeliverStatus: 2,
            }
        case 'UPDATE_SR_DELIVER_SUCCESSED':
            return {
                ...state,
                loading: false,
                updateSRDeliverStatus: 1,
            }
        case 'SUBMIT_NEW_SR_DELIVER_FAILED':
            return {
                ...state,
                loading: false,
                submitNewSRDeliverStatus: 2,
            }
        case 'SUBMIT_NEW_SR_DELIVER_SUCCESSED':
            return {
                ...state,
                loading: false,
                submitNewSRDeliverStatus: 1,
            }
        case 'FETCH_SR_DELIVER_SUCCESSED':
            return {
                ...state,
                loading: false,
                SRDeliver: action.payload?.data,
                totalPage: action.payload?.totalCount,
            }
        case "SET_DEFAULT_STATUS_EDIT":
            return {
                ...state,
                submitNewSRDeliverStatus: 0,
                updateSRDeliverStatus: 0,
                approveSRDeliverStatus: 0,
                submitNewDrivers: 0,
                updateDrivers: 0,
                loading: false
            };   
        case 'SHOW_SR_DELIVER_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'DEFAULT_DATA_SR_DELIVER':
            return initialState;

        default:
            return state
    }
}

export default SRDeliverReducer;
