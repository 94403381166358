import axios from "axios";
import constants from "../reusable/constants";

export function fetchWarehouse(payload) {
  return axios.get(constants.api.url + "api/warehouse?uId=" + payload, {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}
export function fetchWarehousePagination(payload) {
  const { page, pageSize, status, name, code, level } = payload;
  let attributes = [
    { text: "page", value: page },
    { text: "pageSize", value: pageSize },
    { text: "status", value: status },
    { text: "name", value: name },
    { text: "code", value: code },
    { text: "level", value: level },
  ];
  let newAttributes = attributes.filter(
    (item) => item.value !== null && item.value !== undefined
  );
  let urlAtrribute = "";
  newAttributes.forEach((item, index) => {
    urlAtrribute += item.text + "=" + item.value;
    if (index !== attributes.length - 1) {
      urlAtrribute += "&";
    }
  });
  return axios.get(
    constants.api.url +
      `api/Warehouse/GetAllWarehouses?${urlAtrribute}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}
export function updateWarehouse(payload){
  return axios.put(
    constants.api.url +'api/Warehouse/UpdateWarehouse',
    {
      name: payload.name,
      address: payload.address,
      telephone: payload.telephone,
      uId: payload.uId,
      id: payload.id,
    },
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
    }
  )
}
export function fetchAllWarehouse() {
  return axios.get(constants.api.url + "api/warehouse", {
    headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
  });
}

export function createWarehouseApi(payload) {
  return axios.post(
    constants.api.url + "api/Warehouse/CreateWarehouse",
    payload,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    }
  );
}