const initialState = {
    loading: false,
    stores: [],
    message: ''
}

const storeUIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_STORE_UID_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'FETCH_STORE_UID_SUCCEEDED':
            return {
                ...state,
                stores: action.payload,
                loading: false,
                message: ''
            }
        case 'FETCH_STORE_UID_FAILED':
            return {
                ...state,
                stores: [],
                loading: false,
                message: action.payload
            }
        default:
            return state
    }
}

export default storeUIdReducer