const initialState = {
  loading: false,
  status: null,
  message: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST_LOADING':
      return {
        ...state,
        loading: true
      }

    case 'SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST_SUCCESS':
      return {
        ...state,
        status: 'success',
        loading: false,
        message: action.payload.data.message,
      }

    case 'SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST_FAILED':
      return {
        ...state,
        status: 'failed',
        loading: false,
      }

    case 'CLEAR_SUBMIT_GOODS_TRANSFER_APPROVE_REQUEST_STATUS':
      return {
        ...state,
        status: null,
        message: '',
      }

    default:
      return state
  }
};

export default reducer;
