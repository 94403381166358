const initialState = {
    loading: false,
    salesList: '',
    fromDate: '',
    toDate: '',
    storeId: '',
    serviceModeId: '',
    statusId: '',
    phoneNumber: '',
    paymentType: '',
    transactionId: '',
    formValidationFailed: false,
    showModal: false,
    selectedBillId: '',
    submitBillStatus: '',
    filterDataStatus: '',
    detailDataStatus: '',
    saleDetail: '',
    orderDetail: undefined,
    setOrderStatus: undefined,
}

const salesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ORDER_DETAIL_SUCCESS':
            return {
                ...state,
                orderDetail: action.payload,
                loading: false,
            };

        case 'GET_ORDER_DETAIL_FAILED':
            return {
                ...state,
                loading: false,
            };

        case 'CLEAR_ORDERS_DATA':
            return {
                ...state,
                salesList: [],
                fromDate: '',
                toDate: '',
                orderDetail: undefined,
                setOrderStatus: undefined,
            };

        case 'FETCH_ORDERS_SUCCESS':
            return {
                ...state,
                loading: false,
                salesList: action.payload,
                filterDataStatus: 1,
            }

        case 'FETCH_ORDERS_FAILED':
            return {
                ...state,
                loading: false,
                salesList: action.payload,
                filterDataStatus: 1,
            }





        case 'CLEAR_SALES_DATA':
            return {
                ...state,
                salesList: [],
                fromDate: '',
                toDate: '',
            };

        case 'APPROVE_BILL_SUCCESS':
            return {
                ...state,
                submitBillStatus: 1,
            };

        case 'APPROVE_BILL_FAILED':
            return {
                ...state,
                submitBillStatus: 2,
            };

        case 'CANCEL_BILL_SUCCESS':
            return {
                ...state,
                submitBillStatus: 3,
            };

        case 'CANCEL_BILL_FAILED':
            return {
                ...state,
                submitBillStatus: 4,
            };

        case 'HIDE_SALES_MODAL':
            return {
                ...state,
                showModal: false,
                selectedBillId: '',
            };
            
        case 'SHOW_SALES_MODAL':
            return {
                ...state,
                showModal: true,
                selectedBillId: action.payload,
            };

        case 'FETCH_SALES_REPORT_SUCCESS':
            return {
                ...state,
                loading: false,
                salesList: action.payload,
                filterDataStatus: 1,
            }
        case 'FETCH_SALES_FILTER_REPORT_SUCCESS':
            return {
                ...state,
                loading: false,
                salesList: action.payload,
            }
        case 'FETCH_SALE_DETAIL_SUCCEEDED':
            return {
                ...state,
                loading: false,
                saleDetail: action.payload,
            }
        case 'SHOW_SALES_LOADING':
            return {
                ...state,
                loading: true,
            }

        case 'UPDATE_SALES_FORM_VALIDATION':
            return {
                ...state,
                formValidationFailed: action.payload,                
            }

        case 'UPDATE_FROM_DATE':
            return {
                ...state,
                fromDate: action.payload,
            }

        case 'UPDATE_TO_DATE':
            return {
                ...state,
                toDate: action.payload,
            }

        case 'UPDATE_STORE':
            return {
                ...state,
                storeId: action.payload,
            }

        case 'UPDATE_SERVICE_MODE':
            return {
                ...state,
                serviceModeId: action.payload,
            }

        case 'UPDATE_STATUS':
            return {
                ...state,
                statusId: action.payload,
            }

        case 'UPDATE_PAYMENT_TYPE':
            return {
                ...state,
                paymentType: action.payload,
            }
        
        case 'UPDATE_TRANSACTION_ID':
            return {
                ...state,
                transactionId: action.payload,
            }
         case 'SET_ORDER_STATUS_SUCCEEDED':
            return {
                ...state,
               loading: false,
                setOrderStatus: 1,
            }
         case 'SET_ORDER_STATUS_FAILED':
            return {
               ...state,
               loading: false,
                setOrderStatus: 2,
            }
        default:
            return state
    }
}

export default salesReducer