const initialState = {
  loading: false,
  sites: [],
  totalPage: 0,

  modal_id: null,
  modal_code: null,
  modal_name: null,

  updateStatus:-1,
};

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MODAL_CREATE_NEW_SITE_ID": {
      return { ...state, modal_id: action.payload };
    }
    case "SET_MODAL_CREATE_NEW_SITE_CODE": {
      return { ...state, modal_code: action.payload };
    }
    case "SET_MODAL_CREATE_NEW_SITE_NAME": {
      return { ...state, modal_name: action.payload };
    }
    case "SET_MODAL_UPDATE_SITE": {
      return {
        ...state,

        modal_code: action.payload.modal_code,
        modal_name: action.payload.modal_name,
        modal_id: action.payload.modal_id,
      };
    }
    case "CLEAR_MODAL_CREATE_NEW_SITE": {
      return {
        ...state,
        modal_code: null,
        modal_name: null,
        modal_id: null,
      };
    }
    case "SHOW_SITE_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_SITE_SUCCESS":
      return {
        ...state,
        loading: false,
        updateStatus: 1,
      };
      case "UPDATE_SITE_FAILED":
      return {
        ...state,
        loading: false,
        updateStatus: 2,
      };
    case "FETCH_SITE_SUCCESS":
      return {
        ...state,
        sites: action.payload,
        loading: false,
      };
    case "FETCH_SITE_PAGINATION_SUCCESS":
      return {
        ...state,
        sites: action.payload.data,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    default:
      return state;
  }
};

export default siteReducer;
