const initialState = {
    updateUserGroupAppLoading: false,
    updateUserGroupAppStatus: null,
    updateUserGroupAppResponse: null,
}

const webGroupUpdateUserGroupAppReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_UPDATE_USER_GROUP_APP_LOADING':
            return {
                ...state,
                updateUserGroupAppLoading: true
            }

        case 'UPDATE_USER_GROUP_APP_SUCCESS':
            return {
                ...state,
                updateUserGroupAppLoading: false,
                updateUserGroupAppStatus: 'success',
                updateUserGroupAppResponse: action.payload,
            }

        case 'UPDATE_USER_GROUP_APP_FAILED':
            return {
                ...state,
                updateUserGroupAppLoading: false,
                updateUserGroupAppStatus: 'failed',
                updateUserGroupAppResponse: action.payload,
            }
        
        case 'CLEAR_UPDATE_USER_GROUP_APP':
            return {
                ...state,
                updateUserGroupAppStatus: null,
            }

        default:
            return state
    }
}

export default webGroupUpdateUserGroupAppReducer