const initialState = {
  orderStatusList: [],
  loading: false,
};

const orderStatusListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_LIST_STATUS_PURCHASE_ORDER_LOADING":
      return {
        ...state,
        loading: true,
      }

    case "FETCH_LIST_STATUS_PURCHASE_ORDER_SUCCEEDED":
      return {
        ...state,
        orderStatusList: action.payload.data,
        loading: false,
      }

    case "FETCH_LIST_STATUS_PURCHASE_ORDER_FAILED":
      return {
        ...state,
        loading: false,
      }

    default:
      return state;
  }
};

export default orderStatusListReducer;
