const initialState = {
    loading: false,
    SRReceived: [],
    totalPage: 0,
    submitNewSRReceivedStatus: 0,
    updateSRReceivedStatus: 0,
    approveSRReceivedStatus: 0,
    SRReceivedDetais: null,
}

const SRReceivedReducer = (state = initialState, action) => {  
    switch (action.type) {
        
        case 'FETCH_SR_RECEIVED_BY_ID_SUCCESSED':
            return {
                ...state,
                SRReceivedDetais: action.payload,
                loading: false,
             };
        case 'SUBMIT_NEW_SR_RECEIVED_SUCCESSED':
            return {
                ...state,
                loading: false,
                submitNewSRReceivedStatus: 1,
            }
        case 'SUBMIT_NEW_SR_RECEIVED_FAILED':
            return {
                ...state,
                loading: false,
                submitNewSRReceivedStatus: 2,
            }
        case "SET_DEFAULT_STATUS_EDIT_SR_RECEIVED":
            return {
                ...state,
                submitNewSRReceivedStatus: 0,
                updateSRReceivedStatus: 0,
                approveSRReceivedStatus: 0,
                loading: false
            };   
        case 'FETCH_SR_RECEIVED_SUCCESSED':
            return {
                ...state,
                loading: false,
                SRReceived: action.payload?.data,
                totalPage: action.payload?.totalCount,
            }
        case 'SHOW_SR_RECEIVED_LOADING':
            return {
                ...state,
                loading: true,
            };
        case 'DEFAULT_DATA_SR_PRODUCE':
            return initialState;

        default:
            return state;
    }
}

export default SRReceivedReducer;