import React, { useEffect, useState } from "react";
import {
  DEFAULT_FONT_BODY,
  DEFAULT_FONT_HEADER,
  DEFAULT_FONT_HEADER_COLOR,
  Detail,
  HeaderTitle,
  RowItem,
  TableBody,
  TableContainer,
  TableHeader,
  View,
  ViewItem,
} from "./DataTable.Style";
import { CustomeText, isUseCorrectClassName } from "src/components/CustomeText";
import svg from "src/assets/icon/index";
import { SortData } from "./DataTable.Function";
const DataTableCustome = ({
  DataRender,
  Column,
  scopedColumns,
  DefaultHeaderBackgroundColor,
  DefaultBodyBackgroundColor,
  DefaultHeaderFont,
  DefaultHeaderFontColor,
  DefaultBodyFont,
  OnChange,
}) => {
  if (scopedColumns) {
    var { show_detail, content_detail } = scopedColumns;
  }
  const checkShowDetail = () => {
    let isExisted = Column.find((item) => item.key === "show_detail");
    if (isExisted) {
      return true;
    }
    return false;
  };
  const [sortActive, setSortActive] = useState(null);
  return (
    <TableContainer>
      <TableHeader DefaultHeaderBackgroundColor={DefaultHeaderBackgroundColor}>
        {Column.map((item, index) => {
          return (
            <HeaderTitle
              numCols={Column.length}
              style={item._style}
              {...item._props}
              onClick={() => {
                OnChange(item);
                if (item.sort) {
                  setSortActive(item.key);
                  if(sortActive === item.key){
                    setSortActive(null);
                  }
                }
              }}
              sortActive={
                item.sort && sortActive === item.key ? true : false
              }
            >
              <CustomeText
                // className={DefaultHeaderFont || DEFAULT_FONT_HEADER}
                className={
                  DefaultHeaderFont
                    ? isUseCorrectClassName(
                        Column.find((i) => i.key === item.key)?._props
                          ?.className
                      )
                      ? Column.find((i) => i.key === item.key)?._props
                          ?.className
                      : DefaultHeaderFont
                    : DEFAULT_FONT_HEADER
                }
                color={
                  Column.find((i) => i.key === item.key)?._style?.color
                    ? Column.find((i) => i.key === item.key)?._style?.color
                    : DefaultHeaderFontColor || DEFAULT_FONT_HEADER_COLOR
                }
              >
                {item.title}
              </CustomeText>
              {
                item.sort && <svg.IconSort />
              }
            </HeaderTitle>
          );
        })}
      </TableHeader>
      <TableBody DefaultBodyBackgroundColor={DefaultBodyBackgroundColor}>
        {DataRender.map((item, index) => {
          return (
            <RowItem>
              <View>
                {Column.map((col, index2) => {
                  return (
                    <ViewItem
                      key={index2}
                      numCols={Column.length}
                      width={
                        Column.find((i) => i.key === col.key)?._style?.width ||
                        null
                      }
                      style={
                        Column.find((i) => i.key === col.key)?._colStyle || {}
                      }
                    >
                      <CustomeText
                        className={DefaultBodyFont || DEFAULT_FONT_BODY}
                      >
                        {item[col.key]}
                      </CustomeText>
                      {col.key === "show_detail" && show_detail !== undefined
                        ? show_detail(item)
                        : null}
                    </ViewItem>
                  );
                })}
              </View>
              {checkShowDetail() &&
                content_detail !== undefined &&
                content_detail(item)}
            </RowItem>
          );
        })}
      </TableBody>
    </TableContainer>
  );
};

export default DataTableCustome;
